import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
//import "@progress/kendo-theme-default/dist/all.css";
import {
    Link,
    useNavigate,
    NavLink,
    Routes, // instead of "Switch"
    Route,
} from "react-router-dom"; // Import useNavigate
import { getResponse } from "../../Services/Common/Get";
import { postResponse } from "../../Services/Common/Post";
import { putResponse } from "../../Services/Common/Put";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useNodeContext } from "../../Contexts/NodeProvider";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { LeftNavTypes } from "../../enums/LeftNavTypes";
import { toast, Slide } from "react-toastify";

/*UI*/
import logoSm from "../../assets/default/images/ipd/ipd-sm.png";
import logoDark from "../../assets/default/images/ipd/ipd.png";
import logoLight from "../../assets/default/images/ipd/ipd.png";
import SubNodePage from "./SubNodePage";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../styles/LeftNavigationBar.scss";
import { Tooltip } from "react-tooltip";
import { useTrigerSaveQuoteContext } from "../../Contexts/TrigerSaveQuoteContext";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";

const LeftNavigationBar = ({ onSubnodeClick }) => {
    const { id } = useParams();
    const { listOfUnitIdWithNames, setListOfUnitIdWithNames, listOfUnitNames, setListOfUnitNames, isUnitsLoad, setIsUnitsLoad, isCreatedUnit, setIsCreatedUnit, unitId,
        setUnitId, unitDetails, updateUnitDetailsForId, setUnitName, setCreatedName, selectedUnitData, setSelectedUnitData, unitSelected, setUnitSelected,
        selectedSubUnitIndex, setSelectedSubUnitIndex, selectedUnitIndex, setSelectedUnitIndex, selectedUnitName, setSelectedUnitName, triggerUnitSaving, setTriggerUnitSaving } = useUniteDetails();
    const [hidden, setHidden] = useState(false);
    const [workspaceExpanded, setWorkspaceExpanded] = useState(true);
    const [isQuotesExpanded, setIsQuotesExpanded] = useState(true);
    const [isSubLibExpanded, setIsSubLibExpanded] = useState(true);
    const [selectedId, setSelectedId] = useState(0);
    const [libraryNodes, setLibraryNodes] = useState([]);
    const [libraryUnits, setLibraryUnits] = useState([]);
    const [libraryChild, setLibraryChild] = useState([]);
    const [editingLibraryNodeIndex, setEditingLibraryNodeIndex] = useState(-1);
    const [editingUnitIndex, setEditingUnitIndex] = useState(-1);
    const [editingUnitSubIndex, setEditingUnitSubIndex] = useState(-1);
    const [editedLibraryName, setEditedLibraryName] = useState("");
    const [editedUnitName, setEditedUnitName] = useState("");
    const [editedUnitInfo, setEditedUnitInfo] = useState([]);
    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
    const navigate = useNavigate(); // Initialize useNavigate
    const { isSaveClicked, setIsSaveClicked, isSaved, setIsSaved, isdraftToOriginal, setIsdraftToOriginal ,isSavedUpdated, setIsSavedUpdated } = useTrigerSaveQuoteContext();
    const [closedIndex, setClosedIndex] = useState();
    const [closedCurrentNodeIndex, setClosedCurrentNodeIndex] = useState();
    const [isLoad, setIsLoad] = useState(true);
    const { quoteDetails, updateQuoteDetailsForId,quoteCreatedDate, setQuoteCreatedDate } = useQuoteDetails();
    const [hiddenOpen, setHiddenOpen] = useState(false);//unit id selection
    const [selectedUnitId, seSelectedUnitId] = useState(false);//unit id selection
    const [cickAddUnit, setCickAddUnit] = useState(false);

    const { 
         quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        currentApprover, setCurrentApprover,
        quoteOwner, setQuoteOwner,
        approverName, setApproverName,
        shareQuoteFlag, setShareQuoteFlag,
        currAccessLevel, setCurrAccessLevel
        } = useContext(QteApprovalNotificationContext);

    const {
        quotes,
        setQuotes,
        maxQuoteIndex,
        setMaxQuoteIndex,
        handleAddNode,
        handleCloseNode,
        handleDeleteNode,

        newUnits,
        setNewUnits,
        maxNewUnitIndex,
        setMaxNewUnitIndex,
        handleUnitAddNode,
        handleUnitDeleteNode,
        handleDeleteOpenUnit,

        priceListNodes,
        setPriceListNodes,
        maxPriceListIndex,
        setMaxPriceListIndex,
        handlePriceListAddNodes,
        handlePriceListDeleteNode,
        currentDraftQuoteId
    } = useNodeContext();

    useEffect(() => { }, [isSaveClicked]);

    //thised used for manage open units when selecting unit
    useEffect(() => {
        if (cickAddUnit) {
            setHiddenOpen(true);
        }
    }, [cickAddUnit, hiddenOpen]);

    // useEffect(() => {
    //     setQuotes(quotes);

    // }, []);

    useEffect(() => {
       //const draftClosedInndex = closedIndex + 1;
        if (isSaved) {
            var qId = quotes[closedIndex]?.nodeId;
            var currentQId = closedCurrentNodeIndex;
            if (qId !== closedCurrentNodeIndex) {
                currentQId = qId;
            }
            handleDeleteNode(quotes, setQuotes, closedIndex, maxQuoteIndex, setMaxQuoteIndex, currentQId, false);
            setIsSaveClicked(false);
            setIsSaved(false);
        }
    }, [isSaved]);

    useEffect(() => {
        const fetchData = async () => {
            const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            try {
                const data = await getResponse("Library/loaddefaultlibrary", { userId: userId , clientTimezone: clientTimezone});
                setLibraryNodes(data);
            } catch (error) {
                console.error("Error:", error);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {

        const fetchDataUnits = async () => {
            if (isUnitsLoad || isLoad) {
                try {
                    const data = await getResponse("Library/getalluserlibrarieswithunits", { userId: userId });
                    setLibraryUnits(data);
                    //get all unit names
                    const unitNamesArray = data.map(item => item.unitName);
                    setListOfUnitNames(unitNamesArray);
                    //get unitid and unitname (update method - check unitname except previous unitname)
                    const unitInfoArray = data.map(item => ({ unitId: item.unitId, unitName: item.unitName }));
                    setListOfUnitIdWithNames(unitInfoArray);

                    setIsUnitsLoad(false);
                    setIsLoad(false);
                } catch (error) {
                    console.error("Error:", error);
                }
            };
        }
        fetchDataUnits();

    }, [libraryUnits, isUnitsLoad, isLoad, listOfUnitNames, listOfUnitIdWithNames]);  //libraryUnits

    const [initialOrder, setInitialOrder] = useState([]);

    useEffect(() => {
        // Initialize initial order on component mount
        if (quotes.length > 0) {
                setInitialOrder(quotes);
          
        }
    }, [quotes, initialOrder]);

    const addNewQuote = (quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, type) => {
        handleAddNode(quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, type);
        // Maintain the order of existing quotes
        const updatedQuotes = [...quotes];
        setQuotes(updatedQuotes);
    };

    const handleLibraryAddNode = (nodes, setNodes, maxIndex, setMaxIndex, titlePrefix, userId) => {
        const newNodeTitle = `New ${titlePrefix} ${maxIndex + 1}`;
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const libname = {
            userId: userId,
            libraryName: newNodeTitle,
            clientTimezone: clientTimezone

        };

      

        postResponse("Library/adduserlibrary", libname , )
            .then((res) => {
                if (res) {
                    setNodes([...nodes, res.data]);
                    toast.success("The Library has been Created Successfully", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                } else {
                    toast.error("Failed to insert data.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }
            })
            .catch((error) => {
                console.error("Failed to insert data:", error);
            });

        setMaxIndex(maxIndex + 1);
    };

    const handleDeleteLibraryNode = (nodes, setNodes, nodeIndex, libraryId) => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        postResponse(`Library/deletelibrary?UserId=${userId}&UserLibId=${libraryId}&clientTimezone=${clientTimezone}`)
            .then((res) => {
                if (res) {
                    setNodes(nodes.filter((_, index) => index !== nodeIndex));

                    if (nodes.length > 0) {
                        navigate(`/library/${nodes[0]?.userLibId}`, { replace: true });
                    } else {
                        navigate(`/`, { replace: true });
                    }

                    toast.success("The Library has been Deleted Successfully", {
                        position: "top-center",
                        transition: Slide,
                    });
                } else {
                    toast.error("Failed to delete the library.", {
                        position: "top-center",
                        transition: Slide,
                    });
                }
            })
            .catch((error) => {
                toast.error("Connection error. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });
                console.error("Failed to delete data:", error);
            });
    };
    const handleHamburgerClick = () => {
        setHidden(!hidden);
    };

    const handleWorkspaceSelect = () => {
        setWorkspaceExpanded(workspaceExpanded);
    };

    const handleDoubleClick = () => {
        setHidden(!hidden);
    };

    const handleLibraryNameChange = (e) => {
        setEditedLibraryName(e.target.value);
    };
    const handleUnitNameChange = (e, index, unitIndex) => {
        const newValue = e.target.value;
        setEditedUnitName(newValue);
    };

    const handleEditLibraryNode = (libraryNodeIndex, e, valueToChange) => {
        if (editingLibraryNodeIndex !== libraryNodeIndex) {
            setEditingLibraryNodeIndex(libraryNodeIndex);
            setEditedLibraryName(valueToChange);
        } else {
            setEditingLibraryNodeIndex(-1);
        }
    };

    const handleCancelEditMode = (libraryNodeIndex, e) => {
        handleEditLibraryNode(libraryNodeIndex, e, "");
    };

    const handleSaveLibraryNode = (libraryNodeIndex) => {

        if (libraryNodes && libraryNodes.length > 0) {

            const isLibraryNameExist = libraryNodes.some((lib) => lib.libraryName === editedLibraryName);
            if (isLibraryNameExist) {
                toast.error("Library name already exists.", {
                    position: "top-center",
                    transition: Slide,
                });
                setEditingLibraryNodeIndex(-1);
                return;
            }
        }

        const selectedLibrary = libraryNodes[libraryNodeIndex];
        const updatedLib = { ...selectedLibrary, libraryName: editedLibraryName };
        libraryNodes[libraryNodeIndex] = updatedLib;

        if (selectedLibrary) {
            if (
                updatedLib?.libraryName == "" ||
                updatedLib?.libraryName == undefined ||
                updatedLib?.libraryName == null
            ) {
                toast.error("Library name is not set.", {
                    position: "top-center",
                    transition: Slide,
                });
            } else {
                setEditingLibraryNodeIndex(-1);
                putResponse("Library/updateuserlibrary", updatedLib)
                    .then((res) => {
                        if (res.statusCode) {
                            setLibraryNodes(libraryNodes);
                            setEditedLibraryName("");
                            // setEditingLibraryNodeIndex(-1);
                            toast.success("The Library Name has been Updated", {
                                position: "top-center",
                                transition: Slide,
                            });
                        } else {
                            toast.error("Something went wrong. Please try again later.", {
                                position: "top-center",
                                transition: Slide,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error("Failed to update data:", error);
                    });
            }
        } else {
            console.error("Invalid library data");
        }
    };

    const handlePanelBarItemClick = (route) => {
        navigate(route);
    };

    const handleDeleteNodePopup = (
        nodes,
        setNodes,
        nodeIndex,
        maxIndex,
        setMaxIndex,
        currentNodeIndex,
        type,
        quoteName = null
    ) => {
        let isNotSaved = true;
    
        if (quoteName !== null) {
            const qNameAsList = quoteName?.split(" ");
            isNotSaved = qNameAsList.includes("New");
        }
    
        const message = (quoteDetails[id]?.savedQuote?.createdBy !== userId ||
            quoteDetails[id]?.savedQuote?.approvalStatusId === 2 || 
            quoteDetails[id]?.savedQuote?.approvalStatusId === 4 || 
            quoteDetails[id]?.savedQuote?.approvalStatusId === 5)
            ? "Do you want to close this?"
            : "Do you want to save changes you made to quote " + quoteName + " ?";
    
        confirmAlert({
            title: "Close Confirmation",
            message: message,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        if (quoteDetails[id]?.savedQuote?.createdBy !== userId ||
                            quoteDetails[id]?.savedQuote?.approvalStatusId === 2 ||
                            quoteDetails[id]?.savedQuote?.approvalStatusId === 4 || 
                            quoteDetails[id]?.savedQuote?.approvalStatusId === 5) {
                            // Perform the action for closing without saving changes
                            if (type === LeftNavTypes.QUOTES) {
                                handleDeleteNode(
                                    nodes,
                                    setNodes,
                                    nodeIndex,
                                    maxIndex,
                                    setMaxIndex,
                                    currentNodeIndex,
                                    isNotSaved
                                );
                            } else if (type === LeftNavTypes.NEW_UNITS) {
                                handleUnitDeleteNode(
                                    nodes,
                                    setNodes,
                                    nodeIndex,
                                    maxIndex,
                                    setMaxIndex,
                                    currentNodeIndex,
                                    isNotSaved
                                );
                            } else if (type === LeftNavTypes.PRICE_LISTS) {
                                handlePriceListDeleteNode(
                                    nodes,
                                    setNodes,
                                    nodeIndex,
                                    maxIndex,
                                    setMaxIndex,
                                    currentNodeIndex
                                );
                            }
                            return;
                        }
    
                        if (quoteDetails[id]?.selectedItems?.length <= 1) {
                            toast.error(
                                "Sorry! Cannot save without a customer and items. Please add a customer and items to the quote and try again.",
                                {
                                    position: "top-center",
                                    transition: Slide,
                                }
                            );
                            return;
                        } else {
                            setClosedIndex(nodeIndex);
                            setClosedCurrentNodeIndex(currentNodeIndex);
    
                            if (currentApprover.length > 0 && (quoteApprovalStatId === 3)) {
                                if (quoteDetails[id]?.savedQuote?.createdBy !== userId) return;
                                setIsSaveClicked(true);
                                setIsdraftToOriginal(true);
                                setIsSavedUpdated(true);
                            } else {
                                setIsSaveClicked(true);
                                setIsdraftToOriginal(true);
                                setIsSavedUpdated(true);
                            }
    
                            return;
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        if (quoteDetails[id]?.savedQuote?.createdBy !== userId ||
                            quoteDetails[id]?.savedQuote?.approvalStatusId === 4 || 
                            quoteDetails[id]?.savedQuote?.approvalStatusId === 5) {
                            // Do nothing, retain the quote
                            return;
                        }
    
                        if (type === LeftNavTypes.QUOTES) {
                            handleDeleteNode(
                                nodes,
                                setNodes,
                                nodeIndex,
                                maxIndex,
                                setMaxIndex,
                                currentNodeIndex,
                                isNotSaved
                            );
                        } else if (type === LeftNavTypes.NEW_UNITS) {
                            handleUnitDeleteNode(
                                nodes,
                                setNodes,
                                nodeIndex,
                                maxIndex,
                                setMaxIndex,
                                currentNodeIndex,
                                isNotSaved
                            );
                        } else if (type === LeftNavTypes.PRICE_LISTS) {
                            handlePriceListDeleteNode(
                                nodes,
                                setNodes,
                                nodeIndex,
                                maxIndex,
                                setMaxIndex,
                                currentNodeIndex
                            );
                        }
                    },
                },
            ],
        });
    };
    

    const handleDeletePriceListNodePopup = (
        nodes,
        setNodes,
        nodeIndex,
        maxIndex,
        setMaxIndex,
        currentNodeIndex,
        type,
        quoteName = null
    ) => {
        let isNotSaved = true;

        if (quoteName !== null) {
            const qNameAsList = quoteName?.split(" ");
            isNotSaved = qNameAsList.includes("New");
        }

        confirmAlert({
            title: "Close Confirmation",
            message: "Do you want to close the selected Price List?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        // // setClosedIndex(nodeIndex);
                        // // setClosedCurrentNodeIndex(currentNodeIndex);
                        // // // setIsSaveClicked(true);
                        // // // setIsdraftToOriginal(true);
                        // // return;

                        if (type === LeftNavTypes.PRICE_LISTS) {
                            handlePriceListDeleteNode(
                                nodes,
                                setNodes,
                                nodeIndex,
                                maxIndex,
                                setMaxIndex,
                                currentNodeIndex
                            );
                            return;
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        // // // if (type === LeftNavTypes.QUOTES) {
                        // // //     handleDeleteNode(
                        // // //         nodes,
                        // // //         setNodes,
                        // // //         nodeIndex,
                        // // //         maxIndex,
                        // // //         setMaxIndex,
                        // // //         currentNodeIndex,
                        // // //         isNotSaved
                        // // //     );
                        // // // } else if (type === LeftNavTypes.NEW_UNITS) {
                        // // //     handleUnitDeleteNode(nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex,isNotSaved);
                        // // //} else 
                        // // if (type === LeftNavTypes.PRICE_LISTS) {
                        // //     handlePriceListDeleteNode(
                        // //         nodes,
                        // //         setNodes,
                        // //         nodeIndex,
                        // //         maxIndex,
                        // //         setMaxIndex,
                        // //         currentNodeIndex
                        // //     );
                        // //     return;
                        // // }

                        setClosedIndex(nodeIndex);
                        setClosedCurrentNodeIndex(currentNodeIndex);
                        // setIsSaveClicked(true);
                        // setIsdraftToOriginal(true);
                        return;
                    },
                },
            ],
        });
    };





    const handleDeleteUnitNodePopup = (
        nodes,
        setNodes,
        nodeIndex,
        maxIndex,
        setMaxIndex,
        currentNodeIndex,
        type,
        unitName = null
    ) => {
        let isNotSaved = true;

        if (unitName !== null) {
            const qNameAsList = unitName?.split(" ");
            isNotSaved = qNameAsList.includes("New");
        }

        confirmAlert({
            title: "Close Confirmation",
            message: "Do you want to save changes you made to new unit " + unitName + " ?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        setClosedIndex(nodeIndex);
                        setClosedCurrentNodeIndex(currentNodeIndex);
                        setIsSaveClicked(true);
                        triggerSaveUnit();
                        return;
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        handleUnitDeleteNode(nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex, isNotSaved);
                        //  handleCloseNode(nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex,false);

                    },
                },
            ],
        });
    };
    const handleClosePopup = (nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex) => {
        confirmAlert({
            title: "Close Confirmation",
            message: "Are you sure you want to close this record?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleCloseNode(nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex),
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    const navBarClassName = hidden ? "left-nav-bar hidden-left-nav-bar" : "left-nav-bar";
    const hamburgerIconClassName = hidden ? "hamburger-icon" : "hamburger-icon hidden-hamburger-icon";
    const [maxLibraryNodeIndex, setMaxLibraryNodeIndex] = useState(libraryNodes.length);
    const [sohWH, setSohWH] = useState("");

    /*UI*/
    useEffect(() => {
        var verticalOverlay = document.getElementsByClassName("vertical-overlay");
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener("click", function () {
                document.body.classList.remove("vertical-sidebar-enable");
            });
        }
        getSysSetting();
    },[]);

    const getSysSetting = async () => {
        const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });
        if (data) {
            setSohWH(data[0].whCode);
        }
    };
    const loadUnitData = async (unitId) => {
        try {
            setCickAddUnit(false);
            // Fetch data for the selected unit
            // const unitData = await getResponse("NewUnits/getfilteredunit", { unitId }); /// this is commented due to no used


        } catch (error) {
            console.error('Error fetching unit data:', error);
        }
        finally {
            setCickAddUnit(true);
        }
    };
    const handleSelectUnit = async (unitId, unitIndex, index) => {
        try {
            if (selectedUnitIndex === unitIndex && selectedSubUnitIndex === index) {
                // If the unit is already selected, deselect it
                setSelectedUnitIndex(null);
                setSelectedSubUnitIndex(null);
                setSelectedUnitData(null);
                setSelectedUnitName(null);
                setUnitSelected(false);
            //    toast.info("Unit deselected");
            } else {
                // If the unit is not selected, select it
                setSelectedUnitIndex(unitIndex);
                setSelectedSubUnitIndex(index);
                // Fetch data for the selected unit
                const unitData = await getResponse("NewUnits/getfilteredunit", { unitId });
                // Process the unitData as needed         
                setSelectedUnitData(unitData.tblUnits);
                setSelectedUnitName(unitData.unitName);
                setUnitSelected(true);
                toast.success(`You have selected Library Unit '${unitData.unitName}'. You can merge it to your Quote by clicking on add Unit in the Quote you are selecting.`, {
                    position: "top-center",
                    transition: Slide,
                    autoClose: 10000,
                    closeOnClick: true,
                });
            }
        } catch (error) {
            console.error('Error fetching unit data:', error);
        }
    };
    
    // const handleSelectUnit = async (unitId, unitIndex, index) => {

    //     try {
    //         setSelectedUnitIndex(unitIndex);
    //         setSelectedSubUnitIndex(index);
    //         // Fetch data for the selected unit
    //         const unitData = await getResponse("NewUnits/getfilteredunit", { unitId });
    //         // Process the unitData as needed         
    //         setSelectedUnitData(unitData.tblUnits);
    //         setSelectedUnitName(unitData.unitName);
    //         setUnitSelected(true);
    //         toast.success(`You have selected Unit '${unitData.unitName}'`);

    //     } catch (error) {
    //         console.error('Error fetching unit data:', error);
    //     }

    // };
    const handleEditUnit = (index, unitIndex, e, unitName) => {
        e.preventDefault();
        e.stopPropagation();

        // Check if already in edit mode for the specific unit
        if (editingUnitIndex === index && editingUnitSubIndex === unitIndex) {
            setEditingUnitIndex(-1); // Exit edit mode
            setEditingUnitSubIndex(-1); // Reset sub-index
            setEditedUnitName("");
        } else {
            // Enter edit mode
            setEditingUnitIndex(index);
            setEditingUnitSubIndex(unitIndex);
            setEditedUnitName(unitName);
        }
    };
    const triggerSaveUnit = () => {
        setTriggerUnitSaving((triggerUnitSaving) => triggerUnitSaving + 1);
        setTimeout(setTriggerUnitSaving, 100, 0);
    };

    const handleSaveUnit = async (index, unitIndex, e, editedName) => {
        e.preventDefault();
        e.stopPropagation();

        // Validate if the edited name is empty or undefined
        if (!editedName) {
            toast.error("Unit name is not set.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }
        const trimmedUnitName = editedName.trim().toLowerCase();
        const selectedUnit = libraryUnits
            .filter((unit) => unit.userLibId === libraryNodes[index]?.userLibId)[unitIndex];

        const updatedUnit = { ...selectedUnit, unitName: editedName };

        const matchingUnit = listOfUnitIdWithNames.find(item => item.unitId === selectedUnit.unitId);
        const existingUnitName = matchingUnit ? matchingUnit.unitName.toLowerCase() : '';

        // Check if trimmedUnitName is in the listOfUnitNames and is not the same as existingUnitName
        if (listOfUnitNames.map(name => name.toLowerCase()).includes(trimmedUnitName.toLowerCase()) &&
            trimmedUnitName.toLowerCase() !== existingUnitName) {
            toast.error(" '" + editedName + "' cannot be updated. Please choose a different Unit name", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        try {
            const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // Update the unit using your API call
            const response = await putResponse(`NewUnits/updatenewunitname?UnitId=${selectedUnit.unitId}&UnitName=${editedName}&clientTimezone=${clientTimezone}`);

            if (response.statusCode) {
                const updatedLibraryUnits = libraryUnits.map((unit) =>
                    unit.unitId === selectedUnit.unitId ? updatedUnit : unit
                );
                setLibraryUnits(updatedLibraryUnits);
                setEditedUnitInfo((prevInfo) => {
                    const newInfo = [...prevInfo];
                    newInfo[index] = { ...newInfo[index], editedUnitName: editedName };
                    return newInfo;
                });
                setEditedUnitName(editedName);
                setUnitName(editedName)
                setCreatedName(editedName)
                setIsUnitsLoad(true);
                // setEditedUnitName("");

                toast.success("Unit Name has been Updated", {
                    position: "top-center",
                    transition: Slide,
                });
            } else {
                toast.error("Something went wrong. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });
            }
        } catch (error) {
            console.error("Failed to update unit data:", error);
        } finally {
            setEditingUnitIndex(-1);
            setEditingUnitSubIndex(-1);
        }
    };

    const handleCancelUnitEditMode = (index, e) => {
        e.preventDefault();
        e.stopPropagation();
        setEditingUnitIndex(-1);
        setEditingUnitSubIndex(-1);
        setEditedUnitName("");
    };


    const handleDeleteUnitNode = (nodes, setNodes, nodeIndex, unitId) => {
        putResponse(`NewUnits/deletenewunit?unitId=${unitId}`)
            .then((res) => {
                if (res) {
                    setNodes(nodes.filter((_, index) => index !== nodeIndex));

                    if (nodes.length > 0) {
                        navigate(`/new-units/${nodes[0]?.unitId}`, { replace: true });
                    } else {
                        navigate(`/`, { replace: true });
                    }

                    toast.success("The Unit has been Deleted Successfully", {
                        position: "top-center",
                        transition: Slide,
                    });
                } else {
                    toast.error("Failed to delete the Unit.", {
                        position: "top-center",
                        transition: Slide,
                    });
                }
            })
            .catch((error) => {
                toast.error("Connection error. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });
                console.error("Failed to delete data:", error);
            });
    };
    const handleDeleteUnit = async (unitIndex, unitId) => {
        confirmAlert({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this unit?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        if (!unitId) {
                            toast.error(
                                "The unit you are trying to delete is not saved. If you want to close the unit, use the close button instead.", {
                                position: "top-center",
                                transition: Slide,
                            });
                        } else {
                            try {
                                await putResponse(`NewUnits/deletenewunit?unitId=${unitId}`);
                                setNewUnits((prevUnits) =>
                                    prevUnits.filter((_, index) => index !== unitIndex)
                                );

                                // Find the previous unit index
                                const prevUnitIndex = unitIndex > 0 ? unitIndex - 1 : 0;

                                if (newUnits.length > 0) {
                                    // Navigate to the previous unit
                                    navigate(`/unit/${newUnits[prevUnitIndex].nodeId}`, {
                                        replace: true,
                                    });
                                } else {
                                    // If no units left, navigate to the home page
                                    navigate(`/`, { replace: true });
                                }

                                setIsUnitsLoad(true);
                                handleDeleteOpenUnit(newUnits, setNewUnits, unitId);
                                toast.success("The Unit has been Deleted Successfully", {
                                    position: "top-center",
                                    transition: Slide,
                                });

                                // Close the view of the deleted unit
                                navigate(`/dashboard`);
                            } catch (error) {
                                console.error("Failed to delete unit:", error);
                                toast.error("Connection error. Please try again later.", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                            }
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };

    const sortedQuotes = quotes.sort((a, b) => {
        return a.nodeName.localeCompare(b.nodeName);
    });

    return (
        <React.Fragment>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <Link to="/dashboard" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logoSm} alt="" height="32" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoDark} alt="" height="42" />
                        </span>
                    </Link>

                    <Link to="/dashboard" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logoSm} alt="" height="32" />
                        </span>
                        <span className="logo-lg">
                            <img src={logoLight} alt="" height="42" />
                        </span>
                    </Link>
                    <button
                        type="button"
                        className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>

                <PerfectScrollbar className="perfect-workspc-calc perfect-workspc-calc-SM">
                    <div className="ipln-workspc-container-SM">
                        <PanelBar className="ipln-panelbar-SM" expandMode="multiple">

                        <PanelBarItem
            title={
                <div className="d-flex align-items-center flex-column">
                    <Button
                        icon="plus"
                        look="flat"
                        className="ip-add-btn"
                        data-tooltip-id="quote-tooltip-add"
                        data-tooltip-content="Add New Quote"
                        data-tooltip-place="right"
                        data-tooltip-float="true"
                        data-tooltip-position-strategy="fixed"
                        title="Add New"
                        style={{ marginRight: "10px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAddNode(
                                quotes,
                                setQuotes,
                                maxQuoteIndex,
                                setMaxQuoteIndex,
                                "Quote"
                            );
                        }}
                    />
                    <div
                        className="ipln-Title-SM"
                        data-tooltip-id="quote-tooltip-add"
                        data-tooltip-content="Quotes"
                        data-tooltip-place="right"
                        data-tooltip-float="true"
                        data-tooltip-position-strategy="fixed">
                        QUO
                    </div>
                    <Tooltip id="quote-tooltip-add" />
                    <Tooltip id="quote-tooltip" />
                </div>
            }
            expanded={isQuotesExpanded}
            onSelect={(e) => {
                e.preventDefault();
                setIsQuotesExpanded(!isQuotesExpanded);
            }}
            onClick={() => {
                handlePanelBarItemClick("/quotes");
                onSubnodeClick();
            }}>
            {initialOrder.map((quote, index) => (
                <NavLink
                    exact
                    key={quote.nodeId}
                    to={`/quotes/${quote.nodeId}`}
                    state={{ quoteName: quote.nodeName }}
                    className={({ isActive, isPending }) =>
                        isPending
                            ? "panel-bar-item-link"
                            : isActive
                                ? "panel-bar-item-link active"
                                : "panel-bar-item-link"
                    }>
                    <PanelBarItem
                        title={
                            <div
                                className="ipln-panelitem-container-sm"
                                data-tooltip-id="quote-tooltip"
                                data-tooltip-content={quote.nodeName}
                                data-tooltip-place="right"
                                data-tooltip-float="true"
                                data-tooltip-position-strategy="fixed">
                                <div className="sub-box">
                                    <span className="icon k-icon ri-file-text-line" />
                                </div>
                            </div>
                        }
                    />
                </NavLink>
            ))}
            {initialOrder.map((quote, index) => (
                <Routes key={quote.nodeId}>
                    <Route
                        exact
                        path={`/quotes/${quote.nodeId}`}
                        component={SubNodePage}
                    />
                </Routes>
            ))}
        </PanelBarItem>


                            <PanelBarItem
                                // expanded="true"
                                title={
                                    <div className="d-flex align-items-center flex-column">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            data-tooltip-id="quote-tooltip-add"
                                            data-tooltip-content="Add New Unit"
                                            data-tooltip-place="right"
                                            data-tooltip-float="true"
                                            data-tooltip-position-strategy="fixed"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleUnitAddNode(
                                                    newUnits,
                                                    setNewUnits,
                                                    maxNewUnitIndex,
                                                    setMaxNewUnitIndex
                                                );
                                            }}
                                        />
                                        <div
                                            className="ipln-Title-SM"
                                            data-tooltip-id="quote-tooltip-add"
                                            data-tooltip-content="Units"
                                            data-tooltip-place="right"
                                            data-tooltip-float="true"
                                            data-tooltip-position-strategy="fixed">
                                            UNT
                                        </div>
                                        <Tooltip id="quote-tooltip-add" />
                                        <Tooltip id="quote-tooltip" />
                                    </div>
                                }>
                                {newUnits.map((newUnit, index) => (
                                    <NavLink
                                        exact
                                        key={newUnit.nodeId}
                                        to={`/new-units/${newUnit.nodeId}`}
                                        className={({ isActive, isPending }) =>
                                            isPending
                                                ? "panel-bar-item-link"
                                                : isActive
                                                    ? "panel-bar-item-link active"
                                                    : "panel-bar-item-link"
                                        }>
                                        <PanelBarItem
                                            title={
                                                <div
                                                    className="ipln-panelitem-container-sm"
                                                    data-tooltip-id="quote-tooltip"
                                                    data-tooltip-content={newUnit.nodeName}
                                                    data-tooltip-place="right"
                                                    data-tooltip-float="true"
                                                    data-tooltip-position-strategy="fixed">
                                                    <div className="sub-box">
                                                        <span className="icon k-icon ri-ruler-line" />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </NavLink>
                                ))}
                                {newUnits.map((newUnit, index) => (
                                    <Routes>
                                        <Route
                                            exact
                                            Redirect={`/new-units/${newUnit.nodeId}`}
                                            component={() => <SubNodePage />}
                                        />
                                    </Routes>
                                ))}
                            </PanelBarItem>

                            <PanelBarItem
                                title={
                                    <div className="d-flex align-items-center flex-column">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            data-tooltip-id="pricelist-tooltip-add"
                                            data-tooltip-content="Add New Price List"
                                            data-tooltip-place="right"
                                            data-tooltip-float="true"
                                            data-tooltip-position-strategy="fixed"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handlePriceListAddNodes(
                                                    priceListNodes,
                                                    setPriceListNodes,
                                                    maxPriceListIndex,
                                                    setMaxPriceListIndex
                                                );
                                            }}
                                        />
                                        <div
                                            className="ipln-Title-SM"
                                            data-tooltip-id="pricelist-tooltip-add"
                                            data-tooltip-content="Price List"
                                            data-tooltip-place="right"
                                            data-tooltip-float="true"
                                            data-tooltip-position-strategy="fixed">
                                            PL
                                        </div>
                                        <Tooltip id="pricelist-tooltip-add" />
                                        <Tooltip id="pricelist-tooltip" />
                                    </div>
                                }
                                expanded={isQuotesExpanded}
                                onSelect={(e) => {
                                    e.preventDefault();
                                    setIsQuotesExpanded(!isQuotesExpanded);
                                }}
                                onClick={() => {
                                    handlePanelBarItemClick("/price-lists");
                                    onSubnodeClick(); // Call the onSubnodeClick function when subnode is clicked
                                }}>
                                {priceListNodes.map((priceListNode, index) => (
                                    <NavLink
                                        key={priceListNode.nodeId}
                                        to={`/price-lists/${priceListNode.nodeId}`}
                                        className={({ isActive, isPending }) =>
                                            isPending
                                                ? "panel-bar-item-link"
                                                : isActive
                                                    ? "panel-bar-item-link active"
                                                    : "panel-bar-item-link"
                                        }
                                        onClick={() => setSelectedId(priceListNode.nodeId)

                                        }>
                                        <PanelBarItem
                                            title={
                                                <div
                                                    className="ipln-panelitem-container-sm"
                                                    data-tooltip-id="pricelist-tooltip"
                                                    data-tooltip-content={priceListNode.nodeName}
                                                    data-tooltip-place="right"
                                                    data-tooltip-float="true"
                                                    data-tooltip-position-strategy="fixed">
                                                    <div className="sub-box">
                                                        <span className="icon k-icon ri-price-tag-3-line" />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </NavLink>
                                ))}
                            </PanelBarItem>

                            <PanelBarItem
                                title={
                                    <span className="d-flex align-items-center flex-column">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            data-tooltip-id="library-tooltip-add"
                                            data-tooltip-content="Add New Library"
                                            data-tooltip-place="right"
                                            data-tooltip-float="true"
                                            data-tooltip-position-strategy="fixed"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleLibraryAddNode(
                                                    libraryNodes,
                                                    setLibraryNodes,
                                                    maxLibraryNodeIndex,
                                                    setMaxLibraryNodeIndex,
                                                    "Library",
                                                    userId
                                                );
                                            }}
                                        />
                                        <div
                                            className="ipln-Title-SM"
                                            data-tooltip-id="library-tooltip-add"
                                            data-tooltip-content="Library"
                                            data-tooltip-place="right"
                                            data-tooltip-float="true"
                                            data-tooltip-position-strategy="fixed"
                                        >
                                            LIB
                                        </div>
                                        <Tooltip id="library-tooltip-add" />
                                        <Tooltip id="library-tooltip" />
                                    </span>
                                }
                                expanded={libraryNodes.length > 0 && libraryNodes[0].expanded}
                                onExpandChange={(e) => {
                                    if (libraryNodes.length > 0) {
                                        const newLibraryNodes = [...libraryNodes];
                                        newLibraryNodes[0].expanded = e.value;
                                        setLibraryNodes(newLibraryNodes);
                                    }
                                }}
                                onClick={() => {
                                    handlePanelBarItemClick("/library");
                                    onSubnodeClick();
                                }}
                            >
                                {libraryNodes.map((libraryNode, index) => (
                                    <NavLink
                                        key={libraryNode.userLibId}
                                        to={`/library/${libraryNode.userLibId}`}
                                        className="panel-bar-item-link"
                                        onClick={() => setSelectedId(libraryNode.userLibId)}
                                    >
                                        <PanelBarItem
                                            title={
                                                <div
                                                    className="ipln-panelitem-container-sm"
                                                    data-tooltip-id="library-tooltip"
                                                    data-tooltip-content={libraryNode.libraryName}
                                                    data-tooltip-place="right"
                                                    data-tooltip-float="true"
                                                    data-tooltip-position-strategy="fixed"
                                                >
                                                    <div className="sub-box">
                                                        <span className="icon k-icon ri-archive-line" />
                                                    </div>
                                                </div>
                                            }
                                        >

                                        </PanelBarItem>
                                    </NavLink>
                                ))}
                            </PanelBarItem>

                        </PanelBar>
                    </div>

                    <div className="ipln-workspc-container">
                        <div className="ipln-workspace d-flex">
                            <i className="ri-home-smile-line me-2"></i> Estimate Workspace
                        </div>
                        <PanelBar expandMode="multiple">
                            <PanelBarItem
                                title={
                                    <span className="d-flex align-items-center">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            title="Add New"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleAddNode(
                                                    quotes,
                                                    setQuotes,
                                                    maxQuoteIndex,
                                                    setMaxQuoteIndex,
                                                    "Quote"
                                                );
                                            }}
                                        />
                                        <span className="k-icon ri-file-text-line" style={{ marginRight: "5px" }} />
                                        Quotes
                                    </span>
                                }
                                expanded={isQuotesExpanded}
                                onSelect={(e) => {
                                    e.preventDefault();
                                    setIsQuotesExpanded(!isQuotesExpanded);
                                }}
                                onClick={() => {
                                    handlePanelBarItemClick("/quotes");
                                    onSubnodeClick();
                                }}>
                                {quotes.map((quote, index) => (
                                    <NavLink
                                        exact
                                        key={quote.nodeId}
                                        to={`/quotes/${quote.nodeId}`}
                                        state={{ quoteName: quote.nodeName }}
                                        className={({ isActive, isPending }) =>
                                            isPending
                                                ? "panel-bar-item-link"
                                                : isActive
                                                    ? "panel-bar-item-link active"
                                                    : "panel-bar-item-link"
                                        }>
                                        <PanelBarItem
                                            title={
                                                <div className="quote-container">
                                                    <div className="sub-box">
                                                        <span className="icon k-icon ri-file-text-line" />
                                                        <span className="quote-title">{quote.nodeName}</span>
                                                        <div className="button-container">
                                                            <Button
                                                                className="close-quote-icon"
                                                                icon="close"
                                                                title="Close"
                                                                look="flat"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleDeleteNodePopup(
                                                                        quotes,
                                                                        setQuotes,
                                                                        index,
                                                                        maxQuoteIndex,
                                                                        setMaxQuoteIndex,
                                                                        quote.nodeId,
                                                                        LeftNavTypes.QUOTES,
                                                                        quote.nodeName
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </NavLink>
                                ))}
                                {quotes.map((quote, index) => (
                                    <Routes>
                                        <Route
                                            exact
                                            Redirect={`/quotes/${quote.nodeId}`}
                                            component={() => <SubNodePage />}
                                        />
                                    </Routes>
                                ))}
                            </PanelBarItem>

                            <PanelBarItem
                                title={
                                    <span className="d-flex align-items-center">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            title="Add New"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleUnitAddNode(
                                                    newUnits,
                                                    setNewUnits,
                                                    maxNewUnitIndex,
                                                    setMaxNewUnitIndex
                                                );
                                            }}
                                        />
                                        <span className="k-icon ri-ruler-line" style={{ marginRight: "5px" }} />
                                        New Units
                                    </span>
                                }
                                expanded={isQuotesExpanded}
                                onSelect={(e) => {
                                    e.preventDefault();
                                    setIsQuotesExpanded(!isQuotesExpanded);
                                }}
                                onClick={() => {
                                    handlePanelBarItemClick("/new-units");
                                    onSubnodeClick();
                                }}>
                                {/* Render the new units as sub-nodes */}
                                {newUnits.map((newUnit, index) => (
                                    <NavLink
                                        key={newUnit.nodeId}
                                        to={`/new-units/${newUnit.nodeId}`}
                                        className={({ isActive, isPending }) =>
                                            isPending
                                                ? "panel-bar-item-link"
                                                : isActive
                                                    ? "panel-bar-item-link active"
                                                    : "panel-bar-item-link"
                                        }
                                        onClick={() => {
                                            setSelectedId(newUnit.nodeId);
                                            loadUnitData(newUnit.nodeId);
                                            setUnitId(newUnit.nodeId)
                                            setUnitName("")
                                        }}>
                                        <PanelBarItem
                                            title={
                                                <div className="quote-container">
                                                    <div className="sub-box">
                                                        <span className="icon k-icon ri-ruler-line" />

                                                        <span className="quote-title">{newUnit.nodeName}</span>
                                                        <div className="button-container">
                                                            {/* <Button
                                                            className="close-quote-icon"
                                                            icon="close"
                                                            look="flat"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleClosePopup(
                                                                    newUnits,
                                                                    setNewUnits,
                                                                    index,
                                                                    maxNewUnitIndex,
                                                                    setMaxNewUnitIndex,
                                                                    newUnit.nodeId
                                                                );
                                                            }}
                                                        /> */}

                                                            <Button
                                                                className="close-quote-icon"
                                                                icon="close"
                                                                title="Close"
                                                                look="flat"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleDeleteUnitNodePopup(
                                                                        newUnits,
                                                                        setNewUnits,
                                                                        index,
                                                                        maxNewUnitIndex,
                                                                        setMaxNewUnitIndex,
                                                                        newUnit.nodeId,
                                                                        LeftNavTypes.NEW_UNITS,
                                                                        newUnit.nodeName
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </NavLink>
                                ))}
                            </PanelBarItem>

                            <PanelBarItem
                                title={
                                    <span className="d-flex align-items-center">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            title="Add New"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handlePriceListAddNodes(
                                                    priceListNodes,
                                                    setPriceListNodes,
                                                    maxPriceListIndex,
                                                    setMaxPriceListIndex
                                                );
                                            }}
                                        />
                                        <span className="k-icon ri-price-tag-3-line" style={{ marginRight: "5px" }} />
                                        Price Lists
                                    </span>
                                }
                                expanded={isQuotesExpanded}
                                onSelect={(e) => {
                                    e.preventDefault();
                                    setIsQuotesExpanded(!isQuotesExpanded);
                                }}
                                onClick={() => {
                                    handlePanelBarItemClick("/price-lists");
                                    onSubnodeClick(); // Call the onSubnodeClick function when subnode is clicked
                                }}>
                                {priceListNodes.map((priceListNode, index) => (
                                    <NavLink
                                        key={priceListNode.nodeId}
                                        to={`/price-lists/${priceListNode.nodeId}`}
                                        className={({ isActive, isPending }) =>
                                            isPending
                                                ? "panel-bar-item-link"
                                                : isActive
                                                    ? "panel-bar-item-link active"
                                                    : "panel-bar-item-link"
                                        }
                                        onClick={() => setSelectedId(priceListNode.nodeId)}>
                                        <PanelBarItem
                                            title={
                                                <div className="quote-container">
                                                    <div className="sub-box">
                                                        <span className="icon k-icon ri-price-tag-3-line" />

                                                        <span className="quote-title">{priceListNode.nodeName}</span>
                                                        <div className="button-container">
                                                            <Button
                                                                className="close-quote-icon"
                                                                icon="close"
                                                                title="Close"
                                                                look="flat"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleDeletePriceListNodePopup(
                                                                        priceListNodes,
                                                                        setPriceListNodes,
                                                                        index,
                                                                        maxPriceListIndex,
                                                                        setMaxPriceListIndex,
                                                                        priceListNode.nodeId,
                                                                        LeftNavTypes.PRICE_LISTS
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </NavLink>
                                ))}
                            </PanelBarItem>

                            <PanelBarItem
                                title={
                                    <span className="d-flex align-items-center">
                                        <Button
                                            icon="plus"
                                            look="flat"
                                            className="ip-add-btn"
                                            title="Add New"
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                handleLibraryAddNode(
                                                    libraryNodes,
                                                    setLibraryNodes,
                                                    maxLibraryNodeIndex,
                                                    setMaxLibraryNodeIndex,
                                                    "Library",
                                                    userId
                                                );
                                            }}
                                        />
                                        <span className="icon k-icon ri-archive-line" style={{ marginRight: "5px" }} />
                                        Library
                                    </span>
                                }
                                expanded={true}
                                onClick={() => {
                                    handlePanelBarItemClick("/library");
                                    onSubnodeClick(); // Call the onSubnodeClick function when subnode is clicked
                                }}>
                                {libraryNodes.sort((a,b)=>a.libraryName.localeCompare(b.libraryName)).map((libraryNode, index) => (
                                        <PanelBarItem
                                            key={libraryNode.userLibId}
                                            onClick={() => setSelectedId(libraryNode.userLibId)}
                                            expanded={true}
                                            title={
                                                <div className="quote-container library">
                                                    <div className="sub-box">
                                                        {editingLibraryNodeIndex === index ? (
                                                            <>
                                                                <span className="button-container">
                                                                    <input
                                                                        type="text"
                                                                        className="ip-editable-input-always me-2"
                                                                        value={editedLibraryName}
                                                                        onChange={(e) => {
                                                                            //handleLibraryNodeInputChange(index, e);
                                                                            handleLibraryNameChange(e);
                                                                        }}
                                                                        //onBlur={() => setEditingLibraryNodeIndex(-1)}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter") {
                                                                                handleSaveLibraryNode(
                                                                                    index,
                                                                                    e,
                                                                                    editedLibraryName
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className={`icon k-icon ${libraryUnits.some((unit) => unit.userLibId === libraryNode.userLibId) > 0 ? 'ri-stack-line' : 'ri-archive-line'}`} />
                                                                <span className="quote-title">
                                                                    {libraryNode.libraryName}
                                                                </span>
                                                            </>
                                                        )}

                                                        <div className="button-container">
                                                            {/* button for save and close edit */}
                                                            <Button
                                                                className="close-quote-icon"
                                                                icon={
                                                                    editingLibraryNodeIndex === index ? "save" : "edit"
                                                                }
                                                                title={
                                                                    editingLibraryNodeIndex === index ? "Save" : "Edit"
                                                                }
                                                                look="flat/"
                                                                onClick={(e) => {
                                                                    if (editingLibraryNodeIndex === index) {
                                                                        handleSaveLibraryNode(index); // Call the save function if in editing mode
                                                                    } else {
                                                                        handleEditLibraryNode(
                                                                            index,
                                                                            e,
                                                                            libraryNode.libraryName
                                                                        ); // Call the edit function if not in editing mode
                                                                    }
                                                                }}
                                                            />

                                                            {/* button for close */}
                                                            {editingLibraryNodeIndex === index ? (
                                                                <Button
                                                                    className="close-quote-icon"
                                                                    icon="close"
                                                                    title="Cancel"
                                                                    look="flat/"
                                                                    onClick={(e) => {
                                                                        handleCancelEditMode(index, e);
                                                                    }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        className="delete-quote-icon"
                                                                        icon="delete"
                                                                        title="Delete"
                                                                        look="flat"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            confirmAlert({
                                                                                title: "Delete Confirmation",
                                                                                message: "Are you sure you want to delete the Library?",
                                                                                buttons: [
                                                                                    {
                                                                                        label: "Yes",
                                                                                        onClick: () => {
                                                                                            handleDeleteLibraryNode(
                                                                                                libraryNodes,
                                                                                                setLibraryNodes,
                                                                                                index,
                                                                                                libraryNode.userLibId,
                                                                                                libraryNode.libraryName,
                                                                                                libraryNode
                                                                                            );
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        label: "No",
                                                                                        onClick: () => {
                                                                                            return;
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            });

                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>



                                                </div>
                                            }>    
                                            
                                            {libraryUnits.sort((a,b)=>a.unitName.localeCompare(b.unitName))
                                            .filter((unit) => unit.userLibId === libraryNode.userLibId)
                                            .map((unit, unitIndex) => (
                                                <div className="unit-names-container">
                                                    <NavLink
                                                        key={unit.unitId}
                                                        to={`/unit/${unit.unitId}`}
                                                        className={`panel-bar-item-link ${selectedSubUnitIndex === index && selectedUnitIndex === unitIndex ? 'linkedunit' : ''}`}
                                                        onClick={() => {
                                                            setSelectedId(unit.unitId);
                                                            loadUnitData(unit.unitId);
                                                            setUnitId(unit.unitId);
                                                            setHiddenOpen(false);

                                                        }}
                                                    >
                                                        <PanelBarItem
                                                            title={
                                                                <div className="quote-container">
                                                                    <div className="sub-box leveltwo">
                                                                        {editingUnitIndex === index && editingUnitSubIndex === unitIndex ? (
                                                                            <>
                                                                                <span className="button-container">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="ip-editable-input-always me-2"
                                                                                        value={editedUnitName !== undefined ? editedUnitName : unit.unitName}
                                                                                        onChange={(e) => handleUnitNameChange(e, index, unitIndex)}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === "Enter") {
                                                                                                handleSaveUnit(index, unitIndex, e, editedUnitName);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="icon k-icon ri-ruler-line" />
                                                                                <span className="quote-title">
                                                                                    {/* <span className="quote-title">{unit.unitName}</span> */}
                                                                                    <span className="quote-title">{unit.unitName}</span>
                                                                                </span>
                                                                            </>
                                                                        )}

                                                                        <div className="button-container">
                                                                            {/* button for select unit and load data into itemgrid */}
                                                                            <Button
                                                                                className={`close-quote-icon ${selectedSubUnitIndex === index && selectedUnitIndex === unitIndex ? 'linkedunit' : ''}`}
                                                                                // className="close-quote-icon"
                                                                                icon="link"  // Replace with the actual icon you want to use
                                                                                title={selectedSubUnitIndex === index && selectedUnitIndex === unitIndex ? 'Deselect this unit' : 'Select this unit for a quote'}
                                                                                look="flat/"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault(); // Prevent navigation
                                                                                    setCickAddUnit(true);
                                                                                    //   setHiddenOpen(true);
                                                                                    // Add the functionality for the select button here
                                                                                    //    handleSelectUnit(index, unitIndex, e, unit.unitId);  // Replace with your actual function
                                                                                    handleSelectUnit(unit.unitId, unitIndex, index);
                                                                                    // setEditingUnitIndex(index);
                                                                                    // setEditingUnitSubIndex(unitIndex);
                                                                                    // setSelectedUnitIndex(unitIndex);
                                                                                }}
                                                                            />
                                                                            {/* button for save and close edit */}
                                                                            <Button
                                                                                className="close-quote-icon"
                                                                                icon={editingUnitIndex === index && editingUnitSubIndex === unitIndex ? "save" : "edit"}
                                                                                title={editingUnitIndex === index && editingUnitSubIndex === unitIndex ? "Save" : "Edit"}
                                                                                look="flat/"
                                                                                onClick={(e) => {
                                                                                    if (editingUnitIndex === index && editingUnitSubIndex === unitIndex) {
                                                                                        handleSaveUnit(index, unitIndex, e, editedUnitName);
                                                                                    } else {
                                                                                        handleEditUnit(index, unitIndex, e, unit.unitName);
                                                                                    }
                                                                                }}
                                                                            />

                                                                            {/* button for close */}
                                                                            {editingUnitIndex === index && editingUnitSubIndex === unitIndex ? (
                                                                                <Button
                                                                                    className="close-quote-icon"
                                                                                    icon="close"
                                                                                    title="Cancel"
                                                                                    look="flat/"
                                                                                    onClick={(e) => handleCancelUnitEditMode(index, e)}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    <Button
                                                                                        className="delete-quote-icon"
                                                                                        icon="delete"
                                                                                        title="Delete"
                                                                                        look="flat"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation();
                                                                                            handleDeleteUnit(unitIndex, unit.unitId);
                                                                                        }}
                                                                                    />
                                                                                    <button
                                                                                        className="collapse-lib-icon ri-arrow-down-s-line"
                                                                                        look="flat"
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            }></PanelBarItem>
                                                    </NavLink>
                                                </div>))}                                        
                                        </PanelBarItem>
                                ))}
                            </PanelBarItem>
                        </PanelBar>
                    </div>
                </PerfectScrollbar>

                <div className="ipln-system">
                    <div className="inner d-flex">System Information</div>
                    <div className="inner-txt">SOH Warehouse: {sohWH}</div>
                </div>
            </div>
            <div className="vertical-overlay"></div>
        </React.Fragment>
    );
};

export default LeftNavigationBar;
