import React, { useState, useEffect, useContext } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { getResponse } from "../../Services/Common/Get";
import { postResponse } from "../../Services/Common/Post";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ZIndexContext } from "@progress/kendo-react-common";
import { DialogActionsBar,Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { toast, Slide } from "react-toastify";
import "../../styles/SystemSetting.css";
import { SystemSettingContext } from "../../Contexts/SystemSettingContext";
import { useSystemSettings } from "../../Contexts/SystemSettingsContext";
import Spinner from "../Common/Spinner";
import { set } from "lodash";
import { useQuoteHeaders } from "../../Contexts/QuoteHeadersProvider";
import { useQuoteContact } from '../../Contexts/QuoteContactProvider';
import { useParams } from "react-router-dom";
const SystemSettingForm = ({ setSystemSettingFormVisible  }) => {
    const { systemSetttingDbExtraValue, setsystemSetttingDbExtraValue } = useContext(SystemSettingContext);
    const { setQuoteHeaders,setSystemId } = useQuoteHeaders();
    const [visible, setVisible] = useState(false);
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [headerypes, setHeaderTypes] = useState([]);
    const [headerTypesID, setheaderTypesID] = useState([]);
    const [selectedheadertype, setSelectedHeaderType] = useState([]);
    const [selectedheaderTypeId, setSelectedheaderTypeId] = useState([]);
    //const {selectedContactTypeId, setSelectedContactId} = useQuoteContact();

    const [sohWhseTypes, setSOHWhseTypes] = useState([]);
    const [SOHTypesID, setSOGTypesID] = useState([]);
    const [selectedsohtype, setSelectedSOHType] = useState([]);
    const [selectedSOHTypeId, setSelectedSOHId] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [quoteTypes, setQuoteTypes] = useState([]);
    const [quoteTypesID, setQuoteTypesID] = useState([]);
    const [selectedquotetype, setSelectedquoteType] = useState([]);
    const [selectedQuoteTypeId, setSelectedQuoteTypeId] = useState([]);

    const [quoteContactTypes, setQuoteContactTypes] = useState([]);
    const [quoteContactTypesID, setQuoteContactTypesID] = useState([]);
    const [selectedquoteContacttype, setSelectedquoteContactType] = useState([]);
     const [selectedContactTypeId, setSelectedContactId] = useState([]);

    // State to track form modifications
    const [isFormModified, setIsFormModified] = useState(false);

    const user = useContext(UserContext);
    const { settings, setSettings } = useSystemSettings();

    const userId = user?.homeAccountId;
    const userEmail = user?.userName;
    const userName = user?.user;

    const [lowerMargine, setLowerMargine] = useState([]);
    const [pricelistExpiry, setpricelistExpiry] = useState([]);
    const [dbextracharge, setdbextracharge] = useState([]);
   const toggleDialog = () => {
        setVisible(!visible);
    };
    const handleClose = () => {
        setSystemSettingFormVisible(false);
    };

    const handleSubmit = async () => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setIsLoading(true);
        try {
            const dataToSend = {
                userId: userId,
                quoteHeaderId: selectedheaderTypeId,
                quoteContactId: selectedContactTypeId,
                sohwhseId: selectedSOHTypeId,
                quoteCategoryId: selectedQuoteTypeId,
                lowerMargine: lowerMargine,
                priceListExpiry: pricelistExpiry,
                dbextraCharge: dbextracharge,
                createdby: userId,
                modifiedby: userId,
                createdUserName: userName,
                createdUserEmail: userEmail,
                modifiedUserName: userName,
                modifiedUserEmail: userEmail,
                isDeleted: false,
                timeZone: clientTimezone,
            };
            const response = await postResponse("SystemSetting/insertorupdate", dataToSend);

            //updating the context for system settings.
            setIsLoading(false);
            setSettings(response);
            toast.success("Success!", { autoClose: 2000, closeOnClick: true, position: "top-center", transition: Slide, });
        } catch (error) {
            console.error("Failed to save data:", error);
            toast.error("Failed!", { autoClose: 2000, closeOnClick: true, position: "top-center", transition: Slide, });
        }
    };

    const handleOkChanges = async () => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        try {
            const dataToSend = {
                userId: userId,
                quoteHeaderId: selectedheaderTypeId,
                quoteContactId: selectedContactTypeId,
                sohwhseId: selectedSOHTypeId,
                quoteCategoryId: selectedQuoteTypeId,
                lowerMargine: lowerMargine,
                priceListExpiry: pricelistExpiry,
                dbextraCharge: dbextracharge,
                createdby: userId,
                modifiedby: userId,
                createdUserName: userName,
                createdUserEmail: userEmail,
                modifiedUserName: userName,
                modifiedUserEmail: userEmail,
                isDeleted: false,
                timeZone: clientTimezone,
            };
            const response = await postResponse("SystemSetting/insertorupdate", dataToSend);

            console.log("response :", response);

            //updating the context for system settings.
            setSettings(response);
            setSystemSettingFormVisible(false);
            setQuoteHeaders (id,{header: response.quoteHeaderId , category: response.quoteCategoryId})
            console.log("id,{header: response.quoteHeaderId , category: response.quoteCategoryId} :",id,{header: response.quoteHeaderId , category: response.quoteCategoryId})
            setSystemId(id)
            console.log("system id :",id)
        } catch (error) {
            console.error("Failed to save data:", error);
            toast.error("Failed!", { autoClose: 2000, closeOnClick: true, position: "top-center", transition: Slide, });
        }
    };

    const handleApply = async () => {
        if (lowerMargine === '' || pricelistExpiry === '' || dbextracharge === '') {
            toast.error('Please fill in all fields before applying.', { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
        } else {
            try {
                await handleSubmit();
                setIsFormModified(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    const handleOk = async () => {
        if (lowerMargine === '' || pricelistExpiry === '' || dbextracharge === '') {
            toast.error('Please fill in all fields before applying.', { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
        } else {
        try {
            await handleOkChanges();
            setIsFormModified(true);
        } catch (error) {
            console.error("Error:", error);
        }
    }
    };

    useEffect(() => {
        const fetchloadData = async () => {
            setIsLoading(true);
            try {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });

                console.log("data :", data);

                const qttypelist = await getResponse("QuoteType/getallquotetype");
                const headertypelist = await getResponse("QuoteHeaderType/getallquoteheadertype");
                const SOHlist = await getResponse("SystemSetting/getallsohwhse");
                const QuoteContact = await getResponse("SystemSetting/getallquotecontact");

                const category = qttypelist.map((item) => item.qtType);
                const catid = qttypelist.map((item) => item.qtId);
                const systemSettingQtType = data.map((item) => item.qtType);
                const QuoteTypes = [...category];
                const quotetypeid = [...catid];

                setQuoteTypes(QuoteTypes);
                setQuoteTypesID(quotetypeid);
                setSelectedquoteType(data.map((item) => item.qtType));
                setSelectedQuoteTypeId(data.map((item) => item.quoteCategoryId));

                const selectedQuoteType = qttypelist.find((item) => item.qtType === systemSettingQtType[0]);
                setSelectedquoteType(selectedQuoteType ? selectedQuoteType.qtType : "");

                const soh = SOHlist.map((item) => item.whCode);
                const sohid = SOHlist.map((item) => item.whseId);
                const systemSettingsoh = data.map((item) => item.whCode);
                const sohTypes = [...soh];
                const sohtypeid = [...sohid];
                setSOHWhseTypes(sohTypes);
                setSOGTypesID(sohtypeid);
                setSelectedSOHType(data.map((item) => item.whCode));

                const selectedSOHType = SOHlist.find((item) => item.whCode === systemSettingsoh[0]);
                setSelectedSOHType(selectedSOHType ? selectedSOHType.whCode : "");

                const contact = QuoteContact.map((item) => item.quoteContact);
                const contactid = QuoteContact.map((item) => item.qcid);
                const systemSettingcontact = data.map((item) => item.quoteContact);
                const ContactTypes = [...contact];
                const Contacttypeid = [...contactid];
                setQuoteContactTypes(ContactTypes);
                setQuoteContactTypesID(Contacttypeid);
                setSelectedquoteContactType(data.map((item) => item.quoteContact));
                const selectedContactype = QuoteContact.find((item) => item.quoteContact === systemSettingcontact[0]);
                setSelectedquoteContactType(selectedContactype ? selectedContactype.quoteContact : "");

                const header = headertypelist.map((item) => item.headerName);
                const headerid = headertypelist.map((item) => item.qhId);
                const systemSettingheader = data.map((item) => item.headerName);
                const headerNames = [...header];
                const Headertypeid = [...headerid];

                setHeaderTypes(headerNames);
                setheaderTypesID(Headertypeid);
                setSelectedHeaderType(data.map((item) => item.headerName));

                const selectedHeadertype = headertypelist.find((item) => item.headerName === systemSettingheader[0]);
                setSelectedHeaderType(selectedHeadertype ? selectedHeadertype.headerName : "");

                const lowerMargine = data.map((item) => item.lowerMargine);
                setLowerMargine(lowerMargine[0]);

                const pricelistExpiry = data.map((item) => item.priceListExpiry);
                setpricelistExpiry(pricelistExpiry[0]);

                const dbextracharge = data.map((item) => item.dbextraCharge);
                setdbextracharge(dbextracharge[0]);

                const initialQuoteType = data.map((item) => item.quoteCategoryId);
                const initialSOHType = data.map((item) => item.sohwhseId);
                const initialContactType = data.map((item) => item.quoteContactId);
                const initialHeaderType = data.map((item) => item.quoteHeaderId);

                setSelectedheaderTypeId(initialHeaderType[0]);
                setSelectedSOHId(initialSOHType[0]);
                setSelectedQuoteTypeId(initialQuoteType[0]);
                setSelectedContactId(initialContactType[0]);
                setIsLoading(false);
            } catch (error) {
               
                console.error("Error:", error);
            }
        };

        fetchloadData();
    }, []);

    // Add event handlers for dropdown components
    const handleQuoteTypeChange = async (selectedValue) => {
        const selectedIndex = quoteTypes.findIndex((item) => item === selectedValue);
        const selectedId = selectedIndex !== -1 ? quoteTypesID[selectedIndex] : null;
        setSelectedquoteType(selectedValue);
        setSelectedQuoteTypeId(selectedId);
        setIsFormModified(true);
    };

    const handleSOHTypeChange = async (selectedValue) => {
        const selectedIndex = sohWhseTypes.findIndex((item) => item === selectedValue);
        const selectedId = selectedIndex !== -1 ? SOHTypesID[selectedIndex] : null;
        setSelectedSOHType(selectedValue);
        setSelectedSOHId(selectedId);
        setIsFormModified(true);
    };

    const handleContactTypeChange = async (selectedValue) => {
        const selectedIndex = quoteContactTypes.findIndex((item) => item === selectedValue);
        const selectedId = selectedIndex !== -1 ? quoteContactTypesID[selectedIndex] : null;
        setSelectedquoteContactType(selectedValue);
        setSelectedContactId(selectedId);
        setIsFormModified(true);
    };

    const handleHeaderTypeChange = async (selectedValue) => {
        setSelectedHeaderType(selectedValue);
        const selectedIndex = headerypes.findIndex((item) => item === selectedValue);
        const selectedId = selectedIndex !== -1 ? headerTypesID[selectedIndex] : null;
        setSelectedheaderTypeId(selectedId);
        setIsFormModified(true);
    };
    const handleRemove = () => {
        toggleDialog();
        setSystemSettingFormVisible(false);
    };
    if (isLoading) {
        return (
            <div className="ip-spin-dialog-holder">
                <Spinner /> {/* Assuming Spinner is your loading indicator component */}
            </div>
        );
    }
    return (
        
        
        <Form
            onSubmit={handleSubmit}
            render={() => (
                <div>
                    <FormElement>
                        <TabStrip selected={activeTab} onSelect={(e) => setActiveTab(e.selected)}>
                            <TabStripTab title="Preference">
                                <ZIndexContext.Provider value={10003}>
                                    <div className="col-md-12 col-12 p-2">
                                        <div className="form-group">
                                            <h6>Quote Header:</h6>

                                            <DropDownList
                                                data={headerypes}
                                                value={selectedheadertype}
                                                placeholder="Select Headers"
                                                onChange={(e) => handleHeaderTypeChange(e.target.value)}
                                                editable={false}
                                                className="mb-3"
                                            />
                                            <h6>Quote Contact:</h6>

                                            <DropDownList
                                                data={quoteContactTypes}
                                                value={selectedquoteContacttype}
                                                placeholder="Select Quote Contact"
                                                onChange={(e) => handleContactTypeChange(e.target.value)}
                                                editable={false}
                                                className="mb-3"
                                            />
                                            <h6>SOH Whse:</h6>
                                            <DropDownList
                                                data={sohWhseTypes}
                                                value={selectedsohtype}
                                                placeholder="Select SOH Whse"
                                                onChange={(e) => handleSOHTypeChange(e.target.value)}
                                                editable={false}
                                                className="mb-3"
                                            />
                                            <h6>Quote Category:</h6>
                                            <DropDownList
                                                data={quoteTypes}
                                                value={selectedquotetype}
                                                onChange={(e) => handleQuoteTypeChange(e.target.value)}
                                                placeholder="Select Category"
                                                editable={false}
                                            />
                                        </div>
                                    </div>
                                </ZIndexContext.Provider>
                            </TabStripTab>
                            <TabStripTab title="Application">
                                <div className="col-md-12 col-12 p-2">
                                    <div className="form-group">
                                        <h6>Lower Margin: (%)</h6>
                                        <TextBox
                                            name="lowermargine"
                                            label="Lower Margine"
                                            id="lowermargine"
                                            component={Input}
                                            value={lowerMargine}
                                            onChange={(e) => {
                                                const enteredValue = e.target.value.replace(/\D/g, "");
                                                setLowerMargine(`${enteredValue}`);
                                                setIsFormModified(true);
                                            }}
                                            className="mb-3"
                                        />

                                        <h6>Pricelist Expiry (last X Days):</h6>
                                        <TextBox
                                            name="pricelistExpiry"
                                            label="Pricelist Expiry"
                                            id="pricelistExpiry"
                                            component={Input}
                                            value={pricelistExpiry}
                                            onChange={(e) => {
                                                setpricelistExpiry(e.target.value.replace(/\D/g, ""));
                                                setIsFormModified(true);
                                            }}
                                            className="mb-3"
                                        />
                                        <h6>DB Extra Charge: (%)</h6>
                                        <TextBox
                                            name="dbextracharge"
                                            label="DB Extra Charge"
                                            id="dbextracharge"
                                            component={Input}
                                            value={dbextracharge}
                                            onChange={(e) => {
                                                const enteredValue = e.target.value.replace(/\D/g, "");
                                                setdbextracharge(`${enteredValue}`);
                                                setIsFormModified(true);
                                            }}
                                            className="mb-3"
                                        />
                                    </div>
                                </div>
                            </TabStripTab>
                        </TabStrip>
                    </FormElement>

                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic-f2">
                            <Button
                                disabled={!isFormModified}
                                className={isFormModified ? "px-4 me-2 ip-button-general" : "disabled-button, px-4 me-2"}
                                onClick={handleApply}>
                                Apply
                            </Button>
                            <Button className="px-4 me-2 ip-button-general" onClick={handleOk}>
                                Done
                            </Button>                     
                            <Button className="px-4" onClick={toggleDialog}>
                                Cancel
                            </Button>

                            {visible && (
                                    <Dialog title={"Confirm to submit"} onClose={toggleDialog}>
                                        <p
                                            style={{
                                                margin: "30px",
                                                textAlign: "center",
                                            }}>
                                            Do you want to save changes you made to the 'System Settings'?
                                        </p>
                                        <DialogActionsBar>
                                            <div className="d-flex justify-content-center py-3 px-2">                                                                                                                                                                              
                                                <Button className="px-5 me-2 ip-button-positive" onClick={handleOk}>
                                                    Yes
                                                </Button> 
                                                <Button className="px-5 me-2 ip-button-negative" onClick={handleRemove}>
                                                    No
                                                </Button>
                                                <Button className="px-5" onClick={toggleDialog}>
                                                    Cancel
                                                </Button>      
                                            </div>
                                        </DialogActionsBar>
                                    </Dialog>
                                )}
                        </div>
                    </DialogActionsBar>
                </div>
            )}
        />
    );
};

export default SystemSettingForm;
