import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import * as ReactDOM from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { filter, forEach, isEmpty, isNumber, set } from "lodash";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { SaveUserPref } from "../Qoutes/UserGridPreference"
import { Popup } from '@progress/kendo-react-popup';
import { ContextMenu, MenuItem, Menu } from "@progress/kendo-react-layout";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import Spinner from "../Common/Spinner";
import {
    TreeList,
    createDataTree,
    orderBy,
    filterBy,
    mapTree,
    extendDataItem,
    getSelectedState,
    TreeListTextEditor,
    TreeListTextFilter,
    TreeListNumericFilter,
    TreeListDateFilter,
    TreeListBooleanFilter,
    TreeListSelectionCell,
    TreeListHeaderSelectionCell,
    getSelectedStateFromKeyDown,
    TreeListCell,
    TreeListToolbar
} from "@progress/kendo-react-treelist";
import { getter, treeIdUtils } from "@progress/kendo-react-common";
import { Renderers } from "./Renderers.jsx";
import { Button, SplitButton } from "@progress/kendo-react-buttons";
import { QteCurGridViewTypeContext } from "../../Contexts/QteCurGridViewTypeContext";
//#region approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { getResponse } from "../../Services/Common/Get";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
//#endregion
import { useAuthorizationContext } from "../Auth/useAuthorization";
import { itemStyle, indicatorToolTip } from "../../utils/QuoteItemIndicators";
import { toast } from "react-toastify";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";

class ProductCellEditor extends React.Component {

    render() {
        const disableDescForItemType = ["I", "K", "L", "M", "N", "S", "Z", "NSG"];
        const disableCostEAForItemType = ["I", "K", "L", "M", "S", "Z", "G", "SG", "DX"];
        const disableAvgCostForItemType = ["G", "SG", "DX", "S"];
        const disableDcOnLPForItemType = ["G", "SG", "CI", "DX"];


        const disableField =
            this.props.dataItem['rowType'] === "LN"
                && (this.props.field === "qty" || this.props.field === "nettEA" || this.props.field === "repDisc" || this.props.field === "margin"
                    || this.props.field === "stEecostRound" || this.props.field === "actCost" || this.props.field === "stFutureCostRound")
                ? true
                : (this.props.dataItem['rowType'] === "G" || this.props.dataItem['rowType'] === "SG")
                    && (this.props.field === "stStockCode" || this.props.field === "repDisc" || this.props.field === "stFutureCostRound" || this.props.field === "stEecostRound"
                        || this.props.field === "actCost")
                    ? true
                    // : this.props.dataItem['rowType'] === "CI"
                    // && (this.props.field === "stEecostRound" || this.props.field === "actCost" || this.props.field === "stFutureCostRound")
                    // ? true
                    : this.props.dataItem['rowType'] === "A" &&
                        (this.props.field === "stStockCode" ||
                            this.props.field === "description" ||
                            this.props.field === "nettEA" ||
                            this.props.field === "margin")
                        ? false
                        : this.props.dataItem['rowType'] === "A" &&
                            (this.props.field === "qty" ||
                                this.props.field === "repDisc" || this.props.field === "stEecostRound" || this.props.field === "actCost" || this.props.field === "stFutureCostRound")
                            ? true
                            : this.props.dataItem['rowType'] === "AI" &&
                                (this.props.field === "stStockCode" || this.props.field === "description" || this.props.field === "nettEA" || this.props.field === "repDisc"
                                    || this.props.field === "margin" || this.props.field === "stEecostRound" || this.props.field === "actCost" || this.props.field === "stFutureCostRound")
                                ? true
                                : this.props.dataItem['rowType'] === "AI" &&
                                    this.props.field === "qty"
                                    ? false
                                    : this.props.dataItem['type'] === ""
                                        ? false
                                        : (this.props.dataItem['rowType'] === "G" || this.props.dataItem['rowType'] === "SG")
                                            && (this.props.field === "qty" || this.props.field === "nettEA" || this.props.field === "margin")
                                            ? false
                                            : (this.props.dataItem['rowType'] === "S")
                                                && (this.props.field === "qty" || this.props.field === "nettEA" || this.props.field === "margin" || this.props.field === "repDisc")
                                                ? false
                                                : (this.props.dataItem['rowType'] === "S")
                                                    && (this.props.field === "stFutureCostRound")
                                                    ? true
                                                    : this.props.field === "description" &&
                                                        (disableDescForItemType.includes(this.props.dataItem['rowType']))
                                                        ? true
                                                        : this.props.field === "stEecostRound" &&
                                                            (disableCostEAForItemType.includes(this.props.dataItem['rowType']))
                                                            ? true
                                                            : this.props.field === "actCost" &&
                                                                (disableAvgCostForItemType.includes(this.props.dataItem['rowType']))
                                                                ? true
                                                                : this.props.field === "dcOnLp" &&
                                                                    (disableDcOnLPForItemType.includes(this.props.dataItem['rowType']))
                                                                    ? true
                                                                    : this.props.dataItem['rowType'] === "DX" &&
                                                                        (this.props.field === "qty" || this.props.field === "nettEA" || this.props.field === "repDisc" ||
                                                                            this.props.field === "margin" || this.props.field === "stFutureCostRound")
                                                                        ? true
                                                                        : false;

        const textValue = (this.props.dataItem['rowType'] === "" || this.props.dataItem['rowType'] === "LN")
            && (this.props.field === "nettEA" || this.props.field === "repDisc" || this.props.field === "margin"
                || this.props.field === "dcOnLp" || this.props.field === "totalCost" || this.props.field === "totalPrice"
                || this.props.field === "qty" || this.props.field === "stEecostRound" || this.props.field === "actCost"
                || this.props.field === "stFutureCostRound")
            ? ""
            : ((this.props.dataItem['rowType'] === "G" || this.props.dataItem['rowType'] === "SG") && this.props.field === "repDisc")
                ? ""
                : (this.props.field === "stEecostRound" && this.props.authType === "General" && this.props.view === "Normal" && (this.props.dataItem['rowType'] !== "" && this.props.dataItem['rowType'] !== "CI"))
                    ? "***"
                    : (this.props.field === "l50" || this.props.field === "l55" ||
                        this.props.field === "l20" || this.props.field === "l60")
                        ? !isNaN(parseFloat(cellProps.dataItem[cellProps.field])) ? isNumber(cellProps.dataItem[cellProps.field]) ?
                            parseFloat(cellProps.dataItem[cellProps.field]).toFixed(2) <= 0 ? ""
                                : parseFloat(cellProps.dataItem[cellProps.field]).toFixed(2)
                            : ""
                            : ""
                        : isNumber(this.props.dataItem[this.props.field])
                            ? this.props.field !== "qty" && this.props.field !== "margin"
                                && this.props.field !== "actMargin" && this.props.field !== "frcMargin"
                                && this.props.field !== "lcMargin"
                                ? this.props.dataItem['rowType'] === ""
                                    && (this.props.field === "totalCost" || this.props.field === "totalPrice")
                                    ? ""
                                    : parseFloat(this.props.dataItem[this.props.field]).toFixed(2)
                                : (this.props.field === "lcMargin" || this.props.field === "lastCost")
                                    && parseFloat(this.props.dataItem["lcMargin"]) === 0
                                    ? ""
                                    : this.props.dataItem[this.props.field]
                            : this.props.dataItem[this.props.field]

        const colName = this.props.columns.find((col) => col.field === this.props.field)
        let newStkcode = this.props.dataItem["stStockCode"] ? this.props.dataItem["stStockCode"] : "";
        let oldStkcode = this.props.dataItem["oldStockCode"] ? this.props.dataItem["oldStockCode"] : "";
        let titleMsg = (this.props.field === "stStockCode" ? (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() ? indicatorToolTip(this.props.dataItem) : textValue) : textValue);
        return (
            <td style={{ 'text-align': 'right' }} className={this.props.field === "dcOnLp" ? ' ip-gridclr-dc' : this.props.field === "stEecostRound" ? "ip-gridclr-costea" : ""}
                title={textValue ? `${colName.title}: ${textValue}` : textValue}>
                {
                    <input
                        title={titleMsg}
                        type="text"
                        // onClick={(e) => this.props.onClick(this.props)}
                        className={this.props.dataItem.nettEA === 0 && this.props.field === "nettEA"
                            ? "ip-editable-input zeroVal"
                            : this.props.field === "dcOnLp"
                                ? "ip-editable-input ip-gridclr-dc"
                                : this.props.field === "stEecostRound"
                                    ? "ip-editable-input ip-gridclr-costea" : "ip-editable-input"}
                        // className={"ip-editable-input k-input "
                        //     + this.props.field === "stEecostRound" && qteCurGridViewType === "Normal" && qteAuthType === "General"
                        //     ? "ip-gridclr-costea" : ""}
                        hidden={this.props.dataItem['rowType'] === "G" && this.props.field === "stStockCode" ? true : false}
                        disabled={disableField}
                        style={{ textAlign: this.props.field === "stStockCode" || this.props.field === "description" ? 'left' : 'right' }}
                        defaultValue={textValue}
                        onChange={(e) => this.props.onChange({ field: this.props.field, value: e.target.value, dataItem: this.props.dataItem })}
                        onKeyDown={(e) => this.props.onKeyDown({ event: e, field: this.props.field, value: e.target.value, dataItem: this.props.dataItem })}
                    />
                }
            </td>
        );
    }
}


//Region notes *********************************************************************
    //1. Grid.current.flattedData - this is equivalent to grid.data with collapse
    //2. DataItem - this is equivalent to grid without collapse
    //3. to match the data you want to read... use the rowIndex in the grid.current.flattedData
    //3. to fetch the actual row data match first the rowIndex from grid.current.flatteData and link to the dataItem via id
//#endregion ***********************************************************************

const QuotesItemGrid = ({ dataItem = [],
    columns = [],
    selectedRows = [],
    selectedItems = [],
    setSelectedRows,
    toolbarButtons,
    groupButtonOptions,
    itemButtonOptions,
    moreButtonOptions,
    labourButtonOptions,
    isCollapseAll,
    isExpandCollapseTriggered,
    handleItemChange,
    viewType,
    processWithGroups,
    customizedCol,
    selectedIndex,
    setSelectedIndex,
    colMenus,
    allowCopy,
    scrollValue,
    customFieldWidth,
    setSelectedView,
    viewOptions,
    handleItemGrid,
    setDropdownValue,
    setIsQuoteSaved
}) => {
    //#region declarations
    const user = useContext(UserContext);
    const { id } = useParams();
    const { quoteDetails, isUngroup, setIsUngroup, isGroup, setIsGroup, checkedItems, setCheckedItems } = useQuoteDetails();
    const { getQuoteColMap, getQuoteViewOpt, getAccountRole, columnsMapping,
        setColumnsMapping } = useAuthorizationContext();
    const authType = getAccountRole();
    columns = customizedCol;
    const [initialLoad, setInitialLoad] = useState(true);
    const [focusingInitiating, setFocusingInitiating] = useState(false);
    const subItemsField = "subItems";
    const expandField = "expanded";
    const editField = "inEdit";
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "id";
    const [idOnEdit, setIdOnEdit] = useState(null);
    const [editGroup, setEditGroup] = useState({});
    const idGetter = getter(DATA_ITEM_KEY);
    const cellRef = useRef(null);

    const {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected
    } = useContext(QteApprovalNotificationContext);
    const { qteCurGridViewType, setQteCurGridViewType, qteAuthType } = useContext(QteCurGridViewTypeContext);

    const selectionModes = [
        {
            value: "single",
            label: "single selection mode",
        },];
    const [selectionMode, setSelectionMode] = useState(
        selectionModes[0].value
    );

    let currentData = sessionStorage.getItem('sessionData');
    let currentView = sessionStorage.getItem('selectedView');
    const [selectedState, setSelectedState] = useState([]);
    const [expandedState, setExpandedState] = useState([]);
    const gridRef = useRef();
    const editorRef = useRef();
    let grid = gridRef;
    let textEditorRef = editorRef;
    const [showSOHDialog, setShowSOHDialog] = useState(false);
    const [selectedStockCode, setSelectedStockCode] = useState("");
    const [sohData, setSohData] = useState([]);
    const [totalSOH, setTotalSOH] = useState(0);
    const [indicatorLoaded, setIndicatorLoaded] = useState(false);
    const [defineCols, setDefineCols] = React.useState(false);
    let colPref = [];
    const [show, setShow] = React.useState(false);


    let renderers;
    const [enterField,setEnterField] = useState(undefined);
    const [enterDataItem,setEnterDataItem] = useState(undefined);
    const [fetchItem, setFetchItem] = useState(false);
    const [fetchItemCode, setFetchItemCode] = useState('');
    let fetchedItems = {};
    //#endregion



    //#region convert data to tree
    const dataTree = () => {
        return createDataTree(
            dataItem,
            (i) => i.id,
            (i) => i.parentId,
            subItemsField
        );
    }
    //#endregion


    //#region handle data state

    const [state, setState] = useState({
        data: [...dataTree()],
        changes: false,
        expanded: [...dataItem.filter((item) => item.rowType === "G" || item.rowType === "SG").map((item) => item.rowId)],
        selected: [{}],
        editItem: undefined,
        editItemField: undefined,
    });
    //#endregion


    //#region handle expand and collapse
    const onExpandChange = (event) => {
        setExpandedState(event.value ? expandedState.filter((id) => id !== event.dataItem.rowId) : [...expandedState, event.dataItem.rowId]);
        setState({
            ...state,
            expanded: event.value
                ? state.expanded.filter((id) => id !== event.dataItem.rowId)
                : [...state.expanded, event.dataItem.rowId],
        });
    };
    //#endregion


    //#region method for row and column manipulation

    //#endregion


    //#region method/onchange event
    const onRowClickChange = (event) => {
        const currentRowIndex = event.dataItem.position - 1;
        let newSelectedState = selectedState;
        let view = qteCurGridViewType;

        selectedItems = [typeof currentRowIndex !== "undefined" ? event.dataItem : []];
        unCheckHeader();
        unCheckLines();
        setSelectedRows(selectedItems);
        setSelectedIndex(currentRowIndex);
    }

    const getIndex = (dataItem) => {
        return grid.current.flattedData.findIndex((x)=>x.dataItem.rowId === dataItem.rowId);
    }

    // const onSelectionChange = (event) => {
    //     if(!dataItem) return;
    //     if (allowCopy) {
    //         if (typeof event.startColIndex !== "undefined") {
    //             if (event.startColIndex === 0) {
    //                 dataItem.forEach(
    //                     (item) => {
    //                         selectedState[item.id] = false;
    //                     }
    //                 ); //clear selected state
    //             }
    //         }
    //     }
    //     let newSelectedState = selectedState;
    //     let parentId = "";

    //     let view = qteCurGridViewType;
    //     const sortItems = dataItem.sort((a, b) => a.position - b.position);
    //     //let viewType = sessionStorage.getItem('selectedView');
    //     //let viewTypeValue = viewType.search('Normal');

    //     if((event.startColIndex === 16 || event.colIndex === 16) && view == "Normal"){
    //             fetchSOH(event.dataItems[event.startRowIndex].stStockCode);
    //     }

    //     if ((typeof event.startColIndex !== "undefined" && (event.startColIndex === 0 || event.startColIndex === 2 || event.startColIndex === 3)) || (event.colIndex !== 1 && event.colIndex !== undefined)) { //if not equal to 1, it means that the selection is not from the checkbox
    //         // const itemId = typeof event.startRowIndex !== "undefined" ? sortItems[event.startRowIndex].id : event.dataItem.id;
    //         const itemId = typeof event.startRowIndex !== "undefined" ? event.dataItems[event.startRowIndex].id : event.dataItem.id;
    //         const selRow = sortItems.findIndex((x)=>x.id === itemId || x.itemId === itemId);
    //             if(selRow >= 0){
    //                 if (sortItems[selRow].groupId !== undefined){
    //                     selectedItems = [sortItems[selRow]];
    //                     setSelectedRows(selectedItems);
    //                 }
    //             }

    //         if ((typeof event.startColIndex !== "undefined" && (event.startColIndex === 0 || event.startColIndex === 2 || event.startColIndex === 3))) {
    //             editRow = {
    //                 editItemField: "headerRow",
    //                 editItemId: event.dataItems[event.startRowIndex].id,
    //                 dataItem : selectedItems[0],
    //                 editMode: false,
    //                 value: selectedItems[0]["headerRow"],
    //             }
    //         } else {
    //             editRow = {
    //                 editItemField: event.field === "" ? null : event.field,
    //                 editItemId: event.dataItem.id,
    //                 dataItem : selectedItems[0],
    //                 editMode: true,
    //                 value: selectedItems[0][event.field],
    //             }
    //         }

    //         setDataSrc({
    //             data:[...extendData()],
    //             editItem: editRow.dataItem,
    //             editItemField: editRow.editItemField,
    //         });
    //         if (editRow.editMode) {
    //             enterEdit(editRow.dataItem, editRow.editItemField, editRow.value)
    //         }
    //         return;
    //     }

    //     if (typeof event.syntheticEvent !== "undefined" && typeof event.dataItem.subItems !== "undefined"){
    //         const checkboxElement = event.syntheticEvent.target;
    //         const checked = checkboxElement.checked;
    //         const hasActualItems = event.dataItem.subItems.some((item) => item.rowType !== "");
    //         newSelectedState[event.dataItem.id] = event.dataItem.rowType === "" && event.dataItem.stStockCode.length === 0 ? false : checked;
    //         parentId = event.dataItem.id;
    //         if (typeof event.dataItem.subItems !== "undefined") {
    //             event.dataItem.subItems.forEach((item) => {
    //                 if (parentId === item.parentId) {
    //                     newSelectedState[idGetter(item)] = item.rowType === "" && item.stStockCode.length === 0 && hasActualItems ? false : checked;
    //                 }
    //                 if (item.subItems) {
    //                     item.subItems.forEach((subItem) => {
    //                         newSelectedState[idGetter(subItem)] = subItem.rowType === "" && subItem.stStockCode.length === 0 ? false : checked;
    //                         if (subItem.subItems) {
    //                             subItem.subItems.forEach((subItemsOfSub) => {
    //                                 newSelectedState[idGetter(subItemsOfSub)] = subItemsOfSub.rowType === "" && subItemsOfSub.stStockCode.length === 0 ? false : checked;
    //                             });
    //                         }
    //                     });
    //                 }
    //             });
    //         }
    //         setSelectedState(newSelectedState);
    //         selectedItems = sortItems.filter((item) => newSelectedState[idGetter(item)] === true);
    //         // (checked)
    //         //     ? event.syntheticEvent.currentTarget.parentNode.parentNode.parentNode.parentNode.classList.add("selected-row")
    //         //     : event.syntheticEvent.currentTarget.parentNode.parentNode.classList.remove("selected-row");
    //         for (var i = 0; i < event.syntheticEvent.currentTarget.parentNode.parentNode.parentNode.rows.length-1; i++) {
    //             const sameGrpLevel = event.dataItem.rowType === "G"
    //                                     ? dataItem[i].groupId === event.dataItem.groupId
    //                                     : event.dataItem.rowType === "SG"
    //                                         ? dataItem[i].subGroupId === event.dataItem.subGroupId
    //                                         : event.dataItem.rowType === "A"
    //                                             ? dataItem[i].assemblyId === event.dataItem.assemblyId
    //                                             : false;
    //             if (checked && sameGrpLevel) {
    //                 event.syntheticEvent.currentTarget.parentNode.parentNode.parentNode.rows[i].classList.add("selected-row");
    //             } else if (!checked && sameGrpLevel) {
    //                 event.syntheticEvent.currentTarget.parentNode.parentNode.parentNode.rows[i].classList.remove("selected-row");
    //             }
    //         }
    //     } else if (typeof event.syntheticEvent !== "undefined") {
    //         newSelectedState = selectedState;

    //         // if (!renderers.selectedItems.length > 0) {
    //         //     selectedItems = [];
    //         //     setSelectedState([]);
    //         // }

    //         const checkboxElement = event.syntheticEvent.target;
    //         const checked = checkboxElement.checked;
    //         newSelectedState[event.dataItem.id] = checked;
    //         setSelectedState(newSelectedState);
    //         selectedItems = sortItems.filter((item) => newSelectedState[idGetter(item)] === true);
    //         (checked)
    //             ? event.syntheticEvent.currentTarget.parentNode.parentNode.classList.add("selected-row")
    //             : event.syntheticEvent.currentTarget.parentNode.parentNode.classList.remove("selected-row");
    //     } else {
    //         setSelectedState({});
    //         let element = document.querySelectorAll(".selected-row");
    //         element.forEach(function(el){
    //             el.classList.remove('selected-row');
    //         });
    //         selectedItems = [typeof event.startRowIndex === "undefined" ? event.dataItem  : sortItems[event.startRowIndex]];
    //     }


    //     setSelectedRows(selectedItems);
    //     setSelectedIndex(event.endRowIndex);
    //     setDataSrc({data:extendData()});
    // }

    const onSelectionChange = (event) => {
        try {
            setCheckedItems({[id]:[]});
            setInitialLoad(false);
            if(!dataItem) return;
            if (allowCopy) {
                if (typeof event.startColIndex !== "undefined") {
                    if (event.startColIndex === 0) {
                        dataItem.forEach(
                            (item) => {
                                selectedState[item.id] = false;
                            }
                        ); //clear selected state
                    }
                }
            }
            let newSelectedState = selectedState;
            if (typeof document.activeElement.cellIndex !== "undefined") {
                if((columns.filter((f)=>f.show)[document.activeElement.cellIndex].field === "soh") && qteCurGridViewType == "Normal"){
                    const rowIndex = event.startRowIndex??0;
                    if (!["G","SG","A","AI","DX","LN","","CI"].includes(event.dataItems[event.endRowIndex].rowType)){
                        if (typeof event.dataItems[event.endRowIndex].stStockCode === "undefined" || event.dataItems[event.endRowIndex].stStockCode === ""){
                            toast.error("Error reading Part No.");
                        } else  fetchSOH(event.dataItems[event.endRowIndex].stStockCode);
                    }

                }
            }
            const sortItems = grid.current.flattedData;
            if (typeof event.syntheticEvent !== "undefined" && typeof event.dataItem.subItems !== "undefined"){
                const checkboxElement = event.syntheticEvent.target;
                const checked = checkboxElement.checked;
                let selItems = [];
                if (checked) {
                    newSelectedState.push(event.dataItem.id);
                    selItems.push(event.dataItem);
                    event.dataItem.subItems.forEach((item) => {
                        newSelectedState.push(item.id);
                        selItems.push(item);
                        if (item.subItems) {
                            item.subItems.forEach((subItem) => {
                                newSelectedState.push(subItem.id);
                                selItems.push(subItem);
                                if (subItem.subItems) {
                                    subItem.subItems.forEach((subItemsOfSub) => {
                                        newSelectedState.push(subItemsOfSub.id);
                                        selItems.push(subItemsOfSub);
                                        });
                                }
                            });
                        }
                    });
                } else {
                    const idx = selectedState.indexOf(event.dataItem.id);
                    newSelectedState.splice(idx, 1);
                    event.dataItem.subItems.forEach((item) => {
                        const idx = selectedState.indexOf(item.id);
                        newSelectedState.splice(idx, 1);
                        if (item.subItems) {
                            item.subItems.forEach((subItem) => {
                                const idx = selectedState.indexOf(subItem.id);
                                newSelectedState.splice(idx, 1);
                                if (subItem.subItems) {
                                    subItem.subItems.forEach((subItemsOfSub) => {
                                        const idx = selectedState.indexOf(subItemsOfSub.id);
                                        newSelectedState.splice(idx, 1);
                                    });
                                }
                            });
                        }
                    });
                }

                setSelectedState(newSelectedState);
                
                selectedItems = selItems;
                setCheckedItems({[id]:selectedItems});
                setState({
                    ...state,
                    selected: newSelectedState,
                });

                setSelectedRows(selectedItems);


            } else if (typeof event.syntheticEvent !== "undefined") {
                newSelectedState = selectedState;
                const currentRowIndex = event.dataItem.position - 1;
                const checkboxElement = event.syntheticEvent.target;
                const checked = checkboxElement.checked;
                if (checked) {
                    newSelectedState.push(event.dataItem.id);

                } else {
                    const idx = selectedState.indexOf(event.dataItem.id);
                    newSelectedState.splice(idx, 1);
                }

                setSelectedState(newSelectedState);
                
                // selectedItems = sortItems.filter(item =>
                //     newSelectedState.includes(item.dataItem.id)
                // ).map(item => item.dataItem);
                selectedItems = typeof currentRowIndex === "undefined"
                ? [event.dataItem]
                : sortItems
                    .filter(item => newSelectedState.includes(item.dataItem.id))
                    .map(item => item.dataItem);

                setCheckedItems({[id]:selectedItems});
                setState({
                    ...state,
                    selected: newSelectedState,
                });

                setSelectedRows(selectedItems);

            }

            const rowIdx = event.startRowIndex??0;
            const currentRowId = sessionStorage.getItem("currRowId");

            if (rowIdx <= 0) {
                sessionStorage.setItem("currRowIndex", 0)
                sessionStorage.setItem("currColIndex", event.startColIndex??0);
                sessionStorage.setItem("currRowId", getCurrentlyEditingData(0).rowId);
                const origIndex = dataItem.findIndex((x)=>x.rowId === getCurrentlyEditingData(0).rowId);
            } else {
                sessionStorage.setItem("currRowIndex", rowIdx)
                sessionStorage.setItem("currColIndex", event.startColIndex??0);
                sessionStorage.setItem("currRowId", getCurrentlyEditingData(rowIdx).rowId);
                const origIndex = dataItem.findIndex((x)=>x.rowId === getCurrentlyEditingData(rowIdx).rowId);
            }

            if (selectedItems.length > 0 && typeof event.syntheticEvent === "undefined") {
                unCheckHeader();
                unCheckLines();
            }
        } catch (error) {
            console.log("Error selecting item:", error);

        }

    };

    const onHeaderSelectionChange = (event) => {
        try {
            const checkboxElement = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = [];
            const selItems = [];
            setCheckedItems({[id]:[]});

            if (checked) {
                for(let i = 0; i < dataItem.length - 1; i++) {
                    const items = dataItem[i];
                    newSelectedState.push(idGetter(items));
                    selItems.push(items);

                };
            }
            setSelectedState(newSelectedState);

            setState({
                ...state,
                selected: newSelectedState,
            });

            selectedItems = selItems;
            setSelectedRows(selItems);
            setCheckedItems({[id]:selectedItems});
        } catch (error) {
            console.log("Error selecting header:", error);

        }
    };

    const onKeyDownSetSelectedItem = (index) => {
        if (dataItem === undefined) return;
        const dtItem = grid.current.flattedData[index]?.dataItem;
        if (dtItem === undefined) return;
        //// this is for selecting Assembly without checkbox
        let hdrSelRow = dataItem[index];
        if (hdrSelRow?.rowType === "A")
        {
            selectedItems = dataItem.filter((f)=>f.assemblyId === hdrSelRow?.assemblyId);
            setSelectedRows(selectedItems);
            const actualIndex = dataItem.findIndex((x)=>x.rowId === dtItem.rowId);
            setSelectedIndex(actualIndex);
        }
        else
        {
            const actualIndex = dataItem.findIndex((x)=>x.rowId === dtItem.rowId);
            setSelectedIndex(actualIndex);
            selectedItems = [dataItem[actualIndex]];
            setSelectedRows(selectedItems);
        }

    }

    const checkDisabledField = (dataItem, field) => {
        const disableDescForItemType = ["I", "K", "L", "M", "N", "S", "Z", "NSG"];
        const disableCostEAForItemType = ["I", "K", "L", "M", "S", "Z", "G", "SG", "DX"];
        const disableAvgCostForItemType = ["G", "SG", "DX", "S"];
        const disableDcOnLPForItemType = ["G", "SG", "CI", "DX"];
        const disablePartNoForItemType = ["G", "SG", "AI"];
        const enableGroupLevelNameForItemType = ["G", "SG", "A"];
        const disaableNettEAForItemType = ["LN", "DX","AI"];
        const disableRepDiscForItemType = ["CI", "DX","A" ,"AI","LN","G","SG",""];

// if (field === "groupLevelName" && (dataItem.rowType !== "G" && dataItem.rowType !== "SG" && dataItem.rowType !== "A")) return;
        // if (field === "stStockCode" && (dataItem.rowType === "G" || dataItem.rowType === "SG" || dataItem.rowType === "A" || dataItem.rowType === "AI")) return;
        // if (field === "description" && (dataItem.rowType !== "CI" && dataItem.rowType !== "LN" && dataItem.rowType !== "DX" && dataItem.rowType !== "A" && dataItem.rowType !== "G" && dataItem.rowType !== "SG")) return;
        const disableField =
            disableRepDiscForItemType.includes(dataItem['rowType']) && field === "repDisc"
                ? true
                : disaableNettEAForItemType.includes(dataItem['rowType']) && field === "nettEA"
                    ? true
                    :!enableGroupLevelNameForItemType.includes(dataItem['rowType']) && field === "groupLevelName"
                        ? true
                        :disablePartNoForItemType.includes(dataItem['rowType']) && field === "stStockCode"
                            ? true
                            : dataItem['rowType'] === "LN"
                                && (field === "qty" || field === "nettEA" || field === "repDisc" || field === "margin"
                                    || field === "stEecostRound" || field === "actCost" || field === "stFutureCostRound")
                                ? true
                                : (dataItem['rowType'] === "G" || dataItem['rowType'] === "SG")
                                    && (field === "stStockCode" || field === "repDisc" || field === "stFutureCostRound" || field === "stEecostRound"
                                        || field === "actCost")
                                    ? true
                                    : dataItem['rowType'] === "A" &&
                                        (field === "stStockCode" ||
                                            field === "description" ||
                                            field === "nettEA" ||
                                            field === "margin")
                                        ? false
                                        : dataItem['rowType'] === "A" &&
                                            (field === "qty" ||
                                                field === "repDisc" || field === "stEecostRound" || field === "actCost" || field === "stFutureCostRound")
                                            ? true
                                            : dataItem['rowType'] === "AI" &&
                                                (field === "stStockCode" || field === "description" || field === "nettEA" || field === "repDisc"
                                                    || field === "margin" || field === "stEecostRound" || field === "actCost" || field === "stFutureCostRound")
                                                ? true
                                                : dataItem['rowType'] === "AI" &&
                                                    field === "qty"
                                                    ? false
                                                    : dataItem['type'] === ""
                                                        ? false
                                                        : (dataItem['rowType'] === "G" || dataItem['rowType'] === "SG")
                                                            && (field === "qty" || field === "nettEA" || field === "margin")
                                                            ? false
                                                            : (dataItem['rowType'] === "S")
                                                                && (field === "qty" || field === "nettEA" || field === "margin" || field === "repDisc")
                                                                ? false
                                                                : (dataItem['rowType'] === "S")
                                                                    && (field === "stFutureCostRound")
                                                                    ? true
                                                                    : field === "description" &&
                                                                        (disableDescForItemType.includes(dataItem['rowType']))
                                                                        ? true
                                                                        : field === "stEecostRound" &&
                                                                            (disableCostEAForItemType.includes(dataItem['rowType']))
                                                                            ? true
                                                                            : field === "actCost" &&
                                                                                (disableAvgCostForItemType.includes(dataItem['rowType']))
                                                                                ? true
                                                                                : field === "dcOnLp" &&
                                                                                    (disableDcOnLPForItemType.includes(dataItem['rowType']))
                                                                                    ? true
                                                                                    : dataItem['rowType'] === "DX" &&
                                                                                        (field === "qty" || field === "nettEA" || field === "repDisc" ||
                                                                                            field === "margin" || field === "stFutureCostRound")
                                                                                        ? true
                                                                                        : false;

        return disableField;
    }

    const formatValues = (dataItem, field) => {
	    const colsLeftAlign = ["groupLevelName", "selected", "stStockCode", "description"];
        const colsNonEdit = ["type", "dcCode", "dcRate", "listPrice", "l60", "l50", "l55", "l20", "soh", "totalCost", "totalPrice"];
        const costEAStyle = " ip-gridclr-costea";
        const dcOnLpStyle = " ip-gridclr-dc";
        const setToEmptyIfZero = ["repDisc"];
        const setToEmptyIfZeroFields = ["repDisc","l50", "l55", "l20", "l60", "listPrice"];
        const groupLevelZeroRepDisc = ["G","SG","A"];
        const withPercentSign = ["margin","actMargin", "avgcMargin", "frcMargin", "lcMargin","dcOnLp","repDisc"];
        const noDecimalPlaces = ["soh","qty"];

        let value =
          dataItem['rowType'] === ""
          ? ""
          : noDecimalPlaces.includes(field) && dataItem['rowType'] !== "" && parseFloat(dataItem[field]).toFixed(0) !== "NaN"
            ? editItem !== undefined ? dataItem[field] : parseFloat(dataItem[field]).toFixed(0)
            : (setToEmptyIfZero.includes(field) && groupLevelZeroRepDisc.includes(dataItem['rowType']))
            || setToEmptyIfZeroFields.includes(field) && parseFloat(dataItem[field]) === 0
            ? ""
            : withPercentSign.includes(field) && dataItem['rowType'] !== ""
              && parseFloat(dataItem[field]).toFixed(1) !== "NaN"
              ? editItem !== undefined ? parseFloat(dataItem[field]).toFixed(1) : parseFloat(dataItem[field]).toFixed(1)
              : dataItem['rowType'] === "" && dataItem['stStockCode'].length === 0
                && (field === "totalCost" || field === "totalPrice")
                ? ""
                : ((dataItem['rowType'] === "G" || dataItem['rowType'] === "SG") && (field === "lastCost" || field === "dcRate"))
                    ? ""
                    : (dataItem['rowType'] === "")
                        && (field === "nettEA" || field === "repDisc" || field === "margin"
                            || field === "actMargin" || field === "frcMargin" || field === "lcMargin" || field === "qty" || field === "totalCost" || field === "totalPrice")
                        ? ""
                        : (dataItem['rowType'] === "LN")
                            && (field === "nettEA" || field === "repDisc" || field === "margin"
                                || field === "actMargin" || field === "frcMargin" || field === "lcMargin"
                                || field === "qty" || field === "totalCost" || field === "totalPrice"
                                || field === "lastCost" || field === "l50" || field === "l55"
                                || field === "l20" || field === "l60" || field === "stEecostRound"
                                || field === "actCost" || field === "stFutureCostRound" || field === "listPrice"
                                || field === "dcRate" || field === "soh" || field === "dcOnLp")
                            ? ""
                            : (dataItem['rowType'] === "A") && (field === "l50" || field === "l55"
                                || field === "l20" || field === "l60" || field === "listPrice")
                            ? ""
                            : (field === "stEecostRound" && authType === "General" && viewType === "Normal" && (dataItem['rowType'] !== "" && dataItem['rowType'] !== "CI"))
                                ? "***"
                                : isNumber(dataItem[field])
                                      ? field !== "qty" && field !== "margin"
                                          && field !== "actMargin" && field !== "frcMargin"
                                          && field !== "lcMargin"
                                          ? field !== "soh" ? parseFloat(dataItem[field]).toFixed(2) : parseFloat(dataItem[field]).toFixed(0)
                                          : (field === "lcMargin" || field === "lastCost")
                                              && parseFloat(dataItem["lcMargin"]) === 0
                                              ? ""
                                                : parseFloat(dataItem[field]).toFixed(2)
                                      : dataItem[field] = parseFloat(dataItem[field]).toFixed(2) === "NaN"
                                        ? dataItem[field]
                                        : dataItem[field];

          value === undefined ? undefined : value;

          // Check for totalCost and totalPrice and overwrite their values if necessary
          if (dataItem['rowType'] === "" && dataItem['stStockCode'].length === 0) {
              if (dataItem['totalCost'] === "" || dataItem['totalCost'] === 0 || dataItem['totalCost'] === "NaN") {
                  value = ""; // Overwrite with new value
              }
              if (dataItem['totalPrice'] === "" || dataItem['totalPrice'] === 0 || dataItem['totalPrice'] === "NaN") {
                  value = ""; // Overwrite with new value
              }
          }

          if (field === "l50" || field === "l55" ||
              field === "l20" || field === "l60") {
              value = (value === "NaN") ? "" : value;
              value = (value === 0) ? "" : value;
          }

          return value;
    }

    const getIndexAndMatchOnScreenData = (currIndex,rowMovement) => {
        let retIndex = 0;
        try {
            if (rowMovement === "Down") {
                // if (typeof grid.current.flattedData[currIndex+1] === "undefined") return currIndex;
                // else
                retIndex = currIndex + 1;
            } else if (rowMovement === "Up") {
                if (typeof grid.current.flattedData[currIndex-1] === "undefined") return currIndex;
                else retIndex = currIndex - 1;
            } else if (rowMovement === "current") {
                retIndex = currIndex;
            }
        } catch (error) {

            console.log("Error getting index based on screen data:", error);
        }

        return retIndex;
    }

    const getCurrentlyEditingData = (currIndex) => {
        try {
            const basedData = grid.current.flattedData[currIndex].dataItem;
            return basedData;
        } catch (error) {
            console.log("Error getting current on edit data:", error);
            return dataItem[currIndex];
        }

    }

    const onKeyDown = (event) => {
        try {
            const skipKey = ['Control','Shift','Alt','CapsLock','Meta'];
            if (skipKey.includes(event.syntheticEvent.key)) return;
            const arrowKeys = ['ArrowDown','ArrowUp','ArrowLeft','ArrowRight'];
            const colIdx =  Number(sessionStorage.getItem("currColIndex"))||4;
            const rowIdx = Number(sessionStorage.getItem("currRowIndex"))||0;
            const getdataInRow = rowIdx;
            if (!arrowKeys.includes(event.syntheticEvent.key)) {


                if (colIdx < 0) return;
                let col = columns.filter((f)=>f.show)[colIdx];

                const exitEditKey = ['Enter','Tab'];
                const cancelKey = ['Escape'];
                const ignoreLineForItemCode = ['G','SG','AI'];
                const allowedToChangeDesc = ['G','SG','A','CI','LN','DX'];
                const colEditable = columns.find((x)=>x.field === col.field)?.editable;

                if (exitEditKey.includes(event.syntheticEvent.key)) {
                    // if (col.field === "stStockCode"
                    if (state.editItem === undefined && event.syntheticEvent.key === "Enter") {
                        const idx = getIndexAndMatchOnScreenData(rowIdx,"Down");

                        if (idx <= 0) {
                            onKeyDownSetSelectedItem(0);
                            sessionStorage.setItem("currRowIndex", 0);
                        } else {
                            onKeyDownSetSelectedItem(idx);
                            sessionStorage.setItem("currRowIndex", idx);
                        }
                        return;
                    } else if ((colEditable
                        && !ignoreLineForItemCode.includes(getCurrentlyEditingData(rowIdx).rowType) && state.editItem)
                        || (colEditable
                        && allowedToChangeDesc.includes(getCurrentlyEditingData(rowIdx).rowType) && state.editItem)) {
                        itemCodeChange(col, getCurrentlyEditingData(rowIdx));
                        // setTimeout(() => {
                            if (event.syntheticEvent.key === "Enter") {
                                const idx =  getIndexAndMatchOnScreenData(rowIdx,"Down");
                                if (idx < 0) {
                                    onKeyDownSetSelectedItem(0);
                                    sessionStorage.setItem("currRowIndex", 0)
                                } else {
                                    onKeyDownSetSelectedItem(idx);
                                    sessionStorage.setItem("currRowIndex", idx)
                                }
                            } else {
                                const idx =  getIndexAndMatchOnScreenData(rowIdx,"current");
                                if (idx < 0) {
                                    onKeyDownSetSelectedItem(0);
                                    sessionStorage.setItem("currRowIndex", 0)
                                } else {
                                    onKeyDownSetSelectedItem(idx);
                                    sessionStorage.setItem("currRowIndex", idx)
                                }
                                sessionStorage.setItem("currColIndex", colIdx+1);
                            }
                        // }, 500);
                    } else {
                        exitEdit(event.syntheticEvent.key);
                    }
                }
                if (cancelKey.includes(event.syntheticEvent.key)) {
                    cancelChanges();
                    return;
                }
                if (state.editItem) return;

                enterEditField(event.dataItems === undefined
                    ? event.dataItem
                    : getCurrentlyEditingData(rowIdx), col.field);

            } else if (arrowKeys.includes(event.syntheticEvent.key)) {
                if (event.syntheticEvent.key === 'ArrowDown' || event.syntheticEvent.key === "ArrowUp") {

                    const idx = getIndexAndMatchOnScreenData(rowIdx,event.syntheticEvent.key === 'ArrowDown' ? "Down" : "Up");
                    if (idx < 0) {
                        onKeyDownSetSelectedItem(0);
                        sessionStorage.setItem("currRowIndex", 0)
                        sessionStorage.setItem("currRowId", getCurrentlyEditingData(idx).rowId);
                    } else {
                        onKeyDownSetSelectedItem(idx);
                        sessionStorage.setItem("currRowIndex", idx)
                        sessionStorage.setItem("currRowId", getCurrentlyEditingData(idx).rowId);
                    }

                    if (state.editItem) {
                        cancelChanges();
                    }

                } else if (event.syntheticEvent.key === 'ArrowRight' || event.syntheticEvent.key === 'ArrowLeft') {
                    const idx =  event.syntheticEvent.key === 'ArrowRight'
                        ? event.nativeEvent.target.cellIndex + 1
                        : event.nativeEvent.target.cellIndex === 0  ? 0 : event.nativeEvent.target.cellIndex - 1;
                    sessionStorage.setItem("currColIndex", idx);
                }

            }
        } catch (error) {
            console.log("Error on key down:", error);

        }
    };

    const enterEditField = (dataItem, field) => {
        if (!dataItem) return;
        if (!columns.find((x)=>x.field === field)?.editable) return;
        if (checkDisabledField(dataItem, field)) return;
        setState({
        ...state,
        editItem: {
            ...dataItem,
        },
        editItemField: field,
        });
        sessionStorage.setItem("stateUpdate", false);
    };

    const itemChange = (event) => {
        const field = event.field;
        setState({
            ...state,
            changes: true,
            data: mapTree(state.data, subItemsField, (item) =>
                event.dataItem.id === item.id
                ? extendDataItem(item, subItemsField, {
                    [field]: event.value,
                    })
                : item
            )
        });
    };

    const cancelChanges = () => {
        try {
            setState({
                ...state,
                data: mapTree(state.data, subItemsField, (item) =>
                        state.editItem.id === item.id
                        ? extendDataItem(item, subItemsField, {
                            [state.editItemField]: state.editItem[state.editItemField],
                            })
                        : item
                    ),
                editItem: undefined,
                editItemField: undefined,
                changes: false,
            });
        } catch (error) {
            console.log("Error cancelling changes:", error);
        }

    };

    const enterEdit = (dataItem, field) => {

    }

    const itemCodeChange = async (col,dataItem) => {
        if (itemValidation(col.field,dataItem) === false) return;
        const nettEAValid = itemValidationForNettEA(dataItem);
        
        if (nettEAValid) {
            const returnEvent = {
                field: col.field === "groupLevelName"
                    ? dataItem.rowType === "G"
                        ? "displayGroupName"
                        : dataItem.rowType === "SG"
                            ? "displaySubGroupName"
                            : col.field
                    : col.field,
                dataItem: dataItem,
                value: dataItem[col.field],
                rowId: dataItem.rowId
            };
            return handleItemChange(returnEvent);
        }
    }

    const exitEdit = (e) => {
        setState({
            ...state,
            changes: true,
            editItem: undefined,
            editItemField: undefined,
        });
        setEnterField(undefined);
        setEnterDataItem(undefined);
    };

    const unCheckHeader = () => {
        let checkboxHeader = document.querySelector("th .k-checkbox-md");
        if (checkboxHeader !== null) {
            checkboxHeader.checked = false;
        }
    }

    const unCheckLines = () => {
        setSelectedState([]);
        let newSelectedState = [];
        setState({
                ...state,
                selected: newSelectedState,
            });
    }

    const fetchSOH = async (stStockCode) => {
        setSohData([]);
        setTotalSOH(0);
        console.log("Click SOH : " ,stStockCode);
        const response = await getResponse("Quote/getsoh", { stockCode: stStockCode })
            .then((response) => {
                console.log("Click SOH res: " ,response);
                if (response.data !== null) {
                    setSelectedStockCode(stStockCode);
                    const newArray = [];
                    let computeSOH = 0;
                    for (let i = 0; i < response.length; i++) {
                        computeSOH = computeSOH + response[i].soh;
                        newArray.push({ Whse: response[i].whse, Available: response[i].soh });
                        setSohData(newArray);
                    }
                    setTotalSOH(computeSOH);
                    console.log("Successfully retrieved SOH details.");
                    setShowSOHDialog(true);
                }
                else {
                    console.log("Failed to save data.");
                    setShowSOHDialog(false);
                }
            })
            .catch((error) => {
                console.error("Error retrieving SOH details:", error);
                setShowSOHDialog(false);

            });
    }

    const itemValidation = (field,itemRow) => {

        if (field === "nettEA") {
            if (itemRow.rowType === "CI" && (itemRow.qty === 0 && itemRow.stEecostRound === 0)) {
                cancelChanges();
                toast.error("Qty or CostEA should be added before NettEA");
                return false;
            }

            if (itemRow.stStatus === "X" && itemRow.stEecostRound === 0) {
                cancelChanges();
                toast.error("CostEA should be added before NettEA");
                return false;
            }
        }
        if (field === "stStockCode") {
            if (itemRow.stStockCode === "") {
                cancelChanges();
                return false;
            }
        }

        return true;
    }

    const itemValidationForNettEA = (field,itemRow) => {
        if (itemRow?.rowType === "CI" && field === "nettEA") {
            if (Number(itemRow.qty) === 0 || itemRow.qty === "") {
                return false;
            } else return true;
        } else return true;
    }

    const handleDblClick = (event) => {
        onKeyDown(event);
    }
    //#endregion


    //#region render
    renderers = new Renderers(enterEdit, exitEdit, editField, state.editItem,
        onKeyDownSetSelectedItem, cancelChanges,authType,qteCurGridViewType, grid.current,columns.filter((f)=>f.show));
    const { data, changes, expanded, selected, editItem, editItemField } = state;
    const editItemId = editItem ? editItem.id : null;

    const extendData = (dataTree) => {
        const expanded = state.expanded;
        return mapTree(dataTree, subItemsField, (item) =>
        extendDataItem(item, subItemsField, {
            [expandField]: expanded.includes(item.rowId) ? true : undefined,
            selected: selected.includes(item.id) ? true : undefined,
            [editField]: item.id === editItemId ? editItemField : undefined,
        })
        );
    };

    const processData = () => {
        let { data, dataState } = state;
        // let sortedData = orderBy(data, dataState.sort, subItemsField);
        return extendData(data);
    };

    const renderState = () => {
        // if (state.editItem) {
            exitEdit();
            setState({
                ...state,
                data: [...dataTree()],
                expanded: isCollapseAll
                    ? []
                    : [...dataItem.filter((item) => item.rowType === "G" || item.rowType === "SG").map((item) => item.rowId)],
                editItem: undefined,
                changes: true,
                editItemField: undefined,
            });
        // }

        setFocusingInitiating(true);
        setInitialLoad(false);
        unCheckHeader();
    }
    //#endregion

    //#region treelist properties
    const [tableProps, setTableProps] = useState({
        style: {
            tableLayout: "fixed",
            overflow: "auto"
        },
    });

    const [offSet, setOffset] = React.useState({
        current: {
            left: 0,
            top: 0,
        },
    });

    const [renderPopup, setRenderPopup] = useState(false);

    function handleContext(e, field) {

        const viewType = qteCurGridViewType;

        if (viewType !== "Normal") return;
        setOffset({
            current: {
                left: e.pageX,
                top: e.pageY,
            },
        });
        setShow(true);
    }

    const handleOnSelect = (e) => {
        setDefineCols(true);
        // let visibleColumns = columns;
        columns = customizedCol;
        if (!e.item.disabled && e.item) {
            const index = columns.findIndex(itm => itm.title === e.item.text);
            if (index < 0) return;
            const colIndx = colMenus.findIndex(itm => itm.text === e.item.text);
            columns[index].show = e.item.icon === 'check' ? false : true;
            columns[index].userSelected = e.item.icon === 'check' ? false : true;
            colMenus[colIndx].icon = e.item.icon === 'check' ? 'uncheck' : 'check';
            const filterData = columns.filter((f) => f.userConfig === true);
            let paramData = [];
            filterData.map((f) => {
                paramData.push({
                    field: f.field,
                    userConfig: f.userConfig,
                    userSelected: f.userSelected
                })
            }
            );
            const data = {
                userId: user?.homeAccountId,
                module: "Quotation",
                gridMenusFor: "ItemGrid",
                userPrefColumns: paramData
            }

            setTimeout(() => {
                SaveUserPref(data);
            }, 2000);
        };

        setShow(false);
    };

    const handleContextMenu = (props) => {
        setDefineCols(true);
        return (
            <>
                <div
                    className="k-link"
                    onClick={props.onClick}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        handleContext(e, props.field);
                    }}
                >
                    <a className="k-link" onClick={props.onClick}>
                        <span title={props.title}>{props.title}</span>
                        {props.children}
                    </a>
                </div>
            </>

        );

    }
    //#endregion


    // //#region useEffect
    useEffect(() => {
        setInitialLoad(true);
        sessionStorage.setItem("currRowIndex", 0);
        sessionStorage.setItem("currRowId", getCurrentlyEditingData(0).rowId);
        if (sessionStorage.getItem("currColIndex") === null
        || sessionStorage.getItem("currColIndex") === undefined
        || sessionStorage.getItem("currColIndex") === "") {
            sessionStorage.setItem("currColIndex", 4);
        }
        sessionStorage.setItem("stateUpdate", false);
    },[]);

    useEffect(() => {
        currentView = qteCurGridViewType;
    }, [qteCurGridViewType]);

    useEffect(() => {
        setTableProps({
            style: {
                tableLayout: "fixed",
                overflow: "auto",
                width: scrollValue + "%"
            }
        });
    }, [scrollValue]);

    useEffect(() => {

        // Retrieve existing viewTypes from sessionStorage if available
        let viewTypes = JSON.parse(sessionStorage.getItem('selectedView')) || [];

        // Get sessionId from sessionStorage or default to '0'
        let sessionId = (sessionStorage.getItem('id') && sessionStorage.getItem('id').trim() !== '') ? sessionStorage.getItem('id') : '0';

        let selectedViewType = (viewTypes && viewTypes.trim && typeof viewTypes.trim === 'function' && viewTypes.trim() !== '') ? viewTypes.trim() : '';
        selectedViewType = (selectedViewType === null || selectedViewType === "") ? viewOptions[0].text : selectedViewType;

        // Initialize selectedViewType based on existing viewTypes or default to "Management"
        if (viewTypes.length > 0) {
            const existingEntry = viewTypes.find(item => Object.keys(item)[0] === sessionId);
            if (existingEntry) {
                selectedViewType = existingEntry[sessionId];
            }
        }
        // Check if id exists in viewTypes
        let idExists = viewTypes.length > 0 ? viewTypes.some(item => Object.keys(item)[0] === id) : false;


        if (!idExists) {
            // Add draftQuoteId with "Management" viewType to viewTypes array
            viewTypes.push({ [id]: viewOptions[0].text });

            // Store updated viewTypes back to sessionStorage
            sessionStorage.setItem('selectedView', JSON.stringify(viewTypes));

        }

        // Conditional logic based on selectedViewType
        if (selectedViewType !== "Normal") {
            columns.forEach(r => {
                if (r.userConfig) r.show = true;
            });
            setRenderPopup(false);
        } else {
            setRenderPopup(true);
        }

        const selectedSession = viewOptions.find(x => x.text === selectedViewType);
        const findIndex = viewOptions.findIndex(x => x.text === selectedViewType);
        handleItemGrid(findIndex, selectedSession.text);
        setDropdownValue(selectedSession.text);
        setQteCurGridViewType(selectedSession.text);
        setIsQuoteSaved(false);
        setSelectedView(selectedSession);
    }, [qteCurGridViewType]);

    const renderData = useMemo(() => renderState(), [dataItem,isCollapseAll]);
   
    //#region return
   return(

    <>
        {!data && <Spinner />}
        {showSOHDialog ? (  //add Normal view validation here
            <Dialog
                className="ip-dialog-sohwarehouse"
                width="20%"
                title={"Stock Available by Warehouse"}
                onClose={() => {
                    setShowSOHDialog(false);
                }}>
                <p>Stock Code: : <b>{selectedStockCode}</b> </p>
                <Grid resizable={true} data={sohData} className="ip-base-grid-soh">
                    <Column field="Whse" title="Whse" footer="Whse" />
                    <Column field="Available" title="Avialable" footer="Available" />
                </Grid>
                <div className="labour-totalfooter">
                    <span className="me-3 float-end">
                        <span className="ms-5">Total SOH : <span className="value">{totalSOH}</span></span>
                    </span>
                </div>
                <DialogActionsBar>
                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                        <Button className="px-4" onClick={() => {
                            setShowSOHDialog(false);
                        }} >
                            Close
                        </Button>
                    </div>
                </DialogActionsBar>
            </Dialog>
        ) : ""}
        {data  && (
            <Tooltip openDelay={50} position="bottom" anchorElement="target">
                <TreeList
                        ref={gridRef}
                        scrollable="scrollable"
                        style={{ height: "400px", overflow: "auto", width: "auto" }}
                        rowHeight={20}
                        className="ip-quote-item-grid"
                        {...state.data}
                        data={processData()}
                        autofocus={true}
                        onRowDoubleClick={(event) => {handleDblClick(event)}}
                        resizable={true}
                        selectable={{
                            enabled: true,
                            cell: true,
                            mode: selectionMode,
                        }}
                        tableProps={tableProps}
                        editField={editField}
                        expandField={expandField}
                        subItemsField={subItemsField}
                        selectedField={SELECTED_FIELD}
                        dataItemKey={DATA_ITEM_KEY}
                        cellRender={renderers.cellRender}
                        rowRender={renderers.rowRender}
                        onItemChange={(e)=>itemChange(e)}
                        onExpandChange={(e)=>onExpandChange(e)}
                        onKeyDown={(e)=>onKeyDown(e)}
                        navigatable={true}
                        tabIndex={0}
                        onRowClick={(e)=>onRowClickChange(e)}
                        onSelectionChange={(e)=>onSelectionChange(e)}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        columns={columns.map((column,i) => ({
                            ...column,
                            autoFocus: true,
                            ref:{cellRef},
                            tabIndex: i,
                            width: customFieldWidth[0][scrollValue][column.field] ? customFieldWidth[0][scrollValue][column.field] : column.width,
                            headerCell: column.field === "selected" ? TreeListHeaderSelectionCell : props => handleContextMenu(props),
                            editCell: editItemField === column.field && column.editable
                                ? TreeListTextEditor
                                : column.cell,
                            cell: column.field === "headerRow" || column.field === "IconIndicator"
                                || column.field === "hideRow" || column.field === "hideQty" || column.field === "hidePrice"
                                ? column.cell
                                : column.field === "selected"
                                    ? TreeListSelectionCell
                                    : column.cell,
                        })).filter((f) => f.show)}
                />

                {renderPopup ? (
                    <Popup show={show} offset={offSet.current}>
                        <Menu
                            // onItemClick={(e) => handleOnSelect(e)}
                            onSelect={(e) => handleOnSelect(e)}
                            vertical={true}
                            style={{
                                display: 'inline-block',
                            }}
                            items={colMenus}
                        />
                    </Popup>) : ""
                }

            </Tooltip>

        )}
    </>);
    //#endregion

};

export default QuotesItemGrid;