import React, { createContext, useContext, useState, useEffect , useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useQuoteDetails } from "./QuoteDetailsProvider";
import { useFirstCustomer } from "./FirstQuoteCustomerContext";
import { useDataRow } from "./ItemGridRowContext";
import { toast, Slide } from "react-toastify";
import nodeConfig from "../../src/nodeConfig.json";
import { useQtyRow } from "./ItemGridQtyRowContext";
import { useNettEaRow } from "./ItemGridNettEaRowContext";
import { useItemList } from "./ItemListProvider";
import { useSubGroupList } from "./SubGroupListContext";
import { useMarginRow } from "./IGMarginRowContext";
import { set } from "lodash";
import { nodes } from "@progress/kendo-react-editor";
import { useUniteDetails } from "./UnitDetailsProvider";
import { usePriceListDetails } from "./PriceListDetailsProvider";
const NodeContext = createContext();

export const useNodeContext = () => {
    return useContext(NodeContext);
};

export const NodeProvider = ({ children }) => {
    // const [nodeIdList, setNodeIdList] = useState(() => {
    //     const savedNodes = localStorage.getItem("quotes");
    //     return savedNodes ? JSON.parse(savedNodes) : [];
    // });
    const [nodeIdList, setNodeIdList] = useState(() => {
        const savedNodes = sessionStorage.getItem("quotes");
        const allNodes = savedNodes ? JSON.parse(savedNodes) : [];
        return allNodes.filter(node => !node.nodeName.includes("Draft") && !node.nodeName.includes("New"));
    });
    
    const [quotes, setQuotes] = useState([]);
    const [maxQuoteIndex, setMaxQuoteIndex] = useState(0);
    const [module, setModule] = useState("");
    const [quoteContactTypes, setQuoteContactTypes] = useState({});
    const { priceListDetails, updatePriceListDetailsForId} = usePriceListDetails();
    const saveRef = useRef();

    
    const { quoteDetails, setQuoteDetails } = useQuoteDetails();
    const {unitDetails, setUnitDetails,unitName,setUnitName,updateUnitDetailsForId,newUnitName, setNewUnitName } = useUniteDetails();
    const { firstCustomer, setFirstCustomer } = useFirstCustomer();
    const { rowData, setRowData } = useDataRow();
    const { rowQtyData, setQtyRowData } = useQtyRow();
    const { rowNettEaData, setNettEaRowData } = useNettEaRow();
    const { originalItems, setOriginalItems } = useItemList();
    const { sgList, setSGroupList } = useSubGroupList();
    const { rowMarginData, setMarginRowData } = useMarginRow();
    const [saveQuotation, setSaveQuotation] = useState(0);
    const [isQuoteSaved, setIsQuoteSaved] = useState(false);
    const [quoteContactDetailsMobile, setQuoteContactDetailsMobile] = useState("");
    const [isSelectedUnitAdded, setIsSelectedUnitAdded] = useState(false);
    const [quoteUpdated, setQuoteUpdated] = useState(false);
    const [isRemoveItemsClicked , setIsRemoveItemsClicked] = useState(false);
    const [isDoneClickForPdf, setIsDoneClickForPdf] = useState(false);
    const [hasQuoteChanged, setHasQuoteChanged] = useState(false);

    const [createdBy ,setCreatedBy]=useState('');
    const[ isOldQuoteDone, setIsOldQuoteDone] = useState(false);
    const [hideTotalState, setHideTotalState] = useState({});
    const [quoteUpdateTriggered , setQuoteUpdateTrigger] = useState(false);

    //new units
    const [newUnits, setNewUnits] = useState([]);
    const [maxNewUnitIndex, setMaxNewUnitIndex] = useState(0);
    const [unitNodeIdList, setUnitNodeIdList] = useState([]);
    

    //price lists
    const [priceListNodes, setPriceListNodes] = useState([]);
    const [maxPriceListIndex, setMaxPriceListIndex] = useState(0);
    const [priceNodeIdList, setPriceNodeIdList] = useState([]);
    const [quoteNodeIdList, setQuoteNodeIdList] = useState([]);

    //common for close node
    const [commonNodeIdList, setCommonNodeIdList] = useState([]);

    const navigate = useNavigate();

    //Node Count config
    const [maxQuoteNodeCount, setMaxQuoteNodeCount] = useState(0);
    const [maxNewUnitsNodeCount, setMaxNewUnitsNodeCount] = useState(0);
    const [openQuoteCount, setOpenQuoteCount] = useState(0);
    const [isOpenQuoteCount, setisOpenQuoteCount] = useState(false);
    const [isPastedUnits , setIsPastedUnits] = useState(false);

    ///progress bar
    const [openShowProgress, setOpenShowProgress] = useState(false);
    const [importItemShowProgress, setImportItemShowProgress] = useState(false);
    const [copyPasteItemShowProgress, setCopyPasteItemShowProgress] = useState(false);
    const [progress, setProgress] = useState({ percentage: 0, remainingTime: 0 });

    ////Terms and conditions
    const [isDoneChangesForQuoteTerms, setIsDoneChangesForQuoteTerms] = useState(false);

    //Node Config:Currently fetching the data from json file
    useEffect(() => {
        nodeConfig.filter(
            (cfg) => (
                setMaxQuoteNodeCount(cfg.nodeInstance[0].quote), setMaxNewUnitsNodeCount(cfg.nodeInstance[1].newUnit)
            )
        );
    }, []);

    //Node Config: handle all the checking for adding nodes
    const handleCanAddNode = (currCount, maxNodeCount, nodeName) => {
        if (isOpenQuoteCount && currCount === 10) {
            setisOpenQuoteCount(false);
            return;
        }
        // if(nodeName =='New Units'){
        //     setUnitName("") 
        // }
        currCount = currCount + 1; // Added this line of code because the index started at zero
        setOpenQuoteCount(currCount);
        //console.log("Quote Count Maduka currCount : " + currCount + " maxNodeCount : " + maxNodeCount + " nodeName : " + nodeName)
        if (currCount > maxNodeCount && maxNodeCount > 0) {
            console.log("Quote Count Maduka currCount : " + currCount + " maxNodeCount : " + maxNodeCount + " nodeName : " + nodeName)
            toast.error("For making a " + nodeName + ", you have reached the maximum count of " + maxNodeCount + ".", {
                position: "top-center",
                transition: Slide,
            });    
            return false;
        } else return true;
    };

    const handleAddNode = (
        nodes,
        setNodes,
        maxIndex,
        setMaxIndex,
        titlePrefix,
        isOpenQuote = false,
        openedQuote = null
    ) => {
        if (!handleCanAddNode(quoteNodeIdList.length, maxQuoteNodeCount, "Quote")) return;
        let sessionNodes =  sessionStorage.getItem("newnodeId");
        if (!isOpenQuote) {
            const nodeId = uuidv4();
            const node = { nodeName: `New ${titlePrefix} ${maxIndex + 1}`, nodeId: nodeId };
            setNodes([...nodes, node]);
            setMaxIndex(maxIndex + 1);
            if(sessionNodes) sessionNodes = [...JSON.parse(sessionNodes)]; 
            else  {
                sessionNodes = []; 
            }
            sessionNodes.push(nodeId);
            if (quoteNodeIdList.length === 0 && sessionNodes.length > 0) {
                sessionNodes.forEach(element => {
                    setQuoteNodeIdList([...quoteNodeIdList, element]);
                });
            } else setQuoteNodeIdList([...quoteNodeIdList, nodeId]);
            
            sessionStorage.setItem("newnodeId", JSON.stringify(sessionNodes));

        }
         else {
            if (!quoteNodeIdList.includes(openedQuote?.quoteId || openedQuote?.quoteID)) {
                setisOpenQuoteCount(true);
                if (openedQuote.approvalStatusID ? openedQuote && openedQuote.originalQuote.quoteNumber && openedQuote.quoteID : (openedQuote && openedQuote.quoteNumber && openedQuote.quoteId)) {
                    const node = { nodeName: openedQuote.approvalStatusID ? openedQuote.originalQuote.quoteNumber : openedQuote.quoteNumber, nodeId:openedQuote.approvalStatusID ? openedQuote.quoteID : openedQuote.quoteId };
                    if(sessionNodes) sessionNodes = [...JSON.parse(sessionNodes)]; 
                    else  {
                        sessionNodes = []; 
                    }
                    setNodes([...nodes, node]);
                    if (sessionNodes.findIndex((item) => item === node.nodeId) === -1) {
                        sessionNodes.push(node.nodeId);
                        sessionStorage.setItem("newnodeId", JSON.stringify(sessionNodes));
                    }
                    setQuoteNodeIdList([...quoteNodeIdList, node.nodeId]);
                } else {
                    toast.error("Quote number is not available", "Error", 3000, {
                        position: "top-center",
                        transition: Slide,
                    });            
                }
            }
 
        }

        //sessionStorage.setItem("quoteNodeIdList", JSON.stringify(quoteNodeIdList));
        
    };

    // const handleAddMultipleNodes = (nodesToAdd) => {
    //     let isSkip = false;
    //     if( isAlldataLoaded === true) return;
    //     nodesToAdd.forEach(({ titlePrefix, isOpenQuote, openedQuote }) => {
    //         if (!handleCanAddNode(quoteNodeIdList.length, maxQuoteNodeCount, titlePrefix)) return;
    //         const quoteDetailsString = localStorage.getItem('quotes');
    //         if (quoteDetailsString) {
    //             const quoteDetails = JSON.parse(quoteDetailsString);
    //             const quoteDetailsArray = quoteDetails.filter((quote) => {
    //                 return quote.nodeId === openedQuote.quoteId;
    //             });
    //             if (quoteDetailsArray.length > 0) {
    //                 isSkip = true;
    //             }
    //         }

            
    //         if (!isOpenQuote) {
    //             const nodeId = uuidv4();
    //             const node = { nodeName: `New ${titlePrefix} ${maxQuoteIndex + 1}`, nodeId };
    //             setQuotes((prevQuotes) => [...prevQuotes, node]);
    //             setMaxQuoteIndex((prevIndex) => prevIndex + 1);
    //             setQuoteNodeIdList((prevList) => [...prevList, nodeId]);
    //         } else if (openedQuote && !quoteNodeIdList.includes(openedQuote.quoteId || openedQuote.quoteID)) {
    //             const nodeName = openedQuote.approvalStatusID ? openedQuote.originalQuote.quoteNumber : openedQuote.quoteNumber;
    //             const nodeId = openedQuote.approvalStatusID ? openedQuote.quoteID : openedQuote.quoteId;
    //             const node = { nodeName, nodeId };

    //             if (isSkip )localStorage.setItem("quotes", quotes );
    //             else setQuotes((prevQuotes) => [...prevQuotes, node]);
    //             isSkip = false;
    //             setQuoteNodeIdList((prevList) => [...prevList, nodeId]);
    //         }
        
           
    //         setIsAllDataLoaded(true);
    //     });
    
    // }
    
    
// useEffect(() => {
//     const savedNodes = localStorage.getItem("quotes");
//     const allNodes = savedNodes ? JSON.parse(savedNodes) : [];
//     const saveQuotes = allNodes.filter(node => !node.nodeName.includes("Draft") && !node.nodeName.includes("New"))
//     saveQuotes.forEach((quote) => { 
//     if (!quoteNodeIdList.includes(quote.nodeId)) {
//         setQuotes((prevQuotes) => [...prevQuotes, quote]);
//         setQuoteNodeIdList((prevList) => [...prevList, quote.nodeId]);
//     }
    
//     });
//  }, [quoteNodeIdList]);



    

    const handleDeleteNode = (
        nodes,
        setNodes,
        nodeIndex,
        maxIndex,
        setMaxIndex,
        currentNodeIndex,
        isNotSaved = false
    ) => {
        const filteredNodeList = nodes.filter((_, index) => index !== nodeIndex);
        setNodes(filteredNodeList);

        const newIndexList = quoteNodeIdList.filter((item) => {
            return item !== currentNodeIndex;
        });

        setQuoteNodeIdList(newIndexList);

        if (isNotSaved && nodeIndex == filteredNodeList.length) {
            setMaxIndex(maxIndex - 1);
        }

        delete quoteDetails[currentNodeIndex];
        setQuoteDetails(quoteDetails);

        delete firstCustomer[currentNodeIndex];
        setFirstCustomer(firstCustomer);

        delete rowData[currentNodeIndex];
        setRowData(rowData);

        delete rowQtyData[currentNodeIndex];
        setQtyRowData(rowQtyData);

        delete rowNettEaData[currentNodeIndex];
        setNettEaRowData(rowNettEaData);

        delete originalItems[currentNodeIndex];
        setOriginalItems(originalItems);

        delete sgList[currentNodeIndex];
        setSGroupList(sgList);

        delete rowMarginData[currentNodeIndex];
        setMarginRowData(rowMarginData);

        if (newIndexList.length > 0) {
            navigate(`/quotes/${newIndexList[0]}`, { replace: true });
        } else {
            navigate(`/dashboard`, { replace: true });
        }

        let sessionNodes = sessionStorage.getItem("newnodeId");
        if(sessionNodes && typeof nodeIndex !== "undefined") 
        {
            const filterNodeForDelete = nodes.find((_, index) => index === nodeIndex);
            sessionNodes = JSON.parse(sessionNodes);
            if (sessionNodes.findIndex((item) => item === filterNodeForDelete.nodeId) > -1) {
                sessionNodes = sessionNodes.filter((item) => item !== filterNodeForDelete.nodeId);
                sessionStorage.setItem("newnodeId", JSON.stringify(sessionNodes));
            }
        }
    };

    const handleCloseNode = (nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex, isCommon = false) => {
        if (!isCommon) {
            setNodes(nodes.filter((_, index) => index !== nodeIndex));
            setMaxIndex(maxIndex - 1);

            const newIndexList = commonNodeIdList.filter((item) => {
                return item !== currentNodeIndex;
            });

            setCommonNodeIdList(newIndexList);

            navigate(`/dashboard`, { replace: true });
        }
    };

    const handleDeleteOpenQuote = (nodes, setNodes, currentNodeId) => {
        const nodeIndex = quoteNodeIdList.indexOf(currentNodeId);
        setNodes(nodes.filter((_, index) => index !== nodeIndex));

        const newIndexList = quoteNodeIdList.filter((item) => {
            return item !== currentNodeId;
        });

        setQuoteNodeIdList(newIndexList);

        delete quoteDetails[currentNodeId];
        setQuoteDetails(quoteDetails);

        delete firstCustomer[currentNodeId];
        setFirstCustomer(firstCustomer);

        delete rowData[currentNodeId];
        setRowData(rowData);

        delete rowQtyData[currentNodeId];
        setQtyRowData(rowQtyData);

        delete rowNettEaData[currentNodeId];
        setNettEaRowData(rowNettEaData);

        delete originalItems[currentNodeId];
        setOriginalItems(originalItems);

        delete sgList[currentNodeId];
        setSGroupList(sgList);

        delete rowMarginData[currentNodeId];
        setMarginRowData(rowMarginData);

        if (nodeIndex == 0 && newIndexList.length > 0) {
            navigate(`/quotes/${newIndexList[0]}`);
        } else if (nodeIndex !== 0 && newIndexList.length > 0) {
            navigate(`/quotes/${newIndexList[0]}`);
        } else {
            navigate("/dashboard");
        }
    };

    const handleDeleteOpenUnit = (units, setUnits, currentNodeId) => {
        const unitIndex = unitNodeIdList.indexOf(currentNodeId);
        setUnits(units.filter((_, index) => index !== unitIndex));
    
        const newIndexList = unitNodeIdList.filter((item) => {
            return item !== currentNodeId;
        });
    
        setUnitNodeIdList(newIndexList);
       // unitDetails, setUnitDetails
        // Additional cleanup or data deletion specific to units can be done here
        // delete unitDetails[currentNodeId];
        // setUnitDetails(unitDetails);
        navigate(`/unit/${currentNodeId}`);
        // if (unitIndex === 0 && newIndexList.length > 0) {
        //     navigate(`/unit/${newIndexList[0]}`);
        // } else if (unitIndex !== 0 && newIndexList.length > 0) {
        //     navigate(`/unit/${newIndexList[0]}`);
        // } else {
            
        //     navigate("/dashboard");
        // }
    };
    
    // Then, you can use this function in your component where you handle open unit deletion
    
    // Example usage:
    // handleDeleteOpenUnit(newUnits, setNewUnits, id);
    
    //handling new units
    const handleUnitAddNode = (nodes, setNodes, maxIndex, setMaxIndex) => {
        const nodeId = uuidv4();
        const node = { nodeName: `New Unit ${maxIndex + 1}`, nodeId: nodeId };
       // setNewUnitName(node.nodeName);
        updateUnitDetailsForId(nodeId, {
            newUnitName:node.nodeName
         });

        if (!handleCanAddNode(unitNodeIdList.length, maxNewUnitsNodeCount, "New Units")) return;

        setNodes([...nodes, node]);
        setMaxIndex(maxIndex + 1);
        setUnitNodeIdList([...unitNodeIdList, nodeId]);
        localStorage.setItem("newnodeId", JSON.stringify(nodeId));
    };

    const handleUnitDeleteNode = (
        nodes,
        setNodes,
        nodeIndex,
        maxIndex,
        setMaxIndex,
        currentNodeIndex,
        isNotSaved = false
    ) => {
        const filteredNodeList = nodes.filter((_, index) => index !== nodeIndex);
        setNodes(filteredNodeList);

        const newIndexList = unitNodeIdList.filter((item) => {
            return item !== currentNodeIndex;
        });
        setUnitNodeIdList(newIndexList)
        //setQuoteNodeIdList(newIndexList);

        if (isNotSaved && nodeIndex == filteredNodeList.length) {
            setMaxIndex(maxIndex - 1);
        }      

        if (newIndexList.length > 0) {
            navigate(`/new-units/${newIndexList[0]}`, { replace: true });
        } else {
            navigate(`/dashboard`, { replace: true });
        }
    };
    // const handleUnitDeleteNode = (nodes, setNodes, unitId) => {
    //     const nodeIndex = nodes.findIndex((node) => node.nodeId === unitId);

    //     if (nodeIndex !== -1) {
    //         setNodes((prevNodes) => {
    //             const updatedNodes = [...prevNodes];
    //             updatedNodes.splice(nodeIndex, 1);
    //             return updatedNodes;
    //         });

    //         // Additional cleanup or state updates related to the node deletion

    //         // Navigate to the dashboard or another appropriate page
    //         navigateToDashboard();
    //     }
    // };
  
    //handling price list nodes
    const handlePriceListAddNodes = (nodes, setNodes, maxIndex, setMaxIndex) => {
        const nodeId = uuidv4();
        const node = { nodeName: `New price list ${maxIndex + 1}`, nodeId: nodeId };
        setNodes([...nodes, node]);
        setMaxIndex(maxIndex + 1);
        setPriceNodeIdList([...priceNodeIdList, nodeId]);
        updatePriceListDetailsForId(nodeId, {
            newUPriceListName:node.nodeName
         });
    };

    const handlePriceListDeleteNode = (nodes, setNodes, nodeIndex, maxIndex, setMaxIndex, currentNodeIndex) => {
        setNodes(nodes.filter((_, index) => index !== nodeIndex));
        setMaxIndex(maxIndex - 1);

        const newIndexList = priceNodeIdList.filter((item) => {
            return item !== currentNodeIndex;
        });

        setPriceNodeIdList(newIndexList);

        if (newIndexList.length > 0) {
            navigate(`/price-lists/${newIndexList[0]}`, { replace: true });
        } else {
            navigate(`/dashboard`, { replace: true });
        }
        
    };

    const handleNodeWhenUpdating = (nodes, setNodes, currentNodeIndex, newQuoteIndex, newQuoteName) => {
        const filteredNodeList = nodes.filter((node) => {
            return node.nodeId !== currentNodeIndex;
        });

        const newNode = { nodeName: newQuoteName, nodeId: newQuoteIndex };
        const updatedNodeList = [...filteredNodeList, newNode];

        setNodes(updatedNodeList);

        const newIndexList = quoteNodeIdList.filter((item) => {
            return item !== currentNodeIndex;
        });

        const updatedIndexList = [...newIndexList, newQuoteIndex];
        setQuoteNodeIdList(updatedIndexList);

        navigate(`/quotes/${newQuoteIndex}`, { replace: true });
    };

    const handleNodeWhenSaving = (nodes, setNodes, currentNodeIndex, newQuoteName) => {
        // Find the node to be updated
        const nodeToUpdate = nodes.find((node) => node.nodeId === currentNodeIndex);
    
        // Update the node with the new name
        const updatedNode = { ...nodeToUpdate, nodeName: newQuoteName };
    
        // Find the index of the node to be updated
        const currentPosition = nodes.findIndex((node) => node.nodeId === currentNodeIndex);
    
        // Update the node in its current position
        const updatedNodes = [...nodes];
        updatedNodes[currentPosition] = updatedNode;
    
        // Set the nodes state with the updated nodes array
        setNodes(updatedNodes);
        setQuotes(updatedNodes);
    };
    // const handleNodeWhenSavingUnit = (units, setUnits, currentUnitId, newUnitName) => {
    //     const savedUnit = units.find((unit) => unit.unitId === currentUnitId);
    
    //     if (savedUnit) {
    //         const updatedUnit = { ...savedUnit, unitName: newUnitName };
    //         const updatedUnits = units.map((unit) =>
    //             unit.unitId === currentUnitId ? updatedUnit : unit
    //         );
    
    //         setUnits(updatedUnits);
    //     }
    // };
    const handleNodeWhenSavingUnit = (units, setUnits, currentUnitId, newUnitName) => {
        const updatedUnits = units.filter((unit) => unit.unitId !== currentUnitId);
        setUnits(updatedUnits);
    };
    

    const contextValue = {
        quotes,
        setQuotes,
        maxQuoteIndex,
        setMaxQuoteIndex,
        handleAddNode,
        handleDeleteNode,
        handleCloseNode,
        handleDeleteOpenQuote,

        newUnits,
        setNewUnits,
        maxNewUnitIndex,
        setMaxNewUnitIndex,
        handleUnitAddNode,
        handleUnitDeleteNode,
        handleDeleteOpenUnit,
        handleNodeWhenSavingUnit,

        priceListNodes,
        setPriceListNodes,
        maxPriceListIndex,
        setMaxPriceListIndex,
        handlePriceListAddNodes,
        handlePriceListDeleteNode,

        handleNodeWhenUpdating,
        handleNodeWhenSaving,
        module, 
        setModule,
        
        setSaveQuotation,
        saveQuotation, 
        isQuoteSaved,
        setIsQuoteSaved,
        quoteContactDetailsMobile,
        setQuoteContactDetailsMobile,
        openQuoteCount, 
        setOpenQuoteCount,
        quoteUpdated, 
        setQuoteUpdated,
        isSelectedUnitAdded,
        setIsSelectedUnitAdded,
        isPastedUnits , 
        setIsPastedUnits,
        isRemoveItemsClicked ,
        setIsRemoveItemsClicked,
        createdBy ,
        setCreatedBy,
        isOldQuoteDone,
        setIsOldQuoteDone,
        hideTotalState, setHideTotalState,
        quoteUpdateTriggered , setQuoteUpdateTrigger,
        quoteContactTypes, setQuoteContactTypes,
        saveRef,
        isDoneClickForPdf, setIsDoneClickForPdf ,
        openShowProgress, setOpenShowProgress,
        importItemShowProgress, setImportItemShowProgress,
        copyPasteItemShowProgress, setCopyPasteItemShowProgress,
        progress, setProgress,
        isDoneChangesForQuoteTerms, setIsDoneChangesForQuoteTerms,
        hasQuoteChanged, setHasQuoteChanged

    };

    return <NodeContext.Provider value={contextValue}>{children}</NodeContext.Provider>;
};
