import React from 'react';
import { useMemo } from 'react'; 
import { toast, Slide } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import LZString from 'lz-string';

const getQuoteDetailsFromSessionStorage = () => {
  const compressedQuoteDetailsString = sessionStorage.getItem('quoteDetails');
  if (compressedQuoteDetailsString) {
      const decompressedQuoteDetailsString = LZString.decompress(compressedQuoteDetailsString);
      try {
          return JSON.parse(decompressedQuoteDetailsString);
      } catch (e) {
          console.error('Error parsing decompressed quote details:', e);
          return null;
      }
  }
  return null;
};

const calculateGrandTotal = (sortedItems, hiddenStatus) => {
 
  return sortedItems.reduce((acc, item) => {
    const hideInfo = hiddenStatus[item.rowId] || {};
    const isNonGroupItem = item.groupName.includes("Non Grouped Items");
    if (hideInfo.hideRow) {
        if (item.displayGroupName !== "" || isNonGroupItem) {
            return acc + item.totalPrice; // Include this item's total price even if the row is hidden
        }

    }
    if (isNonGroupItem || item.displayGroupName !== "") {
        return acc + item.totalPrice;
    }
    return acc;
}, 0);
};

const handleExportToExcel = (
  userId,
  userFullName,
  userEmail,
  shouldHideTotalPrice,
  hiddenStatus,usermobile
) => {

  const quoteDetails = getQuoteDetailsFromSessionStorage();
  const id = sessionStorage.getItem('id');
  const username = userFullName;
  const UserId = userId;
  const UserEmail = userEmail;

  console.log('quoteDetails data:', quoteDetails);
  if (!quoteDetails || !quoteDetails[id]) {
    toast.error('Please add customers and items to the current Quote to export to Excel', {
            position: "top-center",
            transition: Slide,
      });
    return;
  }

  const primaryCustomer = quoteDetails[id].selectedCustomers.find(
    (customer) => customer.isPrimary
  );
  const quotecustomerNumber = primaryCustomer.customerNumber;
  const contactFullName = primaryCustomer.contactFullName;
  const quotecustomerName = primaryCustomer.customerName;
  const date = quoteDetails[id].quoteDate.substring(0, 10);
  const rawDate = quoteDetails[id]?.quoteDate;
  let formattedDate;

if (rawDate) {
    const dateObject = new Date(rawDate);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();

    formattedDate = `${day}/${month}/${year}`;
} else {
    formattedDate = ''; 
}

console.log("Formatted Date:", formattedDate);

  const quoteNumber = quoteDetails[id]?.savedQuote?.quoteNumber
  let combinedArrayData = [
    { field: 'Quote No', title:quoteNumber },
    { field: 'Quote Date', title: formattedDate },
    { field: 'Customer', title: quotecustomerName },
    { field: 'Contact', title: contactFullName },
    { field: 'Customer Ref', title: quotecustomerNumber },
    
  ];

  let combinedUserArrayData = [
    { field: 'Created By', title: username },
    { field: 'Mobile', title: usermobile },
    { field: 'Email', title: UserEmail },
    
  ];

  if (!quoteDetails[id].selectedItems || quoteDetails[id].selectedItems.length === 0) {
    toast.error('No items selected. Cannot export to Excel.', {
      position: "top-center",
      transition: Slide,
  });
    return;
  }

  const { selectedItems } = quoteDetails[id];

  const sortedItems = [...selectedItems].sort((a, b) => a.position - b.position);
  const grandTotal = calculateGrandTotal(sortedItems, hiddenStatus);

  // Create worksheet
  const ws = XLSX.utils.json_to_sheet(
    [
      ...combinedArrayData,
      { GroupName: 'Group Name', SubGroupName: 'Sub Group Name', ItemNumber: 'Item', Description: 'Description', Quantity: 'Quantity', UnitPrice: 'Unit Price', TotalPrice: 'Total Price' },
      ...sortedItems.map((item) => {
        const hideInfo = hiddenStatus[item.rowId] || {};
        const isAssemblyItem = item.rowType === 'AI';
        const isLineNote = item.rowType === 'LN';
        const emptyRow = item.rowType === '';
        const shouldCheckIsAssemblyHeader = Object.keys(hideInfo).length === 0;
        const isAssemblyHeader = shouldCheckIsAssemblyHeader && item.rowType === 'A';
        const isNonGroupItemToHide =
          (item.groupName.includes('Non Grouped Items') || item.subGroupName.includes('Non Sub Group')) && !isLineNote &&
          (item.totalPrice == null || item.totalPrice === 0);

        if (hideInfo.hideRow || isNonGroupItemToHide || isAssemblyItem || isAssemblyHeader || emptyRow) {
          return null; 
        }

        return {
          GroupName: item.displayGroupName,
          SubGroupName: item.displaySubGroupName,
          ItemNumber: item.stStockCode,
          Description: item.description,
          Quantity: hideInfo.hideQty ? '' : item.qty,
          UnitPrice:shouldHideTotalPrice || hideInfo.hidePrice ? '' : `$${parseFloat(item.nettEA).toFixed(2)}`,
          TotalPrice: shouldHideTotalPrice || hideInfo.hidePrice ? '' : `$${parseFloat(item.totalPrice).toFixed(2)}`,
        };
      }),
      { GroupName: '', SubGroupName: '', ItemNumber: '', Description: '', Quantity: '', UnitPrice: 'Total Amount:', TotalPrice: shouldHideTotalPrice ? '' : `$${parseFloat(grandTotal).toFixed(2)}` },
      ...combinedUserArrayData,
    ].filter(Boolean),
    { skipHeader: true }
  );

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `-${quotecustomerNumber}`);

  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const SaveQuoteNumber = quoteDetails[id]?.savedQuote?.quoteNumber;

// Construct the filename based on the presence of quoteNumber
const filename = SaveQuoteNumber
  ? `${SaveQuoteNumber}-${quotecustomerNumber}.csv`
  : `-${quotecustomerNumber}.csv`;
  saveAs(blob, filename);
 // saveAs(blob, `-${quotecustomerNumber}.csv`);
  toast.success('Exporting to Excel...', {
    position: "top-center",
    transition: Slide,
});
};

export default handleExportToExcel;
