import React, { createContext, useState } from "react";

const QteApprovalNotificationContext = createContext(null);

const AppProvider = ({ children }) => {
    const [quoteApprovalMesasge, setQuoteApprovalMesasge] = useState('');
    const [quoteApprovalStatus, setQuoteApprovalStatus] = useState('');
    const [quoteApprovalStatId, setQuoteApprovalStatId] = useState(-1);
    const [showFormForApproval, setShowFormForApproval] = useState(false);
    const [triggerSaveQteForApproval, setTriggerSaveQteForApproval] = useState(null);
    const [approvalStatList, setApprovalStatList] = useState([]);
    const [forApprovalCount, setForApprovalCount] = useState(0);
    const [currentApprover, setCurrentApprover] = useState("");
    const [quoteOwner, setQuoteOwner] = useState("");
    const [approverName, setApproverName] = useState("");
    const [approvalDateTime, setApprovalDateTime] = useState("");
    const [triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected] = useState({
            oldQuoteId: "", 
            newQuoteId: ""
        });
    const [aprvCategory, setAprvCategory] = useState("");
    const [refreshStatus, setRefreshStatus] = useState(false);
    const [shareQuoteFlag, setShareQuoteFlag] = useState(0);
    const [saveAsNewFlag, setSaveAsNewFlag] = useState(0);
    const [triggerSaveAsNewFlag, setTriggerSaveAsNewFlag] = useState(0);
    const [triggerSaveRevision, setTriggerRevision] = useState(0);
    const [triggerSuffix, setTriggerSuffix] = useState(0);
    const [checkApprovalStateFor, setCheckApprovalStateFor] = useState("");
    const [currAccessLevel, setCurrAccessLevel] = useState(0);
    const [checkTriggerExport, setCheckTriggerExport] = useState(false);

    const qteApprovalParam = {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        forApprovalCount, setForApprovalCount,
        currentApprover, setCurrentApprover,
        quoteOwner, setQuoteOwner,
        approverName, setApproverName,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected,
        aprvCategory, setAprvCategory,
        refreshStatus, setRefreshStatus,
        shareQuoteFlag, setShareQuoteFlag,
        saveAsNewFlag, setSaveAsNewFlag,
        approvalDateTime, setApprovalDateTime,
        triggerSaveAsNewFlag, setTriggerSaveAsNewFlag,
        triggerSaveRevision, setTriggerRevision,
        triggerSuffix, setTriggerSuffix,
        checkApprovalStateFor, setCheckApprovalStateFor,
        currAccessLevel, setCurrAccessLevel,
        checkTriggerExport, setCheckTriggerExport
    };
    
    return (
        <QteApprovalNotificationContext.Provider value={
            qteApprovalParam}>
        {children}
        </QteApprovalNotificationContext.Provider>
    );
};

export { QteApprovalNotificationContext, AppProvider };

