import React, { createContext, useContext, useState } from "react";


const TriggerOldQuoteOpenContext = createContext();

export const useTriggerOldQuoteOpenContext = () => useContext(TriggerOldQuoteOpenContext);

export const TriggerOldQuoteOpen = ({children}) => {
    const [isOldQuoteOpen, setIsOldQuoteOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [hasHeaders, setHasHeaders] = useState(true);
    const [isOldQuote, setIsOldQuote] = useState(true);
  
    return (
      <TriggerOldQuoteOpenContext.Provider value={{ isOldQuoteOpen, setIsOldQuoteOpen, file, setFile, hasHeaders, setHasHeaders, isOldQuote, setIsOldQuote}}>
        {children}
      </TriggerOldQuoteOpenContext.Provider>
    );
  };
