import React, { createContext, useContext, useState } from "react";

const NewItemListContext = createContext();

export const useNewItemList = () => useContext(NewItemListContext);

export const NewItemListContextProvider = ({ children }) => {
    const [itemListNew, setItemListNew] = useState([]);
    const [allowCopy, setAllowCopy] = useState(false);
    return (
        <NewItemListContext.Provider value={{itemListNew, setItemListNew,allowCopy, setAllowCopy}}>
            {children}
        </NewItemListContext.Provider>
    );
};
