import { baseURL } from "../../App";
import { msalInstance } from "../../index"; // Update with the correct path to your MSAL instance

// Helper function to get access token
const getAccessToken = async () => {
    const requestForApi = {
        scopes: ["api://d055ad45-59fa-4049-9802-3efb2ab98eed/access_as_user"],
        account: msalInstance.getAllAccounts()[0] // Assumes you want to use the first account
    };

    try {
        const response = await msalInstance.acquireTokenSilent(requestForApi);
        return response.accessToken;
    } catch (error) {
        if (error.name === "InteractionRequiredAuthError") {
            try {
                const response = await msalInstance.acquireTokenPopup(requestForApi);
                return response.accessToken;
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error(error);
        }
    }
};

export const postResponse = async (url = "", data = {}) => {
    // Default options are marked with *
    const apiPostAccessToken = await getAccessToken();
    if (!apiPostAccessToken) {
        throw new Error("Access token is not available.");
    }
    const options = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${apiPostAccessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    };

    const fullUrl = `${baseURL}/${url}`;

    return await fetch(fullUrl, options).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            return data;
        }
    });
};

//   postData("https://example.com/answer", { answer: 42 }).then((data) => {
// });

// export const postResponse = async (url = "", data = {}) => {
//     // Default options are marked with *
//     const response = await fetch(`${baseURL}/${url}`, {
//         method: "POST", // *GET, POST, PUT, DELETE, etc.
//         mode: "cors", // no-cors, *cors, same-origin
//         //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//         //credentials: "same-origin", // include, *same-origin, omit
//         headers: {
//             "Content-Type": "application/json",
//             // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         //redirect: "follow", // manual, *follow, error
//         //referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//         body: JSON.stringify(data), // body data type must match "Content-Type" header
//     });

//     // response.text().then(text => {
//     //   try {
//     //     const data = JSON.parse(text);
//     //   } catch(err) {
//     //     console.log('Failed to parse as JSON. Here is the raw text:', text);
//     //   }
//     // });

//     if (!response.ok) {
//         throw new Error("Error fetching search results" + response.statusText);
//     }

//     // Awaiting response.json()
//     const resData = await response.json();

//     // Return response data
//     return resData;
//     //commented for check the responcemethod
//     //return response.json(); // parses JSON response into native JavaScript objects
// };
