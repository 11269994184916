import React, { useState, useEffect, useContext } from 'react';
import { LookupTypes } from '../../enums/LeftNavTypes';
import Lookup from '../Layouts/Lookup';
import { getResponse } from '../../Services/Common/Get'; // adjust the path according to your project structure
import { RemoveItemsContext } from '../../Contexts/ReomoveItemsContext';
import { add } from 'lodash';
import { getCustomerModel } from '../../models/Quotations';

const selectColumns = [
  { field: 'customerNumber', title: 'Customer Number', width: "200px" },
  { field: 'customerName', title: 'Customer Name' },
  { field: 'contactFullName', title: 'Contact' },
];

const addColumns = [
  { field: 'customerNumber', title: 'Customer Number' }
];

const CustomerLookup = ({ onAddItems }) => {  // new prop: onAddItems
  const apiURL = 'Customer/searchcustomer'; // your API endpoint
  const filterOptions = selectColumns.map(column => ({ text: column.title, value: column.field }));
  const {addItems, setAddItems} = useContext(RemoveItemsContext);
  
  const handleAddCustomers= (selectedItems) => {
    console.log('Selected items:', selectedItems);
    onAddItems && onAddItems(selectedItems);
    // Add the selected items to the "Items to Add" grid
    setAddItems(prevItems => [...prevItems, ...selectedItems]);
  };

 


  return (
    <Lookup
      type = {LookupTypes.CUSTOMER}
      apiURL={apiURL}
      selectColumns={selectColumns}
      addColumns={addColumns}
      idField='customerNumber'
      selectTableName='Customers to Select'
      addTableName='Customers to Add'
      filterOptions={filterOptions}
      conversionFunction={getCustomerModel}
      onAddItems={handleAddCustomers} // Pass the handleAddItems function as a prop
      onAddItemsToGrid={handleAddCustomers}
    />
  );
};

export default CustomerLookup;
