import  React,{ useContext } from 'react'
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postResponse } from "../../Services/Common/Post";
import { getUserPrefColsGridModel } from "../../models/UserModulePreferences";



export async function SaveUserPref(fetchedData) 
{
    
    if (!fetchedData || fetchedData.length === 0) {
        toast.error("No user preference to be saved.", {
            position: "top-center",
            transition: Slide,
        });
        return;
    }
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dataToSend = {
        userId : fetchedData?.userId,
        module : fetchedData?.module,
        gridMenusFor: fetchedData?.gridMenusFor,
        userPrefColumns : JSON.stringify(fetchedData?.userPrefColumns),
        modifiedOn: new Date(),
        CreatedOn: new Date(),
        timeZone : clientTimezone
    }
    

    const formattedData = getUserPrefColsGridModel(dataToSend);

    postResponse("UserPreferences/saveuserpreference", formattedData)
        .then((response)=>
        {
            console.log(response);
        })
        .catch((error) => 
        {
            console.error("Failed to save data:", error);
            toast.error("Connection error during saving of preference. Please try again later.", {
                position: "top-center",
                transition: Slide,
            });    
            return;
        })

        .finally(() => 
        {
            // setIsLoading(false); // Set loading back to false after request completes (either success or error)
        });
    
  
  
    return formattedData;
}

