export function getQuoteDataModel(data) {
    const quote = data.quote;
    const quoteCustomers = data.quoteCustomers;
    const quoteItems = data.quoteItems;
    const user = data.userId;
    const timeZone = data.timeZone;
    return {
        quoteId: quote.quoteId ? quote.quoteId : "00000000-0000-0000-0000-000000000000",
        quoteNumber: quote.quoteNumber ? quote.quoteNumber : "",
        amount: quote.amount ? quote.amount : 0.0,
        cost: quote.cost ? quote.cost : 0.0,
        margin: quote.margin ? quote.margin : 0.0,
        quoteDate: quote.quoteDate ? quote.quoteDate : new Date(),
        owner: quote.owner ? quote.owner : "",
        createdOn: quote.createdOn ? quote.createdOn : new Date(),
        modifiedOn: quote.modifiedOn ? quote.modifiedOn : new Date(),
        parentQuoteNo: quote.parentQuoteNo ? quote.parentQuoteNo : "",
        quoteType: quote.quoteType ? quote.quoteType : 0,
        projectName: quote.projectName ? quote.projectName : "",
        qttype: quote.qttype ? quote.qttype : "",
        headerName: quote.headerName ? quote.headerName : "",
        createdBy: quote.createdBy ? quote.createdBy : "",
        modifiedBy: user,
        quoteTermsAndConditions: quote.quoteTermsAndConditions ? quote.quoteTermsAndConditions : "",
        pdfQuoteTermsAndConditions: quote.pdfQuoteTermsAndConditions ? quote.pdfQuoteTermsAndConditions : "",
        createdUserEmail: quote.createdUserEmail ? quote.createdUserEmail : "",
        createdUserName: quote.createdUserName ? quote.createdUserName : "",
        modifiedUserName: quote.modifiedUserName ? quote.modifiedUserName : "",
        modifiedUserEmail: quote.modifiedUserEmail ? quote.modifiedUserEmail : "",
        isDeleted: quote.isDeleted ? quote.isDeleted : false,
        quoteContact: quote.quoteContact ? quote.quoteContact : "",
        quoteContactDetailsMobile: quote.quoteContactDetailsMobile ? quote.quoteContactDetailsMobile : "",
        originalQuoteId: quote.originalQuoteId ? quote.originalQuoteId : quote.quoteId,
        previousQuoteRef: quote.previousQuoteRef ? quote.previousQuoteRef : null,
        isActve: true,
        approvalStatusId: quote.approvalStatusId ? quote.approvalStatusId : null,
        tblQuoteCustomers:
            quoteCustomers.length > 0
                ? quoteCustomers.map((quoteCustomer) => {
                      return getTblQuoteCustomerModel(quoteCustomer);
                  })
                : [],
        tblQuoteItems:
            quoteItems && quoteItems.length > 0
                ? quoteItems.map((quoteItem, index) => {
                      return getTblQuoteItemModel(quoteItem, index);
                  })
                : [],
         timeZone : timeZone,
       
        // itemGroup:
        //     quoteItems && quoteItems.length > 0
        //         ? quoteItems.map((quoteItem) => {
        //               return getItemGroupModel(quoteItem, quote.quoteId);
        //           })
        //         : [],
    };
}

export function getDraftQuoteDataModel(data) {
    const quote = data.quote;
    const quoteCustomers = data.quoteDraftCustomers;
    const quoteItems = data.quoteDraftItems;
    const TimeZone = data.clientTimezone;

    return {
        quoteId: quote.quoteId ? quote.quoteId : "00000000-0000-0000-0000-000000000000",
        quoteNumber: quote.quoteNumber ? quote.quoteNumber : "",
        amount: quote.amount ? quote.amount : 0.0,
        cost: quote.cost ? quote.cost : 0.0,
        margin: quote.margin ? quote.margin : 0.0,
        quoteDate: quote.quoteDate ? quote.quoteDate : new Date(),
        owner: quote.owner ? quote.owner : "",
        createdOn: quote.createdOn ? quote.createdOn : new Date(),
        modifiedOn: quote.modifiedOn ? quote.modifiedOn : new Date(),
        parentQuoteNo: quote.parentQuoteNo ? quote.parentQuoteNo : "",
        quoteType: quote.quoteType ? quote.quoteType : 0,
        projectName: quote.projectName ? quote.projectName : "",
        qttype: quote.qttype ? quote.qttype : "",
        headerName: quote.headerName ? quote.headerName : "",
        createdBy: quote.createdBy ? quote.createdBy : "",
        modifiedBy: quote.modifiedBy ? quote.modifiedBy : "",
        createdUserEmail: quote.createdUserEmail ? quote.createdUserEmail : "",
        createdUserName: quote.createdUserName ? quote.createdUserName : "",
        modifiedUserName: quote.modifiedUserName ? quote.modifiedUserName : "",
        modifiedUserEmail: quote.modifiedUserEmail ? quote.modifiedUserEmail : "",
        quoteTermsAndConditions: quote.quoteTermsAndConditions ? quote.quoteTermsAndConditions : "",
        pdfQuoteTermsAndConditions: quote.pdfQuoteTermsAndConditions ? quote.pdfQuoteTermsAndConditions : "",
        isDeleted: quote.isDeleted ? quote.isDeleted : false,
        quoteContact: quote.quoteContact ? quote.quoteContact : "",
        quoteContactDetailsMobile: quote.quoteContactDetailsMobile ? quote.quoteContactDetailsMobile : "",
        originalQuoteId: quote.originalQuoteId ? quote.originalQuoteId : quote.quoteId,
        previousQuoteRef: quote.previousQuoteRef ? quote.previousQuoteRef : null,
        isActve: true,
        tblQuoteCustomers_Drafts:
        quoteCustomers && quoteCustomers.length > 0
                ? quoteCustomers.map((quoteCustomer) => {
                      return getTblQuoteCustomerModel(quoteCustomer);
                  })
                : [],
        tblQuoteItems_Drafts:
            quoteItems && quoteItems.length > 0
                ? quoteItems.map((quoteItem, index) => {
                      return getTblQuoteItemModel(quoteItem, index);
                  })
                : [],
        timeZone : TimeZone,
        // itemGroup:
        //     quoteItems && quoteItems.length > 0
        //         ? quoteItems.map((quoteItem) => {
        //               return getItemGroupModel(quoteItem, quote.quoteId);
        //           })
        //         : [],
    };
}

export function formatMultipleDraftQuotes(quotesData) {
    return quotesData.map(data => getDraftQuoteDataModel(data));
}





export function getTblQuoteCustomerModel(data) {
    return {
        createdOn: data.createdOn ? data.createdOn : new Date(),
        modifiedOn: data.modifiedOn ? data.modifiedOn : new Date(),
        isCrmupdated: data.isCrmupdated ? data.isCrmupdated : false,
        quoteId: data.quoteId ? data.quoteId : "00000000-0000-0000-0000-000000000000",
        isFileExported: data.isFileExported ? data.isFileExported : false,
        contactFullName: data.contactFullName ? data.contactFullName : "",
        owner: data.owner ? data.owner : "",
        alias: data.alias ? data.alias : "",
        enabled: data.enabled ? data.enabled : false,
        custClassification: data.custClassification ? data.custClassification : "",
        isDeleted: data.isDeleted ? data.isDeleted : false,
        quoteCustId: data.quoteCustId ? data.quoteCustId : "00000000-0000-0000-0000-000000000000",
        customerName: data.customerName ? data.customerName : "",
        comments: data.comments ? data.comments : "",
        isPrimary: data.isPrimary ? data.isPrimary : false,
        phone: data.phone ? data.phone.toString() : "",
        contactEmail: data.contactEmail ? data.contactEmail : "",
        isQuoteContact: data.isQuoteContact ? data.isQuoteContact : false,
        ownerJobTitle: data.ownerJobTitle ? data.ownerJobTitle : "",
        ownerMobile: data.ownerMobile ? data.ownerMobile : "",
        ownerEmail: data.ownerEmail ? data.ownerEmail : "",
        debtorType: data.debtorType ? data.debtorType : "",
        priceCategory: data.priceCategory ? data.priceCategory : "",
        createdBy: data.createdBy ? data.createdBy : "00000000-0000-0000-0000-000000000000",
        modifiedBy: data.modifiedBy ? data.modifiedBy : "00000000-0000-0000-0000-000000000000",
        createdUserName: data.createdUserName ? data.createdUserName : "",
        createdUserEmail: data.createdUserEmail ? data.createdUserEmail : "",
        modifiedUserName: data.modifiedUserName ? data.modifiedUserName : "",
        modifiedUserEmail: data.modifiedUserEmail ? data.modifiedUserEmail : "",
        customerNumber: data.customerNumber ? data.customerNumber : "",
        priceListNote: data.priceListNotes ? data.priceListNotes : "",
        customerId: data.customerId ? data.customerId : "00000000-0000-0000-0000-000000000000",
        addressName: data.addressName ? data.addressName : "",
        addressLine1: data.addressLine1 ? data.addressLine1 : "",
        addressLine2: data.addressLine2 ? data.addressLine2 : "",
        addressLine3: data.addressLine3 ? data.addressLine3 : "",
        suburb: data.suburb ? data.suburb : "",
        state: data.state ? data.state : "",
        country: data.country ? data.country : "",
        postCode: data.postCode ? data.postCode.toString() : "",
        fax: data.fax ? data.fax.toString() : "",
        contactFname: data.contactFname ? data.contactFname : "",
        contactLname: data.contactLname ? data.contactLname : "",

        quote: data.quote ? getQuoteDataModel(data.quote) : null,
    };
}

//commented by Manoj on 2021-08-24 for quote new item model
export function getItemModel(data) {
    return {
        stId: data.tblStock.stId || "00000000-0000-0000-0000-000000000000",
        stStockCode: data.tblStock.stStockCode || "",
        stDesc1: data.tblStock.stDesc1 || "",
        stDesc2: data.tblStock.stDesc2 || "",
        stDiscCode: data.tblStock.stDiscCode || "",
        stRetailPrice: data.tblStock.stRetailPrice || 0.0,
        stCost: data.tblStock.stCost || 0.0,
        stSuppCode: data.tblStock.stSuppCode || "",
        stProdGroup: data.tblStock.stProdGroup || "",
        stStatus: data.tblStock.stStatus || "",
        stLastUpdated: data.tblStock.stLastUpdated || new Date(),
        stDisabled: data.tblStock.stDisabled || false,
        stStockAva: data.tblStock.stStockAva || 0,
        stSupStockCode: data.tblStock.stSupStockCode || "",
        stCostReal: data.tblStock.stCostReal || 0.0,
        stDesc3: data.tblStock.stDesc3 || "",
        stLastCost: data.tblStock.stLastCost || 0.0,
        stLastBuyDate: data.tblStock.stLastBuyDate || new Date(),
        createdDate: data.tblStock.createdDate || new Date(),
        enable: data.tblStock.enable || false,
        fxPrice: data.tblStock.fxPrice || 0.0,
        fmoney: data.tblStock.fmoney || 0.0,
        frDuty: data.tblStock.frDuty || 0.0,
        soh: data.tblStock.soh || 0,
        isLabour: data.tblStock.isLabour || false,
        labTime: data.tblStock.labTime || 0,
        labPos: data.tblStock.labPos || 0,
        labCat: data.tblStock.labCat || 0,

        // Properties from tblStockPrice
        spId: data.tblStockPrice.spId || "00000000-0000-0000-0000-000000000000",
        spStockCode: data.tblStockPrice.spStockCode || "",
        spWsprice: data.tblStockPrice.spWsprice || 0.0,
        spListP: data.tblStockPrice.spListP || 0.0,
        spQtyB1: data.tblStockPrice.spQtyB1 || 0,
        spQtyB2: data.tblStockPrice.spQtyB2 || 0,
        spQtyB3: data.tblStockPrice.spQtyB3 || 0,
        spQtyB4: data.tblStockPrice.spQtyB4 || 0,
        spPriceB1: data.tblStockPrice.spPriceB1 || 0.0,
        spPriceB2: data.tblStockPrice.spPriceB2 || 0.0,
        spPriceB3: data.tblStockPrice.spPriceB3 || 0.0,
        spPriceB4: data.tblStockPrice.spPriceB4 || 0.0,
        spEnabled: data.tblStockPrice.spEnabled || false,
        spCrerated: data.tblStockPrice.spCrerated || new Date(),
        spUpdated: data.tblStockPrice.spUpdated || new Date(),
    };
}

//create a new model for quote item - Manoj 2021-08-24
export function getTblQuoteItemModel(data, index) {
    return {
        itemId: "00000000-0000-0000-0000-000000000000",
        quoteId: data.quoteId !== undefined ? data.quoteId : "00000000-0000-0000-0000-000000000000",
        createdBy: data.CreatedBy !== undefined ? data.createdBy : "00000000-0000-0000-0000-000000000000",
        createOn: data.createdOn !== undefined ? data.createdOn : new Date(),
        //   modifiedBy : data.ModifiedBy !== undefined ? data.ModifiedBy : "00000000-0000-0000-0000-000000000000",
        modifiedOn: data.ModifiedOn !== undefined ? data.ModifiedOn : new Date(),
        rowId: data.rowId !== undefined ? data.rowId : "00000000-0000-0000-0000-000000000000",
        actCost: data.actCost !== undefined && data.actCost !== "" ? data.actCost : 0,
        actCostExact: data.actCostExact !== undefined && data?.actCostExact !== "" ? data.actCostExact : 0,
        stFutureCostRound:
        data.stFutureCostRound !== undefined && data?.stFutureCostRound !== "" ? data.stFutureCostRound : 0,
        stFutureCost: data.stFutureCost !== undefined && data?.stFutureCost !== "" ? data.stFutureCost : 0,
        stEecostRound: data.stEecostRound !== undefined && data?.stEecostRound !== "" ? data.stEecostRound : 0,
        stEecost: data.stEecost !== undefined && data?.stEecost !== "" ? data.stEecost : 0,
        costEa: data.costEA !== undefined && data?.costEA !== "" ? data.costEA : 0,
        costEaexact: data.costEAExact !== undefined && data?.costEAExact !== "" ? data.costEAExact : 0,
        dcOnLp: data.dcOnLp !== undefined && data.dcOnLp !== "" ? data.dcOnLp : 0,
        description: data.description !== undefined && data.description !== "" ? data.description : "",
        l20: data.l20 !== undefined && data.l20 !== "" ? data.l20 : 0,
        l50: data.l50 !== undefined && data.l50 !== "" ? data.l50 : 0,
        l55: data.l55 !== undefined && data.l55 !== "" ? data.l55 : 0,
        l60: data.l60 !== undefined && data.l60 !== "" ? data.l60 : 0,
        lastCost: data.lastCost !== undefined && data.lastCost !== "" ? data.lastCost : 0,
        lastCostExact: data.lastCostExact !== undefined && data.lastCostExact !== "" ? data.lastCostExact : 0,
        listPrice: data.listPrice !== undefined && data.listPrice !== "" ? data.listPrice : 0,
        margin: data.margin !== undefined && data.margin !== "" ? data.margin : 0,
        nettEa: data.nettEA !== undefined && data.nettEA !== "" ? data.nettEA : 0,
        qty: data.qty !== undefined && data.qty !== "" ? data.qty : 0,
        stStockCode: data.stStockCode !== undefined && data.stStockCode !== "" ? data.stStockCode.toString() : "",
        totalCost: data.totalCost !== undefined && data.totalCost !== "" ? data.totalCost : 0,
        totalPrice: data.totalPrice !== undefined && data.totalPrice !== "" ? data.totalPrice : 0,
        type: data.type !== undefined ? data.type : "",
        rowType: data.rowType !== undefined ? data.rowType : "",
        soh: data.Soh !== undefined ? data.Soh : 0,

        repDisc: data.repDisc !== undefined && data.repDisc !== "" ? data.repDisc.toString() : 0,
        groupId: data?.groupId !== undefined ? data?.groupId : "00000000-0000-0000-0000-000000000000",
        groupName: data?.groupName !== undefined ? data?.groupName : "",
        displayGroupName: data?.displayGroupName !== undefined ? data?.displayGroupName : "",
        subGroupId: data?.subGroupId !== undefined ? data?.subGroupId : "", //uncomment to this when db is changed.

        //here
        // subGroupId: data?.subGroupId == "" ? "00000000-0000-0000-0000-000000000000" : data?.subGroupId,

        subGroupName: data?.subGroupName !== undefined ? data?.subGroupName : "",
        displaySubGroupName: data?.displaySubGroupName !== undefined ? data?.displaySubGroupName : "",
        quote: data.quote ? getQuoteDataModel(data.quote) : null,
        stId: data.stId !== undefined ? data.stId : "00000000-0000-0000-0000-000000000000",

        //newly added
        dcCode: data?.dcCode !== undefined ? data?.dcCode : "",
        dcRate: data?.dcRate !== undefined && data?.dcRate !== "" ? data?.dcRate : 0,
        soh: data?.soh !== undefined && data?.soh !== "" ? data?.soh : 0,
        customerId: data?.customerId !== undefined ? data?.customerId : "00000000-0000-0000-0000-000000000000",
        actMargin: data?.actMargin !== undefined && data?.actMargin !== "" ? data?.actMargin : 0,
        frcMargin: data?.frcMargin !== undefined && data?.frcMargin !== "" ? data?.frcMargin : 0,
        lcMargin: data?.lcMargin !== undefined && data?.lcMargin !== "" ? data?.lcMargin : 0,
        groupDbExtraId: data?.groupDbExtraId ? data?.groupDbExtraId : "",
        subGroupDbExtraId: data?.subGroupDbExtraId ? data?.subGroupDbExtraId : "",
        nonGroupDbExtraId: data?.nonGroupDbExtraId ? data?.nonGroupDbExtraId : "",

        position: index,

        assemblyId: data?.assemblyId !== undefined ? data?.assemblyId : "",
        assemblyGrpName : data?.assemblyGrpName !== undefined ? data?.assemblyGrpName : "",
        displayAssemblyGroupName : data?.displayAssemblyGroupName !== undefined ? data?.displayAssemblyGroupName : "",
        rowType: data?.rowType !== undefined ? data?.rowType : "",
        oldStockCode: data?.oldStockCode !== undefined ? data?.oldStockCode : null,
        stkWithDiffSPPrice: data?.stkWithDiffSPPrice !== undefined ? data?.stkWithDiffSPPrice : false,
    };
}

export function getCustomerModel(data) {
    return {
        customerId: data.customerId || "00000000-0000-0000-0000-000000000000",
        customerName: data.customerName || "",
        customerNumber: data.customerNumber || "",
        addressName: data.addressName || "",
        addressLine1: data.addressLine1 || "",
        addressLine2: data.addressLine2 || "",
        addressLine3: data.addressLine3 || "",
        suburb: data.suburb || "",
        state: data.state || "",
        country: data.country || "",
        postCode: data.postCode || "",
        phone: data.phone || "",
        fax: data.fax || "",
        contactFname: data.contactFname || "",
        contactLname: data.contactLname || "",
        contactFullName: data.contactFullName || "",
        contactEmail: data.contactEmail || "",
        owner: data.owner || "",
        ownerDomainName: data.ownerDomainName || "",
        debtorType: data.debtorType || "",
        priceCategory: data.priceCategory || "",
        enabled: data.enabled || false,
        created: data.created || new Date(),
        updated: data.updated || new Date(),
        custCategory: data.custCategory || "",
        ctId: data.ctId || 0,
        crmacctId: data.crmacctId || "",
        active: data.active || false,
        crmuniqueAcctId: data.crmuniqueAcctId || "",
        custClassification: data.custClassification || "",
        displayName: data.displayName,
        allowUpdate: data.allowUpdate,
    };
}

export function convertToModel(data) {
    return data.map((customer) => {
        if (customer?.tblCustomer) {
            return {
                createdOn: customer.tblCustomer?.created ? customer.tblCustomer?.created : new Date(),
                modifiedOn: customer.modifiedOn ? customer.modifiedOn : new Date(),
                isCrmupdated: customer.isCrmupdated ? customer.isCrmupdated : false,
                quoteId: customer.quoteId ? customer.quoteId : "00000000-0000-0000-0000-000000000000",
                isFileExported: customer.isFileExported ? customer.isFileExported : false,
                contactFullName: customer.tblCustomer?.contactFullName ? customer.tblCustomer?.contactFullName : "",
                owner: customer.tblCustomer?.owner ? customer.tblCustomer?.owner : "",
                enabled: customer.tblCustomer?.enabled ? customer?.tblCustomer.enabled : false,
                custClassification: customer.tblCustomer?.custClassification
                    ? customer.tblCustomer?.custClassification
                    : "",
                isDeleted: customer.isDeleted ? customer.isDeleted : false,
                quoteCustId: customer.quoteCustId ? customer.quoteCustId : "00000000-0000-0000-0000-000000000000",
                customerName: customer.tblCustomer?.customerName ? customer.tblCustomer?.customerName : "",
                comments: customer.comments ? customer.comments : "",
                isPrimary: customer.isPrimary ? customer.isPrimary : false,
                phone: customer.tblCustomer?.phone ? customer.tblCustomer?.phone : "",
                contactEmail: customer.tblCustomer?.contactEmail ? customer.tblCustomer?.contactEmail : "",
                isQuoteContact: customer.isQuoteContact ? customer.isQuoteContact : false,
                ownerJobTitle: customer.ownerJobTitle ? customer.ownerJobTitle : "",
                ownerMobile: customer.ownerMobile ? customer.ownerMobile : "",
                ownerEmail: customer.ownerEmail ? customer.ownerEmail : "",
                debtorType: customer.tblCustomer?.debtorType ? customer.tblCustomer?.debtorType : "",
                priceCategory: customer.tblCustomer?.priceCategory ? customer.tblCustomer.priceCategory : "",
                createdBy: customer.createdBy ? customer.createdBy : "00000000-0000-0000-0000-000000000000",
                modifiedBy: customer.modifiedBy ? customer.modifiedBy : "00000000-0000-0000-0000-000000000000",
                createdUserName: customer.createdUserName ? customer.createdUserName : "",
                createdUserEmail: customer.createdUserEmail ? customer.createdUserEmail : "",
                modifiedUserName: customer.modifiedUserName ? customer.modifiedUserName : "",
                modifiedUserEmail: customer.modifiedUserEmail ? customer.modifiedUserEmail : "",
                customerNumber: customer.tblCustomer?.customerNumber ? customer.tblCustomer?.customerNumber : "",
                priceListNote: customer.pricelistNotes ? customer.pricelistNotes : "",
                customerId: customer.tblCustomer?.customerId
                    ? customer.tblCustomer?.customerId
                    : "00000000-0000-0000-0000-000000000000",
                addressName: customer.tblCustomer?.addressName ? customer.tblCustomer?.addressName : "",
                addressLine1: customer.tblCustomer?.addressLine1 ? customer.tblCustomer?.addressLine1 : "",
                addressLine2: customer.tblCustomer?.addressLine2 ? customer.tblCustomer?.addressLine2 : "",
                addressLine3: customer.tblCustomer?.addressLine3 ? customer.tblCustomer?.addressLine3 : "",
                suburb: customer.tblCustomer?.suburb ? customer.tblCustomer?.suburb : "",
                state: customer.tblCustomer?.state ? customer.tblCustomer?.state : "",
                country: customer.tblCustomer?.country ? customer.tblCustomer?.country : "",
                postCode: customer.tblCustomer?.postCode ? customer.tblCustomer?.postCode : "",
                fax: customer.tblCustomer?.fax ? customer.tblCustomer?.fax : "",
                contactFname: customer.tblCustomer?.contactFname ? customer.tblCustomer?.contactFname : "",
                contactLname: customer.tblCustomer?.contactLname ? customer.tblCustomer?.contactLname : "",
                quote: customer.quote ? customer.quote : null,
                alias: customer.tblCustomer.alias ? customer.tblCustomer.alias : "",
            };
        } else {
            return {
                createdOn: customer.specificCustomer?.createdOn ? customer.specificCustomer?.createdOn : new Date(),
                modifiedOn: customer.specificCustomer?.modifiedOn ? customer.specificCustomer?.modifiedOn : new Date(),
                //not found in the specific customer object
                isCrmupdated: customer.isCrmupdated ? customer.isCrmupdated : false,
                //not found in the specific customer object
                quoteId: customer.quoteId ? customer.quoteId : "00000000-0000-0000-0000-000000000000",
                //not found in the specific customer object
                isFileExported: customer.isFileExported ? customer.isFileExported : false,
                contactFullName: customer.specificCustomer?.contactFullName
                    ? customer.specificCustomer?.contactFullName
                    : "",
                owner: customer.specificCustomer?.owner ? customer.specificCustomer?.owner : "",
                enabled: customer.specificCustomer?.enabled ? customer?.specificCustomer.enabled : false,
                custClassification: customer.specificCustomer?.custClassification
                    ? customer.specificCustomer?.custClassification
                    : "",
                //not found in the specific customer object
                isDeleted: customer.isDeleted ? customer.isDeleted : false,
                //not found in the specific customer object
                quoteCustId: customer.quoteCustId ? customer.quoteCustId : "00000000-0000-0000-0000-000000000000",
                customerName: customer.specificCustomer?.customerName ? customer.specificCustomer?.customerName : "",
                //  comments: customer.specificCustomer?.comments ? customer.specificCustomer?.comments : "",
                comments: customer.comments ? customer.comments : "",
                //not found in the specific customer object
                isPrimary: customer.isPrimary ? customer.isPrimary : false,
                phone: customer.specificCustomer?.phone ? customer.specificCustomer?.phone : "",
                contactEmail: customer.specificCustomer?.contactEmail ? customer.specificCustomer?.contactEmail : "",
                //not found in the specific customer object
                isQuoteContact: customer.isQuoteContact ? customer.isQuoteContact : false,

                //change here if when table name is set - begin
                ownerJobTitle: customer.ownerjobtitle ? customer.ownerjobtitle : "",
                ownerMobile: customer.ownermobile ? customer.ownermobile : "",
                ownerEmail: customer.owneremail ? customer.owneremail : "",
                //change here if when table name is set - end

                debtorType: customer.specificCustomer?.debtorType ? customer.specificCustomer?.debtorType : "",

                priceCategory: customer.specificCustomer?.priceCategory ? customer.specificCustomer.priceCategory : "",
                createdBy: customer.createdBy ? customer.createdBy : "00000000-0000-0000-0000-000000000000",
                modifiedBy: customer.modifiedBy ? customer.modifiedBy : "00000000-0000-0000-0000-000000000000",
                createdUserName: customer.createdUserName ? customer.createdUserName : "",
                createdUserEmail: customer.createdUserEmail ? customer.createdUserEmail : "",
                modifiedUserName: customer.modifiedUserName ? customer.modifiedUserName : "",
                modifiedUserEmail: customer.modifiedUserEmail ? customer.modifiedUserEmail : "",
                customerNumber: customer.specificCustomer?.customerNumber
                    ? customer.specificCustomer?.customerNumber
                    : "",
                priceListNote: customer.pricelistNotes ? customer.pricelistNotes : "",
                customerId: customer.specificCustomer?.customerId
                    ? customer.specificCustomer?.customerId
                    : "00000000-0000-0000-0000-000000000000",
                addressName: customer.specificCustomer?.addressName ? customer.specificCustomer?.addressName : "",
                addressLine1: customer.specificCustomer?.addressLine1 ? customer.specificCustomer?.addressLine1 : "",
                addressLine2: customer.specificCustomer?.addressLine2 ? customer.specificCustomer?.addressLine2 : "",
                addressLine3: customer.specificCustomer?.addressLine3 ? customer.specificCustomer?.addressLine3 : "",
                suburb: customer.specificCustomer?.suburb ? customer.specificCustomer?.suburb : "",
                state: customer.specificCustomer?.state ? customer.specificCustomer?.state : "",
                country: customer.specificCustomer?.country ? customer.specificCustomer?.country : "",
                postCode: customer.specificCustomer?.postCode ? customer.specificCustomer?.postCode : "",
                fax: customer.specificCustomer?.fax ? customer.specificCustomer?.fax : "",
                contactFname: customer.specificCustomer?.contactFname ? customer.specificCustomer?.contactFname : "",
                contactLname: customer.specificCustomer?.contactLname ? customer.specificCustomer?.contactLname : "",
                quote: customer.quote ? customer.quote : null,
                alias: customer.specificCustomer.alias ? customer.specificCustomer.alias : "",
            };
        }
    });
}

export function getItemGroupModel(data, quoteId) {
    return {
        quoteId: data.quoteId ? data.quoteId : quoteId,
        groupId: data.groupId ? data.groupId : null,
        groupName: data.groupName ? data.groupName : null,
        tblQuoteItems: [getGroupedItemModel(data, quoteId)],
    };
}

export function getGroupedItemModel(data, quoteId) {
    return {
        itemId: data.itemId ? data.itemId : "00000000-0000-0000-0000-000000000000",
        quoteId: data.quoteId ? data.quoteId : quoteId,
        createdBy: data.createdBy ? data.createdBy : "00000000-0000-0000-0000-000000000000",
        createOn: data.createOn ? data.createOn : new Date(),
        modifiedBy: data.modifiedBy ? data.modifiedBy : "00000000-0000-0000-0000-000000000000",
        modifiedOn: data.modifiedOn ? data.modifiedOn : new Date(),
        actCost: data.actCost ? data.actCost : 0,
        actCostExact: data.actCostExact ? data.actCostExact : 0,
        costEa: data.costEa ? data.costEa : 0,
        costEaexact: data.costEaexact ? data.costEaexact : 0,
        stEecostRound: data.stEecostRound ? data.stEecostRound : 0,
        stEecost: data.stEecost ? data.stEecost : 0,
        dcOnLp: data.dcOnLp ? data.dcOnLp : 0,
        description: data.description ? data.description : "",
        l20: data.l20 ? data.l20 : 0,
        l50: data.l50 ? data.l50 : 0,
        l55: data.l55 ? data.l55 : 0,
        l60: data.l60 ? data.l60 : 0,
        lastCost: data.lastCost ? data.lastCost : 0,
        lastCostExact: data.lastCostExact ? data.lastCostExact : 0,
        listPrice: data.listPrice ? data.listPrice : 0,
        margin: data.margin ? data.margin : 0,
        nettEa: data.nettEa ? data.nettEa : 0,
        qty: data.qty ? data.qty : 0,
        stStockCode: data.stStockCode ? data.stStockCode : "",
        totalCost: data.totalCost ? data.totalCost : 0,
        totalPrice: data.totalPrice ? data.totalPrice : 0,
        type: data.type ? data.type : "",
        rowType: data.rowType ? data.rowType : "",
        soh: data.soh ? data.soh : 0,
        RepDisc: data.repDisc ? data.repDisc : 0,
        groupId: data.groupId ? data.groupId : "00000000-0000-0000-0000-000000000000",
        groupName: data.groupName ? data.groupName : "",
        subGroupId: data.subGroupId ? data.subGroupId : "00000000-0000-0000-0000-000000000000",
        subGroupName: data.subGroupName ? data.subGroupName : "",
        quote: data.quote ? data.quote : null,
        itemGroup: data.itemGroup ? data.itemGroup : null,
        assemblyGrpName : data?.assemblyGrpName !== undefined ? data?.assemblyGrpName : "",
        displayAssemblyGroupName : data?.displayAssemblyGroupName !== undefined ? data?.displayAssemblyGroupName : "",
    };
}
export function getStockModel(data) {
    return {
        stId: data.tblStock.stId || "00000000-0000-0000-0000-000000000000",
        stStockCode: data.tblStock.stStockCode || "",
        stDesc1: data.tblStock.stDesc1 || "",
        stDesc2: data.tblStock.stDesc2 || "",
        stDiscCode: data.tblStock.stDiscCode || "",
        stRetailPrice: data.tblStock.stRetailPrice || 0.0,
        stCost: data.tblStock.stCost || 0.0,
        stSuppCode: data.tblStock.stSuppCode || "",
        stProdGroup: data.tblStock.stProdGroup || "",
        stStatus: data.tblStock.stStatus || "",
        stLastUpdated: data.tblStock.stLastUpdated || new Date(),
        stDisabled: data.tblStock.stDisabled || false,
        stStockAva: data.tblStock.stStockAva || 0,
        stSupStockCode: data.tblStock.stSupStockCode || "",
        stCostReal: data.tblStock.stCostReal || 0.0,
        stDesc3: data.tblStock.stDesc3 || "",
        stLastCost: data.tblStock.stLastCost || 0.0,
        stLastBuyDate: data.tblStock.stLastBuyDate || new Date(),
        createdDate: data.tblStock.createdDate || new Date(),
        enable: data.tblStock.enable || false,
        fxPrice: data.tblStock.fxPrice || 0.0,
        fmoney: data.tblStock.fmoney || 0.0,
        frDuty: data.tblStock.frDuty || 0.0,
        soh: data.tblStock.soh || 0,
        isLabour: data.tblStock.isLabour || false,
        labTime: data.tblStock.labTime || 0,
        labPos: data.tblStock.labPos || 0,
        labCat: data.tblStock.labCat || 0,
    };
}


