
import React, { createContext, useContext,useState } from "react";
const RoundUpContext = createContext();

export const useRoundUp = () => useContext(RoundUpContext);

export const RoundUpProvider = ({ children }) => {
    const [roundupPrice,setRoundupPrice]=useState(false);
  
   
    return (
        <RoundUpContext.Provider value={{roundupPrice,setRoundupPrice}}>
            {children}
        </RoundUpContext.Provider>
    );
};