import React, { createContext, useContext, useState } from "react";

const UnitDetailsContext = createContext();

export const useUniteDetails = () => useContext(UnitDetailsContext);

export const UnitDetailsProvider = ({ children }) => {
    const [unitDetails, setUnitDetails] = useState([]);
    const [unitId, setUnitId] = useState([]);
    const [isCreatedUnit, setIsCreatedUnit] = useState(false);
    const [isUnitsLoad, setIsUnitsLoad] = useState(false);
    const [unitCopy, setUnitCopy] = useState(false);
    const [createdName, setCreatedName] = useState("");
    const [unitName, setUnitName] = useState("");
    const [copiedUnits, setCopiedUnits] = useState([]);
    const [totalOfUnitItems, setTotalOfUnitItems] = useState(0);
    const [newUnitName, setNewUnitName] = useState("");
    const [listOfUnitNames ,setListOfUnitNames]= useState([]);
    const [listOfUnitIdWithNames ,setListOfUnitIdWithNames]= useState([]);
    const [selectedUnitData, setSelectedUnitData] = useState([]);
    const [unitSelected, setUnitSelected] = useState(false);
    const [selectedUnitIndex, setSelectedUnitIndex] = useState(null); 
    const [selectedSubUnitIndex, setSelectedSubUnitIndex] = useState(null);
    const [selectedUnitName, setSelectedUnitName] = useState("");
    const [triggerUnitSaving, setTriggerUnitSaving] = useState(0);
    const updateUnitDetailsForId = (id, data) => {
        setUnitDetails((prevUnitDetails) => ({
            ...prevUnitDetails,
            [id]: { ...prevUnitDetails[id], ...data },
        }));
    };

    return (
        <UnitDetailsContext.Provider value={{triggerUnitSaving, setTriggerUnitSaving,selectedUnitName, setSelectedUnitName,selectedSubUnitIndex, setSelectedSubUnitIndex,selectedUnitIndex, setSelectedUnitIndex,unitSelected, setUnitSelected,selectedUnitData, setSelectedUnitData,listOfUnitIdWithNames ,setListOfUnitIdWithNames,listOfUnitNames ,setListOfUnitNames,newUnitName, setNewUnitName,totalOfUnitItems, setTotalOfUnitItems,copiedUnits, setCopiedUnits,unitCopy, setUnitCopy,unitName, setUnitName,createdName, setCreatedName,isUnitsLoad, setIsUnitsLoad,isCreatedUnit, setIsCreatedUnit, unitId, setUnitId,unitDetails, setUnitDetails,updateUnitDetailsForId }}>
            {children}
        </UnitDetailsContext.Provider>
    );
};
