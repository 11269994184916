import {
    getQtyBasedValuesWithGroups,
    calculateItemTotalCost,
    calculateGroupWiseTotal,
    calculateGroupWiseMarginForSNumberChange,
    calculateGroupWiseActMarginForSNumberChange,
    calculateGroupWiseFrcMarginForSNumberChange,
} from "../Calculations";
import { getAllGroupRows, getAllNonGroupedItems } from "../GroupFilters";

export function footerCalculation(items) {
    let groupRows = getAllGroupRows(items);
    let nonGroupRows = getAllNonGroupedItems(items);
    groupRows = getQtyBasedValuesWithGroups(groupRows);
    nonGroupRows = getQtyBasedValuesWithGroups(nonGroupRows);
    let dbExtraValues = 0;

    for (let i = 0; i < items.length; i++) {
        if (items[i].stStockCode === 'CONSUMABLES')
        {
            dbExtraValues = dbExtraValues + items[i].nettEA;
        }
    }
    dbExtraValues = dbExtraValues ? dbExtraValues : 0;
    let combinedList = [...groupRows, ...nonGroupRows];

    let excludeAI = combinedList.filter((f) => f.rowType !== "AI");
    combinedList = excludeAI;
    let totalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
    let totalStEeCost = calculateGroupWiseTotal(combinedList, "stEecost");
    let totalStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
    let totalActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
    let totalListPrice = calculateGroupWiseTotal(combinedList, "listPrice");
    let totalNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
    let totalCost = calculateGroupWiseTotal(combinedList, "totalCost");
    let totalStEeCostRound = parseFloat(totalStEeCost.toFixed(2));
    let totalMargin = calculateGroupWiseMarginForSNumberChange(totalNettEA - dbExtraValues, totalStEeCost);
    let totalActMargin = calculateGroupWiseActMarginForSNumberChange(totalNettEA - dbExtraValues, totalActCostExact);
    let totalFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(totalNettEA - dbExtraValues, totalStFutureCost);
    let totalDcOnLp = calculateFooterTotalDiscount(totalListPrice, totalNettEA);
    totalDcOnLp = totalDcOnLp > 0 ? totalDcOnLp : 0;

    return {
        totalPrice,
        totalStEeCostRound,
        totalStFutureCost,
        totalActCostExact,
        totalListPrice,
        totalNettEA,
        totalCost,
        totalMargin,
        totalActMargin,
        totalFrcMargin,
        totalDcOnLp,
    };
}

export function calculateFooterTotalPrice(items, subGroupQty = 1, groupQty = 1) {
    let itm = items.filter((f) => f.rowType !== "AI"); //// ignore assembly items and fetch the sub totals of it which is type = A
    return itm.reduce((totalPrice, item) => {
        if (item.totalPrice) {
            //item.stStockCode !== "" && item.totalPrice
            // totalPrice += parseFloat(item.totalPrice * subGroupQty * groupQty);
            totalPrice += parseFloat(item.totalPrice);
        }
        return totalPrice;
    }, 0);
}

export function calculateFooterTotalstEecostRound(items) {
    let itm = items.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
    return itm.reduce((stEecost, item) => {
        if (item.stEecost) {
            stEecost += parseFloat(item.stEecost);
        }

        return stEecost;
    }, 0);
}

export function calculateFooterTotalStFutureCost(items) {
    let itm = items.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
    return itm.reduce((stFutureCost, item) => {
        if (item.stFutureCost) {
            stFutureCost += parseFloat(item.stFutureCost);
        }
        return stFutureCost;
    }, 0);
}

export function calculateFooterTotalActCostEAExact(items) {
    let itm = items.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
    return itm.reduce((actCostExact, item) => {
        if (item.actCostExact) {
            actCostExact += parseFloat(item.actCostExact);
        }
        return actCostExact;
    }, 0);
}

export function calculateFooterTotalListPrice(items) {
    let itm = items.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
    return itm.reduce((listPrice, item) => {
        if (item.listPrice) {
            listPrice += parseFloat(item.listPrice);
        }
        return listPrice;
    }, 0);
}

export function calculateFooterTotalNettEA(items) {
    const itemsWithNettEA = items.filter((item) => item.rowType !== "AI"); //// ignore assembly items and fetch the sub totals of it which is type = A

    const totalNettEA = itemsWithNettEA.reduce((total, item) => {
        const nettEA = parseFloat(item.nettEA);
        if (!isNaN(nettEA)) {
            return total + nettEA;
        }
        return total;
    }, 0);

    return totalNettEA;
}

export function calculateFooterTotalCost(items, subGroupQty = 1, groupQty = 1) {
    let itm = items.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
    if (itm && itm.length > 0) {
        const itemsWithStockCode = itm.filter((item) => item.stStockCode);

        const totalCostSum = itemsWithStockCode.reduce((accumulator, currentValue) => {
            {
                return (
                    accumulator +
                    // calculateItemTotalCost(currentValue.qty, currentValue.stEecost) * subGroupQty * groupQty
                    calculateItemTotalCost(currentValue.qty, currentValue.stEecost)
                );
            }
        }, 0);

        return totalCostSum;
    }
    return 0;
}

export function calculateFooterTotalDiscount(newTotalListPrice, newTotalNettEA, totalNettEAAssembly = 0) {
    return ((newTotalListPrice - (newTotalNettEA - totalNettEAAssembly)) / newTotalListPrice) * 100;
}
