
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Button } from "@progress/kendo-react-buttons";
import DataGridView from "../Layouts/DataGridView";
import Lookup from "../Layouts/Lookup";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { postResponse } from "../../Services/Common/Post";
import { getResponse } from "../../Services/Common/Get";
import { toast, Slide } from "react-toastify";
import Spinner from "../Common/Spinner";
/*UI*/
import { Tooltip } from "@progress/kendo-react-tooltip";



const StockPriceQtyBreakSearch = () => {


    const columns = [

        { field: "stId", title: "ID", width: "50px", show: false },
        { field: "stStockCode", title: "Part No", width: "200px" },
        { field: "fullDescription", title: "Desc" },

    ];
    const [isInputDisabled, setIsInputDisabled] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [search, setSearch] = useState("");
    const [pageNumberSelectItems, setPageNumberSelectItems] = useState(1);
    const [searchData, setSearchData] = useState([]);
    const [searchDataWithItemAndPrice, setSearchDataWithItemAndPrice] = useState([]);
    setSearchDataWithItemAndPrice
    const [priceData, setPriceData] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);

    const onChangeSearchValue = (value) => {
        console.log(value);
        setSearchValue(value);
        if (value.length > 0) {
            setIsInputDisabled(false);

        } else {
            setIsInputDisabled(true);
        }
    }
    const wildcardSearch = searchValue.replace(/\*|\?|_|\\|\#|\@|\$|\-|\=/g, "%");
    const [filter, setFilter] = useState("all");
    const [pageSize, setPageSize] = useState(50);
    const [listPrice, setListPrice] = useState(0);
    const [itemPartNo, setItemPartNo] = useState('');
    const [total, setTotal] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchClicked, setIsSearchClicked] = useState(false);

    useEffect(() => {
        console.log("priceData", priceData);
    }, [priceData]);

    useEffect(() => {
        if (isSearchClicked) {
            onSearchClick();
        }
    }, [pageNumberSelectItems]);

    const searchRequest = {
        name: wildcardSearch,
        filter: filter,
        pageNumber: pageNumberSelectItems,
        pageSize: pageSize,
    };
    // const search = {
    //     name: wildcardSearch,
    //     filter: "all",
    //     pageNumber: pageNumberSelectItems,
    //     pageSize: pageSize,
    // };

    const onSearchClick = async () => {
        findByPartNo();

    }

    const findByPartNo = async (event) => {

        setIsLoading(true);
        setPriceData([]);
        if (searchValue.length === 0) {
            toast.error("Please enter Part No.", {
                position: "top-center",
                transition: Slide,
            });    
            setIsLoading(false);
            return;
        }


        const result = await postResponse("Stocks/searchparts", searchRequest);
        //tblStockPrice

        setSearchDataWithItemAndPrice(result.data);
        if (result && result?.statusCode) {

            // const { statusCode, data } = result;
            // if (statusCode !== 200) {
            //     toast.error("Could not find Part No.");
            //     return;
            // }
            if (result.data.length > 0) {

                const searchedData = result.data.map((item) => {
                    return {
                        // ...item,
                        stId: item.tblStock.stId,
                        stStockCode: item.tblStock.stStockCode,
                        fullDescription: item.tblStock.stDesc1 + " " + item.tblStock.stDesc2 + " " + item.tblStock.stDesc3,
                    };
                });

                setSearchData(searchedData)
                setTotal(result.count);
                setIsLoading(false);
                setIsSearchClicked(true);
            } else {
                setSearchData([]);
                toast.error("Could not find Part No.", {
                    position: "top-center",
                    transition: Slide,
                });        
                setIsLoading(false);
                return;

            }
        } else {
            setSearchData([]);
            toast.error("Could not find Part No.", {
                position: "top-center",
                transition: Slide,
            });    
            setIsLoading(false);
            return;
        }
    }

    const paginate = (items, pageNumber, pageSize) => {
        const startIndex = (pageNumber - 1) * pageSize;
        return items.slice(startIndex, startIndex + pageSize);
    };

    // const handlePageChange = ({ skip, take }) => {
    //     setPageNumberSelectItems(skip / take + 1);
    //     //setPageSize(take);
    // };

    const [gridState, setGridState] = useState({
        skip: 0,
        take: 50,
    });

    const pageChange = (event) => {
        setGridState({
            skip: event.page.skip,
            take: event.page.take,
        });

        setPageNumberSelectItems(event.page.skip / event.page.take + 1);
    };

    const handleStockPriceQtyBreakRowClick = (e) => {
        console.log("searchDataWithItemAndPrice : ", searchDataWithItemAndPrice);
        const newArray = [];

        for (let i = 0; i < searchDataWithItemAndPrice.length; i++) {
            if (searchDataWithItemAndPrice[i].tblStock.stId === e.dataItem.stId) {

                newArray.push({ PriceLevel: 0, Price: searchDataWithItemAndPrice[i].tblStockPrice.spListP.toFixed(2), Quantity: 1 });
                newArray.push({ PriceLevel: 1, Price: searchDataWithItemAndPrice[i].tblStockPrice.spPriceB1.toFixed(2), Quantity: searchDataWithItemAndPrice[i].tblStockPrice.spQtyB1 });
                newArray.push({ PriceLevel: 2, Price: searchDataWithItemAndPrice[i].tblStockPrice.spPriceB2.toFixed(2), Quantity: searchDataWithItemAndPrice[i].tblStockPrice.spQtyB2 });
                newArray.push({ PriceLevel: 3, Price: searchDataWithItemAndPrice[i].tblStockPrice.spPriceB3.toFixed(2), Quantity: searchDataWithItemAndPrice[i].tblStockPrice.spQtyB3 });
                newArray.push({ PriceLevel: 4, Price: searchDataWithItemAndPrice[i].tblStockPrice.spPriceB4.toFixed(2), Quantity: searchDataWithItemAndPrice[i].tblStockPrice.spQtyB4 });

                console.log(searchDataWithItemAndPrice[i].tblStockPrice);
                setPriceData(newArray);
                setItemPartNo(searchDataWithItemAndPrice[i].tblStock.stStockCode);
                setListPrice(searchDataWithItemAndPrice[i].tblStockPrice.spListP.toFixed(2));
            }
        }

        console.log("newArray", newArray);
        console.log("priceData", priceData);

    };

    const handlePartSearchKeyPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            findByPartNo();
        }
    }

    /*Tooltip UI*/
    class ColumnNameCell extends React.Component {
        render() {
            return this.props.rowType !== "groupHeader" ? (
                <td className="ip-base-grid-noneditable" title={this.props.dataItem[this.props.field]}>
                    {this.props.dataItem[this.props.field]}
                </td>
            ) : null;
        }
    }

    return (
        <>
            {isLoading && (
                <div className="ip-spin-dialog-holder">
                    <Spinner />
                </div>
            )}

            {!isLoading ? (
                <>
                    <Container fluid className="ip-dialog-grid-container">
                        <Row>
                            <Col xs={12} md={6} className="mb-4">
                                <div className="d-flex">
                                    <Input
                                        placeholder="Search..."
                                        value={searchValue}
                                        onChange={(e) => { onChangeSearchValue(e.target.value) }}
                                        onKeyDown={handlePartSearchKeyPress}

                                    />
                                    <Button primary={true}
                                        className="ms-1"
                                        disabled={isInputDisabled}
                                        onClick={onSearchClick}>Search
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                                    <Grid
                                        data={paginate(searchData || [], pageNumber, pageSize)}
                                        className="ip-base-grid"
                                        skip={gridState.skip}
                                        take={gridState.take}
                                        scrollable="scrollable"
                                        pageable={true}
                                        pageSize={pageSize}
                                        resizable={true}
                                        total={total}
                                        showEmptyRow={false}
                                        singleSelect={true}
                                        showSelectionColumn={true}
                                        // onPageChange={({ skip, take }) => {
                                        //     setPageNumberSelectItems(skip / take + 1);
                                        // }}
                                        onPageChange={pageChange}
                                        onRowClick={handleStockPriceQtyBreakRowClick}
                                    >
                                        {columns.map((column, idx) =>
                                            column.show !== false && <Column key={idx} field={column.field} title={column.title} cell={ColumnNameCell} width={column.width} />
                                        )}
                                    </Grid>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                                    <Grid resizable={true} data={priceData} className="ip-base-grid">
                                        <Column field="PriceLevel" title="Price Level" cell={ColumnNameCell} footer="PriceLevel" />
                                        <Column field="Quantity" title="Quantity" cell={ColumnNameCell} footer="Quantity" />
                                        <Column field="Price" title="Price" cell={ColumnNameCell} footer="Price" />
                                    </Grid>
                                </Tooltip>
                                <div className="labour-totalfooter">
                                    <span className="ms-3 float-start"><span className="value">{itemPartNo}</span></span>
                                    <span className="me-3 float-end">
                                        <span className="ms-5">List Price : <span className="value">{listPrice}</span></span>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default StockPriceQtyBreakSearch;