import React, { useState, useContext } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
//import images
import avatar1 from "../../assets/default/images/users/user-dummy-img.jpg";
import { HiddenStatusContext } from "../../Contexts/HiddenStatusContext";

const ProfileDropdown = ({ toggleQuotesModal, toggleSystemSettingModal }) => {
    const { instance } = useMsal();
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const user = useContext(UserContext);
    const username = user?.userFullName;
    const userId = user?.homeAccountId;

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const { graphVisible, setGraphVisible } = useContext(HiddenStatusContext);

    // const logout = () => {
    //     instance.logoutPopup({
    //         postLogoutRedirectUri: "/",
    //         mainWindowRedirectUri: "/",
    //     });
    // };
    const toggleGraph = () => 
    {
        setGraphVisible(true);
    }

    const logout = async () => {
        const currentAccount = instance.getAccountByHomeId(userId);
        // await instance.logoutRedirect({ account: currentAccount, onRedirectNavigate: "/" });
        await instance.logoutRedirect({
            account: currentAccount,
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
        });
    };

    return (
        <React.Fragment>
            <Dropdown
                isOpen={isProfileDropdown}
                toggle={toggleProfileDropdown}
                className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{username}</span>
                            <span className="d-none d-xl-inline-block ms-1 fw-medium">
                                <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle me-1"></i>
                            </span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Settings</h6>

                    {/* <DropdownItem onClick={toggleQuotesModal}>
                        <i className="ri-folder-open-line fs-16 align-middle me-2"></i>{" "}
                        <span className="align-middle">Open Quotes</span>
                    </DropdownItem> */}

                    <DropdownItem onClick={toggleSystemSettingModal}>
                        <i className="ri-settings-2-line fs-16 align-middle me-2"></i>{" "}
                        <span className="align-middle">System Settings</span>
                    </DropdownItem>
                    {/* 
                    <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile-settings"}>
                        <span className="badge bg-success-subtle text-success mt-1 float-end">New</span>
                        <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">Settings</span>
                    </DropdownItem> */}
                    <div className="dropdown-divider"></div>
                    <h6 className="dropdown-header">User Statistics</h6>
                    <DropdownItem
                            id="btnDrpItemGraph"
                            onClick={toggleGraph}
                        >
                            <i className="align-middle ri-bar-chart-2-line me-2 ip-font-btn"></i>
                                <span className="align-middle">
                                    Quote Statistics
                                </span>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <h6 className="dropdown-header">User</h6>

                    {/* <DropdownItem href={process.env.PUBLIC_URL + "/help"}>
                        <i className="ri-questionnaire-line fs-16 align-middle me-2"></i>{" "}
                        <span className="align-middle">Help File</span>
                        </DropdownItem>*/}
                     <DropdownItem href="https://ipdgroup.sharepoint.com/sites/Main/IT%20Collection/Forms/AllItems.aspx?id=%2Fsites%2FMain%2FIT%20Collection%2FEstimate%20Express%20Training%20and%20User%20guide&viewid=98d23c9c%2De047%2D48b2%2Da237%2Dc332ddcda6c3&OR=Teams%2DHL&CT=1717127115301&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA0MTEyMjMxNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D" target="_blank">
                        <i className="ri-questionnaire-line fs-16 align-middle me-2"></i>{" "}
                        <span className="align-middle" data-key="t-logout">
                        Operating Guide
                        </span>
                    </DropdownItem>

                    <DropdownItem onClick={logout}>
                        <i className="ri-shut-down-line fs-16 align-middle me-2"></i>{" "}
                        <span className="align-middle" data-key="t-logout">
                            Logout
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
