import React, { createContext, useState } from "react";

const QteCurGridViewTypeContext = createContext();

const AppProvider = ({ children }) => {
  const [qteCurGridViewType, setQteCurGridViewType] = useState('');
  const [qteAuthType, setQteAuthType] = useState('');

  return (
    <QteCurGridViewTypeContext.Provider value={{ qteCurGridViewType, setQteCurGridViewType, qteAuthType, setQteAuthType }}>
      {children}
    </QteCurGridViewTypeContext.Provider>
  );
};

export { QteCurGridViewTypeContext, AppProvider };

