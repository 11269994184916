import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-all';
import { Button } from "@progress/kendo-react-buttons";
import "../../styles/importDialog.css";

const ImportOldQuoteDialog = ({ visible, onClose, onImport }) => {
  const [file, setFile] = useState(null);
  const [hasHeaders, setHasHeaders] = useState(true);
  const [isOldQuote, setIsOldQuote] = useState(true);
  const [importFields, setImportFields] = useState({
    PartNo: true,
    Qty: false,
    EachPrice: false,
  });

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getImportFieldsArray = (importFields) => {
    return Object.keys(importFields).filter(key => importFields[key]);
  };

  const handleImport = () => {
    const importFieldsArray = getImportFieldsArray(importFields);
    onImport(file, hasHeaders, importFieldsArray, isOldQuote);
    onClose();
  };

  return (
    <Dialog width="50%" title="Import Old Quotes" onClose={onClose} visible={visible}>
      <div className="mb-3">
        If you have an old quote and would like to import it. You can do it here by uploading your quote (.CSV Format).
      </div>
      <div className="imp-dialog-section">
        <input type="file" onChange={handleFileChange} />
      </div>
      <DialogActionsBar>
        <div className="d-flex justify-content-end ip-dialog-btnstatic">
          <Button
            className="px-4 me-2 ip-button-general"
            onClick={handleImport}
            disabled={!file}
          >
            Import
          </Button>
          <Button className="px-4" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ImportOldQuoteDialog;
