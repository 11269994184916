import React, { useState, useContext, useEffect } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row} from 'reactstrap';
import { Button } from "@progress/kendo-react-buttons";
//#region import approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
//#endregion

const NotificationDropdown = ({toggleQuotesModal,setDefaultTab}) => {
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };
    const { forApprovalCount } = useContext(QteApprovalNotificationContext);

    const toggleNotification = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
        toggleQuotesModal();
        setDefaultTab(3);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span
                        className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{forApprovalCount}<span
                            className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head rounded-top">
                        <div className="p-4">
                            <Row className="align-items-center">
                                <Col className='d-flex justify-content-center'>
                                    <div className='appr-mgr-notify-text'>You have <span>{forApprovalCount}</span> approval's pending </div>
                                </Col>
                                <Col className='d-flex justify-content-center mt-3'>
                                    <Button 
                                        className="appr-msg-btn px-4"
                                        onClick={toggleNotification}
                                    >
                                        <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                        Open Quote for Approval
                                    </Button>
                                </Col>         
                            </Row>
                        </div>
                    </div>            
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;