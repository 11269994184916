import {
    getExistingGroupWiseDbExtraRow,
    getExistingNonGroupWiseDbExtraRow,
    getExistingSubGroupWiseDbExtraRow,
    getItemsWithoutDBExtra,
    getGroupedRowItemsWithoutDBExtra,
} from "./DbExtraFilters";
import {
    getListWithQtyBasedNettEA,
    calculateGroupWiseTotal,
    getQtyBasedValues,
    getQtyBasedValuesWithGroups,
} from "../Calculations";
import { GroupTypes } from "../../enums/GroupTypes";
import {
    getAllItemsWithoutGroupSubGroupAndDbExtra,
    getAllExistingSubGroupsByGroup,
    getAllGroupRows,
} from "../GroupFilters";

export function calcNettEAChangeDbExtraRow(items, groupId, subGroupId, dbExtraCharge, groupType) {
    let dbExtraRow;
    const listWithoutDbExtra = getItemsWithoutDBExtra(items, groupType, groupId, subGroupId);

    switch (groupType) {
        case GroupTypes.GROUP:
            dbExtraRow = getExistingGroupWiseDbExtraRow(items, groupId)[0];
            break;
        case GroupTypes.SUB_GROUP:
            dbExtraRow = getExistingSubGroupWiseDbExtraRow(items, subGroupId)[0];
            break;
        case GroupTypes.NON_GROUP:
            dbExtraRow = getExistingNonGroupWiseDbExtraRow(items)[0];
            break;
        default:
            break;
    }

    const sgNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
    const dbExtraValue = calculateDbExtra(dbExtraCharge, sgNettEA);

    return {
        ...dbExtraRow,
        nettEA: dbExtraValue,
        totalPrice: dbExtraValue,
    };
}

export function calcQtyChangeDbExtraRow(items, groupId, subGroupId, dbExtraCharge, groupType) {}

export function calculateDbExtra(dbExtraCharge, groupWiseTotalNettEA) {
    const dbExtraChargeValue = typeof dbExtraCharge === "string" ? 0 : parseFloat(dbExtraCharge);
    const groupWiseTotalNettEAValue = typeof groupWiseTotalNettEA === "string" ? 0 : parseFloat(groupWiseTotalNettEA);
    return Math.ceil(parseFloat(groupWiseTotalNettEAValue * (dbExtraChargeValue / 100).toFixed(2)));
}

export function getUpdatedGroupSubGroupDbExtraRow(items, groupId, subGroupId, dbExtraCharge, groupType) {
    let dbExtraRow = null;

    if (subGroupId) {
        dbExtraRow = getExistingSubGroupWiseDbExtraRow(items, subGroupId)[0];
    } else {
        dbExtraRow = getExistingGroupWiseDbExtraRow(items, groupId)[0];
    }

    const dbExtraRowIndex = items.indexOf(dbExtraRow);
    let itemsWithoutDbExtra = getItemsWithoutDBExtra(items, groupType, groupId, subGroupId);
    itemsWithoutDbExtra = getListWithQtyBasedNettEA(itemsWithoutDbExtra);

    const groupNettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");
    const updatedGroupDbExtraNettEA = calculateDbExtra(dbExtraCharge, groupNettEA);

    const updatedDbExtraRow = {
        ...dbExtraRow,
        nettEA: updatedGroupDbExtraNettEA,
        totalPrice: updatedGroupDbExtraNettEA,
    };

    return { dbExtraRow: updatedDbExtraRow, dbExtraRowIndex };
}

export function getNonGroupDbExtraRow(items, dbExtraCharge) {
    const dbExtraRow = getExistingNonGroupWiseDbExtraRow(items)[0];
    const dbExtraRowIndex = items.indexOf(dbExtraRow);

    let allItems = getAllItemsWithoutGroupSubGroupAndDbExtra(items);
    // allItems = allItems.map((item) => {
    //     return {
    //         ...item,
    //         nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
    //     };
    // });
    allItems = getListWithQtyBasedNettEA(allItems);

    const nonGroupNettEA = calculateGroupWiseTotal(allItems, "nettEA");
    const nettEA = calculateDbExtra(dbExtraCharge, nonGroupNettEA);

    const updatedDbExtraRow = {
        ...dbExtraRow,
        nettEA: nettEA,
        totalPrice: nettEA,
    };

    return { dbExtraRow: updatedDbExtraRow, dbExtraRowIndex };
}

export function getQtyBasedNonGroupDbExtraRow(items, nonGroupId, dbExtraCharge) {
    const dbExtraRow = getExistingNonGroupWiseDbExtraRow(items)[0];
    const dbExtraRowIndex = items.indexOf(dbExtraRow);

    let nonGroupItemsNonDX = getItemsWithoutDBExtra(items, GroupTypes.NON_GROUP, nonGroupId, null);
    nonGroupItemsNonDX = getQtyBasedValues(nonGroupItemsNonDX);

    let groupRows = getAllGroupRows(items);
    let updatedGroupRows = groupRows.map((groupRow) => {
        const groupId = groupRow.groupId;

        let groupRowItems = getGroupedRowItemsWithoutDBExtra(items, groupId);
        groupRowItems = getQtyBasedValues(groupRowItems);

        let subGroupRows = getAllExistingSubGroupsByGroup(items, groupId);
        let updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
            const subGroupId = subGroupRow.subGroupId;
            let subGroupItems = getItemsWithoutDBExtra(items, GroupTypes.SUB_GROUP, groupId, subGroupId);
            subGroupItems = getQtyBasedValues(subGroupItems);

            const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
            const subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");

            return {
                ...subGroupRow,
                nettEA: subGroupNettEA,
                totalPrice: subGroupTotalPrice,
            };
        });
        updatedSubGroupRows = getQtyBasedValuesWithGroups(updatedSubGroupRows);

        let combinedList = [...groupRowItems, ...updatedSubGroupRows];

        const groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
        const groupNettEA = calculateGroupWiseTotal(combinedList, "nettEA");

        return {
            ...groupRow,
            nettEA: groupNettEA,
            totalPrice: groupTotalPrice,
        };
    });
    updatedGroupRows = getQtyBasedValuesWithGroups(updatedGroupRows);

    let groupItems = [...nonGroupItemsNonDX, ...updatedGroupRows];

    const nettEA = calculateGroupWiseTotal(groupItems, "nettEA");
    const dbExtraValue = calculateDbExtra(dbExtraCharge, nettEA);

    const updatedDbExtraRow = {
        ...dbExtraRow,
        nettEA: dbExtraValue,
        totalPrice: dbExtraValue,
    };

    return { dbExtraRow: updatedDbExtraRow, dbExtraRowIndex };
}

export function getQtyBasedGroupDbExtraRow(items, groupId, dbExtraCharge) {
    const dbExtraRow = getExistingGroupWiseDbExtraRow(items, groupId)[0];
    const dbExtraRowIndex = items.indexOf(dbExtraRow);

    let groupRowItems = getGroupedRowItemsWithoutDBExtra(items, groupId);
    groupRowItems = getQtyBasedValues(groupRowItems);

    let subGroupRows = getAllExistingSubGroupsByGroup(items, groupId);
    subGroupRows = subGroupRows.map((subGroupRow) => {
        const subGroupId = subGroupRow.subGroupId;
        let subGroupItems = getItemsWithoutDBExtra(items, GroupTypes.SUB_GROUP, groupId, subGroupId);
        subGroupItems = getQtyBasedValues(subGroupItems);

        const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
        const subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");

        return {
            ...subGroupRow,
            nettEA: subGroupNettEA,
            totalPrice: subGroupTotalPrice,
        };
    });
    subGroupRows = getQtyBasedValuesWithGroups(subGroupRows);

    let groupItems = [...groupRowItems, ...subGroupRows];

    const nettEA = calculateGroupWiseTotal(groupItems, "nettEA");
    const dbExtraValue = calculateDbExtra(dbExtraCharge, nettEA);

    const updatedDbExtraRow = {
        ...dbExtraRow,
        nettEA: dbExtraValue,
        totalPrice: dbExtraValue,
    };

    return { dbExtraRow: updatedDbExtraRow, dbExtraRowIndex };
}
