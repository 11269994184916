import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
import logoCover from "../../assets/default/images/ipd/ipd-white.png"

const AuthCarouselSlider = () => {
    const [classNameRandom, setClassName] = useState('');
    const ImageRefreshRandom =`h-100 ip-bg-overlay-pad ${classNameRandom}`;

    useEffect(() => {
      const classList = ['auth-one-bg', 'auth-two-bg', 'auth-three-bg', 'auth-four-bg', 'auth-five-bg'];
      const randomIndex = Math.floor(Math.random() * classList.length);
  
      setClassName(classList[randomIndex]);
    }, []);

    return (
        <React.Fragment>
            <Col lg={8} className="desktopblock">
            <div className="ip-bg-overlay"></div>
            <div className={ImageRefreshRandom}>                
                <div className="position-relative h-100 d-flex flex-column">
                    <div className="mt-auto">
                        <Carousel interval={5000} showThumbs={false} autoPlay={true} showArrows={false} showStatus={false} infiniteLoop={true} className="carousel slide" id="qoutescarouselIndicators" >
                            <div className="carousel-inner text-start text-white pb-5">
                                <div className="item">
                                    <h1 className="text-white">ESTIMATE EXPRESS SYSTEM</h1>
                                    <p className="fs-15">" The IPD Estimate Express is a custom developed application for IPD Group Ltd to create quotes for customers. This is widely used by all the departments and mainly by all the sales representatives in IPD . "</p>
                                </div>
                            </div>
                            <div className="carousel-inner text-start text-white pb-5">
                                <div className="item">
                                    <h1 className="text-white">OUR DEDICATED TEAM TO ASSIST YOU!</h1>
                                    <p className="fs-15">" Have any inquries? Contact our dedicated team of IT professionals "</p>
                                </div>
                            </div>
                            <div className="carousel-inner text-start text-white pb-5">
                                <div className="item">
                                    <h1 className="text-white">NEW EE SYSTEM HAS ARRIVED!</h1>
                                    <p className="fs-15">"We have released the EE web version for your convenience."</p>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            </Col>
        </React.Fragment >
    );
};

export default AuthCarouselSlider;