import React, { createContext, useContext, useState } from "react";

const ImportUnitDataContext = createContext();

export const useImportData = () => useContext(ImportUnitDataContext);

export const ImportUnitData = ({ children }) => {
    
    const [isLastRowSelected, setIsLastRowSelected] = useState(true); 
    const [isSelectedRow, setIsSelectedRow] = useState(false);
    const [isImportData, setIsImportData] = useState(false);
    const [importedData, setImportedData] = useState([]);
    const [isNotLastRow,SetIsNotLastRow]= useState(false);
    const storeImportedData = (data) => {
      setImportedData(data);
    };
  
    return (
        <ImportUnitDataContext.Provider value={{isImportData, setIsImportData,importedData,storeImportedData,isLastRowSelected, setIsLastRowSelected,isSelectedRow, setIsSelectedRow,isNotLastRow,SetIsNotLastRow}}>
            {children}
        </ImportUnitDataContext.Provider>
    );
};
