
import React, { useState, useContext, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {  useMsal } from "@azure/msal-react";
import { useQuoteContact } from '../../Contexts/QuoteContactProvider';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { QuoteContactTypes } from "../../enums/GroupTypes";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { TextBox,Input} from '@progress/kendo-react-inputs';
import SearchQuoteContact from './SearchQuoteContact';
import { postResponse } from "../../Services/Common/Post";

const QuoteContact = ({ handleOkSetContact }) => {
    const { accounts } = useMsal();
    const [quoteContactDialogVisible, setQuoteContactDialogVisible] = useState(false);
    const [searchQuoteContactVisible, setSearchQuoteContactVisible] = useState(false); 
    const {pageSize, setPageSize,pageNumberSelectItems, setPageNumberSelectItems,customEmail, setCustomEmail,customPhone, setCustomPhone,owner, setOwner,search, setSearch,allowSetContact, setAllowSetContact,selectedContactOption,
         setSelectedContactOption,currentContact, contactList,setContactList,fullName, total,setTotal,customName, setCustomName,updatedQuoteContact }=useQuoteContact();
    const { quoteDetails } = useQuoteDetails();
    const { id } = useParams();
    const [validate, setValidate] = useState(false);
  //  const systemUser = quoteDetails[id]?.quoteContact;
    const systemUser = accounts[0]?.name;
    //const accountOwner = quoteDetails[id]?.selectedquoteCustomers;
//(accountOwner !== undefined && accountOwner !== "") ?accountOwner: owner}  

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedContactOption(value);
        console.log("option value :",event.target.value)
    };  
    
    const toggleQuoteContactDialog = () => {
        setQuoteContactDialogVisible(!quoteContactDialogVisible);
    };
    const toggleQuoteContacSearchtDialog = () => {
        setSearchQuoteContactVisible(!searchQuoteContactVisible);
    };
    const toggleValidate =() =>{
        setValidate(!validate)
    }
    const handleOkSetContactAndOpenSearch = (value) => {
        setSearchQuoteContactVisible(true);
        setSearch(value);
        toggleValidate();
      //  handleSearch();
    };  
    const handleOkSetContactInQuoteContact = () => {
        setSearch(fullName);
        //setFullName(selectedRows[0]?.fullName);
        setSearchQuoteContactVisible(false); // Close the search dialog
        // Additional logic if needed
        console.log("selectedRows :", fullName);
      };
      const emailRegex = new RegExp(/\S+@\S+\.\S+/);
      const emailValidator = (value) => (emailRegex.test(value) ? "" : "Please enter a valid email.");
      const EmailInput = (fieldRenderProps) => {
          const { validationMessage, visited, ...others } = fieldRenderProps;
          return (
              <div>
                  <Input {...others} />
                  {visited && validationMessage && <Error>{validationMessage}</Error>}
              </div>
          );
      };
      const validateEmail = (email) => {
        // Basic email validation using a regular expression
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailPattern.test(email);
    };
    const [emailError, setEmailError] = useState("");
      const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setCustomEmail(newEmail);

        if (!validateEmail(newEmail)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };
    return (
        <div className="p-2">

            <header >
                <p>Here you choose the contact person for the Quote. The person you choose here will be the contact person for the quote</p>
            </header>
            
            <div className='reset-quote-container'>
                <section className="card-content">               
                    <div className="options">
                        <div className="radio-buttons">
                            <div className='mb-2'>
                                <input
                                    type="radio"
                                    id="option1"
                                    name="option1"
                                    value={QuoteContactTypes.OPTION1}
                                    checked={selectedContactOption === QuoteContactTypes.OPTION1}
                                    onChange={handleOptionChange}
                                    className='me-1'
                                />
                                <label className="align-middle" htmlFor="option1">Option 1: Current Contact ({currentContact})</label>
                            </div>

                            <div className='mb-2'>
                                <input
                                    type="radio"
                                    id="option2"
                                    name="option2" 
                                    value={QuoteContactTypes.OPTION2}
                                    checked={selectedContactOption === QuoteContactTypes.OPTION2}
                                    onChange={handleOptionChange}
                                    className='me-1'
                                />
                                <label className="align-middle" htmlFor="option2">Option 2: System User ({systemUser})</label>
                            </div>

                            <div className='mb-2'>
                                <input
                                    type="radio"
                                    id="option3"
                                    name="option3"
                                    value={QuoteContactTypes.OPTION3}
                                    checked={selectedContactOption === QuoteContactTypes.OPTION3}
                                    onChange={handleOptionChange}
                                    className='me-1'
                                />
                                <label className="align-middle" htmlFor="option3">Option 3: Account Owner ({owner})</label>
                            </div>
                            <div className='mb-1'>
                                <input
                                    type="radio"
                                    id="option4"
                                    name="option4"
                                    value={QuoteContactTypes.OPTION4}
                                    checked={selectedContactOption === QuoteContactTypes.OPTION4}
                                    onChange={handleOptionChange}
                                    className='me-1'
                                />
                                <label className="align-middle" htmlFor="option4">Option 4: Select the one below</label>
                                
                            </div>
                            <div className="mb-2">
                                <div className="d-flex align-items-center mb-2">
                                <label htmlFor="name" className="me-2 mb-0">
                                Name:
                                </label>
                                <TextBox
                                    name="name"
                                    label="Name "
                                    id="name"
                                    component={Input}
                                    disabled={!(selectedContactOption === QuoteContactTypes.OPTION4)}
                                    value={search}  
                                    onChange={(e) => setSearch(e.target.value)} 
            
                                />
                                <Button 
                                    onClick={() => handleOkSetContactAndOpenSearch(search)}
                                    disabled={!(selectedContactOption === QuoteContactTypes.OPTION4)}
                                    className='ms-1'
                                >
                                    Validate
                                </Button>
                                </div>  
                            </div>
                            <div className='mb-2 mt-3'>
                                <input
                                    type="radio"
                                    id="option5"
                                    name="option5"
                                    value={QuoteContactTypes.OPTION5}
                                    checked={selectedContactOption === QuoteContactTypes.OPTION5}
                                    onChange={handleOptionChange}
                                    className='me-1'
                                />
                                <label className="align-middle" htmlFor="option5">Option 5: Use custom one from below</label>

                                <div className="mb-2">
                                <div className="mb-1">
                                    <label htmlFor="name" className="mr-2 mb-1" title="Required field if option 5 selected">
                                    *Name:
                                    </label>
                                    <TextBox
                                    name="name"
                                    label="Name "
                                    id="name"
                                    component={Input}
                                    disabled={!(selectedContactOption === QuoteContactTypes.OPTION5)}
                                    required ={true}
                                    value={customName}
                                    onChange={(e) => {
                                        setCustomName(e.target.value);
                                    }}
                                    />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="phone" className="mr-2 mb-1" title="Required field if option 5 selected">
                                    *Phone:
                                    </label>
                                    <TextBox
                                    name="phone"
                                    label="Phone "
                                    id="phone"
                                    component={Input}
                                    disabled={!(selectedContactOption === QuoteContactTypes.OPTION5)}
                                    required ={true}
                                    value={customPhone}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const numericInput = input.replace(/[^0-9]/g, "").slice(0, 12);
                                        // Format the numeric input as (XXX) XXXXXXXXXXXX
                                        let formattedPhone = `(${numericInput.slice(
                                            0,
                                            3
                                        )}) ${numericInput.slice(3, 6)} ${numericInput.slice(
                                            6,
                                            8
                                        )} ${numericInput.slice(8, 10)} ${numericInput.slice(10)}`;
                                    
                                        setCustomPhone(formattedPhone);
                                    }}
                                    //  onChange={(e) => {
                                    //      setCustomPhone(e.target.value);
                                    //  }}
                                    />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="email" className="mr-2 mb-1" title="Required field if option 5 selected">
                                    *Email:
                                    </label>
                                    <TextBox
                                    name="email"
                                    label="Email "
                                    id="email"
                                    component={EmailInput}
                                    disabled={!(selectedContactOption === QuoteContactTypes.OPTION5)}
                                    value={customEmail}
                                    validator={emailValidator}
                                    onChange={handleEmailChange}                              
                                    />
                                    {emailError && <div style={{ color: "red" }}>{emailError}</div>}
                                </div>


                            </div>
                        </div>
                            
                        </div>

                    

                    </div>
                </section>
            </div>
            {searchQuoteContactVisible && (
        <Dialog width={'45%'} title={"Quote Contact Search Results"} onClose={toggleQuoteContacSearchtDialog}>
                                <SearchQuoteContact
                                 handleOkSetContactAndOpenSearch={handleOkSetContactAndOpenSearch}  
                                 setValidate ={setValidate}
                                 validate={validate}
                                 
                                 />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button className="px-4 me-2 ip-button-general" onClick={handleOkSetContactInQuoteContact} disabled ={fullName == undefined }>
                                            Done
                                        </Button>
                                        <Button className="px-4" onClick={toggleQuoteContacSearchtDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
      )}

        </div>
    );
};

export default QuoteContact;
