import React, { useState, useEffect, useContext, useRef } from "react";
import { getResponse } from "../Services/Common/Get.js";
import { postResponse } from "../Services/Common/Post";

export async function searchQuoteByQuotesId (quoteId) 
{   const pageNumber = 1;
    const PAGE_SIZE = 5;
    if (quoteId === null || typeof quoteId === "undefined") return;

    const trimmedSearchValue = quoteId + "#" + "quoteId";
    let processedQuotes = [];
    const searchModel = {
        name: trimmedSearchValue,
        filter: "all",
        pageNumber: pageNumber,
        pageSize: PAGE_SIZE,
    };

    const formatDateCustom = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    
    await postResponse("Quote/searchallquotes", searchModel)
       .then((res) => 
       {
           if (res.data !== null) 
           {
               processedQuotes = res.data.map(quote => {
                const isApprovalQuotesSearch = 1;
                const statusIdProp = quote.approvalStatus === null ? 5 : quote.approvalStatusId;
                // Format date fields
                const formattedQuoteDate = formatDateCustom(quote.quoteDate);
                const formattedExpirationDate = quote.expirationDate ? formatDateCustom(quote.expirationDate) : null;
                const formattedApprovedDateTime = quote.approvedDateTime ? formatDateCustom(quote.approvedDateTime) : null;

                return {
                    ...quote,
                    quoteDate: formattedQuoteDate,
                    expirationDate: formattedExpirationDate,
                    approvedDateTime: formattedApprovedDateTime,
                    status: statusIdProp === 4 ? 'Approved' :
                            (statusIdProp === 2 ? 'Pending Approval' :
                            (statusIdProp === 1 ? 'Approval Required' :
                            (statusIdProp === 5 ? 'Rejected' : 'No Approval Required'))),
                    customerNo: quote.tblQuoteCustomers?.map(c => c.customerNumber).join(", "),
                    customerName: quote.tblQuoteCustomers?.map(c => c.customerName).join(", "),
                    projectName: quote.projectName,
                    quoteNumber:  quote.quoteNumber,
                    owner:  quote.owner

                };
            });
           } else processedQuotes = [];
       })
       .catch((error) => 
       {
           console.error("Error retrieving data via quoteid:", error);
           return;
       });

       return processedQuotes;
}