import React, { createContext, useContext, useState } from "react";

const PartConsolidatedDetailContext = createContext();

export const usePartConsolidatedDetail = () => useContext(PartConsolidatedDetailContext);

export const PartConsolidatedDetailProvider = ({ children }) => {
    const [partConsolidatedDetail, setPartConsolidatedDetail] = useState([]);
    const [allowAddValue, setAllowAddValue] = useState(false);
    const [isApplyActionForParts, setIsApplyActionForParts] = useState(false);
    const [differ, setDiffer] = useState(false);
    const [differNettEA, setDifferNettEA] = useState(false);
    const [isAppliedDeleted, setIsAppliedDeleted] = useState(false);

    const updatePartConsolidatedDetailForId = (id, data) => {
        setPartConsolidatedDetail((prevsetPartConsolidatedDetail) => ({
            ...prevsetPartConsolidatedDetail,
            [id]: { ...prevsetPartConsolidatedDetail[id], ...data },
        }));
    };

   // const toggleAllowAddValue = () => {
     //   setAllowAddValue(!allowAddValue);
    //  };

    return (
        <PartConsolidatedDetailContext.Provider value={{ partConsolidatedDetail, updatePartConsolidatedDetailForId, setPartConsolidatedDetail,allowAddValue,setAllowAddValue, isApplyActionForParts, setIsApplyActionForParts,differ, setDiffer,differNettEA, setDifferNettEA, isAppliedDeleted,setIsAppliedDeleted}}>
            {children}
        </PartConsolidatedDetailContext.Provider>
    );
};



// import React, { createContext, useContext, useState } from "react";

// const PartConsolidatedDetailContext = createContext();

// export const usePartConsolidatedDetail = () => useContext(PartConsolidatedDetailContext);

// export const PartConsolidatedDetailProvider = ({ children }) => {
//     const [partConsolidatedDetail, setPartConsolidatedDetail] = useState([]);

//     const updatePartConsolidatedDetailForId = (id, data) => {
//         // Find the index of the item with the matching id
//         const index = partConsolidatedDetail.findIndex(item => item.id === id);
        
//         if (index !== -1) {
//             // Update the item at the found index
//             setPartConsolidatedDetail(prevPartConsolidatedDetail => [
//                 ...prevPartConsolidatedDetail.slice(0, index), // items before the updated one
//                 { ...prevPartConsolidatedDetail[index], ...data }, // updated item
//                 ...prevPartConsolidatedDetail.slice(index + 1), // items after the updated one
//             ]);
//         }else {
//             // If the item with the specified id doesn't exist, add a new item
//             setPartConsolidatedDetail(prevPartConsolidatedDetail => [
//                 ...prevPartConsolidatedDetail,
//                 { id, ...data },
//             ]);
//         }
//     };

//     return (
//         <PartConsolidatedDetailContext.Provider value={{ partConsolidatedDetail, updatePartConsolidatedDetailForId, setPartConsolidatedDetail }}>
//             {children}
//         </PartConsolidatedDetailContext.Provider>
//     );
// };
