import React, { createContext, useContext, useState } from "react";

const FirstCustomerContext = createContext();

export const useFirstCustomer = () => useContext(FirstCustomerContext);

export const FirstCustomerProvider = ({ children }) => {
    const [firstCustomer, setFirstCustomer] = useState([]);

    const updateFirstCustomerForId = (id, data) => {
        setFirstCustomer((firstCustomer) => ({
            ...firstCustomer,
            [id]: { ...firstCustomer[id], ...data },
        }));
    };

    return (
        <FirstCustomerContext.Provider
            value={{
                firstCustomer,
                setFirstCustomer,
                updateFirstCustomerForId,
            }}>
            {children}
        </FirstCustomerContext.Provider>
    );
};
