export const GroupTypes = {
    GROUP: "group",
    SUB_GROUP: "subGroup",
    NON_GROUP: "nonGroup",
    NON_SUBGROUP: "nonSubGroup",
};

export const GroupItemTypes = {
    GROUPED_ITEMS: "Grouped Items",
    SUB_GROUPED_ITEMS: "Sub Grouped Items",
    NON_GROUPED_ITEMS: "Non Grouped Items",
    NON_SUB_GROUPED_ITEMS: "Non Sub Grouped Items",
};

export const ResetQuoteTypes = {
    SETDEFAULT: "setdefault",
    KEEPNETTEA: "keepnettea",
    keepnettEA: "keep-nettEA",
    KEEPMARGIN: "keepmargin",
};

export const NonGroupTypes = {
    NON_SUB_GROUP: "Non Sub Group",
};
export const QuoteContactTypes = {
    OPTION1: "option1",
    OPTION2: "option2",
    OPTION3: "option3",
    OPTION4: "option4",
    OPTION5: "option5",
};