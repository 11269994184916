import React, { useState, useMemo, useRef, useEffect, useContext  } from "react";
import { useParams } from "react-router-dom";
import DataGridView from "../Layouts/DataGridView";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Row, Accordion, AccordionItem, Collapse } from "reactstrap";
import classnames from "classnames"; //for accordion classnames
import { getResponse } from "../../Services/Common/Get";
import { postResponse } from "../../Services/Common/Post";
import { putResponse } from "../../Services/Common/Put";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { toast, Slide } from "react-toastify";
import ItemLookup from "../Qoutes/ItemLookup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import CustomerIdContext from "../../Contexts/CustomerIdContext";
import Spinner from "../Common/Spinner";
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert";
import { guid } from "@progress/kendo-react-common";
import { isNumber } from "lodash";
import { useNewItemList } from "../../Contexts/NewItemListContextProvider";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { CSVLink } from 'react-csv';
import ImportDialogUnit from './ImportDialogUnit';
import { useImportData } from "../../Contexts/ImportUnitData";
import { usePriceListDetails } from "../../Contexts/PriceListDetailsProvider";
import { useNavigate } from "react-router-dom";

const NewUnitDataGrid = ({isSavedUnitChange,isNavigate}) => {
    const navigate = useNavigate();
    //#region variables
    const {isImportData, setIsImportData,importedData,storeImportedData,isLastRowSelected,isSelectedRow} = useImportData();
    const {totalOfUnitItems, setTotalOfUnitItems,copiedUnits, setCopiedUnits,unitCopy, setUnitCopy,setCreatedName,isUnitsLoad, setIsUnitsLoad,isCreatedUnit, setIsCreatedUnit, unitId, setUnitId ,unitDetails, updateUnitDetailsForId,unitName, setUnitName,newUnitName, setNewUnitName,listOfUnitNames,listOfUnitIdWithNames ,setListOfUnitIdWithNames,triggerUnitSaving, setTriggerUnitSaving} = useUniteDetails();
    const {  setIsPriceListCopyed } = usePriceListDetails();
    const { id } = useParams();
    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
   // const [selectedOption, setSelectedOption] = useState();
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(true);
    const changedOptionRef = useRef(null);
    const [libraryTypes, setLibraryTypes] = useState([]);  
   // const [selectedLibrary, setSelectedLibrary] = useState();  
   const [selectedLibrary, setSelectedLibrary] = useState("Select one from the list"); 
    const [libraryListing, setLibraryListing] = useState([]);
    const [itemLookUpVisible, setItemLookUpVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [itemspageNumber, setitemsPageNumber] = useState(1);
    const [itemspageSize, setitemsPageSize] = useState(50);
    const customerIdContext = useContext(CustomerIdContext);
    const { addItems, setAddItems } = useContext(RemoveItemsContext);
    const { customerId } = useContext(CustomerIdContext);
    const nonGroupedId = useRef();
    const [selectedRows, setSelectedRows] = useState([]);
    const [triggerNewItem, setTriggerNewItem] = useState(false);
    const [itemCount, setItemCount] = useState(0);
    let newItem = [];
    const [isLoading, setIsLoading] = useState(false);    
    const [optType, setOptType] = useState([]);
    const [optTypeId, setOptTypeId] = useState(null);
    const [userLibId, setUserLibId] = useState(null);
    const { itemListNew, setItemListNew, allowCopy, setAllowCopy } = useNewItemList();
    const [unitData, setUnitData] = useState({});
    const [currentUnitId, setcurrentUnitId] = useState("");
    const [skipUnits, setSkipUnits] = useState(0);
    const [takeUnits, setTakeUnits] = useState(50);
    const MAX_HISTORY_SIZE = 10; 
    const {
        newUnits,
        setNewUnits,
        maxNewUnitIndex,
        setMaxNewUnitIndex,
        handleUnitAddNode,
        handleUnitDeleteNode,
        handleNodeWhenUpdating,
        handleNodeWhenSaving,
        handleNodeWhenSavingUnit,
        handleDeleteOpenUnit
    } = useNodeContext();
    const [csvData, setCsvData] = useState([]);
    const [exportCSV, setExportCSV] = useState(false);
    const [history, setHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
    const [hasUndone, setHasUndone] = useState(false);
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [addImportItems, setAddImportItems] = useState(false);
    //let itemList = [];
    //#endregion

    //#region Const
    const columns = useMemo(
        () => [
            { field: "groups", title: "Groups", editable: false },
            { field: "stStockCode", title: "Part No", editable: true },
            { field: "description", title: "Description", editable: false },
            { field: "type", title: "Type", editable: false },
            { field: "listPrice", title: "List Price", editable: false },
            { field: "qty", title: "Qty", editable: true },
            { field: "rowId", title: "rowId", editable: false, show:false },
            { field: "position", title: "position", editable: false, show:false },
        ],
        []
    );
 
    const options = useMemo(
        () => [
            { id: 0, text: "Select an option..." },
            { id: 1, text: "Import items" },
            { id: 2, text: "Export items (As in the grid)" },
        ],
        []
    );
    
    const [selectedOption, setSelectedOption] = useState(options[0]);
    console.log("itemList",itemList)
    const handleChangeOption = (e) => {
        console.log("e.target.value ", e.target.value);
        setSelectedOption(e.target.value);
        const selectedOptionId = e.target.value.id || e.target.value;
        if(selectedOptionId ===1){
            setSelectedOption(e.target.value); 
            setImportDialogVisible(true);
        }
        else if (selectedOptionId === 2) { 
            const csvFormattedData = [
                ["PartNo", "Description", "Type", "ListPrice", "Qty"],
                // Include data
                
                ...itemList.map(item => [
                    item.stStockCode,
                    item.description,
                    item.type,
                    item.listPrice,
                    item.qty,
                ]),
            ];
    
            const csvContent = csvFormattedData.map(row => row.join(',')).join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "UnitList.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setSelectedOption(options[0]);
        }
      //  setSelectedOption(e.target.value);
    };
        
    const t_col2 = () => {
        setcol2(!col2);
        setcol1(true);
    };

    const handleAdd = () => {
        setItemLookUpVisible(true);
    }

    const closeDialog = () => {
        setItemLookUpVisible(false);
        setAddItems([]);
    };

    const handleSelectedItems = (items) => {
        setSelectedItems(items);
        
    };

    const validateItem = (itemTocheck) => {
         let itemExist = itemList.filter((e)=> {
                if(itemTocheck.some((item) => item.stStockCode === e.stStockCode)) return e;
            }
        )

        return itemExist;
    }

    const handlePageChangeUnits = ({ skip, take }) => {
        setSkipUnits(skip);
        setTakeUnits(take);
        setitemsPageNumber(skip / take + 1);
    };

   
    const handleAddParts = () => {
        const UserId = user?.homeAccountId;
        let systemSettings = null;

        newItem = [];

        setIsLoading(true);
        addItems.map((item) =>
            newItem.push({
                groups:"",
                stStockCode: item.stStockCode,
                description: item.stDesc1 + item.stDesc2 + item.stDesc3,
                type: item.stStatus,
                listPrice: parseFloat(parseFloat(item.spListP).toFixed(2)),
               // listPrice: item.spListP,
                qty: 1,
                rowId: uuidv4(),
                rowType:item.stStatus,
            })
        )
        console.log("newItem",newItem);  
        ///validation if exist
       let itemExist = validateItem(newItem);

        let existMsg = "";
        let i = 1;
        if(itemExist.length > 0) {
            existMsg+= "Duplicate item(s) detected\n";
            existMsg+= "==========================  \n";
            existMsg+= "Total Item(s) : " + itemExist.length + " \n";
            itemExist.forEach((item)=>{
                existMsg+= i.toString() + ". " + item.stStockCode + "\n";
                i+=1;
            })

            toast.error(<pre><b>{existMsg}</b></pre>, { autoClose: 8000, position: "top-center", transition: Slide, });
        }

        newItem = newItem.filter((e)=> {
            if(!itemExist.some((item) => item.stStockCode === e.stStockCode)) return e;
        })
          
        
        let updatedIItem = [...itemList,...newItem];
        setItemList(updatedIItem); 
        setItemCount(updatedIItem.length);
        updateUnitDetailsForId(id, { selectedItems: updatedIItem  });
        // setHistory((prevHistory) => {
        //     const newHistory = [...prevHistory, updatedIItem];
        //     return newHistory.slice(-MAX_HISTORY_SIZE);
        // });
        saveToHistory(updatedIItem);
        
        closeDialog();
        setIsLoading(false);
    }

    const handleItemChange = async (event) => {
        const { field, dataItem, clearInput } = event;
        const value = event.value;
        let updatedItemList = [];
        const UserId = user?.homeAccountId;
        if (field === "stStockCode") {
            
                const isNewlyInserted = dataItem.isNewlyInserted || false;

                if (isNewlyInserted) {
                    const responses = await getResponse("Stocks/getstockdetailsusingpartno", {
                        partno: value,
                        UserId: UserId,
                    });
                    if (!responses || responses.stStockCode === null || responses.stStockCode === undefined) {
                        toast.error("No parts found for the given stock code.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        clearInput(); 
                        return;
                    }
                    const trimmedValue = value.trim();
                    const isDuplicate = itemList.some((item) => item.stStockCode.trim().toUpperCase() === trimmedValue.toUpperCase());
                    
        
                    if (isDuplicate) {
                    toast.error("Sorry! The item named '" + value + "' already exists in this view.", { autoClose: 5000, position: "top-center", transition: Slide, });
                    clearInput(); 
                    return;
                    }
                    const updatedItemList = itemList.map((item) => {
                        if (item.rowId === dataItem.rowId) {
                            return {
                                ...item,
                                groups:"",
                                stStockCode: responses.stStockCode, 
                                description: responses.description,
                                type: responses.type,
                                listPrice: parseFloat(parseFloat(responses.listPrice).toFixed(2)),
                                qty: 1,
                                rowId: uuidv4()
                            };
                        }
                        return item;
                    });

                    setItemList(updatedItemList);
                    updateUnitDetailsForId(id, { selectedItems: updatedItemList }); 
                    // setHistory((prevHistory) => {
                    //     const newHistory = [...prevHistory, updatedItemList];
                    //     return newHistory.slice(-MAX_HISTORY_SIZE);
                    // });
                    saveToHistory(updatedItemList);
                } else{

                        const responses = await getResponse("Stocks/getstockdetailsusingpartno", {
                            partno: value,
                            UserId: UserId,
                        });         
                        if (!responses || responses.stStockCode === null || responses.stStockCode === undefined) {
                            toast.error("No parts found for the given stock code.", { autoClose: 5000, position: "top-center", transition: Slide, });
                            clearInput(); 
                            return;
                        }
                        const trimmedValue = value.trim();
                        const isDuplicate = itemList.some((item) => item.stStockCode.trim().toUpperCase() === trimmedValue.toUpperCase());
                        

                        if (isDuplicate) {
                            toast.error("Sorry! The item named '" + value + "' already exists in this view.", { autoClose: 5000, position: "top-center", transition: Slide, });
                            clearInput(); 
                            return;
                        }
                        const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
                        newItem = [];
                        if(responses !== null && responses !== undefined) {
                            if(dataItem.stStockCode){
                                if (responses.stStockCode !== null && responses.stStockCode !== undefined) {
                                    const itemRow = itemList[itemIndex];
                                    let updatedItem = {
                                        ...itemRow,
                                        groups:"",
                                        stStockCode: responses.stStockCode,
                                        stId:responses.stId,
                                        description: responses.description,
                                        type: responses.type,
                                        stkWithDiffSPPrice:responses.stkWithDiffSPPrice,
                                    //  listPrice: parseFloat(responses.listPrice).toFixed(2),
                                        listPrice: parseFloat(parseFloat(responses.listPrice).toFixed(2)),
                                        costEA:responses.costEA,
                                        costEAExact:responses.costEAExact,
                                        l20:responses.l20,
                                        l50:responses.l50,
                                        l55:responses.l55,
                                        l60:responses.l60,
                                        nettEA:responses.nettEA,
                                        lastCost:responses.lastCost,
                                        lastCostExact:responses.lastCostExact,
                                        stStatus:responses.stStatus,
                                        qty: 1,
                                        actCost:responses.actCost,
                                        actCostExact:responses.actCostExact,  
                                        dcCode: responses.dcCode,
                                        dcOnLp: responses.dconLp,
                                        frcMargin: responses.frcMargin,
                                        lcMargin: responses.lcMargin,
                                        actMargin: responses.actMargin,
                                        margin: responses.margin,
                                        repDisc: responses.repDisc,
                                        soh: responses.soh,
                                        stEecost: responses.stEecost,
                                        stEecostRound: responses.stEecostRound,
                                        stFutureCost: responses.stFutureCost,
                                        stFutureCostRound: responses.stFutureCostRound,
                                        totalCost: responses.totalCost,
                                        totalPrice: responses.totalPrice,
                                        rowType: responses.type,
                                        groupName: dataItem.groupName ? dataItem.groupName : "",
                                        groupId: dataItem.groupId ? dataItem.groupId : "",
                                        displayGroupName: dataItem.displayGroupName ? dataItem.displayGroupName : "",
                                        subGroupName: dataItem.subGroupName ? dataItem.subGroupName : "",
                                        subGroupId: dataItem.subGroupId ? dataItem.subGroupId : "",
                                        displaySubGroupName: dataItem.displaySubGroupName
                                            ? dataItem.displaySubGroupName
                                            : "",
                                        rowId: uuidv4(),
                                        groupDbExtraId: "",
                                        subGroupDbExtraId: "",
                                        nonGroupDbExtraId: "",


                                        
                                    }                     

                                    itemList[itemIndex] = updatedItem;
                                    setItemList(itemList); 
                                    updateUnitDetailsForId(id, { selectedItems: itemList });
                                    // setItemCount(itemCount + updatedItem.length);
                                }
                            } else {
                                
                                if (responses.stStockCode !== null && responses.stStockCode !== undefined) {
                                    let maxOrderValue = 0;

                                    // Find the maximum orderValue from the existing itemList
                                    if (itemList.length > 0) {
                                        maxOrderValue = Math.max(...itemList.map(item => item.orderValue || 0));
                                    }
                                    if(currentUnitId !==""){
                                        newItem.push({
                                            ...newItem,
                                            unitId: id,
                                            unItemId: uuidv4(),
                                            createdBy: user?.homeAccountId,
                                            modifiedBy: user?.homeAccountId,
                                            createdOn: new Date(),
                                            modifiedOn: new Date(),
                                            stStockCode: responses.stStockCode,
                                            description: responses.description,
                                            qty: parseInt(responses.qty),
                                            type: responses.type,
                                            listPrice: parseFloat(parseFloat(responses.listPrice).toFixed(2)),
                                            rowId:uuidv4(),
                                            unit:null,
                                            orderValue :maxOrderValue +1,
                                        });
                                    }

                                   else{
                                    newItem.push({
                                        
                                            ...newItem,
                                            unitId: id,
                                            unItemId: uuidv4(),
                                            createdBy: user?.homeAccountId,
                                            modifiedBy: user?.homeAccountId,
                                            createdOn: new Date(),
                                            modifiedOn: new Date(),
                                            stStockCode: responses.stStockCode,
                                            description: responses.description,
                                            qty: parseInt(responses.qty),
                                            type: responses.type,
                                            listPrice: parseFloat(parseFloat(responses.listPrice).toFixed(2)),
                                            rowId:uuidv4(),
                                            unit:null,
                                            orderValue :maxOrderValue +1,
                                        });
                                        // ...newItem,
                                        // groups:"",
                                        // stStockCode: responses.stStockCode,
                                        // stId:responses.stId,
                                        // description: responses.description,
                                        // type: responses.type,
                                        // stkWithDiffSPPrice:responses.stkWithDiffSPPrice,
                                        // //listPrice: parseFloat(responses.listPrice).toFixed(2),
                                        // listPrice: parseFloat(responses.listPrice),
                                        // costEA:responses.costEA,
                                        // costEAExact:responses.costEAExact,
                                        // l20:responses.l20,
                                        // l50:responses.l50,
                                        // l55:responses.l55,
                                        // l60:responses.l60,
                                        // nettEA:responses.nettEA,
                                        // lastCost:responses.lastCost,
                                        // lastCostExact:responses.lastCostExact,
                                        // stStatus:responses.stStatus,
                                        // qty: responses.qty,
                                        // actCost:responses.actCost,
                                        // actCostExact:responses.actCostExact,  
                                        // dcCode: responses.dcCode,
                                        // dcOnLp: responses.dconLp,
                                        // frcMargin: responses.frcMargin,
                                        // lcMargin: responses.lcMargin,
                                        // actMargin: responses.actMargin,
                                        // margin: responses.margin,
                                        // repDisc: responses.repDisc,
                                        // soh: responses.soh,
                                        // stEecost: responses.stEecost,
                                        // stEecostRound: responses.stEecostRound,
                                        // stFutureCost: responses.stFutureCost,
                                        // stFutureCostRound: responses.stFutureCostRound,
                                        // totalCost: responses.totalCost,
                                        // totalPrice: responses.totalPrice,
                                        // rowType: responses.type,
                                        // groupName: dataItem.groupName ? dataItem.groupName : "",
                                        // groupId: dataItem.groupId ? dataItem.groupId : "",
                                        // displayGroupName: dataItem.displayGroupName ? dataItem.displayGroupName : "",
                                        // subGroupName: dataItem.subGroupName ? dataItem.subGroupName : "",
                                        // subGroupId: dataItem.subGroupId ? dataItem.subGroupId : "",
                                        // displaySubGroupName: dataItem.displaySubGroupName
                                        //     ? dataItem.displaySubGroupName
                                        //     : "",
                                        // rowId: uuidv4(),
                                        // groupDbExtraId: "",
                                        // subGroupDbExtraId: "",
                                        // nonGroupDbExtraId: "",
                                    
                                }
                                    let updatedIItem = [...itemList,...newItem];
                                    setItemList(updatedIItem); 
                                    updateUnitDetailsForId(id, { selectedItems: updatedIItem });
                                    setItemCount(itemCount + updatedIItem.length);
                                    // setHistory((prevHistory) => {
                                    //     const newHistory = [...prevHistory, updatedIItem];
                                    //     return newHistory.slice(-MAX_HISTORY_SIZE);
                                    // });
                                    saveToHistory(updatedIItem);
                                } else {
                                    toast.error("Part may either Obsolete or not exist in the system", {
                                        autoClose: 3000,
                                        closeOnClick: true,
                                        position: "top-center", 
                                        transition: Slide,
                                    });
                                }
                            }
                        }}

        } if (field === "qty") {
            // if(dataItem.stStockCode){
            //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            //     const itemRow = itemList[itemIndex];
            //     let updatedItem = {
            //         ...itemRow,
            //         qty: parseInt(value),
            //         }

            //     itemList[itemIndex] = updatedItem;
            // }
            const numericRegex = /^[0-9]+$/;
            if (numericRegex.test(value)) {
            if (dataItem.stStockCode) {
                const updatedItemList = itemList.map((item) => {
                    if (item.rowId === dataItem.rowId) {
                        // If the rowId matches, update the qty
                        return {
                            ...item,
                            qty: parseInt(value),
                        };
                    }
                    return item;
                });
        
                // Update the state with the modified list
                setItemList(updatedItemList);
                updateUnitDetailsForId(id, { selectedItems: updatedItemList });
                // setHistory((prevHistory) => {
                //     const newHistory = [...prevHistory, updatedItemList];
                //     return newHistory.slice(-MAX_HISTORY_SIZE);
                // });
                saveToHistory(updatedItemList);
            }
          } else {
            toast.error("Invalid quantity input. Please enter a numeric value", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center", 
                transition: Slide,
            });
            
         }
        }
        
    }

    function getUnitModel(){

    }

    const onChangeUnitName = (e) =>{
        setUnitName(e.target.value);
        updateUnitDetailsForId(id, { unitName: e.target.value });
    }

    const onChangeType = (e) =>{
        const value = e.target.value;
        setSelectedLibrary(value)
        if (value.length > 0) {
            const selectedLibrary = libraryListing.find((itm) => itm.libraryName === value);
            if (selectedLibrary) {
                setOptTypeId(selectedLibrary.userLibId);
                updateUnitDetailsForId(id, { libraryId:value , optTypeId:selectedLibrary.userLibId});
            }
        }
        
    }
    useEffect(() => {      
    }, [optTypeId]); 

    const handleSaveUnits = () => {
        const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
       // const trimmedUnitName = unitName.trim().toLowerCase();
        let units = [];
        const unitId = uuidv4();

        let trimmedUnitName;
        if (unitName) {
          trimmedUnitName = unitName.trim().toLowerCase();
        } else {
          // Handle the case when unitName is undefined
          toast.error("Unit Name is Required", {
            autoClose: 3000,
            closeOnClick: true,
            position: "top-center", 
            transition: Slide,
          });
          return;
        }
        
        // Check if the unitName is already in the listOfUnitNames array
        

        if(itemList.length == 0) {
            toast.error("No items on the grid.", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center", 
                transition: Slide,
            });
            return;
        }
      
        if (currentUnitId === "" || currentUnitId === null || currentUnitId === undefined) {
           if (optTypeId == null ) {
            toast.error("Please select a unit type.", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center", 
                transition: Slide,
            });
            return;
            }
            if (listOfUnitNames.map(name => name.toLowerCase()).includes(trimmedUnitName)) {
                toast.error(" ' "+unitName+" ' already exists. Please choose a different Unit name", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center", 
                    transition: Slide,
                });
                return;
            }
            itemList.forEach((item) => {
                if (item.stStockCode.trim() !== "") { // Check if stStockCode is not an empty string
                    units = [
                        ...units,
                {
                    unitId: id,
                    unItemId: uuidv4(),
                    createdBy: user?.homeAccountId,
                    modifiedBy: user?.homeAccountId,
                    createdOn: new Date(),
                    modifiedOn: new Date(),
                    stStockCode: item.stStockCode,
                    description: item.description,
                    qty: parseInt(item.qty),
                    type: item.type,
                    listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
                    rowId:item.rowId,
                    unit:null
                }
            ];
        }   
        })
       
        const unitHeader = {
           // unitId: unitId,
            unitId: id,
            unitName: unitName,
            createdOn: new Date(),
            modified: new Date(),
            createdBy: user?.homeAccountId,
            modifiedBy: user?.homeAccountId,
            libraryId:optTypeId,
            tblUnits : units,
            timeZone : clientTimezone
        }
        if (units.length === 0) {
            toast.error("No items with valid stStockCode to save.", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center", 
                transition: Slide,
            });
            return;
        }
        postResponse("NewUnits/createnewunit", unitHeader)
            .then((res) => {
                if (res.statusCode) {                
                    toast.success("The Unit has been Created Successfully", {
                        position: "top-center",
                        transition: Slide,
                    });            
                    setcurrentUnitId(res.data.unitId);
                    setIsCreatedUnit(true) /////used for clear node after creating unit
                    setNewUnits([]);
                    setIsUnitsLoad(true);
                    setCreatedName(res.data.unitName)
                    setItemList(res.data?.tblUnits)
                    updateUnitDetailsForId(res.data?.unitId, {
                       unitName: res.data?.unitName,
                       libraryId: res.data?.libraryId,
                       selectedItems: res.data?.tblUnits
                        });
                    handleDeleteOpenUnit(newUnits,setNewUnits,id);
                return res.data.unitId; 
                } else {
                    toast.error("Failed to create the New Unit.", {
                        position: "top-center",
                        transition: Slide,
                    });            
                    return null;
                }
            })
            .catch((error) => {
                console.error("Failed to save data:", error);
                toast.error("Connection error. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });        
                return null;
            })
            .finally(() => {
                setIsLoading(false);
            });  

        }else{
            itemList.forEach((item) => {
                if (item.stStockCode.trim() !== "") { // Check if stStockCode is not an empty string
                    units = [
                        ...units,
                    {
                        unitId: currentUnitId,
                        unItemId: uuidv4(),
                        createdBy: user?.homeAccountId,
                        modifiedBy: user?.homeAccountId,
                        createdOn: new Date(),
                        modifiedOn: new Date(),
                        stStockCode: item.stStockCode,
                        description: item.description,
                        qty: parseInt(item.qty),
                        type: item.type,
                        listPrice: parseFloat(parseFloat(item.listPrice).toFixed(2)),
                        rowId:item.rowId,
                        unit:null
                    }
                ];
            }
                
            })
            const unitHeader = {
               // unitId: unitId,
                unitId: currentUnitId,
                unitName: unitName,
                createdOn: new Date(),
                modified: new Date(),
                createdBy: user?.homeAccountId,
                modifiedBy: user?.homeAccountId,
                libraryId: optTypeId,
                tblUnits : units,
                timeZone : clientTimezone
            }
            if (units.length === 0) {
                toast.error("No items with valid stStockCode to Update.", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }
            const matchingUnit = listOfUnitIdWithNames.find(item => item.unitId === currentUnitId);
            const existingUnitName = matchingUnit ? matchingUnit.unitName.toLowerCase() : '';
            
            // Check if trimmedUnitName is in the listOfUnitNames and is not the same as existingUnitName
            if (listOfUnitNames.map(name => name.toLowerCase()).includes(trimmedUnitName.toLowerCase()) &&
                trimmedUnitName.toLowerCase() !== existingUnitName) {
                toast.error(" '" + unitName + "' cannot be updated. Please choose a different Unit name", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }
           
            else{
            putResponse("NewUnits/updatenewunit", unitHeader)
                .then((res) => {
                    if (res.statusCode) {                
                        toast.success("Unit updated successfully", {
                            position: "top-center",
                            transition: Slide,
                        });                
                        setcurrentUnitId(res.data.unitId);
                        setIsCreatedUnit(true) 
                        setIsUnitsLoad(true);
                        setCreatedName(res.data.unitName)
                        setItemList(res.data?.tblUnits)
                        updateUnitDetailsForId(res.data?.unitId, {
                            unitName: res.data?.unitName,
                            libraryId: res.data?.libraryId,
                            selectedItems: res.data?.tblUnits
                             });
                      //  handleNodeWhenUpdating(newUnits,setNewUnits, id, res.data.unitId, res.data.unitName);
                        return;
                    } else {
                        toast.error("Failed to create the New Unit.", {
                            position: "top-center",
                            transition: Slide,
                        });                
                        return;
                    }
                })
                .catch((error) => {
                    console.error("Failed to save data:", error);
                    toast.error("Connection error. Please try again later.", {
                        position: "top-center",
                        transition: Slide,
                    });            
                    return;
                })
                .finally(() => {
                    setIsLoading(false);
                    // setUnitName("")
                    // setItemList([])
                    // setSelectedLibrary("Select one from the list");
                });
            }     

        }            
    }
    const removeSelectedItems = () => {
        if (selectedItems.length > 0) {
            if (selectedItems[0].rowId == null || selectedItems[0].rowId == undefined ) {
                toast.error("Please select atleast one Item!", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,        
                });
            } else {
                confirmAlert({
                    title: "Delete Confirmation",
                    message: "Are you sure you want to delete the item(s)?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                const selectedRowIds = selectedItems.map(selectedItem => selectedItem.rowId);
                                const filteredItemList = itemList.filter(item => !selectedRowIds.includes(item.rowId));
                                setItemList(filteredItemList);
                                updateUnitDetailsForId(id, { selectedItems: filteredItemList });
                                // setHistory((prevHistory) => {
                                //     const newHistory = [...prevHistory, filteredItemList];
                                //     return newHistory.slice(-MAX_HISTORY_SIZE);
                                // });
                                saveToHistory(filteredItemList);
                                setItemCount(filteredItemList.length);
                                setSelectedItems([]);
                            },
                        },
                        {
                            label: "No",
                            onClick: () => {
                                return;
                            },
                        },
                    ],
                });
            }
        } else {
            toast.error("Please select atleast one item!", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,    
            });
        }
    };

 
    //     let currIdx = 0;
    //     let updatedItemList;
    //     if (selectedItems.length > 0) {
    //         if (selectedItems[0].stStockCode == null || selectedItems[0].stStockCode == undefined) {
    //             toast.error("Please select an item", {
    //                 autoClose: 1000,
    //                 closeOnClick: true,
    //             });
    //         } else {
    //             currIdx = itemList.findIndex((item) => item.stStockCode === selectedItems[0].stStockCode);
    //              const filteredItemList = itemList.filter((item) => {
    //                 return !selectedItems.some((selectedItem) => selectedItem.stStockCode === item.stStockCode);
    //             });

    //             let idx = currIdx;

    //             switch (move){
    //                 case "Up":  
    //                     if (idx > 1) {
    //                         updatedItemList = [...itemList.slice(0, idx-1), ...selectedItems, ...itemList.slice(idx-1,idx)];
    //                         setItemList(updatedItemList);
    //                         updateUnitDetailsForId(id, { selectedItems: updatedItemList });
    //                     } else {
    //                         updatedItemList = [...selectedItems, ...itemList.slice(idx-1,idx), ...itemList.slice(idx+1)];
    //                         setItemList(updatedItemList);
    //                         updateUnitDetailsForId(id, { selectedItems: updatedItemList });
    //                     }
                        
    //                 case "Down":
    //                     if (idx < itemList.length-1 && move === "Down") {
    //                         idx=idx+1;
    //                         updatedItemList = filteredItemList;
    //                         updatedItemList.splice(idx,0,...selectedItems);
    //                         setItemList(updatedItemList);
    //                         updateUnitDetailsForId(id, { selectedItems: updatedItemList });
    //                     }
    //             }
    //         }
    //     }
        

    // };
    const itemMove = (move) => {
        let currIdx = 0;
        let updatedItemList;
    
        if (selectedItems.length > 0) {
            if (selectedItems[0].stStockCode == null || selectedItems[0].stStockCode == undefined) {
                toast.error("Please select an item", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,

                });
            } else {
                currIdx = itemList.findIndex((item) => item.stStockCode === selectedItems[0].stStockCode);
    
                let idx = currIdx;
    
                switch (move) {
                    case "Up":
                        if (idx > 0) {
                            updatedItemList = [...itemList];
                            [updatedItemList[idx - 1], updatedItemList[idx]] = [updatedItemList[idx], updatedItemList[idx - 1]];
                            setItemList(updatedItemList);
                            updateUnitDetailsForId(id, { selectedItems: updatedItemList });
                            // setHistory((prevHistory) => {
                            //     const newHistory = [...prevHistory, updatedItemList];
                            //     return newHistory.slice(-MAX_HISTORY_SIZE);
                            // });
                            saveToHistory(updatedItemList);
                        }
                        break;
    
                    case "Down":
                        if (idx < itemList.length - 1) {
                            updatedItemList = [...itemList];
                            [updatedItemList[idx], updatedItemList[idx + 1]] = [updatedItemList[idx + 1], updatedItemList[idx]];
                            setItemList(updatedItemList);
                            updateUnitDetailsForId(id, { selectedItems: updatedItemList });
                            // setHistory((prevHistory) => {
                            //     const newHistory = [...prevHistory, updatedItemList];
                            //     return newHistory.slice(-MAX_HISTORY_SIZE);
                            // });
                            saveToHistory(updatedItemList);
                        }
                        break;
    
                    default:
                        break;
                }
            }
        }
    };
    
    
    
    const paginate = (items, pageNumber, pageSize) => {
        if (!items) {
            return [];
        }

        const startIndex = (pageNumber - 1) * pageSize;
        return items.slice(startIndex, startIndex + pageSize);
    };

    const handlePageChange = ({ skip, take }) => {
        setitemsPageNumber(skip / take + 1);
        setitemsPageSize(take);
    };
    
    //#endregion

    //#region useEffect
    useEffect(() => {
        const fetchLibaryData = async () => {
            let library = [];
            const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            try {
                const data = await getResponse("Library/loaddefaultlibrary", { userId: userId, clientTimezone: clientTimezone});
                console.log("data :",data)
                const lib = data.map((item) => item.libraryName);
                const userLibId= data.map((item) => item.userLibId);
                setUserLibId(userLibId)
                setLibraryTypes(lib);
                setLibraryListing(data);
                // lib.map((itm)=>
                //     library = [...library,{text: itm.libraryName,id: itm.id}]
                // )
                // console.log("lib :",library)
                // setOptType(library);
                const options = lib.map((itm) => ({ text: itm, id: itm }));
                setOptType(options);
                console.log("Library",options)
            } catch (error) {
                console.error("Error:", error);
            }
            
        };

        fetchLibaryData();
    }, []);

    useEffect(()=>{
        if(!triggerNewItem) return;
    },[addItems])

    useEffect(() => {
        if (triggerUnitSaving == 1) {
            if (!isLoading) handleSaveUnits();
        }
    }, [triggerUnitSaving]);
    //#endregion
    const handleCopy = () => {
        console.log("unit selected items :",selectedItems)
        setAddItems(selectedItems)
        setCopiedUnits(selectedItems)
        // const newItems = selectedItems.flat().map((items) => {
        //     let dcOnLp = items.dcOnLp;
        //     let rowId = uuidv4();
        //     let totalPrice = items.totalPrice;
        //     let margin = items.margin;
        //     let actMargin = items.actMargin;
        //     let frcMargin = items.frcMargin;
        //     let totalCost = items.totalCost;
        //     let groupDbExtraId = "";
        //     let subGroupDbExtraId = "";
        //     let nonGroupDbExtraId = "";
        //     return {
        //         ...items,
        //         dcOnLp,
        //         totalPrice,
        //         margin,
        //         frcMargin,
        //         actMargin,
        //         totalCost,
        //         rowId,
        //         groupDbExtraId,
        //         subGroupDbExtraId,
        //         nonGroupDbExtraId,
        //     };
        // });

        // const updatelist = [...newItems];
        //  setItemListNew(updatelist);
        // // setAllowCopy(true);
        setSelectedItems([]);
        
        setIsPriceListCopyed(false);
        setUnitCopy(true)
    };
    const handlePaste = () => {
        console.log("paste add :",addItems)
        // Check if items are available for pasting
        if (addItems.length === 0 && !isImportData) {
          toast.warning("No items to paste.", {
            position: "top-center",
            transition: Slide,
        });
          return;
        }
      
        // Determine the index to insert the pasted items
     //   let insertIndex = selectedItems.length > 0 ? itemList.indexOf(selectedItems[0]) + 1 : itemList.length;
     let insertIndex;

     if (isLastRowSelected && isImportData) {
       // Insert at the last row
       insertIndex = itemList.length;
     } else if (isSelectedRow && isImportData) {
       // Insert below the selected item
       insertIndex = selectedItems.length > 0 ? itemList.indexOf(selectedItems[0]) + 1 : itemList.length;
     } else {
       // Default: Insert at the end
       insertIndex = selectedItems.length > 0 ? itemList.indexOf(selectedItems[0]) + 1 : itemList.length;
     }
        // Create a copy of the current item list
        let updatedItemList = [...itemList];
      
        // Separate the pasted items into duplicate and non-duplicate lists
        const { duplicateItems, nonDuplicateItems } = addItems.reduce(
          (acc, pastedItem) => {
            if (updatedItemList.some((item) => item.stStockCode === pastedItem.stStockCode)) {
              acc.duplicateItems.push(pastedItem);
            } else {
              acc.nonDuplicateItems.push(pastedItem);
            }
            return acc;
          },
          { duplicateItems: [], nonDuplicateItems: [] }
        );
      
        if (duplicateItems.length > 0) {
          // Display a warning message for duplicate stock codes
          toast.warning(`Duplicate Item(s) found: ${duplicateItems.map((item) => item.stStockCode).join(", ")} Pasted without those item(s).`, {
            position: "top-center",
            transition: Slide,
        });
        }
        else{
            // if(isImportData){
            //     setAddItems("")
            // }
            setAddImportItems(false)
            setIsImportData(false)
            toast.success("Items pasted successfully.", {
                position: "top-center",
                transition: Slide,
            });    
        }
      
        // Insert the non-duplicate pasted items at the specified index
        updatedItemList.splice(insertIndex, 0, ...nonDuplicateItems);
      
        // Update the state with the modified list
        setItemList(updatedItemList);
        updateUnitDetailsForId(id, { selectedItems: updatedItemList });
        setItemCount(updatedItemList.length);
        // setHistory((prevHistory) => {
        //     const newHistory = [...prevHistory, updatedItemList];
        //     return newHistory.slice(-MAX_HISTORY_SIZE);
        // });
        saveToHistory(updatedItemList);
      
        // setIsImportData(false);
        // Reset the pasted items
       // setAddItems([]);
      
        
      };
      
      
      
    // const handlePaste = () => {
    //     // Check if items are available for pasting
    //     if (addItems.length === 0) {
    //       toast.warning("No items to paste.");
    //       return;
    //     }
      
    //     // Determine the index to insert the copied items
    //     let insertIndex = selectedItems.length > 0 ? itemList.indexOf(selectedItems[0]) + 1 : itemList.length;
      
    //     // Create a copy of the current item list
    //     let updatedItemList = [...itemList];
      
    //     // Insert the copied items at the specified index
    //     updatedItemList.splice(insertIndex, 0, ...addItems);
      
    //     // Update the state with the modified list
    //     setItemList(updatedItemList);
    //     updateUnitDetailsForId(id, { selectedItems: updatedItemList });
    //     setItemCount(updatedItemList.length);
      
    //     // Reset the copied items
    // //    setCopiedItems([]);
      
    //     toast.success("Items pasted successfully.");
    //   };
      
    // useEffect(() => {
    //     // Function to be executed every 1 minute
    //     const intervalFunction = () => {
    //         // Check if quoteUpdated is true and customerData array has elements
    //         if (unitDetails[id]?.selectedItems?.length > 0 ) {
    //             isSavedUnitChange(false);
    //         }
    //     };

    //     // Setting up the interval
    //     const intervalId = setInterval(intervalFunction, 15000); // 60000 milliseconds = 1 minute

    //     // Clearing the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    // }, [unitDetails]); 
    
    useEffect(() => { 
        if (unitDetails[id] && (currentUnitId === "" || currentUnitId === null || currentUnitId === undefined) ) {
             // setUnitId( unitDetails[id]?.unitId);
              setItemList(unitDetails[id]?.selectedItems?.length > 0 ? unitDetails[id].selectedItems : []);
              setUnitName( unitDetails[id].unitName);
             // setLibraryTypes( unitDetails[id].libraryId ? unitDetails[id].libraryId  : libraryTypes[0]);
              setSelectedLibrary( unitDetails[id].libraryId ? unitDetails[id].libraryId  : libraryTypes[0]);
                            setOptTypeId( unitDetails[id].optTypeId);

              setNewUnitName(unitDetails[id]?.newUnitName)
            //    setItemList((prevUnitData) => ({
            //         ...prevUnitData,
            //         [id]: unitDetails[id]?.selectedItems?.length > 0 ? unitDetails[id].selectedItems : [],
            //     }));
            
        }
        
        
    }, [id]);
    useEffect(() => {       
        if (window.performance && unitDetails.length ===0) {
            let navigationEntries = performance.getEntriesByType("navigation");           
            if (navigationEntries.length > 0) {
                let type = navigationEntries[0].type;
                if (type === 'reload') {
                    navigate("/dashboard");
                } else {
                    console.log('This page was not reloaded');                    
                }
            }
        }
    }, []);
  console.log("init detailsss :",unitDetails[id])
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const unitData = await getResponse("NewUnits/getfilteredunit", { unitId:unitId });
    //            // const unitData = await getResponse(`NewUnits/getfilteredunit/${unitId}`);
    //             console.log("unitData new:",unitData)
    //             if (unitData) {
    //                 setItemList(unitData.tblUnits);
    //                 setUnitName(unitData.unitName);
    //                 const libraryName = libraryListing.find((itm) => itm.userLibId === unitData?.libraryId)?.libraryName;
    //                 console.log("library libraryListing:",libraryTypes)
    //                 console.log("library Name:",libraryName)
    //                 setSelectedLibrary(libraryName);
    //                 updateUnitDetailsForId(unitData?.unitId, {
    //                     unitName: unitData?.unitName,
    //                     libraryId: unitData?.libraryId,
    //                     selectedItems: unitData?.tblUnits
                       
    //                 });
                 
    //             }
    //         } catch (error) {
    //             console.error("Error fetching unit data:", error);
               
    //         }
    //     };

       
    //         fetchData();
      
    // }, [unitId]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (unitId) {
                    const unitData = await getResponse("NewUnits/getfilteredunit", { unitId: unitId });
    
                    console.log("unitData new:", unitData);
    
                    if (unitData) {
                        setItemList(unitData.tblUnits);
                        setUnitName(unitData.unitName);
                        setcurrentUnitId(unitData.unitId);
                        setCreatedName(unitData.unitName)
                        const libraryName = libraryListing.find((itm) => itm.userLibId === unitData?.libraryId)?.libraryName;
                     
                        setSelectedLibrary(libraryName);
                      //  updateUnitDetailsForId(unitData?.unitId, {
                            updateUnitDetailsForId(id, {
                           // unitId:unitData?.unitId,
                            unitName: unitData?.unitName,
                            libraryId: unitData?.libraryId,
                            selectedItems: unitData?.tblUnits
                        });

                    }
                } else {
                    // Handle the case for new units (clearing fields or any specific action)
                    setItemList([]);
                    setUnitName("");
                    setSelectedLibrary("Select one from the list"); // Assuming you have a default library type ///libraryTypes[0]
                    // ... any other actions needed for new units
                }
            } catch (error) {
                console.error("Error fetching unit data:", error);
            }
        };
    
        fetchData();
    }, [unitId,libraryListing, libraryTypes,id]); // Include dependencies as needed  /////, libraryListing, libraryTypes,selectedLibrary
    
    // ... rest of the component

    // const loadUnitData = async (unitId) => {
    //     try {
    //       // Fetch data for the selected unit
    //       const unitData = await getResponse("NewUnits/getfilteredunit", { unitId });
          
    //       // Process the unitData as needed
    //       console.log('Unit Data :', unitData);
    //       setUnitName(unitData.unitName)
    //       setSelectedLibrary(unitData.libraryId)
    //       setItemList(unitData.tblUnits)
    //     } catch (error) {
    //       console.error('Error fetching unit data:', error);
    //     }
    //   };
    const insertRow = (position) => {
        if (selectedItems.length > 0) {
            const selectedRowIds = selectedItems.map((selectedItem) => selectedItem.rowId);
            const updatedItemList = itemList.reduce((acc, item) => {
                if (selectedRowIds.includes(item.rowId)) {
                    // Insert a new row above the selected item
                    const newRow = {
                        groups:"",
                        stStockCode:"",
                        description:"",
                        type:"",
                        listPrice:"",
                        qty: "",
                        rowId: uuidv4(), 
                        isNewlyInserted: true, // Flag indicating a newly inserted row
                    };
                    acc.push(newRow, item);
                } else {
                    acc.push(item);
                }
                return acc;
            }, []);
            console.log("updatedItemList unit:",updatedItemList)
            setItemList(updatedItemList);
            updateUnitDetailsForId(id, { selectedItems: updatedItemList });
            // setHistory((prevHistory) => {
            //     const newHistory = [...prevHistory, updatedItemList];
            //     return newHistory.slice(-MAX_HISTORY_SIZE);
            // });
            saveToHistory(updatedItemList);
            setItemCount(updatedItemList.length);
            setSelectedItems([]);
        }
    };
    useEffect(() => {
        // Update totalOfUnitItems whenever itemList changes
        setTotalOfUnitItems(itemList.length);
      }, [itemList]);  // Add itemList as a dependency
    
    const saveToHistory = (newItemList) => {
        setHistory((prevHistory) => {
            const newHistory = [...prevHistory, newItemList];
            return newHistory.slice(-MAX_HISTORY_SIZE);
          });
        
          // Clear the redo history when a new action is performed
          setRedoHistory([]);
          setHasUndone(false); // Reset the hasUndone state 
      };

    const undo = () => {

        if (history.length > 1) {
            const previousState = history[history.length - 2];
            setHistory(history.slice(0, -1));
            setRedoHistory([...redoHistory, itemList]);
            setItemList(previousState);
            setHasUndone(true); 
          }
    };
    
    const redo = () => {
            if (redoHistory.length > 0) {
            const nextState = redoHistory[redoHistory.length - 1];
            setRedoHistory(redoHistory.slice(0, -1));
            setHistory([...history, itemList]);
            setItemList(nextState);
        }
    };
     
    // useEffect(() => {
      
    //     if (isImportData) {
    //         setAddItems(importedData);
    //         handlePaste();
                  
    //     }
        
    //   }, [isImportData,addItems]);
    // const handlePasteButtonClick = () => {
    //     console.log("Imported Data:", importedData);
    //     setAddItems(importedData);
    //     handlePaste();
    // };
    const handlePasteButtonClick = async () => {
        console.log("Imported Data:", importedData);
        if (importedData.length > 0) {
            setAddImportItems(true)
          await setAddItems(importedData);
         // handlePaste();
        } 
      };
      console.log("addItems after set:", addItems);

    useEffect(() => {
         if (addImportItems) {         
          handlePaste();
        } 
    }, [addImportItems]);
      
      
    return (
        <>  
            <Row>
                <div className="row p-0 m-0">
                    <div className="mb-3">
                        <div className="col-12 col-md-6 pe-2 float-start">
                            <strong title="Required Field">*Name</strong>
                            <Input 
                                value={unitName}
                                onChange={onChangeUnitName}
                            />
                        </div>

                        <div className="col-12 col-md-2 pe-2 float-start">
                            <strong title="Required Field">*Type</strong>
                            {/* <DropDownList
                                data={optType}
                                textField="text"
                                dataItemKey="id"
                                value={selectedOptType}
                                onChange={onChangeType}
                            /> */}
                            <DropDownList
                                // data={libraryTypes}
                                // // textField="text"
                                // // dataItemKey="id"
                                // // valueField={selectedOptType}
                                // valueFied="options"
                                // defaultValue="Select one from the list"
                                // onChange={onChangeType}
                                data={libraryTypes}
                                valueField="text"
                                //id={optTypeId}
                                value={selectedLibrary}
                                defaultValue="Select one from the list" // Set default value
//defaultValue={libraryTypes[0]}
                                onChange={onChangeType}
                                disabled={(currentUnitId === "" || currentUnitId === null || currentUnitId === undefined)? false : true}
                            />
                        </div>
                    </div>
                </div>
            </Row>
            <Accordion id="default-accordion-example2">
                <AccordionItem>
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className={classnames("accordion-button", { collapsed: !col2 })}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}>
                            New Unit ({itemList.length})
                        </button>
                    </h2>
                    <Collapse isOpen={col2} className="accordion-collapse" id="collapseTwo">
                        <div className="accordion-body">
                            {isLoading && (
                                <div className="ip-spin-dialog-holder">
                                    <Spinner />
                                </div>
                            )}

                            {!isLoading &&(
                                <DataGridView
                                    data={paginate(itemList || [], itemspageNumber, itemspageSize)}
                                    columns={columns}
                                    // onEdit={onEdit}
                                    selectedRows={selectedItems}
                                    setSelectedRows={setSelectedItems}
                                    handleItemChange={handleItemChange}
                                    showSelectionColumn={true}
                                    isHeaderCheckboxRender={true}
                                    singleSelect={false}
                                   // idField="stStockCode"
                                    idField="rowId"
                                    tableName="UnitItems"
                                    className="ip-newunit-btns"
                                    toolbarButtons={[
                                        {
                                            label: <strong>Add Items</strong>,
                                            onClick: handleAdd,
                                            // icon: "plus",
                                            look: "flat",
                                        },
                                        {
                                            label: <strong>Remove Items</strong>,
                                            onClick: () => removeSelectedItems(itemList),
                                            look: "flat",
                                        },
                                        {
                                            label: <strong title="Click on copy">Copy</strong>,
                                            onClick: () => handleCopy(),
                                            disabled:  selectedItems <= 0,
                                            look: "flat",
                                        },
                                        {
                                            label: <strong title="Click on paste">Paste</strong>,
                                            onClick: () => handlePaste(),
                                            disabled: unitCopy ? false :true,
                                            look: "flat",
                                        },
                                        {
                                            label: <strong title="Click on Add Row">Insert Row</strong>,
                                            onClick: () => insertRow("Above"),
                                            disabled:  selectedItems <= 0,
                                            look: "flat",
                                        },
                                        /*{
                                            label: <strong title="Undo">Undo</strong>,
                                            onClick: () => undo(),
                                            disabled:  totalOfUnitItems <= 0,
                                            look: "flat",
                                        },
                                        {
                                            label: <strong title="Redo">Redo</strong>,
                                            onClick: () => redo(),                                            
                                            disabled:hasUndone? false:true,
                                            look: "flat",
                                        },*/
                                        {
                                            label: <i title="Move Item Up (moves selected row item up)" className="ri-arrow-up-fill"></i>,
                                            onClick: () => itemMove("Up"),
                                            icon: "",
                                            look: "flat",
                                        },
                                        {
                                            label: <i title="Move Item Down (moves selected row item down)" className="ri-arrow-down-fill"></i>,
                                            onClick: () => itemMove("Down"),
                                            icon: "",
                                            look: "flat",
                                        },
                                        {
                                            label: (
                                                <div className="ip-unit-view-btn">
                                                    {/* <span>View:</span> */}
                                                    <DropDownList
                                                        data={options}
                                                        textField="text"
                                                        dataItemKey="id"
                                                        value={selectedOption}
                                                        onChange={handleChangeOption}
                                                        ref={changedOptionRef}
                                                    />
                                                </div>
                                            ),
                                        },
                                    ]}
                                    total={itemList?.length || 0}
                                    showEmptyRow={true}
                                    skip = {skipUnits}
                                    take = {takeUnits}
                                    onPageChange={handlePageChangeUnits}
                                    isGroupable={false}
                                />
                            )}
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>

            {itemLookUpVisible && (
                <Dialog width="100%" title={"Parts Lookup"} onClose={closeDialog}>
                    <ItemLookup onAddItems={handleSelectedItems} />

                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button className="px-4 me-2 ip-button-general" onClick={handleAddParts}>
                                Add
                            </Button>
                            <Button className="px-4" onClick={closeDialog}>
                                Cancel
                            </Button>

                           
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
            {exportCSV && (
                <CSVLink data={csvData} filename={'UnitList.csv'}>
                    Download CSV
                </CSVLink>
            )}
             {importDialogVisible && (
             <ImportDialogUnit 
             onClose={() => {
                setImportDialogVisible(false);
                setSelectedOption(options[0]); // Reset selected option to the default value
            }}
             handlePasteButtonClick={handlePasteButtonClick}
             />
        )}
        </>
    );
};

export default NewUnitDataGrid;
