import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { Button, SplitButton } from "@progress/kendo-react-buttons";
import EditableCell from "./EditableCell"; // adjust the path according to your project structure
import CheckboxCell from "./CheckboxCell"; // adjust the path according to your project structure
import HeaderCheckboxCell from "./HeaderCheckboxCell"; // adjust the path according to your project structure
import { setGroupIds, setExpandedState } from "@progress/kendo-react-data-tools";
import { groupBy } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { GroupTypes } from "../../enums/GroupTypes";
import { useAuthorizationContext } from "../Auth/useAuthorization";
import { filter, isNumber, set } from "lodash";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { object } from "prop-types";
import { itemStyle, indicatorToolTip } from "../../utils/QuoteItemIndicators";
import { ContextMenu, MenuItem, Menu } from "@progress/kendo-react-layout";
import { Popup } from '@progress/kendo-react-popup';
import { QteCurGridViewTypeContext } from "../../Contexts/QteCurGridViewTypeContext";
import { SaveUserPref } from "../Qoutes/UserGridPreference"
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { getResponse } from "../../Services/Common/Get";
import { QteRepDiscCheckerContext } from "../../Contexts/QteRepDiscCheckerContext";
//#region approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
//#endregion
import QuotesItemGrid from "../Qoutes/QuotesItemGrid";

// const processWithGroups = (data, group) => {
//     const newDataState = groupBy(data, group);
//     setGroupIds({
//         data: newDataState,
//         group: group,
//     });
//     return newDataState;
// };
// class ProductNameHeader extends React.Component {
//     render() {
//         return (
//             <a className="k-link" onClick={this.props.onClick}>
//                 <span title={this.props.title}>{this.props.title}</span>
//                 {this.props.children}
//             </a>
//         );
//     }
// }
const applyInitialFormat = false;
class ProductNameCell extends React.Component {
    render() {
        return applyInitialFormat
        ? null
        : this.props.rowType !== "groupHeader" ? (
            <td className="ip-gridclr-noneditable" title={this.props.dataItem[this.props.field]}>
                {
                    (this.props.field === "l50" || this.props.field === "l55" ||
                    this.props.field === "l20" || this.props.field === "l60")
                    ? isNumber(this.props.dataItem[this.props.field])
                        ? parseFloat(this.props.dataItem[this.props.field]).toFixed(2) <= 0
                            ? ""
                            : parseFloat(this.props.dataItem[this.props.field]).toFixed(2)
                        : this.props.dataItem[this.props.field]
                    : isNumber(this.props.dataItem[this.props.field]) 
                        ? this.props.field !== "qty" && this.props.field !== "margin" 
                        && this.props.field !== "actMargin" && this.props.field !== "frcMargin"
                        && this.props.field !== "lcMargin"
                            ? parseFloat(this.props.dataItem[this.props.field]).toFixed(2)
                            : this.props.dataItem[this.props.field]     
                        : this.props.dataItem[this.props.field]
                }
            </td>
        ) : null;
    }
    applyInitialFormat = true;
}

class ProductIconCell extends React.Component {
    render() {
        let newStkcode = this.props.dataItem["stStockCode"] ? this.props.dataItem["stStockCode"] : "";
        let oldStkcode = this.props.dataItem["oldStockCode"] ? this.props.dataItem["oldStockCode"] : "";
        let titleMsg = indicatorToolTip(this.props.dataItem);//(newStkcode == oldStkcode ? indicatorToolTip(this.props.dataItem) : "");
        return (
            this.props.rowType !== "groupHeader" ? 
            <td className={`k-grid-cell-icon`} title={titleMsg}>
            {this.props.dataItem[this.props.field]}
            </td>:null
        );
    }
}

const multipleGroups = [{ field: "groupName" }, { field: "subGroupName" }, { field: "assemblyGroupName"}];
const multipleGroupsWOSub = [{ field: "groupName" }, { field: "assemblyGroupName"}];

const GroupDataGridView = ({
    data = [],
    columns = [],
    tableName,
    toolbarButtons = [],
    selectedRows = [],
    setSelectedRows = () => {},
    idField = "",
    isHeaderCheckboxRender = false,
    total,
    onPageChange,
    showSelectionColumn = true,
    singleSelect = false, // new prop for single selection
    showEmptyRow = true,
    handleCustomerNumberChange, // new prop
    handleItemChange,
    getQuoteDetailsById,
    isGroupable,
    itemAssemblyList,
    labourCostName,
    currentMargin,
    excelExportRef,
    isSubGroupEnabled,
    isInsertGroupEnabled,
    isInsertSubGroupEnabled,
    handleAddGroup,
    selectedIndex,
    setSelectedIndex,
    handleLabourChange,
    insertLineNotes,
    setInsertLineNotes,
    handleLineNoteChanges,
    shouldFocusOnStStockCode,
    isInsertItems,
    isAddDbExtra,
    isInsertDbExtra,
    onAdd,
    isEditLabourHoursEnabled,
    isInsertEditLabourHoursEnabled,
    onActionLabour,
    isExportedItems,
    isExportedConsolidateItems,
    onActionItems,
    selectedView,
    selectedItems,
    isCollapseAll,
    isExpandCollapseTriggered,
    showIconColumn = true,
    allowCopy,
    scrollValue,
    customFieldWidth,
    setSelectedView,
    viewOptions,
    handleItemGrid,
    setDropdownValue,
    setIsQuoteSaved
}) => {
    const [gridState, setGridState] = useState({
        skip: 0,
        take: 2000, //The number of items to show in grid
    });
    const [group, setGroup] = useState([{ field: "groupName" }]);
    const [collapsedState, setCollapsedState] = useState([]);
    const stStockcodeRef = useRef(null);
    const [view, setView] = useState();
    const {quoteRepDiscData, setQuoteRepDiscData} = useContext(QteRepDiscCheckerContext);
    const DATA_ITEM_KEY = "rowId";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const selectionModes = [
        {
            value: "single",
            label: "Single selection mode",
        },
        {
            value: "multiple",
            label: "Multiple selection mode",
        },
    ];
    const user = useContext(UserContext);


    const { 
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected
    } = useContext(QteApprovalNotificationContext);

    // const [selectedState, setSelectedState] = React.useState({});
    // const [selectionMode, setSelectionMode] = React.useState(
    //     selectionModes[0].value
    // );

    // const onSelectionChange = (event) => {
    //     const newSelectedState = getSelectedState({
    //     event,
    //     selectedState: selectedState,
    //     dataItemKey: DATA_ITEM_KEY,
    //     });
    //     (selectedState[event.dataItems[event.endRowIndex].rowId]) ? setSelectedState({}) : setSelectedState(newSelectedState);
    // };

    const groupButtonOptions = useMemo(
        () => [
            { text: "Add Sub Group", onClick: () => handleAddGroup(null, 0), disabled : !isSubGroupEnabled }, 
            { text: "Insert Group", onClick: () => handleAddGroup(null, 1), disabled: !isInsertGroupEnabled},
            { text: "Insert Sub Group", onClick: () => handleAddGroup(null, 2), disabled: !isInsertSubGroupEnabled },
            { text: "Expand All", onClick: () => handleAddGroup(null, 3) },
            { text: "Collapse All", onClick: () => handleAddGroup(null, 4) },
        ],
        [handleAddGroup ,isSubGroupEnabled, isInsertGroupEnabled, isInsertSubGroupEnabled]
    );

    const itemButtonOptions = useMemo(
        () => [
            { text: "Insert Items", onClick: () => onAdd(null, 0), disabled: !isInsertItems },
            { text: "Add DB Extra", onClick: () => onAdd(null, 1), disabled: !isAddDbExtra },
            { text: "Insert DB Extra", onClick: () => onAdd(null, 2), disabled: !isInsertDbExtra },
        ],
        [isInsertItems, isAddDbExtra, isInsertDbExtra]
    );

    const moreButtonOptions = useMemo(
        () => [
            { text: "Import Items" , onClick: () => onActionItems(null , 0)},
            { text: "Export Items(as in the grid)", onClick: () => onActionItems(null, 1), disabled: !isExportedItems },
            {
                text: "Export Items(Consolidated)",
                onClick: () => onActionItems(null, 2),
                disabled: !isExportedItems
              //  disabled: !isExportedConsolidateItems,
            },
            { text: "Refresh SOH" },
            
        ],
        [isExportedItems, isExportedConsolidateItems]
    );

    const labourButtonOptions = useMemo(
        () => [
            { text: "Add Labour Hours" },
            { text: "Edit Labour Hours", onClick: () => onActionLabour(null, 1), disabled: !isEditLabourHoursEnabled },
            {
                text: "Insert Labour Hours",
                onClick: () => onActionLabour(null, 2),
                disabled: !isInsertEditLabourHoursEnabled,
            },
        ],
        [isEditLabourHoursEnabled, isInsertEditLabourHoursEnabled]
    );

    // const visibleColumns = columns.filter((column) => column.show !== false);
    let visibleColumns = [];
    const gridRef = useRef();

    //#region prvious approach on resizing
    // const [triggerResized,setTriggerResized] = useState(false);

    // const componentDidMount = () => {
    //     let grid = gridRef.current;
    //     //grid.resize();
    //     var doubleClickEvent = new Event("dblclick", {"bubbles":true, "cancelable":false});

    //     grid.element.querySelectorAll('.k-column-resizer').forEach((item) => {

    //          item.dispatchEvent(doubleClickEvent);
    //     });
    // }

    // useEffect(() => {
    //     componentDidMount();
    // },[])
    //#endregion

    //#region new approach grid-sizing
    const ADJUST_PADDING = 19;
    const COLUMN_MIN = 19;
    let grid = gridRef;
    const [applyMinWidth, setApplyMinWidth] = React.useState(false);
    // const [gridCurrent, setGridCurrent] = React.useState(0);
    let gridCurrent = 0;
    const minGridWidth = React.useRef(0);
    let totalCustomWidth = 0;

    let customizedCol = [];
    let colMenus = [];
    const [defineCols, setDefineCols] = React.useState(true);
    const [colPref, setColPref] = React.useState([]);
    // useEffect(() => {
    //     resizeDatagrid();
    // },[sideBarSize]);

    // const resizeDatagrid = () => {
    //     grid.current = document.querySelector(".k-grid");
    //     window.addEventListener("resize", handleResize);

    //     gridCurrent = grid.current.offsetWidth;
    //     setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);

    //     customizedCol = columns.map((item) =>
    //         // item.minWidth !== undefined ? (minGridWidth.current += item.minWidth) : minGridWidth.current
    //        item = {...item, minWidth: (item.minWidth !== undefined ? item.minWidth : setWidth(item.width))}
    //     );
    // }

    const setHandleGridOffsetWidth = () => {
        totalCustomWidth = 0;
        visibleColumns.forEach((x) => {
            totalCustomWidth += x.minWidth !== undefined ? x.minWidth : 0;
        });
    };

    const renderResize = () => {
        grid.current = document.querySelector(".k-grid");
        gridCurrent = grid.current.offsetWidth;
    };

    const handleResize = () => {
        if (grid.current === null) return;
        if (typeof grid.current.offsetWidth === undefined || grid.current.offsetWidth === null) return;
        if (grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
            setApplyMinWidth(true);
        } else if (grid.current.offsetWidth > minGridWidth.current) {
            gridCurrent = grid.current.offsetWidth;
            setApplyMinWidth(false);
        }
    };

    const setWidth = (minWidth) => {
        gridCurrent === 0 ? setHandleGridOffsetWidth() : null;
        ///removing 69 pixel for checkbox and extra cell on the first part. Also -1 length because the checkbox cell also take a width part of the grid.
        minWidth =
            minWidth === undefined
                ? (gridCurrent - totalCustomWidth - 69) / customizedCol.filter((x) => x.minWidth === undefined).length
                : minWidth;
        return parseInt(minWidth);
    };
    //#endregion

    //ref : Begin - GROUPING FUNCTIONS
    useEffect(() => {
        const subGroup = data.filter((f) => f.subGroupName !== "" && f.subGroupName !== undefined);
        const assemblyGroup = data.filter((f) => f.assemblyGroupName !== "" && f.assemblyGroupName !== undefined);
        assemblyGroup.length > 0 ? setGroup(multipleGroupsWOSub) : subGroup.length > 0 ? setGroup(multipleGroups) : <></>;
        setDefineCols(true);
        processWithGroups(group);
    }, [data, group]);

    useEffect(() => {
        if (isCollapseAll === null) return;

        let collapseIds = [];
        const newDataState = groupBy(data, group);
        setGroupIds({
            data: newDataState,
            group: group,
        });

        if (isCollapseAll)
            newDataState.filter((item) => item.aggregates).forEach((data) => collapseIds.push(data.groupId));
        else collapseIds = [];
        setCollapsedState(collapseIds);
        processWithGroups(group);
    }, [isCollapseAll]);

    const fetchData = async () =>{
        
        try {
            const response = await getResponse("UserPreferences/getuserpreferences", { userId: user?.homeAccountId });
            if (Object.keys(response).length > 0) {
                const index = response.findIndex((x)=>x.gridMenusFor === "ItemGrid");
                setColPref(JSON.parse(response[index].userPrefColumns));
                setDefineCols(!defineCols);
            }

        } catch (error) {
            console.error("Error:", error);
        }
    }


    const processWithGroups = (group) => {
        let uConfig;
        let uSelected;
        let index = 0;
        // colMenus = [];
        // if (qteCurGridViewType === "Normal" && defineCols) fetchData();
        columns.map(
            (item,idx) => {
                if (qteCurGridViewType === "Normal" && columns[idx].userConfig) {
                    const disabledCol = ['stStockCode','description','qty','nettEA'];
                    index = colPref.length > 0 ? colPref.findIndex((x)=>x.field === item.field) : -1;
                    uSelected = index > -1 ? colPref[index]?.userSelected : true
                    columns[idx].show = uSelected;
                    colMenus.push({
                        text: columns[idx].title,
                        icon: uSelected ? "check" : "uncheck",
                        disabled: disabledCol.includes(item.field) ? true : false,
                        cssClass : columns[idx].userConfig ? '' : 'displaynone'
                    })
                
                }

                columns[idx].minWidth = item.minWidth !== undefined ? item.minWidth : setWidth(item.minWidth)
            }
        );
        customizedCol = columns;

        const newDataState = groupBy(data, group);
        setGroupIds({
            data: newDataState,
            group: group,
        });

        return setExpandedState({
            data: newDataState,
            collapsedIds: collapsedState,
        });
    };

    const onGroupChange = useCallback((event) => {
        processWithGroups(event.group);
        setGroup(event.group);
    }, []);

    const onExpandChange = useCallback(
        (event) => {
            const item = event.dataItem;
            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );
    //ref : End - GROUPING FUNCTIONS

    const pageChange = (event) => {
        setGridState({
            skip: event.page.skip,
            take: event.page.take,
        });

        if (onPageChange) {
            onPageChange(event.page);
        }
    };


    const rowRender = (trElement, props) => {
        const isRowSelected = selectedRows.some((row) => row[idField] === props.dataItem[idField]);
        const isGroup = props.dataItem.displayGroupName !== "" && props.dataItem.stStockCode == "" ? true : false;
        const isSubGroup = props.dataItem.displaySubGroupName !== "" && props.dataItem.stStockCode == "" ? true : false;
        let applyStyle = "";

        if (props.dataItem.stStockCode) {
            if (quoteRepDiscData) 
            {   
                const rIndex = quoteRepDiscData.findIndex((x)=>x.stStockCode === props.dataItem.stStockCode && x.rowId === props.dataItem.rowId);
                rIndex > -1
                ? quoteRepDiscData[rIndex].stStockCode === props.dataItem.stStockCode 
                    ? quoteRepDiscData[rIndex].currRepDisc > quoteRepDiscData[rIndex].cuMaxDiscount
                        ? props.dataItem.iconIndicator = "notif-repdisc" 
                        : ""
                    : <></> 
                : <></>;
                
            }
            applyStyle = itemStyle(props.dataItem);
        }

        /* start Render of GroupSummaryRow */

           if (props.dataItem.rowType === "G" )
           {
                    let groupheader = props.dataItem.displayGroupName + "    -  Qty: " +  props.dataItem.qty + 
                        " | Total Cost: " + props.dataItem.totalCost + " | Total Price: " + props.dataItem.totalPrice + "$";
                        sessionStorage.setItem(props.dataItem.groupName,groupheader);    
            }

            
            if (props.dataItem.field === "subGroupName" && props.dataItem.value != '' && props.dataItem.items[0].rowType === "SG" ){
                
                props.dataItem.value = props.dataItem.items[0].displaySubGroupName + "    -  Qty: " +  props.dataItem.items[0].qty + 
                " | Total Cost: " + props.dataItem.items[0].totalCost + " | Total Price: " + props.dataItem.items[0].totalPrice + "";
                
            }

            if(props.rowType === "groupHeader")
            {
                if(props.dataItem.value != '')
                {
                    if(!props.dataItem.value.includes('Qty:') && !props.dataItem.value.includes('Non Grouped Items'))
                    {
   
                      let gID = props.dataItem.groupId.replace('groupName','');
                      let gheader = sessionStorage.getItem(gID);
                      let finalValueHeader = '';
                      if(gheader != null)
                      {
                        let splittedStr = gheader.includes('$') ? gheader.split('$') : gheader;
                        finalValueHeader = splittedStr[0];
                      }else{
                        finalValueHeader = props.dataItem.value;
                      }
   
                      props.dataItem.value = finalValueHeader;
                    }

                    if(!props.dataItem.value.includes('Qty:') && props.dataItem.value.includes('Non Grouped Items'))
                    {
                       props.dataItem.value = 'Non Grouped Items';
                    }
                }

            }

           /* end Render of GroupSummaryRow */

        if (qteCurGridViewType === "Normal" && qteAuthType === "General" && props.dataItem.stEecostRound) {
             props.dataItem.stEecostRound = props.dataItem.rowType !== "CI" ? "****" : props.dataItem.stEecostRound;
        } 

        /*handling styling for grouping*/
        const trProps = {
            className:
                (isGroup ? "ip-grouprow-style" : isSubGroup ? "ip-subgrouprow-style" : "ip-normalrow-style") +
                applyStyle 
                +
                (props.dataItem.value === "" && props.dataItem.groupId !== "" ? " displaynone" : "") +
                (typeof props.dataItem.subGroupName !== "undefined" 
                ? ((props.dataItem.subGroupName).includes("Non Sub Group") && props.dataItem.rowType === "SG")
                    ? " displaynone" : ""
                : "") + " " + props.rowType ,
                 
        };

     

        const handleRowClick = (e) => {
            const itemToChange = props.dataItem;
            const checkboxContainer = e.currentTarget.querySelector(`div[data-id="${itemToChange[idField]}"]`);
            let isAHeader = // 3 is the additional columns including the checkbox and the empty 2 columns
                e.currentTarget.querySelector(`[colspan="${customizedCol.length + 3}"]`) ||
                e.currentTarget.querySelector(`[colspan="${customizedCol.length - 1 + 3}"]`);

            if (isAHeader) {
                return;
            } else {
                let arrowIcon = document.querySelector(".arrowRight");
                let rowHighLight = document.querySelector(".selected-row");
                arrowIcon !== null ? arrowIcon.classList.remove("arrowRight") : <></>;
                rowHighLight !== null ? rowHighLight.classList.remove("selected-row") : <></>;
                e.currentTarget.cells[0].classList.add("arrowRight");
                e.currentTarget.classList.add("selected-row");
            }

            if (checkboxContainer) {
                checkboxContainer.click();
            }

            const isChecked = isRowSelected;

            if (singleSelect) {
                if (isChecked) {
                    setSelectedRows([]);
                } else {
                    setSelectedRows([itemToChange]);
                }
            } else {
                if (isChecked) {
                    itemToChange.rowType === "A"
                        ? setSelectedRows(
                              selectedRows.filter((row) => row["assemblyId"] !== itemToChange["assemblyId"])
                          )
                        : setSelectedRows(selectedRows.filter((row) => row[idField] !== itemToChange[idField]));
                } else {
                    itemToChange.rowType === "A"
                        ? setSelectedRows([
                              ...selectedRows,
                              ...data.filter(
                                  (row) => row["assemblyId"] === itemToChange["assemblyId"] && row !== selectedRows
                              ),
                          ])
                        : setSelectedRows([...selectedRows, itemToChange]);
                }

                setSelectedIndex(e.currentTarget.rowIndex);
            }
        };

        return React.cloneElement(
            trElement,
            {
                onClick: handleRowClick,
                className: isRowSelected ? "selected-row" : "",
                ...trProps,
            },
            trElement.props.children
        );
    };

    const isLastSubGroup = (groupId, dataList, subGroupedData) => {
        const groupedData = dataList.filter((item) => item.groupId === groupId);
        const remainingList = groupedData.filter((g) => !subGroupedData.some((s) => s[idField] === g[idField]));

        if (remainingList.length === 1) {
            return true;
        }
        return false;
    };

    const selectMultipleItems = (dataItem, status) => {
        const checkedGroupId = dataItem?.groupId;
        const checkedSubGroupId = dataItem?.subGroupId;
        const isMainGroup = dataItem?.displayGroupName !== "" && dataItem?.stStockCode == "" ? true : false;
        const isMainSubGroup = dataItem?.displaySubGroupName !== "" && dataItem?.stStockCode == "" ? true : false;

        if (isMainGroup) {
            if (status) {
                const groupedData = data.filter((item) => item.groupId === checkedGroupId);
                const combinedList = [...selectedRows, ...groupedData];
                setSelectedRows(combinedList);
            } else {
                const groupedData = data.filter((item) => item.groupId === checkedGroupId);
                const combinedList = selectedRows.filter(
                    (row) => !groupedData.some((item) => item[idField] === row[idField])
                );
                setSelectedRows(combinedList);
            }
        } else if (isMainSubGroup) {
            let groupId = null;

            if (status) {
                const subGroupedData = data.filter((item) => item.subGroupId === checkedSubGroupId);
                // let isLast = false;

                // if (subGroupedData.length > 0) {
                //     groupId = subGroupedData[0].groupId;
                //     isLast = isLastSubGroup(groupId, data, subGroupedData);
                // } else {
                //     isLast = false;
                // }

                // if (isLast) {
                //     const groupedData = data.filter((item) => item.groupId === groupId);
                //     const combinedList = [...selectedRows, ...groupedData];
                //     setSelectedRows(combinedList);
                // } else {
                //     const combinedList = [...selectedRows, ...subGroupedData];
                //     setSelectedRows(combinedList);
                // }
                const combinedList = [...selectedRows, ...subGroupedData];
                setSelectedRows(combinedList);
            } else {
                // const subGroupedData = data.filter((item) => item.subGroupId === checkedSubGroupId);
                // let isLast = false;

                // if (subGroupedData.length > 0) {
                //     groupId = subGroupedData[0].groupId;
                //     isLast = isLastSubGroup(groupId, data, subGroupedData);
                // }

                // if (isLast) {
                //     const groupedData = data.filter((item) => item.groupId === groupId);
                //     const combinedList = selectedRows.filter(
                //         (row) => !groupedData.some((item) => item[idField] === row[idField])
                //     );
                //     setSelectedRows(combinedList);
                // } else {
                //     const subGroupedData = data.filter((item) => item.subGroupId === checkedSubGroupId);
                //     const combinedList = selectedRows.filter(
                //         (row) => !subGroupedData.some((item) => item[idField] === row[idField])
                //     );
                //     setSelectedRows(combinedList);
                // }
                const subGroupedData = data.filter((item) => item.subGroupId === checkedSubGroupId);
                const combinedList = selectedRows.filter(
                    (row) => !subGroupedData.some((item) => item[idField] === row[idField])
                );
                setSelectedRows(combinedList);
            }
        } else {
            if (singleSelect) {
                if (isChecked) {
                    setSelectedRows([]);
                } else {
                    setSelectedRows([...selectedRows, dataItem]);
                }
            }
        }
    };

    const isObjEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const nonEmptyRowCount = data.filter((row) => Object.keys(row).length !== 0).length;
    const { qteCurGridViewType, qteAuthType } = useContext(QteCurGridViewTypeContext);
    //#region  context-menu column hide/show
    const [show, setShow] = React.useState(false);
    useEffect(() => {
        document.addEventListener('click', () => {
        show ? setShow(false) : null;
        });
    });
    const [offSet, setOffset] = React.useState({
        current: {
        left: 0,
        top: 0,
        },
    });

    useEffect(()=>{
        if(qteCurGridViewType !== "Normal") {
            columns.forEach((r)=>{
                if (r.userConfig) r.show = true;
            })
        } else fetchData()
    },[qteCurGridViewType]);

    const handleContext = (e, field) => {
    
        const viewType = qteCurGridViewType;

        if (viewType !== "Normal") return;
 
        console.log(e.pageX);
        console.log(e.pageY);
        setOffset({
        current: {
            left: e.pageX,
            top: e.pageY,
        },
        });
        setShow(true);
    }

    //#region context-menu column hide/show
    // const handleOnSelect = (e) => {
    //     setDefineCols(true);
    //     if(!e.item.disabled && e.item) {
    //         const index = columns.findIndex(itm => itm.title === e.item.text);
    //         if (index < 0) return;
    //         columns[index].show = e.item.icon === 'check' ? false : true;
    //         columns[index].userSelected = e.item.icon === 'check' ? false : true;
        
    //         const filterData = columns.filter((f)=>f.userConfig === true);
    //         let paramData = [];
    //         filterData.map((f)=>
    //             {
    //                 paramData.push({
    //                     field: f.field,
    //                     userConfig: f.userConfig, 
    //                     userSelected: f.userSelected
    //                 })
    //             }
    //         );
    //         const data = {
    //             userId: user?.homeAccountId,
    //             module : "Quotation",
    //             gridMenusFor : "ItemGrid",
    //             userPrefColumns : paramData
    //         }

    //         const response = SaveUserPref(data);

    //     };

    //     setShow(false);
    // };

    const ProductNameHeader = (props) => {

        return (
            <div
                className="k-link"
                onClick={props.onClick}
                onContextMenu={(e) => {
                    e.preventDefault();
                    handleContext(e, props.field);
                }}
            >
                <a className="k-link" onClick={props.onClick}>
                    <span title={props.title}>{props.title}</span>
                    {props.children}
                </a>
            </div>
        );

    }

    //#endregion

    // useEffect(() => {
    //     if (stStockcodeRef.current) {
    //         stStockcodeRef.current.focus();
    //     }
    // }, [data]);

    // const IconCell = ({dataItem,...props}) => {
    //     if (!dataItem) {
    //         return dataItem;
    //     }
    //     const imageDataUrl = dataItem.image.replace(/url\('(.*)'\)/, '$1');
    //     return <td {...props.tdProps}>
    //         <img src={imageDataUrl} width="34" height="34" className="contact-img" />
    //         <span style={{
    //         display: 'inlineBlock',
    //         paddingLeft: '10px',
    //         verticalAlign: 'middle',
    //         lineHeight: '32px'
    //         }} className="person-name">
    //             {dataItem.full_name}
    //         </span>
    //         </td>;
    // };

    return (
        <>
            {/* <ExcelExport
                data={exportItems}
                ref={excelExportRef}
                fileName="ItemList.xlsx"
                headerPaddingCellOptions={{ rowHeight: 0 }} // Example prop to control header padding
                style={{ display: "none" }}>
                {customizedCol.map((column) => (
                    <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
                ))}
            </ExcelExport> */}

            {/* <ExcelExport
            data={exportItems}
            ref={excelExportRef}
            fileName="ItemList.xlsx">
            {customizedCol.map((column) => (
                <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
            ))}
           </ExcelExport> */}

            <div className="grid-container">
                <div className="grid-box">
                    <div className="grid-header-box">
                        <h6>{tableName}</h6>
                    </div>

                    <Tooltip openDelay={100} position="bottom" anchorElement="target">
                        <div className="ip-itembase-grid">
                            <GridToolbar>
                                {toolbarButtons.map((button) => {
                                    return button.label.props.children === "Add Group" ? (
                                        <SplitButton
                                            id = "btnGroupOptions"
                                            disabled={(quoteApprovalStatId >= 4) ? true : false} //disable all buttons if quote is approved or more
                                            key={button.label}
                                            text={<strong>Add Group</strong>}
                                            onButtonClick={button.onButtonClick}
                                            onItemClick={button.onClick}
                                            items={groupButtonOptions}></SplitButton>
                                    ) : button.label.props.children === "Add Item" ? (
                                        <SplitButton
                                            id = "btnItemOptions"
                                            disabled={(quoteApprovalStatId >= 4) ? true : false} //disable all buttons if quote is approved or more
                                            key={button.label}
                                            text={<strong>Add Item</strong>}
                                            onItemClick={button.onClick}
                                            onButtonClick={button.onButtonClick}
                                            items={itemButtonOptions}></SplitButton>
                                    ) : button.label.props.children === "More..." ? (
                                        <SplitButton
                                            id = "btnMoreOptions"
                                            disabled={(quoteApprovalStatId >= 4) ? true : false} //disable all buttons if quote is approved or more
                                            key={button.label}
                                            text={<strong>More...</strong>}
                                            onItemClick={button.onClick}
                                            onButtonClick={button.onButtonClick}
                                            items={moreButtonOptions}></SplitButton>
                                    ) : button.label.props.children === "Add Item" ? (
                                        <SplitButton
                                            id = "btnItemOptions"
                                            disabled={(quoteApprovalStatId >= 4) ? true : false} //disable all buttons if quote is approved or more
                                            key={button.label}
                                            text={<strong>Add Item</strong>}
                                            onItemClick={button.onClick}
                                            onButtonClick={button.onButtonClick}
                                            items={itemButtonOptions}></SplitButton>
                                    ) : button.label.props.children === "Add Labour Hours" ? (
                                        <SplitButton
                                            id = "btnLabourOptions"
                                            disabled={(quoteApprovalStatId >= 4) ? true : false} //disable all buttons if quote is approved or more
                                            key={button.label}
                                            text={<strong>Labour Hours</strong>}
                                            onItemClick={button.onClick}
                                            onButtonClick={button.onButtonClick}
                                            items={labourButtonOptions}></SplitButton>
                                    ) : (
                                        <Button
                                            key={button.label}
                                            onClick={button.onClick}
                                            icon={button.icon}
                                            look={button.look}
                                            disabled={(quoteApprovalStatId >= 4) ? true : button.disabled} //disable all buttons if quote is approved or more
                                            className={
                                                Array.isArray(button.label.props.children)
                                                    ? "ip-item-view-btn-kbutton"
                                                    : ""
                                            }>
                                            {button.label}
                                        </Button>
                                    );
                                })}
                            </GridToolbar>
                            <QuotesItemGrid 
                                dataItem={data.sort((a, b) => a.position - b.position)} 
                                columns={columns.filter((column) => column.show === true)} 
                            // columns={columns} 
                                selectedRows={selectedRows}
                                selectedItems={selectedItems} 
                                setSelectedRows={setSelectedRows} 
                                toolbarButtons={toolbarButtons}
                                groupButtonOptions={groupButtonOptions}
                                itemButtonOptions={itemButtonOptions}
                                moreButtonOptions={moreButtonOptions}
                                labourButtonOptions={labourButtonOptions}
                                isCollapseAll={isCollapseAll}
                                isExpandCollapseTriggered={isExpandCollapseTriggered}
                                handleItemChange={handleItemChange}
                                viewType={qteCurGridViewType}
                                processWithGroups={processWithGroups(group)}
                                customizedCol={customizedCol}
                                selectedIndex={selectedIndex}
                                setSelectedIndex={setSelectedIndex}
                                colMenus={colMenus}
                                colPref={colPref}
                                allowCopy={allowCopy}
                                scrollValue={scrollValue}
                                customFieldWidth={customFieldWidth}
                                setSelectedView={setSelectedView}
                                viewOptions={viewOptions}
                                handleItemGrid = {handleItemGrid}
                                setDropdownValue = {setDropdownValue}
                                setIsQuoteSaved = {setIsQuoteSaved}
                            />
                        </div>
                        
                         <Popup show={show} offset={offSet.current}>
                            <Menu
                                onSelect={(e) => handleOnSelect(e)}
                                vertical={true}
                                style={{
                                    display: 'inline-block',
                                }}
                                items={colMenus}
                             />  
                        </Popup>
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default GroupDataGridView;
