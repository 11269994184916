import React, { useContext, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { ProfileData } from "./components/Auth/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";
import { Routes } from "react-router-dom";
import { routes } from "./Routes/Routes";
import { ToastContainer } from "react-toastify";
import { NotificationContainer } from 'react-notifications';
import { QuoteCustomerContext } from "./Contexts/QuoteCustomerContext"; // adjust path as needed
import { SystemSettingContext } from "./Contexts/SystemSettingContext";
import { OpenQuoteDataContext } from "./Contexts/OpenQuoteDataContext";
import CustomerIdContext from "./Contexts/CustomerIdContext";
import { RemoveItemsContext } from "./Contexts/ReomoveItemsContext";
import { ItemsContext } from "./Contexts/ItemsContext";
import { CustomerNumberContext } from "./Contexts/CustomerNumberContext";
import { QteCurGridViewTypeContext } from "./Contexts/QteCurGridViewTypeContext";
import { QteRepDiscCheckerContext } from "./Contexts/QteRepDiscCheckerContext";
import { QuoteDetailsProvider } from "./Contexts/QuoteDetailsProvider";
import { PageCountProvider } from "./Contexts/PageCountContext";
import { PriceListDetailsProvider } from "./Contexts/PriceListDetailsProvider";
import { UnitDetailsProvider } from "./Contexts/UnitDetailsProvider";
import { NodeProvider } from "./Contexts/NodeProvider";
import { PrimaryCustomerProvider } from "./Contexts/PrimaryCustomerContext";
import { FirstCustomerProvider } from "./Contexts/FirstQuoteCustomerContext";
import { Loadstatuscontext } from "./Contexts/LoadStatus";
import Authentication from "./components/Auth/Authentication";
import { Route } from "react-router-dom";
import { QuoteCustomerIdContext } from "./Contexts/QouteCustomerIdContext";
import { ItemGridRowProvider } from "./Contexts/ItemGridRowContext";
import { ItemGridQtyRowProvider } from "./Contexts/ItemGridQtyRowContext";
import { UseAuthorization } from "./components/Auth/useAuthorization";
import { SystemSettingsProvider } from "./Contexts/SystemSettingsContext";
import HideRowContext from "./Contexts/HideRowContext";
import { RowIdContext } from "./Contexts/RowIdContext";
import { ItemGridNettEaRowProvider } from "./Contexts/ItemGridNettEaRowContext";
import { PartConsolidatedDetailProvider } from "./Contexts/PartConsolidatedDetailContext";
import { RoundUpProvider } from "./Contexts/RoundUpProvider";
import { MarginAndPriceProvider } from "./Contexts/MarginAndPriceProvider";
import { ResetProvider } from "./Contexts/ResetProvider";
import { ItemListProvider } from "./Contexts/ItemListProvider";
import { HiddenStatusProvider } from "./Contexts/HiddenStatusContext";
import { StockCodeProvider } from "./Contexts/StockCodeProvider";
import { QuoteHeadersProvider } from "./Contexts/QuoteHeadersProvider";
import { SubGroupListProvider } from "./Contexts/SubGroupListContext";
import { SGLNettEAProvider } from "./Contexts/SGLNettEAProvider";
import { TrigerSaveQuote } from "./Contexts/TrigerSaveQuoteContext";
import { IGMarginProvider } from "./Contexts/IGMarginRowContext";
import { IGDconlpProvider } from "./Contexts/IGDconlpRowContext";
import { NewItemListContextProvider } from "./Contexts/NewItemListContextProvider";
import { DraftSaveContext } from "./Contexts/DraftSaveContext";
import { QuoteContactProvider } from "./Contexts/QuoteContactProvider";
import { QteApprovalNotificationContext } from "./Contexts/QteApprovalNotificationContext";
import { ImportUnitData } from "./Contexts/ImportUnitData";
import { TriggerOldQuoteOpen } from "./Contexts/TriggerOldQuoteOpenContext";
import { CustomerCustPriceContext } from "./Contexts/CustomerCustPriceContext";


export const baseURL = "https://eeapilive.azurewebsites.net/api"; //live
//  export const baseURL = "https://localhost:7156/api"; //test  "https://localhost:44371/api";
// export const baseURLWithoutApi = "https://localhost:44371";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            });
    }

    return (
        <>
            <h5 className="card-title">Welcome {accounts[0].name}</h5>
            {graphData ? (
                <ProfileData graphData={graphData} />
            ) : (
                <Button variant="secondary" onClick={RequestProfileData}>
                    Request Profile Information
                </Button>
            )}
        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    const [quoteCustomer, setQuoteCustomer] = useState({});
    const [systemSetttingUserId, setsystemSetttingUserId] = useState({});
    const [isDataFromOpenQuote, setIsDataFromOpenQuote] = useState(false);
    const [isDraftQuoteSave, setIsDraftQuoteSave] = useState(false);
    const [customerId, setCustomerId] = useState("");
    const [addItems, setAddItems] = useState([]);
    const [LoadStatus, SetLoadStatus] = useState(false);
    const [items, setItems] = useState({});
    const isAuthenticated = useIsAuthenticated();
    const [customerNumber, setCustomerNumber] = useState("");
    const [quoteCustomerId, setQuoteCustomerId] = useState("");
    //const {quoteCustomerID, setQuoteCustomerID} = useState();
    const [rowIdCounter, setRowIdCounter] = useState(1);
    const [hiddenRows, setHiddenRows] = useState({}); // Keyed by rowId, value is a boolean
    const [qteCurGridViewType, setQteCurGridViewType] = useState("");
    const [qteAuthType, setQteAuthType] = useState('');
    const [quoteRepDiscData, setQuoteRepDiscData] = useState("");
    const [quoteApprovalMesasge, setQuoteApprovalMesasge] = useState('');
    const [quoteApprovalStatus, setQuoteApprovalStatus] = useState('');
    const [quoteApprovalStatId, setQuoteApprovalStatId] = useState(0);
    const [showFormForApproval, setShowFormForApproval] = useState(false);
    const [triggerSaveQteForApproval, setTriggerSaveQteForApproval] = useState(null);
    const [approvalStatList, setApprovalStatList] = useState([]);
    const [forApprovalCount, setForApprovalCount] = useState(0);
    const [currentApprover, setCurrentApprover] = useState("");
    const [quoteOwner, setQuoteOwner] = useState("");
    const [approverName, setApproverName] = useState("");
    const [triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected] = useState({
        oldQuoteId: "",
        newQuoteId: ""
    });
    const [aprvCategory, setAprvCategory] = useState("");
    const [refreshStatus, setRefreshStatus] = useState(false);
    const [addCustomerCustPrice, setCustomerCustPrice] = useState([]);
    const [shareQuoteFlag, setShareQuoteFlag] = useState(0);
    const [saveAsNewFlag, setSaveAsNewFlag] = useState(0);
    const [approvalDateTime, setApprovalDateTime] = useState("");
    const [triggerSaveAsNewFlag, setTriggerSaveAsNewFlag] = useState(0);
    const [triggerSaveRevision, setTriggerRevision] = useState(0);
    const [triggerSuffix, setTriggerSuffix] = useState(0);
    const [checkApprovalStateFor, setCheckApprovalStateFor] = useState("");
    const [currAccessLevel, setCurrAccessLevel] = useState(0);
    const [checkTriggerExport, setCheckTriggerExport] = useState(false);


    const toggleRowHiddenStatus = (rowId) => {
        setHiddenRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };

    const qteApprovalParam = {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus, quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval, triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        forApprovalCount, setForApprovalCount,
        currentApprover, setCurrentApprover,
        quoteOwner, setQuoteOwner,
        approverName, setApproverName,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected,
        aprvCategory, setAprvCategory,
        refreshStatus, setRefreshStatus,
        shareQuoteFlag, setShareQuoteFlag,
        saveAsNewFlag, setSaveAsNewFlag,
        approvalDateTime, setApprovalDateTime,
         triggerSaveAsNewFlag, setTriggerSaveAsNewFlag,
        triggerSaveRevision, setTriggerRevision,
        triggerSuffix, setTriggerSuffix,
        checkApprovalStateFor, setCheckApprovalStateFor,
        currAccessLevel, setCurrAccessLevel,
        checkTriggerExport, setCheckTriggerExport
    };


    return (
        <UseAuthorization>
            <PageCountProvider>
            <RowIdContext.Provider value={{ rowIdCounter, setRowIdCounter }}>
                <QteCurGridViewTypeContext.Provider value={{ qteCurGridViewType, setQteCurGridViewType, qteAuthType, setQteAuthType }}>
                    <QteApprovalNotificationContext.Provider value={qteApprovalParam}>
                        <QteRepDiscCheckerContext.Provider value={{ quoteRepDiscData, setQuoteRepDiscData }}>
                            <DraftSaveContext.Provider value={{ isDraftQuoteSave, setIsDraftQuoteSave }}>
                                <QuoteCustomerIdContext.Provider value={{ quoteCustomerId, setQuoteCustomerId }}>
                                    <Loadstatuscontext.Provider value={{ LoadStatus, SetLoadStatus }}>
                                        <PrimaryCustomerProvider>
                                            <ItemsContext.Provider value={{ items, setItems }}>
                                                <CustomerCustPriceContext.Provider value={{ addCustomerCustPrice, setCustomerCustPrice }}>
                                                    <RemoveItemsContext.Provider value={{ addItems, setAddItems }}>
                                                        <QuoteCustomerContext.Provider value={{ quoteCustomer, setQuoteCustomer }}>
                                                            <SystemSettingContext.Provider
                                                                value={{ systemSetttingUserId, setsystemSetttingUserId }}>
                                                                <OpenQuoteDataContext.Provider
                                                                    value={{ isDataFromOpenQuote, setIsDataFromOpenQuote }}>
                                                                    <CustomerIdContext.Provider value={{ customerId, setCustomerId }}>
                                                                        <CustomerNumberContext.Provider
                                                                            value={{ customerNumber, setCustomerNumber }}>
                                                                            <HideRowContext.Provider
                                                                                value={{
                                                                                    hiddenRows,
                                                                                    setHiddenRows,
                                                                                    toggleRowHiddenStatus,
                                                                                }}>
                                                                                <QuoteDetailsProvider>
                                                                                    <PriceListDetailsProvider>
                                                                                        <UnitDetailsProvider>
                                                                                            <HiddenStatusProvider>
                                                                                                <ResetProvider>
                                                                                                    <QuoteContactProvider>
                                                                                                        <ItemListProvider>
                                                                                                            <MarginAndPriceProvider>
                                                                                                                <StockCodeProvider>
                                                                                                                    <NewItemListContextProvider>
                                                                                                                        <RoundUpProvider>
                                                                                                                            <TrigerSaveQuote>
                                                                                                                                <TriggerOldQuoteOpen>
                                                                                                                                        <QuoteHeadersProvider>
                                                                                                                                        <ImportUnitData>
                                                                                                            <PartConsolidatedDetailProvider>
                                                                                                                                            <SystemSettingsProvider>
                                                                                                                                                <SGLNettEAProvider>
                                                                                                                                                    <FirstCustomerProvider>
                                                                                                                                                        <ItemGridNettEaRowProvider>
                                                                                                                                                            <ItemGridRowProvider>
                                                                                                                                                                <ItemGridQtyRowProvider>
                                                                                                                                                                    <IGMarginProvider>
                                                                                                                                                                        <IGDconlpProvider>
                                                                                                                                                                            <SubGroupListProvider>
                                                                                                                                                                                <NodeProvider>
                                                                                                                                                                                    <Routes>
                                                                                                                                                                                        <Route
                                                                                                                                                                                            path="/"
                                                                                                                                                                                            element={
                                                                                                                                                                                                <Authentication />
                                                                                                                                                                                            }
                                                                                                                                                                                        />
                                                                                                                                                                                        {
                                                                                                                                                                                            routes
                                                                                                                                                                                        }
                                                                                                                                                                                    </Routes>
                                                                                                                                                                                    <ToastContainer />
                                                                                                                                                                                    <NotificationContainer />
                                                                                                                                                                                </NodeProvider>
                                                                                                                                                                            </SubGroupListProvider>
                                                                                                                                                                        </IGDconlpProvider>
                                                                                                                                                                    </IGMarginProvider>
                                                                                                                                                                </ItemGridQtyRowProvider>
                                                                                                                                                            </ItemGridRowProvider>
                                                                                                                                                        </ItemGridNettEaRowProvider>
                                                                                                                                                    </FirstCustomerProvider>
                                                                                                                                                </SGLNettEAProvider>
                                                                                                                                            </SystemSettingsProvider>
                                                                                                                                        </PartConsolidatedDetailProvider>
                                                                                                                                        </ImportUnitData>
                                                                                                        </QuoteHeadersProvider>
                                                                                                                                </TriggerOldQuoteOpen>
                                                                                                                            </TrigerSaveQuote>
                                                                                                                        </RoundUpProvider>
                                                                                                                    </NewItemListContextProvider>
                                                                                                                </StockCodeProvider>
                                                                                                            </MarginAndPriceProvider>
                                                                                                        </ItemListProvider>
                                                                                                    </QuoteContactProvider>
                                                                                                </ResetProvider>
                                                                                            </HiddenStatusProvider>
                                                                                        </UnitDetailsProvider>
                                                                                    </PriceListDetailsProvider>
                                                                                </QuoteDetailsProvider>
                                                                            </HideRowContext.Provider>
                                                                        </CustomerNumberContext.Provider>
                                                                    </CustomerIdContext.Provider>
                                                                </OpenQuoteDataContext.Provider>
                                                            </SystemSettingContext.Provider>
                                                        </QuoteCustomerContext.Provider>
                                                    </RemoveItemsContext.Provider>
                                                </CustomerCustPriceContext.Provider>
                                            </ItemsContext.Provider>
                                        </PrimaryCustomerProvider>
                                    </Loadstatuscontext.Provider>
                                </QuoteCustomerIdContext.Provider>
                            </DraftSaveContext.Provider >
                        </QteRepDiscCheckerContext.Provider>
                    </QteApprovalNotificationContext.Provider>
                </QteCurGridViewTypeContext.Provider>
            </RowIdContext.Provider>
            </PageCountProvider>
        </UseAuthorization>
    );
}
