import { React, useContext } from "react";
import FriendlyIcon from "../../assets/default/images/ipd/greetings.svg";
import { Col, Row, Container } from "reactstrap";
import { Button } from "@progress/kendo-react-buttons";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import LeftNavigationBar from "../Layouts/LeftNavigationBar";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { Link, useNavigate, NavLink } from "react-router-dom"; // Import useNavigate
import SubNodePage from "../Layouts/SubNodePage";

const Introduction = ({ toggleQuotesModal, onSubnodeClick }) => {
    const navigate = useNavigate(); // Initialize useNavigate

    const { quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, handleAddNode, nodeIdList } = useNodeContext();
    const user = useContext(UserContext);

    const username = user?.userFullName;
    return (
        <Row>
            <Col xs={12}>
                <div className="ip-introworkspace">
                    <img className="d-block mx-auto mb-4" src={FriendlyIcon} alt="" width="90" />
                    <h3 className="fw-bold">{username}</h3>
                    <div className="col-lg-8 mx-auto">
                        <p className="mb-4">
                            Currently there is nothing open in your workspace. Please select one of the below to get
                            started or use the left navigation.
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <Button icon="folder" onClick={toggleQuotesModal}>
                                Open Quote
                            </Button>
                            <Button
                                icon="add"
                                onClick={(e) => {
                                    handleAddNode(quotes, setQuotes, maxQuoteIndex, setMaxQuoteIndex, "Quote");
                                    const storedIds = sessionStorage.getItem("newnodeId");
                                    if (storedIds) {
                                        const parsedIds = JSON.parse(storedIds);
                                        const newId = parsedIds[parsedIds.length - 1];
                                        navigate("/quotes/" + newId, { replace: true });
                                    } else {
                                        console.error("No new node ID found in session storage");
                                    }
                                }}>
                                Add Quote
                            </Button>
                            {/*<Button icon="add">Add Unit</Button>
                                <Button icon="add">Add Price List</Button>
                                <Button icon="add">Add Library</Button>*/}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Introduction;
