import { baseURL } from "../../App";
import { msalInstance } from "../../index"; // Update with the correct path to your MSAL instance

// Helper function to get access token
const getAccessToken = async () => {
    const requestForApi = {
        scopes: ["api://d055ad45-59fa-4049-9802-3efb2ab98eed/access_as_user"],
        account: msalInstance.getAllAccounts()[0], // Assumes you want to use the first account
        forceRefresh: false
    };

    try {
        const response = await msalInstance.acquireTokenSilent(requestForApi);
        return response.accessToken;
    } catch (error) {
        if (error.name === "InteractionRequiredAuthError") {
            try {
                const response = await msalInstance.acquireTokenPopup(requestForApi);
                return response.accessToken;
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error(error);
        }
    }
};

export const getResponse = async (url, params = {}) => {
    // Convert params object to URLSearchParams

    const urlParams = new URLSearchParams(Object.entries(params));

    // Construct full URL with parameters

    const fullUrl = `${baseURL}/${url}?${urlParams.toString()}`;

    const apiGetAccessToken = await getAccessToken();

    const options = {
        method: "GET",

        headers: {
            Authorization: `Bearer ${apiGetAccessToken}`,
            "Content-Type": "application/json",
        },
    };

    return await fetch(fullUrl, options).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            return data;
        }
    });
};

export const getResponseWithoutParam = async (url) => {
    const fullUrl = `${baseURL}/${url}`;

    const apiGetAccessToken = await getAccessToken();

    const options = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${apiGetAccessToken}`,
            "Content-Type": "application/json",
        },
    };

    return await fetch(fullUrl, options).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            return data;
        }
    });
    // Construct full URL with parameters
};

// catch (e){
//     console.log(e.message, e.name);
//     //throw new Error(e.message, e.name)
// }

//}
