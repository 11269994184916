import React, { createContext, useContext, useState } from "react";

const StockCodeContext = createContext();

export const useStockCode = () => useContext(StockCodeContext);

export const StockCodeProvider = ({ children }) => {
    const [ stockNumber, setStockNumber ] =useState(" ")
    const [stockCode, setStockCode] = useState("");
    const [ststatus, setStstatus] = useState("");
    const [totalSoh, setTotalSoh] = useState("");
   // const [compcode, setCompcode] = useState("")
    const [compcode, setCompcode] = useState([]);
    const [compstatus, setCompstatus] = useState("");
    const [compSOH, setCompSOH] = useState("");
    const [selectedStockType, setSelectedStockType] = useState("");
    const [bomPart, setBOMPart] = useState(false);
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [substockCode, setSubStockCode] = useState("");
    const [subSOH, setSubSOH] = useState("");
    const [bomPartSub, setBOMPartSub] = useState(false);
    const [bomisChecked, setBomIsChecked] = useState(true);
    const [visiblePasteOption, setVisiblePasteOption] = useState(false);
    const [isSOH, setIsSOH] = useState(false);
    const [isPartNo, setIsPartNo] = useState(true);
    const [isDesc, setIsDesc] = useState(true);
    const [isType, setIsType] = useState(false);
    const [selectedItemData, setSelectedItemData] = useState([]);
    const [hasZeroCostLPriceNettEA, setHasZeroCostLPriceNettEA] = useState(false);
    const [totalCompParts, setTotalCompParts] = useState(0);
    return (
        <StockCodeContext.Provider value={{selectedItemData, setSelectedItemData,totalCompParts, setTotalCompParts,stockNumber, setStockNumber,isDesc, setIsDesc,isType, setIsType,isSOH, setIsSOH,isPartNo, setIsPartNo,bomisChecked, setBomIsChecked,stockCode, setStockCode,ststatus, setStstatus,totalSoh, setTotalSoh,compcode, setCompcode,compstatus, setCompstatus,compSOH, setCompSOH,selectedStockType, setSelectedStockType,bomPart, setBOMPart,selectedComponents, setSelectedComponents
        ,substockCode, setSubStockCode,bomPartSub, setBOMPartSub,subSOH, setSubSOH,visiblePasteOption, setVisiblePasteOption, hasZeroCostLPriceNettEA, setHasZeroCostLPriceNettEA}}>
            {children}
        </StockCodeContext.Provider>
    );
};
