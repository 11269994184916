import React, { createContext, useContext, useState } from "react";

const SGLNettEAContext = createContext();

export const useSGLNettEA = () => useContext(SGLNettEAContext);

export const SGLNettEAProvider = ({ children }) => {
    const [sGNettEAStatus, setSGNettEAStatus] = useState(false);

    const updateSGNettEAStatusForId = (id, data) => {
        setSGNettEAStatus((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <SGLNettEAContext.Provider
            value={{
                sGNettEAStatus,
                setSGNettEAStatus,
                updateSGNettEAStatusForId,
            }}>
            {children}
        </SGLNettEAContext.Provider>
    );
};
