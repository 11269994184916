import { React, useState, useContext } from "react";
import { LookupTypes } from "../../enums/LeftNavTypes";
import Lookup from "../Layouts/Lookup";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { getItemModel } from "../../models/Quotations";

const selectColumns = [
    { field: "stStockCode", title: "Part No" },
    { field: "stSupStockCode", title: "Sup Part No" },
    { field: "fullDescription", title: "Full Desc", width: "350px" },
    { field: "stDesc3", title: "Desc 3", width: "300px" },
];

const addColumns = [{ field: "stStockCode", title: "Part No" }];

const ItemLookup = ({ onAddItems }) => {
    const apiURL = "Stocks/searchparts"; // your API endpoint
    const filterOptions = [{ text: "All", value: "all" }, ...selectColumns.map((column) => ({ text: column.title, value: column.field }))];
    const { addItems, setAddItems } = useContext(RemoveItemsContext);

    const handleAddItems = (selectedItems) => {
        console.log("Selected items:", selectedItems);
        onAddItems && onAddItems(selectedItems);
        // Add the selected items to the "Items to Add" grid
        setAddItems((prevItems) => [...prevItems, ...selectedItems]);
    };

    return (
        <Lookup
           type = {LookupTypes.ITEM}
            apiURL={apiURL} // pass the API endpoint here
            selectColumns={selectColumns}
            addColumns={addColumns}
            showSelectionColumn={true}
            idField="stStockCode"
            selectTableName="Item to Select"
            conversionFunction={getItemModel}
            addTableName="Item to Add"
            filterOptions={filterOptions}
            onAddItems={handleAddItems}
            onAddItemsToGrid={handleAddItems}
        />
    );
};

export default ItemLookup;
