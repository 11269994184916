import { useState, useEffect } from 'react';

const useQuoteState = (id) => {
    const [loading, setLoading] = useState(true);
    const [selectedNode, setSelectedNode] = useState(null);
    const [data, setData] = useState([]);
    const [header, setHeader] = useState('');
    const [showOnQuote, setShowOnQuote] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [editorContent, setEditorContent] = useState('');
    const [mainNodeData, setMainNodeData] = useState([]);
    const [defaultEditorContent, setDefaultEditorContent] = useState('');
    const [appendedContent, setAppendedContent] = useState('');
    const [previousNode, setPreviousNode] = useState(null);
    const [appendedNodes, setAppendedNodes] = useState([]);
    const [storedCombinedItems, setStoredCombinedItems] = useState([]);
    const [toDefaultConfirmed, setToDefaultConfirmed] = useState(false);
    const [editorModified, setEditorModified] = useState(false);
    const [nodeChanges, setNodeChanges] = useState({});
    const [isMainNode, setIsMainNode] = useState(false);
    const [isClear, setIsClear] = useState(false);
    const [checkboxStates, setCheckboxStates] = useState(() => {
        const savedState = JSON.parse(sessionStorage.getItem(`checkboxStates-${id}`)) || {};
        
        // Ensure IDs 1, 2, and 3 are always true
        return {
            ...savedState,
            1: true,
            2: true,
            3: true,
        };
    });

    useEffect(() => {
        sessionStorage.setItem(`checkboxStates-${id}`, JSON.stringify(checkboxStates));
    }, [checkboxStates, id]);

    return {
        loading, setLoading,
        selectedNode, setSelectedNode,
        data, setData,
        header, setHeader,
        showOnQuote, setShowOnQuote,
        selectedTab, setSelectedTab,
        editorContent, setEditorContent,
        mainNodeData, setMainNodeData,
        defaultEditorContent, setDefaultEditorContent,
        appendedContent, setAppendedContent,
        previousNode, setPreviousNode,
        appendedNodes, setAppendedNodes,
        storedCombinedItems, setStoredCombinedItems,
        toDefaultConfirmed, setToDefaultConfirmed,
        editorModified, setEditorModified,
        nodeChanges, setNodeChanges,
        isMainNode, setIsMainNode,
        isClear, setIsClear,
        checkboxStates, setCheckboxStates
    };
};

export default useQuoteState;
