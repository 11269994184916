import React, { createContext, useContext, useState } from 'react';

// Create a context
const PageCountContext = createContext();

// Context provider component
const PageCountProvider = ({ children }) => {
  const [totalPages, setTotalPages] = useState(2);
  return (
    <PageCountContext.Provider value={{ totalPages, setTotalPages }}>
      {children}
    </PageCountContext.Provider>
  );
};

export { PageCountProvider, PageCountContext };
