import React, { createContext, useContext, useState } from "react";

const ItemGridNettEaRowContext = createContext();

export const useNettEaRow = () => useContext(ItemGridNettEaRowContext);

export const ItemGridNettEaRowProvider = ({ children }) => {
    const [rowNettEaData, setNettEaRowData] = useState({});

    const updateNettEaRowForId = (id, data) => {
        setNettEaRowData((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <ItemGridNettEaRowContext.Provider value={{ rowNettEaData, setNettEaRowData, updateNettEaRowForId }}>
            {children}
        </ItemGridNettEaRowContext.Provider>
    );
};
