import { React, useState, useRef, useEffect, useContext, Fragment, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
//import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import ipdLogoPath1 from '../../assets/default/images/ipd/pdf/IPD.png';
import ipdLogoPath2 from '../../assets/default/images/ipd/pdf/Addelec.png';
import ipdLogoPath3 from '../../assets/default/images/ipd/pdf/PJWhite.png';
import ipdPdfImg1 from '../../assets/default/images/ipd/pdf/CL_Qtebanner.jpg';
import ipdPdfImg2 from '../../assets/default/images/ipd/pdf/HTC_Qtebanner.jpg';
import ipdPdfImg3 from '../../assets/default/images/ipd/pdf/PJW_Qtebanner.jpg';
import ipdPdfImg4 from '../../assets/default/images/ipd/pdf/PS_Qtebanner.jpg';
import ipdPdfImg5 from '../../assets/default/images/ipd/pdf/SRV_Qtebanner.jpg';
import ipdPdfImg6 from '../../assets/default/images/ipd/pdf/WS_Qtebanner.jpg';
import ipdPdfimg7 from '../../assets/default/images/ipd/pdf/DR_Qtebanner.jpg';
import ipdPdfimg8 from '../../assets/default/images/ipd/pdf/GT_Qtebanner.jpg';
import ipdPdfimgApprpending from '../../assets/default/images/ipd/pdf/pdf-pending-stamp.png';
import ipdPdfimgApprreject from '../../assets/default/images/ipd/pdf/pdf-reject-stamp.png';
import ipdPdfimgApprrequired from '../../assets/default/images/ipd/pdf/pdf-required-stamp.png';
import ipdPdfImgPreview from '../../assets/default/images/ipd/pdf/pdf-previewonly-stamp2.png';
import LZString from 'lz-string';
import { useParams } from "react-router-dom";
import { getResponse } from "../../Services/Common/Get";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { Interweave } from 'interweave';
import { useReactToPrint } from 'react-to-print';
import { toast, Slide } from 'react-toastify';
import "../../assets/default/scss/config/default/pdf-style.css";
import "../../assets/default/scss/config/default/print-style.css";
import { NULL } from 'sass';
import { QuoteContactTypes } from '../../enums/GroupTypes';
import { parseJSON } from 'date-fns/fp/parseJSON';



const imageToDataUrl = async (path) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.onerror = (errorEvent) => reject(new Error("Error converting the image to Data URL"));
        img.src = path;
    });
};

const ipdLogoDataUrl1 = await imageToDataUrl(ipdLogoPath1);
const ipdLogoDataUrl2 = await imageToDataUrl(ipdLogoPath2);
const ipdLogoDataUrl3 = await imageToDataUrl(ipdLogoPath3);
const ipdAdvertDataUrl1 = await imageToDataUrl(ipdPdfImg1);
const ipdAdvertDataUrl2 = await imageToDataUrl(ipdPdfImg2);
const ipdAdvertDataUrl3 = await imageToDataUrl(ipdPdfImg3);
const ipdAdvertDataUrl4 = await imageToDataUrl(ipdPdfImg4);
const ipdAdvertDataUrl5 = await imageToDataUrl(ipdPdfImg5);
const ipdAdvertDataUrl6 = await imageToDataUrl(ipdPdfImg6);
const ipdAdvertDataUrl7 = await imageToDataUrl(ipdPdfimg7);
const ipdAdvertDataUrl8 = await imageToDataUrl(ipdPdfimg8);
const ipdAdvertDataUrlApprP = await imageToDataUrl(ipdPdfimgApprpending);
const ipdAdvertDataUrlApprR = await imageToDataUrl(ipdPdfimgApprreject);
const ipdAdvertDataUrlApprReq = await imageToDataUrl(ipdPdfimgApprrequired);
const ipdPreviewDataUrl = await imageToDataUrl(ipdPdfImgPreview);

const getAdvertImageByCustCategory = (custCategory) => {
    switch (custCategory) {
        case "CL":
            return ipdAdvertDataUrl1;
        case "HTC":
            return ipdAdvertDataUrl2;
        case "PJW":
            return ipdAdvertDataUrl3;
        case "PS":
            return ipdAdvertDataUrl4;
        case "SRV":
            return ipdAdvertDataUrl5;
        case "WS":
            return ipdAdvertDataUrl6;
        case "DR":
            return ipdAdvertDataUrl7;
        case "GT":
            return ipdAdvertDataUrl8;
        default:
            return ipdAdvertDataUrl1;  // default image if no category matches
    }
};



const getQuoteDetailsFromSessionStorage = () => {
    const compressedQuoteDetailsString = sessionStorage.getItem('quoteDetails');
    if (compressedQuoteDetailsString) {
        const decompressedQuoteDetailsString = LZString.decompress(compressedQuoteDetailsString);
        try {
            return JSON.parse(decompressedQuoteDetailsString);
        } catch (e) {
            console.error('Error parsing decompressed quote details:', e);
            return null;
        }
    }
    return null;
};

const getCompanyDetails = () => {
    let companyName, companyAddress, companyDescription;
    const id = sessionStorage.getItem('id')

    const quoteDetails = getQuoteDetailsFromSessionStorage();
    console.log("quoteDetails", quoteDetails)

    if (!quoteDetails || !quoteDetails[id]) {
        console.error('Quote details not found for id:', id);
        return { companyName: "Unknown Company", companyAddress: 'No address available' };
    }

    const headerName = quoteDetails[id].header;
    console.log("headerName", headerName);

    switch (headerName) {
        case "IPD":
            companyName = "IPD Group Limited";
            companyAddress = `
                43 Newton Road
                Wetherill Park, NSW 2164
                T: 1300 556 601
                E: customerservice@ipd.com.au
                W: www.ipd.com.au
            `;
            break;
        case "PJW":
            companyName = "PJ White & Co";
            companyDescription = "A business of IPD Group Ltd";
            companyAddress = `
                27 Croydon Road
                Keswick, SA 5035
                T: 1300 253 306
                E: lamps@pjwhite.com.au
                W: www.pjwhite.com.au
            `;
            break;
        case "Services":
            companyName = "Addelec Power Services";
            companyAddress = `
                43 Newton Road
                Wetherill Park, NSW 2164
                T: 1300 556 601
                E: customerservice@ipd.com.au
                W: www.ipd.com.au
            `;
            break;
        default:
            companyName = "Unknown Company";
            companyAddress = 'There is no given address';
            break;
    }

    return { companyName, companyDescription, companyAddress };
}



const PDFHeader = ({ systemSetting, userFullName, userId, shouldHideTotalPrice, grandTotal }) => {
    const id = sessionStorage.getItem('id')

    const quoteDetails = getQuoteDetailsFromSessionStorage();

    const primaryCustomer = quoteDetails[id].selectedCustomers.find(customer => customer.isPrimary);
    const { companyName, companyAddress, companyDescription } = getCompanyDetails(quoteDetails[id].header);
    const advertImageUrl = getAdvertImageByCustCategory(primaryCustomer.custCategory);
    //const user = useContext(UserContext);



    let contactPerson = quoteDetails[id].quoteContact;
    let contactPersonPhoneNumber = quoteDetails[id].quoteContactDetailsMobile;

    // let contactPersonPhoneNumber;

    // if (QuoteContactTypes.OPTION1 === quoteDetails[id].selectedContactOption) {
    //     contactPersonPhoneNumber = quoteDetails[id].quoteId ? quoteDetails[id].savedQuote.quoteContactDetailsMobile : quoteDetails[id].quoteContactDetailsMobile;
    // }
    // else if (QuoteContactTypes.OPTION2 === quoteDetails[id].selectedContactOption) {
    //     contactPersonPhoneNumber = quoteDetails[id].quoteContactDetailsMobile;
    // }
    // else if (QuoteContactTypes.OPTION3 === quoteDetails[id].selectedContactOption) {
    //     contactPersonPhoneNumber = quoteDetails[id].savedQuote.quoteContactDetailsMobile;
    // }
    // else if (QuoteContactTypes.OPTION4 === quoteDetails[id].selectedContactOption) {
    //     contactPersonPhoneNumber = quoteDetails[id].searchContactMobile;
    // }
    // else if (QuoteContactTypes.OPTION5 === quoteDetails[id].selectedContactOption){
    //     contactPersonPhoneNumber = quoteDetails[id].customPhone;
    // }
    // else {

    //     contactPersonPhoneNumber = quoteDetails[id].quoteId ? quoteDetails[id].savedQuote.quoteContactDetailsMobile : quoteDetails[id].quoteContactDetailsMobile;

    // }


    let ipdLogoToUse;
    switch (quoteDetails[id].header) {
        case "IPD":
            ipdLogoToUse = ipdLogoDataUrl1;
            break;
        case "PJW":
            ipdLogoToUse = ipdLogoDataUrl3;
            break;
        case "Services":
            ipdLogoToUse = ipdLogoDataUrl2;
            break;
        default:
            ipdLogoToUse = ipdLogoDataUrl1;  // Default case, modify as needed
            break;
    }

    const renderLines = (text) => {
        if (!text) return null; // Return null if text is falsy
        return text.split("\n").map((line, index) => {
            return <p><Interweave key={index} content={line.trim()} /></p>;
        });
    };


    return (
        <table className="pdf-table" border="0">
            <tbody>
                <tr>
                    <td width="50%"><img src={ipdLogoToUse} width="auto" height="40" alt="" /></td>
                    <td width="50%">&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        <div className="pdf-company">
                            <p className="r1">{renderLines(companyName)}</p>
                            <p className="r2">{renderLines(companyDescription)}</p>
                            <p className="r2">ABN: 12 111 178 351</p>
                        </div>
                        <div className="pdf-address">
                            {renderLines(companyAddress)}
                        </div>
                    </td>
                    <td><div className="pdf-quotehead">Quotation</div>
                        <div className="pdf-quotetitle">
                            <table border="0">
                                <tbody>
                                    <tr height="25">
                                        <td width="100px">Quote No:</td>
                                        <td valign="middle" className="pdf-fbolder">{quoteDetails[id]?.savedQuote.quoteId ? quoteDetails[id].savedQuote.quoteNumber : ""}</td>
                                    </tr>
                                    <tr height="25">
                                        <td>Date:</td>
                                        <td valign="middle" className="pdf-fbolder">{new Date(quoteDetails[id].quoteDate).toLocaleDateString('en-GB')}</td>
                                    </tr>
                                    {
                                        quoteDetails[id].projectName &&
                                        <tr height="25">
                                            <td>Project Name:</td>
                                            <td valign="middle" className="pdf-fbolder">{quoteDetails[id].projectName}</td>
                                        </tr>
                                    }
                                    <tr height="25">
                                        <td>Customer Ref:</td>
                                        <td valign="middle" className="pdf-fbolder">{primaryCustomer.customerNumber}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="pdf-cutomerto">
                            <p>To:</p>
                            <p className="pdf-fbolder">{primaryCustomer.contactFullName}</p>
                            <p className="pdf-fbolder">{primaryCustomer.customerName}</p>
                        </div>
                    </td>
                    <td><div className="pdf-advertimg displaynone"><img src={advertImageUrl} width="auto" height="90" alt="" /></div></td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td valign="middle" colspan="2">
                        <div className="pdf-contact" >
                            Contact Person: <span>{contactPerson}</span> <span className='seperator'>|</span>Contact No: <span>{contactPersonPhoneNumber}</span>
                        </div>
                        {(!shouldHideTotalPrice) && (
                        <div className='pdf-head-grandtotal'>
                            Grand Total - ex GST (AU$): <span className='pdf-fbolder'>${grandTotal.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </div>
                         )}
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
};





const getItemClass = (item) => {
    return (item.rowType === 'I') ? 'pdf-highlight' : '';
}


const PDFDataGrid = ({ shouldHideTotalPrice, hiddenStatus }) => {
    const id = sessionStorage.getItem('id')
    const quoteDetails = getQuoteDetailsFromSessionStorage();

    const isTotalPriceHidden = quoteDetails[id] ? quoteDetails[id].quoteType === 'DB Quote' : false;
    const isUnitPriceHidden = quoteDetails[id] ? quoteDetails[id].quoteType === 'DB Quote' : false;
    let isEngineeringItem = false;

    // Sort items based on 'position' property
    const sortedItems = [...quoteDetails[id].selectedItems].sort((a, b) => a.position - b.position);
    const grandTotal = useMemo(() => {
        return sortedItems.reduce((acc, item) => {
            const hideInfo = hiddenStatus[item.rowId] || {};
            const isNonGroupItem = item.groupName.includes("Non Grouped Items");
            const itemTotalPrice = parseFloat(item.totalPrice) || 0;
            if (hideInfo.hideRow) {
                if (item.displayGroupName !== "" || isNonGroupItem) {
                    return acc + itemTotalPrice; // Include this item's total price even if the row is hidden
                }
            }
            if (isNonGroupItem || item.displayGroupName !== "") {
                return acc + itemTotalPrice;
            }
            return acc;
        }, 0);
    }, [sortedItems, hiddenStatus]);


    // Get all items with rowType === 'I'
    let indentItems = sortedItems.filter(item => item.rowType === 'I');
    let hasVisibleIndentItems = indentItems.some(item => !hiddenStatus[item.rowId]?.hideRow);
    let isOnlyEngineeringIndentItems = indentItems.length > 0 && indentItems.every(item => item.stStockCode === 'ENGINEERING');
    let hideInfo = {};
    

    return (
        <table className="pdf-quotetable" width="100%" border="0">
            <thead>
                <tr className="r-head">
                    <td width="27%">ITEM#</td>
                    <td align="left" width="40%">DESCRIPTION</td>
                    <td align="right" width="8%">QTY</td>
                    <td align="right" width="12%">UNIT ($)</td>
                    <td align="right" width="13%">TOTAL ($)</td>
                </tr>
                <tr className="r-head-spacer"></tr>
            </thead>
            <tbody>
                {sortedItems.map((item, index) => {
                    hideInfo = hiddenStatus[item.rowId] || {};
                    const isAssemblyItem = item.rowType === "AI";
                    isEngineeringItem = item.stStockCode === "ENGINEERING";
                    if(isEngineeringItem && hideInfo.hideRow && isOnlyEngineeringIndentItems){
                        hasVisibleIndentItems = false;
                        
                    }
                    const isDbextraItem = item.rowType === "DX";
                    const shouldCheckIsAssemblyHeader = Object.keys(hideInfo).length === 0;
                    const isAssemblyHeader = shouldCheckIsAssemblyHeader && item.rowType === "A";
                    const isblankrow = item.stStockCode === "";

                    if (hideInfo.hideRow || isAssemblyItem) {
                        return null;  // Skip rendering this row if hideRow is true
                    }

                    const displayGroupHeader = item.displayGroupName &&
                        (index === 0 || item.displayGroupName !== sortedItems[index - 1].displayGroupName);
                    const displaySubGroupHeader = item.displaySubGroupName &&
                        (index === 0 || item.displaySubGroupName !== sortedItems[index - 1].displaySubGroupName);

                    const shouldHideTotalLn = item.rowType === "LN";

                    const nettEA = parseFloat(item.nettEA) || 0;
                    const totalPrice = parseFloat(item.totalPrice) || 0;

                    return (
                        <Fragment key={item.itemId}>
                            {displayGroupHeader && (
                                <tr className='pdf-group-title'>
                                    <td colSpan="1" className='c1 left'>{item.displayGroupName}</td>
                                    <td align="left" width="40%" className='c1 left'>
                                        {item.description}
                                    </td>
                                    <td align="right" width="8%" className='c1 numfrendbold'>
                                        {hideInfo.hideQty ? "" : item.qty}
                                    </td>
                                    <td align="right" width="12%" className='c1 numfrendbold'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : nettEA.toFixed(2)}
                                    </td>
                                    <td align="right" width="12%" className='c1 numfrendbold'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : totalPrice.toFixed(2)}
                                    </td>
                                </tr>
                            )}
                            {displaySubGroupHeader && (
                                <tr className='pdf-subgroup-title'>
                                    <td colSpan="1" className='c1 left'>{item.displaySubGroupName}</td>
                                    <td align="left" width="40%" className='c1 left'>
                                        {item.description}
                                    </td>
                                    <td align="right" width="8%" className='c1 numfrendboldi'>
                                        {hideInfo.hideQty ? "" : item.qty}
                                    </td>
                                    <td align="right" width="12%" className='c1 numfrendboldi'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : nettEA.toFixed(2)}
                                    </td>
                                    <td align="right" width="12%" className='c1 numfrendboldi'>
                                        {shouldHideTotalLn || hideInfo.hidePrice ? "" : totalPrice.toFixed(2)}
                                    </td>
                                </tr>
                            )}
                            {!(displayGroupHeader || displaySubGroupHeader) && (
                                <tr className='pdf-normal-title'>
                                    <td width="27%" className={`pdf-quotetable-item ${getItemClass(item)}`}>
                                        {item.stStockCode}{(item.rowType === 'I') && '*'}
                                    </td>
                                    <td align="left" width="40%">
                                        {item.description}
                                    </td>
                                    <td align="right" className='numfrend' width="8%">
                                        {hideInfo.hideQty || shouldHideTotalLn || isblankrow ? "" : item.qty}
                                    </td>
                                    <td align="right" className='numfrend' width="12%">
                                        {isUnitPriceHidden || shouldHideTotalLn || hideInfo.hidePrice || isblankrow ? "" : nettEA.toFixed(2)}
                                    </td>
                                    <td align="right" className='numfrend' width="13%">
                                        {shouldHideTotalLn || isTotalPriceHidden || hideInfo.hidePrice || isblankrow ? "" : totalPrice.toFixed(2)}
                                    </td>
                                </tr>
                            )}
                        </Fragment>
                    );
                })}
                {!shouldHideTotalPrice && (
                    <tr className="r-head grand">
                        <td align="right" colSpan="3"> Grand Total - ex GST (AU$)</td>
                        <td align="right" colSpan="2" className="pdf-grandtotal">
                            {shouldHideTotalPrice ? "" : `$${grandTotal.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        </td>
                    </tr>
                )}
                { hasVisibleIndentItems  && (
                    <tr className="pdf-note-row">
                        <td className="pdf-highlight pdf-note" colSpan="5">
                            Note: Items marked with * are indent or custom manufactured.
                            These items are <span>non-returnable.</span>
                        </td>
                    </tr>
                )}
                <tr>
                    <td className="pdf-fixed-spacer" colSpan="5">&nbsp;</td>
                </tr>
            </tbody>
        </table>
    );
};


const CustomPageTemplate = (props, quoteDetails) => {
    const id = sessionStorage.getItem('id')
    const currentYear = new Date().getFullYear(); // Dynamic current year

    let stampSrc;
    switch (quoteDetails[id]?.savedQuote?.approvalStatusId) {
        case 2:
            stampSrc = ipdAdvertDataUrlApprP;
            break;
        case 5:
            stampSrc = ipdAdvertDataUrlApprR;
            break;
        // Add more cases as needed
        default:
            stampSrc = null; // Or a default stamp image
            break;
    }


    let ipdLogoToUsePageTemplate;
    switch (quoteDetails[id]?.header) {
        case "IPD":
            ipdLogoToUsePageTemplate = ipdLogoDataUrl1;
            break;
        case "PJW":
            ipdLogoToUsePageTemplate = ipdLogoDataUrl3;
            break;
        case "Services":
            ipdLogoToUsePageTemplate = ipdLogoDataUrl2;
            break;
        default:
            ipdLogoToUsePageTemplate = ipdLogoDataUrl1; // Default case, modify as needed
            break;
    }


    const logoClass = props.pageNum === 1 ? 'displaynone' : '';

    return (
        <div>
            <div className="pdf-stamp">
                <img src={stampSrc} width="auto" height="140" alt="Stamp" />
            </div>
            <div className={`${logoClass}`}>
                <div className="pdf-header">
                    <img src={ipdLogoToUsePageTemplate} width="auto" height="30" alt="" />
                </div>
            </div>

            <div className="pdf-footer">
                <div className="r1line"></div>
                <div className="r1-c1">Power Distribution & Protection I Enclosures & Climate Control I Energy Management & Power Quality I Automation & Motor Control Safety & Hazardous Area Equipment I Industrial Communications I EV Chargers & PV Solutions I Installation & Accessories</div>
                <div className="r2">
                    <div className="r2-c1">Copyright {currentYear} IPD Group Ltd. All rights reserved.</div>
                    <div className="r2-c2">Page: {props.pageNum} of {props.totalPages}</div>
                </div>
            </div>
        </div>
    );
};

const Stamp = ({ approvalStatusId }) => {
    let src;
    switch (approvalStatusId) {
        case 2:
            src = ipdAdvertDataUrlApprP;
            break;
        case 5:
            src = ipdAdvertDataUrlApprR;
            break;
        case 1:
            src = ipdAdvertDataUrlApprReq;
            break;
        default:
            src = null; // Provide a default image or leave blank
    }

    if (!src) return null;

    return (
        <div> {/* Hide the stamp by default */}
            <div className="pdf-stamp">
                <img src={src} width="auto" height="220" alt="Stamp" />
            </div>
        </div>
    );
};

const QuotePDFLayout = ({ onFinish, systemSetting, userFullName, termsAndConditions, userId, shouldHideTotalPrice, hiddenStatus ,  printComponentRef }) => {
    const id = sessionStorage.getItem('id')
    const approvalStatusIdString = sessionStorage.getItem('approvalStatusId');
    const approvalStatusId = parseInt(approvalStatusIdString, 10); 


    const quoteDetails = getQuoteDetailsFromSessionStorage();
    const pdfExportComponent = useRef(null);
    //const primaryCustomer = quoteDetails[id]?.selectedItems?.find(customer => customer.isPrimary);
    const username = userFullName;
    const UserId = userId;
 
    // Sort items based on 'position' property
    const sortedItems = [...quoteDetails[id].selectedItems].sort((a, b) => a.position - b.position);
    const grandTotal = useMemo(() => {
        return sortedItems.reduce((acc, item) => {
            const hideInfo = hiddenStatus[item.rowId] || {};
            const isNonGroupItem = item.groupName.includes("Non Grouped Items");
            if (hideInfo.hideRow) {
                if (item.displayGroupName !== "" || isNonGroupItem) {
                    return acc + item.totalPrice; // Include this item's total price even if the row is hidden
                }

            }
            if (isNonGroupItem || item.displayGroupName !== "") {
                return acc + item.totalPrice;
            }
            return acc;
        }, 0);
    }, [sortedItems, hiddenStatus]);

    // Fetch the item from sessionStorage

    const keys = Object.keys(sessionStorage);

// Define the prefix for the keys
const prefix = "combinedItems_";

// Find the key that starts with the prefix
let matchingKey = keys.find(key => key.startsWith(prefix));
let DYNAMIC_ID = null;
if (matchingKey) {
  // Extract the dynamic part from the key
  const dynamicPart = matchingKey.substring(prefix.length);

  // Store the extracted part in a constant
  DYNAMIC_ID = dynamicPart;

  console.log(DYNAMIC_ID);
} else {
  console.log("No matching key found.");
}

   let storedAppendedNodes = null;
    
   let  combinedItemsKey = `combinedItems_${DYNAMIC_ID}`;
   if (matchingKey) {
    combinedItemsKey = `combinedItems_${DYNAMIC_ID}`;

    if(quoteDetails[id]?.savedQuote?.createdBy === userId &&  quoteDetails[id]?.savedQuote?.approvalStatusId !== 4 && quoteDetails[id]?.savedQuote?.approvalStatusId !== 2 && quoteDetails[id]?.savedQuote?.approvalStatusId !== 5){
        storedAppendedNodes = sessionStorage.getItem(combinedItemsKey);
    }
   }

    combinedItemsKey = `combinedItems_${id}`;
    if(quoteDetails[id]?.savedQuote?.createdBy === userId &&  quoteDetails[id]?.savedQuote?.approvalStatusId !== 4 && quoteDetails[id]?.savedQuote?.approvalStatusId !== 2 && quoteDetails[id]?.savedQuote?.approvalStatusId !== 5){
        if (sessionStorage.getItem(combinedItemsKey) === null) {
            // Set storedAppendedNodes to null if the key doesn't exist
            storedAppendedNodes = null;
        }
       else{
        storedAppendedNodes = sessionStorage.getItem(combinedItemsKey);

       }
        
    }
    if (storedAppendedNodes === null) {
        storedAppendedNodes = quoteDetails[id]?.pdfQuoteTermsAndConditions;
    }


    useEffect(() => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
            if (onFinish) {
                onFinish();
            }
         

        }
    }, [onFinish]);

    

    //const fileName = `Quote${quoteDetails[id]?.savedQuote.quoteNumber}_${primaryCustomer?.customerNumber}.pdf`;

    // const handlePrint = useReactToPrint({
    //     content: () => pdfExportComponent.current,
    //     onAfterPrint: onFinish, // or handle any cleanup here if necessary
    //     removeContainer: true, // This will remove the iframe after printing
    // });

    // // Call print function directly to only trigger print dialog
    // useEffect(() => {

    //         handlePrint();
   
       
    // }, [handlePrint]);




    const renderTermsAndConditions = () => {
        const tradingConditionsContent = `Unless otherwise stated, this offer is subject to IPD Group Ltd Standard Trading Conditions. Any contract arising from your acceptance of this offer will be governed by these conditions. The Terms and Conditions can be viewed on our website at <a href="https://www.ipd.com.au/Documents/Pdf/TermsConditions.pdf" target="_blank">www.ipd.com.au/pdf/termsconditions.pdf</a>`;
        const parseHTMLString = (htmlString) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = htmlString;
        
            const listItems = Array.from(tempDiv.children).filter(item => item.tagName === 'OL' || item.tagName === 'UL')
            .flatMap(list => Array.from(list.children).filter(item => item.tagName === 'LI'));
            const termsArray = [];
        
            listItems.forEach((item) => {
                // Find the first strong tag and treat it as the header
                const strongTag = item.querySelector('strong');
                const header = strongTag ? strongTag?.innerHTML : "";
        
                // Remove the first strong tag from the content, but keep any other strong tags intact
                if (strongTag) {
                    strongTag.remove();
                }
                
                // The remaining content (with subsequent strong tags, if any)
                const content = item?.innerHTML.trim();
        
                termsArray.push({ header, content });
            });
        
            return termsArray;
        };
        
    
        const parsedTerms = storedAppendedNodes ? parseHTMLString(storedAppendedNodes) : [];
    
      
    
        const termsContent = storedAppendedNodes
            ? parsedTerms.map((term, index) => (
                <div className='pdf-terms-inner' key={index}>
                    <span className='heading'><Interweave content={term.header} /></span>
                    <span className='body'><Interweave content={term.content} /></span>
                </div>
                 ))
            : termsAndConditions?.map(term => (
                <div className='pdf-terms-inner' key={term.id}>
                    <span className='heading'>{term.headerName}</span>
                    <span className='body'><Interweave content={term.templateDescription} /></span>
                </div>
            ));

        return (
            <div>
                <div className='pdf-terms'>
                    <div className='heading'>Terms and Conditions</div>
                    <div className='pdf-bodyadj'>{termsContent}</div>
                    <div className='pdf-terms trading preview'>
                        <div className='heading2'><p className='h1'>Trading Conditions</p><p className='b1'><Interweave content={tradingConditionsContent} /></p></div>
                    </div>
                </div>
            </div>
        );
    };

    const stampPreview = ipdPreviewDataUrl;
    return (
        <div>
            {/* <PDFExport
                ref={pdfExportComponent}
                paperSize="A4"
                margin="1.2cm"
                forcePageBreak=".page-break"
            /> */}
            {/* <pageTemplate>
                <CustomPageTemplate quoteDetails = {quoteDetails} />
            </pageTemplate> */}
            <Stamp approvalStatusId={approvalStatusId} />
            <div className="pdf-preview-stamp">
                <img src={stampPreview} width="auto" height="100%" alt="Stamp Preview" />
            </div>
            <PDFHeader grandTotal = {grandTotal} userFullName={username} userId={UserId} systemSetting={systemSetting} shouldHideTotalPrice={shouldHideTotalPrice} />
            <PDFDataGrid shouldHideTotalPrice={shouldHideTotalPrice} hiddenStatus={hiddenStatus} />
            <div className="page-break"></div>
            {renderTermsAndConditions()}


        </div>
    );
}



const GetTermsandConditionData = async (userId) => {
    const response = await getResponse("TermsCondition/GetCombinedTermsAndConditions", { userId });
    const desiredHeaders = ["Pricing", "Delivery", "Validity"];
    return response.filter(item => desiredHeaders.includes(item.headerName));
}

const PrintPreviewComponent = ({ userId, userFullName, termsAndConditionsData, shouldHideTotalPrice, hiddenStatus, onCleanUp }) => {
    const printComponentRef = useRef();
    const [isDataLoaded, setIsDataLoaded] = useState(false); 
    const quoteDetails = getQuoteDetailsFromSessionStorage();
    const id = sessionStorage.getItem('id')
    


    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onAfterPrint: onCleanUp,
        copyStyles: true,
    });

    useEffect(() => {
        if (isDataLoaded) {
            if ((printComponentRef.current !== undefined || null) && quoteDetails[id].selectedItems.length > 0) {
            handlePrint();
            }
        }
    }, [isDataLoaded, handlePrint]);
   
    useEffect(() => {
       
        const timeoutId = setTimeout(() => {
            setIsDataLoaded(true);
        }, 5000); 
 
        return () => clearTimeout(timeoutId);
    }, []);

    // useEffect(() => {
    //     // Trigger the print dialog with a delay
    //     const timeoutId = setTimeout(() => {
    //         if (printComponentRef.current) {
    //             handlePrint();
    //         }
    //     }, 4000); // Adjust the delay time as needed

    //     return () => clearTimeout(timeoutId);
    // }, [handlePrint]);

    return (
        <div ref={printComponentRef}>
            <QuotePDFLayout
                userFullName={userFullName}
                userId={userId}
                termsAndConditions={termsAndConditionsData}
                shouldHideTotalPrice={shouldHideTotalPrice}
                hiddenStatus={hiddenStatus}
            />
        </div>
    );
};




const handlePreviewWithComponent = async (userId, userFullName, shouldHideTotalPrice, hiddenStatus) => {
    const termsAndConditionsData = await GetTermsandConditionData(userId);
    const quoteDetails = getQuoteDetailsFromSessionStorage();
    const id = sessionStorage.getItem('id');
   
    // if(approvalStatusId !== 0 && ( !quoteDetails[id]?.savedQuote?.approvalStatusId || quoteDetails[id]?.savedQuote?.approvalStatusId === null) ){ {
    //     toast.error("Approval changes only effected after save the quote , Please save the quote before previewing the PDF", {
    //         position: "top-center",
    //         transition: Slide,
    //     });
    //     return;
    // }



    if (!quoteDetails || !quoteDetails[id]) {
        toast.error("Please add customers and items to the current Quote to preview PDF", {
            position: "top-center",
            transition: Slide,
        });
        return;
    }

    const { selectedCustomers, selectedItems } = quoteDetails[id];

    if (selectedCustomers && selectedCustomers.length > 0 && selectedItems && selectedItems.length > 0) {
        const toastId = toast.success("Processing Preview PDF...", {
            autoClose: false,
            position: "top-center",
            transition: Slide,
        });

        setTimeout(() => {
            toast.dismiss(toastId);
        }, 4000);


        const container = document.createElement('div');
        const onCleanUp = () => {
            ReactDOM.unmountComponentAtNode(container);
            container.remove(); // This is slightly cleaner than `document.body.removeChild(container)`
        };


        // Render the PrintPreviewComponent
        ReactDOM.render(
            <PrintPreviewComponent
                userFullName={userFullName}
                userId={userId}
                termsAndConditionsData={termsAndConditionsData}
                shouldHideTotalPrice={shouldHideTotalPrice}
                hiddenStatus={hiddenStatus}
                onCleanUp={onCleanUp}
            />,
            container
        );
    } else {
        toast.error("No customers or items selected. Cannot preview the PDF.", {
            position: "top-center",
            transition: Slide,
        });
    }
};

export default handlePreviewWithComponent;
