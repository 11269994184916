import { React, useState } from "react";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { useMarginAndPrice } from "../../Contexts/MarginAndPriceProvider";
import { el } from "date-fns/locale";

const EditQuotePriceandMargin = () => {
    const {
        margin,
        total,
        newPrice,
        setNewPrice,
        newMargin,
        setNewMargin,
        setAllowSetValue,
        setAllowSetMarginValue,
        isMarginSelected,
        setIsMarginSelected,
        isPriceSelected,
        setIsPriceSelected,
        setIsKeyPressedForSave,
        totalItemPrice, setTotalItemPrice,maxMargin, setMaxMargin,
        isValue, setIsValue
    } = useMarginAndPrice();

    useState(() => {
        setNewPrice(
            totalItemPrice?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        );
        setNewMargin(parseFloat(maxMargin)?.toFixed(2));
    }, []);

    const isNumeric = (value) => {
        return /^[0-9]*\.?[0-9]*$/.test(value);
    };

    const totalInput = (e) => {
        if (isNumeric(e.target.value)) {
            setNewPrice(e.target.value);
        }
    };

    const marginInput = (e) => {
        const enteredValue = e.target.value;
        if (isNumeric(e.target.value)) {
            setNewMargin(e.target.value);
        }
        if( enteredValue === "" ) setIsValue(true);
        if(isNumeric(e.target.value) && enteredValue !== "" ) setIsValue(false);
    };

    const handlePriceRadioButtonChange = () => {
        if (newMargin === "" || newMargin <= 0) {
            setIsPriceSelected(false);
            setAllowSetValue(false);
        } else {
            setIsPriceSelected(true);
            setIsMarginSelected(false);
            setAllowSetValue(true);
            setAllowSetMarginValue(false);
        }
    };

    const handleMarginRadioButtonChange = () => {
        if (newPrice === "" || newPrice <= 0) {
            setIsMarginSelected(false);
            setAllowSetMarginValue(false);
        } else {
            setIsMarginSelected(true);
            setIsPriceSelected(false);
            setAllowSetMarginValue(true);
            setAllowSetValue(false);
        }
    };

    const handleKeyPressedForSave = (e) => {
        const charCode = e.which || e.keyCode;

        if (charCode === 13 && newMargin !== "" && newPrice !== "") { 
            setIsKeyPressedForSave(true);
        } else if (charCode === 13 && (newMargin == "" || newPrice == "")) {
            e.preventDefault(); 
        }
    };


    return (
        <Form
            render={() => (
                <FormElement className="pricemargin-quote-container p-2">
                    <fieldset className={"k-form-fieldset"}>
                        <header>
                            <p>
                                {" "}
                                Please select the option to change and enter the value in the respective text box under
                                New. Click Done to apply the changes.
                            </p>
                        </header>

                        <table className="mt-2 ip-set-pricemargin">
                            <tr>
                                <th> </th>
                                <th>Current</th>
                                <th>New</th>
                                <th>Modify</th>
                            </tr>
                            <tr>
                                <td>Margin (%)</td>
                                <td>
                                    <TextBox
                                        id="currentmargin"
                                        component={Input}
                                        value={parseFloat(maxMargin)?.toFixed(2)}
                                        readOnly={true}
                                    />
                                </td>
                                <td>
                                    <TextBox
                                        name="newmargin"
                                        label="newmargin"
                                        id="newmargin"
                                        component={Input}
                                        value={newMargin}
                                        onChange={(e) => marginInput(e)}
                                        disabled={!isMarginSelected}
                                        onKeyDown={handleKeyPressedForSave}
                                    />
                                </td>
                                <td>
                                    <td>
                                        <input
                                            type="radio"
                                            id="newmargin"
                                            name="newmargin"
                                            checked={isMarginSelected}
                                            onChange={handleMarginRadioButtonChange}
                                            disabled={newPrice === "" || newPrice <= 0}
                                        />
                                    </td>
                                </td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td>
                                    <TextBox
                                        id="currentprice"
                                        component={Input}
                                        value={totalItemPrice?.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                        readOnly={true}
                                    />
                                </td>
                                <td>
                                    {/* <TextBox
                                        name="newprice"
                                        label="newprice"
                                        id="newprice"
                                        component={Input}
                                        value={newPrice}
                                        onChange={(e) => totalInput(e)}
                                        disabled={!isPriceSelected}
                                        onKeyDown={handleKeyPressedForSave}
                                    /> */}
                                    <TextBox
    name="newprice"
    label="newprice"
    id="newprice"
    component={Input}
    value={newPrice}
    onChange={(e) => {
        const enteredValue = parseFloat(e.target.value);
        if(e.target.value === "") setIsValue(true);
        if(isNumeric(e.target.value) && e.target.value !== "" ) setIsValue(false);
        if (enteredValue <= 0) {
            // Set value to totalItemPrice when entered value is zero or less
            totalInput({ target: { value: totalItemPrice } });
        } else {
            // Set value to the entered value
            totalInput(e);
        }
    }}
    disabled={!isPriceSelected}
    onKeyDown={handleKeyPressedForSave}
/>

                                </td>
                                <td>
                                    <td>
                                        <input
                                            type="radio"
                                            id="newprice"
                                            name="priceType"
                                            checked={isPriceSelected}
                                            onChange={handlePriceRadioButtonChange}
                                            disabled={newMargin === "" || newMargin <= 0}
                                        />
                                    </td>
                                </td>
                            </tr>
                        </table>
                    </fieldset>
                </FormElement>
            )}
        />
    );
};

export default EditQuotePriceandMargin;
