import { Route } from "react-router-dom";
import {React,useContext } from "react";
import SubNodePage from "../components/Layouts/SubNodePage";
import OptionsPage from "../components/OptionPage/OptionPage";
import { PageLayout,toggleQuotesModal } from "../components/Auth/PageLayout";
import Introduction from "../components/Layouts/Introduction";
import {quotesDialogVisible} from "../components/Auth/PageLayout";


export const routes = [

    <Route
        path="/dashboard"
        element={
            <PageLayout />
        }
    />,
    <Route
        path="/quotes/:id/*"
        element={
            <PageLayout>
                <SubNodePage />
            </PageLayout>
        }
    />,
    <Route
        path="/new-units/:id"
        element={
            <PageLayout>
                <SubNodePage />
            </PageLayout>
        }
    />,
    <Route
        path="/price-lists/:id"
        element={
            <PageLayout>
                <SubNodePage />
            </PageLayout>
        }
    />,
    <Route
        path="/library/:id"
        element={
            <PageLayout>
                <SubNodePage />
            </PageLayout>
        }
    />,
    // <Route
    // path="/unit/:unitId"
    // element={
    //     <PageLayout>
    //         <SubNodePage />
    //     </PageLayout>
    // }
    // />,
    <Route
    path="/unit/:id"
    element={
        <PageLayout>
            <SubNodePage />
        </PageLayout>
    }
    />,
    <Route
        path="/options"
        element={
            <PageLayout>
                <OptionsPage />
            </PageLayout>
        }
    />,
    // <Route path="/" element={<Introduction />} />,
];
