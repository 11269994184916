import { TextBox, Input, RadioButton, TextArea, Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { React, useState, useContext,useEffect } from "react";
import { useStockCode } from "../../Contexts/StockCodeProvider";
import DataGridView from "../Layouts/DataGridView";
import { getResponse } from "../../Services/Common/Get";
import { postResponse } from "../../Services/Common/Post";
import { Button } from "@progress/kendo-react-buttons";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { toast, Slide } from "react-toastify";
import { useNewItemList } from "../../Contexts/NewItemListContextProvider";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import CustomerIdContext from "../../Contexts/CustomerIdContext";
import { useSystemSettings } from "../../Contexts/SystemSettingsContext";
import { v4 as uuidv4 } from "uuid";
import Spinner from '../Common/Spinner';
//import Dropdown from "react-bootstrap/esm/Dropdown";
import { Form, Dropdown } from 'react-bootstrap';

const ComponentPartsWhseSOHSub = ({idField}) => {
    const {isDesc, setIsDesc,isType, setIsType,isSOH, setIsSOH,isPartNo, setIsPartNo, visiblePasteOption,setVisiblePasteOption,bomisChecked, setBomIsChecked, compcode,stockCode,bomPart, totalSoh, compSOH,selectedComponents, setSelectedComponents, setSubStockCode,bomPartSub ,stockNumber} = useStockCode();
    const user = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [selectItems, setSelectItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedWhse, setSelectedWhse] = useState([]); 
    const [sohWhseTypes, setSOHWhseTypes] = useState([]);
    const [SOHTypesID, setSOGTypesID] = useState([]);
    const [selectedSOHTypeId, setSelectedSOHId] = useState([]);
    const [isFormModified, setIsFormModified] = useState(false);
    const { addItems, setAddItems } = useContext(RemoveItemsContext);
    const {itemListNew, setItemListNew,allowCopy, setAllowCopy }=useNewItemList()
    const { customerId } = useContext(CustomerIdContext);
    const { settings } = useSystemSettings();
    const [dropdownValue, setDropdownValue] = useState('all');
    const [itemspageNumber, setitemsPageNumber] = useState(1);
    const [itemspageSize, setitemsPageSize] = useState(50);
    const [skipSelectItems, setSkipSelectItems] = useState(0);
    const [takeSelectItems, setTakeSelectItems] = useState(50);
    const [total, setTotal] = useState(0);

    const columns = [
        
        { field: "compcode", title: "Part No", width: "300px" },
        { field: "desc", title: "Description" },
        { field: "compstatus", title: "Type",  width: "150px" },
        { field: "qty", title: "Qty", width: "150px" },
        { field: "soh", title: "SOH", width: "150px" },
       
    ];
   
   // const categories = ["GEN1", "WA01", "DB01", "NATW"];
    useEffect(() => {
       
      if (stockCode) {

        setIsPartNo(true);
        setIsDesc(true);        
        const fetchData = async () => {
          try {
            setLoading(true);
            const data = await getResponse("Stocks/getbomcomponentparts", { stockCode: stockCode ,whse:selectedWhse});
            console.log("data:", data);
            if (data) {
          //   setSelectItems(data);
             const sortedData = data.sort((a, b) => a.compcode.localeCompare(b.compcode));
             setSelectItems(sortedData);
             setTotal(sortedData.length);
          }
           
          } catch (error) {
            console.error("Error fetching data:", error);
          
        } finally {
           setLoading(false);
        }
        };
  
        fetchData();
      }
      
   //stockCode,selectedWhse,
      }, [stockCode,selectedWhse,setSelectItems]);
      useEffect(() => {
        const fetchloadData = async () => {
            try {
                const SOHlist = await getResponse("SystemSetting/getallsohwhse");
                const values = SOHlist.map((item) => ({
                whse: item.whCode,
                whseId: item.whseId,
                }));
                const sohValues = SOHlist.map((item) => item.whCode);
                setSOHWhseTypes(sohValues)
                const sohValuesID = SOHlist.map((item) => item.whseId);
                setSOGTypesID(sohValuesID)
                const selectedSOHType = SOHlist.find((item) => item.whCode === sohValues[0]);
                setSelectedWhse(selectedSOHType ? selectedSOHType.whCode : "");

            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchloadData();
    }, []);
    const handleSOHTypeChange = async (selectedValue) => {
      const selectedIndex = sohWhseTypes.findIndex((item) => item === selectedValue);
      const selectedId = selectedIndex !== -1 ? SOHTypesID[selectedIndex] : null;
      setSelectedWhse(selectedValue);
      setSelectedSOHId(selectedId);
      setIsFormModified(true);
  };

   
    const handleRowClick = (selectedRow) => {
        console.log("Row clicked:", selectedRow);
        
      };
    const handleCheckboxChange = () => {
      // if(customerId =="" || customerId == null || customerId ==undefined){
      //   toast.error("Before copying component parts, please add the customer to the customer grid.")
      // }
      // else{
        setBomIsChecked((prevChecked) => !prevChecked);
      // }
      };
    const handleCheckboxChangeForPartNo = () => {
        setIsPartNo((prevChecked) => !prevChecked);       
        };
    const handleCheckboxChangeForDesc = () => {
          setIsDesc((prevChecked) => !prevChecked);         
        };
    const handleCheckboxChangeForType = () => {
          setIsType((prevChecked) => !prevChecked);
        };
    const handleCheckboxChangeForSOH = () => {
          setIsSOH((prevChecked) => !prevChecked);
        };

    const handleAdd = async () => {
      if(customerId =="" || customerId == null || customerId == undefined){
        toast.error("Before copying component parts into Item Grid, please add the customer to the customer grid.", {
          position: "top-center",
          transition: Slide,
      });
      }
      else{
            let uniqueSelectedItems = selectedItems.filter(
                (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
            );
             console.log("uniqueSelectedItems :",uniqueSelectedItems)

            if (uniqueSelectedItems.length === 0) {
                // toast.error("No unique items to add");
                // return;
                console.log("uniqueSelectedItems selectedItems :",selectItems)
                uniqueSelectedItems = selectItems;
                setSelectedComponents(selectItems)
            }
          //  setBomIsChecked((prevChecked) => !prevChecked);
          setSelectedComponents(uniqueSelectedItems)
          const UserId = user?.homeAccountId;
          let systemSettings = null;
          if (uniqueSelectedItems.length > 0) {
              const compcodeList = {
                  lstStocks: uniqueSelectedItems,
              };
          } else {
              console.log("error components");
          }
        
          var url = `Stocks/getstockdetailsusingcompcode?CustomerId=${customerId}&bomisChecked=${!bomisChecked}`;
         
          const responses = await postResponse(url, uniqueSelectedItems);
  
          setSelectedComponents([]);
  
          if (settings === null) {
              const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                  userId: user?.homeAccountId,
              });
              systemSettings = data[0];
          } else {
              systemSettings = settings;
          }
  
          const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
  
          const newItems = responses.flat().map((response) => {
              let dcOnLp =
              response?.listPrice === 0.0
                      ? 0
                      : parseFloat((((response.listPrice - response.nettEA) / response.listPrice) * 100).toFixed(1));
                  // response.listPrice && response.nettEA
                  //     ? calculateItemDcOnLp(response.nettEA, response.listPrice)
                  //     : // parseFloat(
                  //       //       parseFloat(((response.listPrice - response.nettEA) / response.listPrice) * 100).toFixed(1)
                  //       //   )
                  //       0;
              let rowId = uuidv4();
              let totalPrice = response.repDisc
                  ? parseFloat(parseFloat(response.repDisc).toFixed(2))
                  : parseFloat(parseFloat(response.qty * response.nettEA).toFixed(2));
              let margin =
                  response?.nettEA === 0.0
                      ? 0
                      : parseFloat((((response.nettEA - response.stEecost) / response.nettEA) * 100).toFixed(1));
              let actMargin =
                  response?.nettEA === 0.0
                      ? 0
                      : parseFloat((((response.nettEA - response.actCostExact) / response.nettEA) * 100).toFixed(1));
              let frcMargin =
                  response?.nettEA === 0.0
                      ? 0
                      : parseFloat((((response.nettEA - response.stFutureCost) / response.nettEA) * 100).toFixed(1));
              let totalCost = response.qty * response.stEecostRound;
              let groupDbExtraId = "";
              let subGroupDbExtraId = "";
              let nonGroupDbExtraId = "";
              
             // let rowType ="";
              return {
                  ...response,
                  dcOnLp,
                  totalPrice,
                  margin,
                  frcMargin,
                  actMargin,
                  totalCost,
                  rowId,
                  rowType: response.type,
                  groupDbExtraId,
                  subGroupDbExtraId,
                  nonGroupDbExtraId,
                  l60 : response.l60 === 0 ? '' : response.l60,
                  l55 : response.l55 === 0 ? '' : response.l55,
                  l50 : response.l50 === 0 ? '' : response.l50,
                  l20 :  response.l20 === 0 ? '' : response.l20
              };
          });
  
          const updatelist =[...newItems]
          
          setItemListNew(updatelist)
          setAllowCopy(true)
          setSelectedItems([])
          
        }
            const pastePartNo = isPartNo;
            const pasteDesc = isDesc;
            const pasteType = isType;
            const pasteQty = !bomisChecked;
            const pasteSOH = isSOH;
          
            const selectedDropdownValue = dropdownValue;
          
            const clipboardData = selectItems.map((item) => {
              let data = '';
          
              if (pastePartNo) {
                data += item.compcode+ '\t';
              }
          
              if (pasteDesc) {
                data += ' ' + item.desc+ '\t';
              }
          
              if (pasteType) {
                data += ' ' + item.compstatus+ '\t';
              }
          
              if (pasteQty) {
                data += ' ' + item.qty+ '\t';
              }
          
              if (pasteSOH) {
                data += ' ' + item.soh+ '\t';
              }
          
              return data.trim();
            });
          
            let uniqueSelectedItemss = selectedItems.filter(
              (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
          );
            const clipboardDataSelectedRows = uniqueSelectedItemss.map((item) => {
              let data = '';
          
              if (pastePartNo) {
                data += item.compcode+ '\t';
              }
          
              if (pasteDesc) {
                data += ' ' + item.desc+ '\t';
              }
          
              if (pasteType) {
                data += ' ' + item.compstatus+ '\t';
              }
          
              if (pasteQty) {
                data += ' ' + item.qty+ '\t';
              }
          
              if (pasteSOH) {
                data += ' ' + item.soh+ '\t';
              }
          
              return data.trim();
            });
            if (selectedDropdownValue === 'all') {
              //all rows all cols
              const allData = selectItems.map((item) => {
                return `${item.compcode}\t${item.desc}\t${item.compstatus}\t${item.qty}\t${item.soh}`;
              }).join('\n');
              try {
                navigator.clipboard.writeText(allData);
                toast.success('Data copied successfully', {
                  position: "top-center",
                  transition: Slide,
              });      
              } catch (error) {
                console.error('Error copying:', error);
                toast.error('Error copying', {
                  position: "top-center",
                  transition: Slide,
              });      
              }
            }
            if (selectedDropdownValue === 'selectedCols') {
              //all rows selected cols
              const allData = clipboardData.join('\n'); 
              console.log("all data :", allData);
              try {
                navigator.clipboard.writeText(allData);
                toast.success('Data copied successfully', {
                  position: "top-center",
                  transition: Slide,
              });      
              } catch (error) {
                console.error('Error copying:', error);
                toast.error('Error copying', {
                  position: "top-center",
                  transition: Slide,
              });      
              }    
            }   
            if (selectedDropdownValue === 'selectedRowsAndCols') {
              //selected rows selected cols
              const selectedRowsData = clipboardDataSelectedRows.join('\n');
              try {
                navigator.clipboard.writeText(selectedRowsData);
                toast.success('Data copied successfully', {
                  position: "top-center",
                  transition: Slide,
              });      
              } catch (error) {
                console.error('Error copying:', error);
                toast.error('Error copying', {
                  position: "top-center",
                  transition: Slide,
              });      
              }
            }
            if (selectedDropdownValue === 'selectedRows') {
              //selected rows all cols
              let uniqueSelectedItems = selectedItems.filter(
                (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
            );
              const selectedRowsData = uniqueSelectedItems.map((item) => {
                return `${item.compcode}\t${item.desc}\t${item.compstatus}\t${item.qty}\t${item.soh}`;
              }).join('\n');
          
              try {
                navigator.clipboard.writeText(selectedRowsData);
                toast.success('Data copied successfully', {
                  position: "top-center",
                  transition: Slide,
              });      
              } catch (error) {
                console.error('Error copying:', error);
                toast.error('Error copying', {
                  position: "top-center",
                  transition: Slide,
              });      
              }
            }
          
          
       
  
    };
    // useEffect(() => {

    // }, [bomisChecked,isPartNo,isDesc,isType,isSOH,dropdownValue]);

    // const handleCheckboxChange = () => {
    //   setBomIsChecked(false);
    // };
    
    
  
  //   const selectComp = selectedItems.filter(
  //     (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField][0])
  // );
//  if(selectedItems.length>0) {
//   const comp = selectedItems.map((item) => item.compcode);
//   const selectComp =selectedItems.find((item) => item.compcode === comp[0])
//   console.log("selectComp A :",comp)
//   console.log("selectComp :",selectComp)
//  // setSubStockCode([selectComp])
// }
 // Initialize with an empty string

const dropdownOptions = [
  { value: 'all', label: 'All' },
  { value: 'selectedCols', label: 'All rows with selected cols' },
  { value: 'selectedRowsAndCols', label: 'Selected rows and cols' },
  { value: 'selectedRows', label: 'Selected rows and all cols' },
];

////////paste to clipboard
const handlePaste = () => {
  const pastePartNo = isPartNo;
  const pasteDesc = isDesc;
  const pasteType = isType;
  const pasteQty = !bomisChecked;
  const pasteSOH = isSOH;

  const selectedDropdownValue = dropdownValue;

  const clipboardData = selectItems.map((item) => {
    let data = '';

    if (pastePartNo) {
      data += item.compcode+ '\t';
    }

    if (pasteDesc) {
      data += ' ' + item.desc+ '\t';
    }

    if (pasteType) {
      data += ' ' + item.compstatus+ '\t';
    }

    if (pasteQty) {
      data += ' ' + item.qty+ '\t';
    }

    if (pasteSOH) {
      data += ' ' + item.soh+ '\t';
    }

    return data.trim();
  });

  let uniqueSelectedItemss = selectedItems.filter(
    (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
);
  const clipboardDataSelectedRows = uniqueSelectedItemss.map((item) => {
    let data = '';

    if (pastePartNo) {
      data += item.compcode+ '\t';
    }

    if (pasteDesc) {
      data += ' ' + item.desc+ '\t';
    }

    if (pasteType) {
      data += ' ' + item.compstatus+ '\t';
    }

    if (pasteQty) {
      data += ' ' + item.qty+ '\t';
    }

    if (pasteSOH) {
      data += ' ' + item.soh+ '\t';
    }

    return data.trim();
  });
  if (selectedDropdownValue === 'all') {
    //all rows all cols
    const allData = selectItems.map((item) => {
      return `${item.compcode}\t${item.desc}\t${item.compstatus}\t${item.qty}\t${item.soh}`;
    }).join('\n');
    try {
      navigator.clipboard.writeText(allData);
      toast.success('Data copied successfully', {
        position: "top-center",
        transition: Slide,
    });
    } catch (error) {
      console.error('Error copying:', error);
      toast.error('Error copying', {
        position: "top-center",
        transition: Slide,
    });
    }
  }
  if (selectedDropdownValue === 'selectedCols') {
    //all rows selected cols
    const allData = clipboardData.join('\n'); 
    console.log("all data :", allData);
    try {
      navigator.clipboard.writeText(allData);
      toast.success('Data copied successfully', {
        position: "top-center",
        transition: Slide,
    });
    } catch (error) {
      console.error('Error copying:', error);
      toast.error('Error copying', {
        position: "top-center",
        transition: Slide,
    });
    }    
  }   
  if (selectedDropdownValue === 'selectedRowsAndCols') {
    //selected rows selected cols
    const selectedRowsData = clipboardDataSelectedRows.join('\n');
    try {
      navigator.clipboard.writeText(selectedRowsData);
      toast.success('Data copied successfully', {
        position: "top-center",
        transition: Slide,
    });
    } catch (error) {
      console.error('Error copying:', error);
      toast.error('Error copying', {
        position: "top-center",
        transition: Slide,
    });
    }
  }
  if (selectedDropdownValue === 'selectedRows') {
    //selected rows all cols
    let uniqueSelectedItems = selectedItems.filter(
      (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
  );
    const selectedRowsData = uniqueSelectedItems.map((item) => {
      return `${item.compcode}\t${item.desc}\t${item.compstatus}\t${item.qty}\t${item.soh}`;
    }).join('\n');

    try {
      navigator.clipboard.writeText(selectedRowsData);
      toast.success('Data copied successfully', {
        position: "top-center",
        transition: Slide,
    });
    } catch (error) {
      console.error('Error copying:', error);
      toast.error('Error copying', {
        position: "top-center",
        transition: Slide,
    });
    }
  }

};
//const dataToDisplay = selectItems;
const paginate = (dataToDisplay,  pageNumber, itemspageSize) => {
  const startIndex = (pageNumber - 1) * itemspageSize;
  return dataToDisplay.slice(startIndex, startIndex + itemspageSize);
};
const handlePageChangeSelectItems = ({ skip, take }) => {
  setSkipSelectItems(skip);
  setTakeSelectItems(take);
  setitemsPageNumber(skip / take + 1);
};

  return (
    <div>
      <div className="d-flex">
        <div className="row">
          <div className="col-12 d-flex flex-row"> 
              <div className="me-3">
                <div className="me-2">Component:</div>
                <TextBox
                  style={{
                    width: "200px",
                  }}
                  id="stockcode"
                  component={Input}
                  value={stockCode !=='' || stockCode !== undefined ?  stockCode : stockNumber}
                  readOnly={true}
                />
              </div>
              <div className="me-3">
                <div className="me-2">SOH:</div>
                <TextBox
                  style={{
                    width: "100px",
                  }}
                  id="soh"
                  component={Input}
                  value={bomPartSub ? totalSoh : compcode[compcode.length - 1]?.soh}
                  readOnly={true}
                />
              </div>
              <div className="me-3">
            <div className="me-2">Whse:</div>
            <DropDownList
              style={{
                width: "100px",
              }}
              data={sohWhseTypes}
              value={selectedWhse}
              onChange={(e) => handleSOHTypeChange(e.target.value)}
              editable={false}
            />
              </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-row align-items-center ms-5 mt-3">
              <Button className="px-4 me-2 ip-button-general me-2" 
              onClick={handleAdd}
          //   disabled ={selectedItems <= 0}
              >
                Copy
              </Button>

              {/* <Button className="px-4 me-2 ip-button-general" onClick={handlePaste}>
              Paste
            </Button> */}
              {/* <Dropdown onSelect={(value) => setDropdownValue(value)}>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        {dropdownValue.length > 0 ? dropdownValue : 'Select Option'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {dropdownOptions.map((option) => (
          <Dropdown.Item key={option.value} eventKey={option.value}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown> */}
    <Form.Group controlId="dropdownForm">    
          <Form.Control as="select" value={dropdownValue} onChange={(e) => setDropdownValue(e.target.value)} className="ip-SOHsub-select">
            {/* <option value="">Select an option</option> */}
            {dropdownOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

              <div className="d-flex align-items-end ms-2 k-white-space-pre">
                <Checkbox
                  type="checkbox"
                  className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                  checked={isPartNo}
                  onChange={handleCheckboxChangeForPartNo}
                />
                <div className="me-2">Part No</div>
              </div>

              <div className="d-flex align-items-end ms-1">
                <Checkbox
                  type="checkbox"
                  className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                  checked={isDesc}
                onChange={handleCheckboxChangeForDesc}
                />
                <div className="me-2">Desc</div>
              </div>

              <div className="d-flex align-items-end ms-1">
                <Checkbox
                  type="checkbox"
                  className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                  checked={isType}
                  onChange={handleCheckboxChangeForType}
                />
                <div className="me-2">Type</div>
              </div>

              <div className="d-flex align-items-end ms-1">
                <Checkbox
                  type="checkbox"
                  className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                  checked={!bomisChecked}
                  onChange={handleCheckboxChange}
                />
                <div className="me-2">Qty</div>
              </div>
              <div className="d-flex align-items-end ms-1">
                <Checkbox
                  type="checkbox"
                  className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                  checked={isSOH}
                  onChange={handleCheckboxChangeForSOH}
                />
                <div className="me-2">SOH</div>
              </div>
            </div>
          </div>
        </div>

        {/* {bomPart === false ? (
             <>
           <Button className="px-4 me-2 ip-button-general" onClick={handleAdd}>
                Copy selected Items
           </Button>
                Copy with Qty
           <input
                type="checkbox"
                className="form-check-input form-check-input ip-tick-show"
                checked={bomisChecked}
                onChange={handleCheckboxChange}
          />
           </>
          ) : (
           <></>
            )} */}


      </div>
      {loading ? (
        <Spinner />
      ) : (
      <DataGridView
        columns={columns}
        data={paginate(selectItems, itemspageNumber, itemspageSize)}
      //  data={selectItems}
        singleSelect={false}
        showEmptyRow={false}
        idField="compcode"
        onRowClick={handleRowClick}
        selectedRows={selectedItems}
        setSelectedRows={setSelectedItems}
        showSelectionColumn={true}
        skip = {skipSelectItems}
        take={takeSelectItems}
        isHeaderCheckboxRender={!visiblePasteOption ? true : false}
        total={total}
        onPageChange={handlePageChangeSelectItems}
      />
      )}
    </div>
  );
  
};
export default ComponentPartsWhseSOHSub;
