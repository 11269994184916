// ResetContext.js
import React, { createContext, useState,useContext  } from 'react';
import { QuoteContactTypes } from "../enums/GroupTypes";

const QuoteContactContext = createContext();

export const useQuoteContact = () => useContext(QuoteContactContext);

export const QuoteContactProvider = ({ children }) => {
  
  const [allowSetContact, setAllowSetContact] = useState(false);
  const [selectedContactOption, setSelectedContactOption] = useState(QuoteContactTypes.OPTION1);
  const [currentContact, setCurrentContact] = useState("");
  const [contactList, setContactList] = useState([]);
  const [search, setSearch] = useState("");  
  const [fullName, setFullName] = useState("");  
  const [total, setTotal] = useState(0);
  const [customName, setCustomName] = useState("");
  const [customPhone, setCustomPhone] = useState("");
  const [customEmail, setCustomEmail] = useState("");
  const [owner, setOwner] = useState("");
  const [pageNumberSelectItems, setPageNumberSelectItems] = useState(1);
  const [pageSize, setPageSize] = useState(50);  
  const [usermobile, setUserMobile] = useState("");
  const [currentContactOption , setCurrentContactOption] = useState("");
  
  const [updatedQuoteContact , setUpdatedQuoteContact ] = useState("")
  const [selectedContactTypeId, setSelectedContactId] = useState([]);
  const [newQuoteContact, setNewQuoteContact] = useState(false);
  const [searchContactMobile , setSearchContactMobile] = useState("");
  const [isDoneButtonClicked , setIsDoneButtonClicked] = useState(false);
  return (
    <QuoteContactContext.Provider value={{newQuoteContact,selectedContactTypeId,isDoneButtonClicked , setIsDoneButtonClicked,setSelectedContactId, setNewQuoteContact,pageSize, setPageSize,pageNumberSelectItems, setPageNumberSelectItems,customEmail, setCustomEmail,customPhone, setCustomPhone,owner, setOwner,updatedQuoteContact , setUpdatedQuoteContact,customName, setCustomName,total, setTotal,fullName, setFullName, search, setSearch,allowSetContact, setAllowSetContact,selectedContactOption, setSelectedContactOption,currentContact, setCurrentContact,contactList, setContactList ,searchContactMobile , setSearchContactMobile , usermobile, setUserMobile ,currentContactOption , setCurrentContactOption }}>
      {children}
    </QuoteContactContext.Provider>
  );
};
