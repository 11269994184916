import { baseURL } from "../../App";
import { msalInstance } from "../../index"; // Update with the correct path to your MSAL instance

// Helper function to get access token
const getAccessToken = async () => {
    const requestForApi = {
        scopes: ["api://d055ad45-59fa-4049-9802-3efb2ab98eed/access_as_user"],
        account: msalInstance.getAllAccounts()[0] // Assumes you want to use the first account
    };

    try {
        const response = await msalInstance.acquireTokenSilent(requestForApi);
        return response.accessToken;
    } catch (error) {
        if (error.name === "InteractionRequiredAuthError") {
            try {
                const response = await msalInstance.acquireTokenPopup(requestForApi);
                return response.accessToken;
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error(error);
        }
    }
};

export const putResponse = async (url = "", data = {}) => {
    const apiPutAccessToken = await getAccessToken();
    if (!apiPutAccessToken) {
        throw new Error("Access token is not available.");
    }
    const options = {
        method: "PUT",

        headers: {
            Authorization: `Bearer ${apiPutAccessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    };

    const fullUrl = `${baseURL}/${url}`;

    return await fetch(fullUrl, options).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            return data;
        }
    });
};

// Make an HTTP PUT Request
// export const putResponse =  async (url, data) => {
//   console.log('Request Body:', JSON.stringify(data, null, 2)); // Add this line to log the request body

//  // Awaiting fetch which contains method,
//  // headers and content-type and body

//       const options = {
//           method: 'PUT',
//           headers: {
//             'Content-type': 'application/json'
//           },
//           body: JSON.stringify(data)
//       }

//       const fullUrl = `${baseURL}/${url}`;

//       return await fetch(fullUrl, options)
//       .then(async response => {

//         const data = await response.json();

//         if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         else{
//             return data;
//         }

// })
// .catch(error => {
//   console.error('Failed to insert data:', error);

// });

// }

// //Reference

// // // Instantiating new EasyHTTP class
// // const http = new EasyHTTP;
// // // User Data
// // const data = {
// //     name: 'sunny yadav',
// //     username: 'sunnyyadav',
// //     email: 'sunny@gmail.com'
// //   }

// // // Update Post
// // http.put(
// // 'https://jsonplaceholder.typicode.com/users/2',
// //      data)

// // // Resolving promise for response data
// // .then(data => console.log(data))

// // // Resolving promise for error
// // .catch(err => console.log(err));
