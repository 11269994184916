// ResetQuote.js
import React, { useState, useContext, useEffect } from 'react';
import { useResetQuote } from '../../Contexts/ResetProvider';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ResetQuoteTypes } from "../../enums/GroupTypes";
import { Bar as ChartJS } from 'chart.js/auto';
import { Bar }            from 'react-chartjs-2';

const GraphQuotes  = ({data}) => {
    let months =[];
    let quotesCreated = [];

  Object.keys(data).forEach(key => {
    const entry = data[key];
    months.push(entry.Month);
    quotesCreated.push(entry.Total);
  });

  
    const chartData = {
      labels: months,
      datasets: [
        {
          label: 'Quotes Created',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(75,192,192,0.4)',
          hoverBorderColor: 'rgba(75,192,192,1)',
          data: quotesCreated,
        },
      ],
    };
    const totalSum = quotesCreated.reduce((acc, curr) => acc + curr, 0);
    return (
      <div>
        
        <Bar
          data={chartData}
          options={{
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          }}
        />
        <div className="labour-totalfooter">
                    <span className="me-3 float-end">
                        <span className="ms-5">Total Quotes Created : <span className="value">{totalSum}</span></span>
                     </span>
        </div>
      </div>
    );
  };

export default GraphQuotes;
