import authMatrix from "../../auth-matrix.json";
import authMatrixPriceList from "../PriceLists/Auth-Materix-PriceList.json";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { json } from "react-router-dom";
import { clear } from "@progress/kendo-react-dropdowns/dist/npm/messages";

const AuthorizationContext = createContext();

export const useAuthorizationContext = () => {
    return useContext(AuthorizationContext);
}

export const  UseAuthorization = ({children}) => {
    const MINE = "MINE"
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [ username, setUsername ] = useState("");
    const [columnsMapping , setColumnsMapping] = useState(["displayGroupName"]);
    let currentRole = "";
    let viewOptionList = "";

    const getAccountRole = () => {
        const curretAccount = instance.getActiveAccount();

        if (curretAccount) {
            if (curretAccount.idTokenClaims.roles != null) {
                currentRole = curretAccount.idTokenClaims.roles[0].toString();
            } else {
                currentRole = "No Role";
            }
        }
         caches.keys().then(list => list.map(key => caches.delete(key)));
         console.log('Clear cache/local storage');
        return currentRole;
    }

    const getQuoteViewOpt = () => {
        getAccountRole();
        viewOptionList = [];
        
        (currentRole == "No Role" || !isAuthenticated) ? viewOptionList = [{ "text": "No Role", "id": -1 }] : "";

        const mainData =  authMatrix.filter((auth) => (auth.AuthType == currentRole));        
        viewOptionList = (currentRole == "No Role" ? viewOptionList : mainData[0]?.QuoteViewType);
        viewOptionList = (viewOptionList === undefined) ? [{ "text": "No View", "id": 0 }] : viewOptionList;

        return viewOptionList;

    }

   const getPriceListViewOpt = () => {
    getAccountRole();
    viewOptionList = [];

    (currentRole == "No Role" || !isAuthenticated) ? viewOptionList = [{ "text": "No Role", "id": -1 }] : "";

    const mainData =  authMatrixPriceList.filter((auth) => (auth.AuthType == currentRole));        
    viewOptionList = (currentRole == "No Role" ? viewOptionList : mainData[0]?.QuoteViewType);
    viewOptionList = (viewOptionList === undefined) ? [{ "text": "No View", "id": 0 }] : viewOptionList;

    return viewOptionList;


   }

   const getPriceListColMap = (defualtIndex,viewType) => {
    getAccountRole();
    
    if(currentRole == "No Role" || !isAuthenticated) return [];
    
    const mainData =  authMatrixPriceList.filter((auth) => (auth.AuthType == currentRole));
    var itemGrid = (viewType) ?
            (mainData[0]['ItemGrid-Access'][defualtIndex][viewType].length == 0) 
            ? ['No Access']
            : mainData[0]['ItemGrid-Access'][defualtIndex][viewType]
        : [];

    return (itemGrid != null) ? itemGrid : [];

}

    const getQuoteColMap = (defualtIndex,viewType) => {
        getAccountRole();
        
        if(currentRole == "No Role" || !isAuthenticated) return [];
        
        const mainData =  authMatrix.filter((auth) => (auth.AuthType == currentRole));
        var itemGrid = (viewType) ?
                (mainData[0]['ItemGrid-Access'][defualtIndex][viewType].length == 0) 
                ? ['No Access']
                : mainData[0]['ItemGrid-Access'][defualtIndex][viewType]
            : [];

        return (itemGrid != null) ? itemGrid : [];

    }

    const canView = (AuthType) => {
        var result = false;

        if(isAuthenticated) {
            getAccountRole;
            const mainData =  authMatrix.filter((auth) => (auth.AuthType == currentRole));
            var components = mainData[0]?.Type[0]?.View.includes(currentRole); //view
            // var components = authMatrix.filter((auth) => (
            //      ( (auth.AuthType == componentName)
            //     // && (
            //     //         (auth.Type['View'].filter((authRole) => (authRole == role)).length > 0) 
            //     //         || ((auth.Type['View'].filter((authRole) => (authRole == MINE)).length > 0 ) && (owner == username))
            //     //     )
            //     )
            //     // ( (auth.AuthType == componentName)
            //     // && (
            //     //         (auth.Type['View'].filter((authRole) => (authRole == role)).length > 0) 
            //     //         || ((auth.Type['View'].filter((authRole) => (authRole == MINE)).length > 0 ) && (owner == username))
            //     //     )
            //     // )
                
            // ));
            result = ((components == null) ? false : components);
        }

         return result;
    }

    const canEdit = (AuthType) => {
        var result = false;

        if(isAuthenticated) {
            getAccountRole;
            const mainData =  authMatrix.filter((auth) => (auth.AuthType == currentRole));
            var components = mainData[0]?.Type[0]?.Edit.includes(currentRole);
            // var components = authMatrix.filter((auth) => (
            //     (auth.AuthType == componentName) 
            //     && ((auth.Edit.filter((authRole) => (authRole == role)).length > 0) 
            //     || ((auth.Edit.filter((authRole) => (authRole == MINE)).length > 0 ) && (owner == username)))
            // ));

            result = ((null != components) && (components.length > 0));
        }

        return result;
    }
    
    const canUpdate = (AuthType) => {
        var result = false;

        if(isAuthenticated) {
            getAccountRole;
            const mainData =  authMatrix.filter((auth) => (auth.AuthType == currentRole));
            var components = mainData[0]?.Type[0]?.Update.includes(currentRole);
            // var components = authMatrix.filter((auth) => (
            //     (auth.AuthType == componentName) 
            //     && ((auth.Update.filter((authRole) => (authRole == role)).length > 0) 
            //     || ((auth.Update.filter((authRole) => (authRole == MINE)).length > 0 ) && (owner == username)))
            // ));

            result = ((null != components) && (components.length > 0));
        }

        return result;
    }

    const contextValue = {
        canView,
        canEdit,
        canUpdate,
        getQuoteColMap,
        getQuoteViewOpt,
        getPriceListViewOpt,
        getPriceListColMap,
        getAccountRole,
        columnsMapping , 
        setColumnsMapping
    };

    return <AuthorizationContext.Provider value={contextValue}>{children}</AuthorizationContext.Provider>
}