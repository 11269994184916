import React, { useState, useEffect, useContext, useRef } from "react";
import * as ReactDOM from "react-dom";
import "@progress/kendo-react-inputs";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { toast, Slide } from "react-toastify";
import { postResponse } from "../../Services/Common/Post";
import { getResponse } from "../../Services/Common/Get";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@progress/kendo-react-buttons";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Spinner from "../Common/Spinner";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { Splitter } from '@progress/kendo-react-layout';
import { useNodeContext } from "../../Contexts/NodeProvider";
import {
  TreeView,
  processTreeViewItems,
  handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import "../../styles/Labour.css";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process, groupBy, filterBy  } from "@progress/kendo-data-query";
import {
  Filter,
  Operators,
  TextFilter,
  NumericFilter,
  BooleanFilter,
} from "@progress/kendo-react-data-tools";
import EditableLabourCellNumeric from "../Layouts/EditableLabourCellNumeric"; // adjust the path according to your project structure

const LabourDataGrid = ({
    setLabourFormVisible,
    labourHoursForm,
    itemList=[],

    setItemAssemblyList,
    itemAssemblyList=[],

    labourCostName,
    setLabourCostName,
    currentMargin,
    setCurrentMargin,

    currtotalCost,
    currTotalPrice,
    setCurrtotalCost,
    setCurrTotalPrice,

    setEditLabourItems,
    editLabourItems,

    handleTotalTimeApprx,
    totalTimeApprx,

    setCurrNetEA,
    currNetEA,

    setFutCost,
    futCost
}) => {

    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [treeValues, setTreeValues] = useState([]);
    const [labourTree, setLabourTree] = useState([
        {
            text: "Labour Hours",
            expanded: true,
            items: [],
        }
    ]);
    const [labourDialogVisible, setLabourDialogVisible] = useState(false);
    const [dataExp, setDataExp] = useState([]);
    const [check, setCheck] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [loadItemLabour, setLoadItemLabour] = useState([]);
    const [defaultItem, setDefaultItem] = useState([]);
    const [select, setSelect] = useState([""]);
    const [selectedLabour, setSelectedLabour] = useState("");

    const gridRef = useRef();
    const gridRefSelected = useRef();
    const currCel = useRef();
    const [totalHrs,setTotalHrs] = useState(0);
    const [marginPercent,setMarginPercent] = useState("0");
    const [currMarginPercent,setCurrMarginPercent] = useState("0");
    const [totalCost,setTotalCost] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);

    const [applyMinWidth, setApplyMinWidth] = useState(false);
    const [applyMinWidthSelected, setApplyMinWidthSelected] = useState(false);
    const minGridWidth = useRef(0);
    const minGridWidthSelected = useRef(0);
    const {
        quotes,
        setQuotes,
        maxQuoteIndex,
        setMaxQuoteIndex,
        handleAddNode,
        handleCloseNode,
        handleDeleteNode,
        handleDeleteOpenQuote,
        handleNodeWhenUpdating,
        handleNodeWhenSaving,
        setIsQuoteSaved,
        openQuoteCount,
        quoteUpdated,
        setQuoteUpdated,
        quoteContactDetailsMobile,
        setQuoteContactDetailsMobile,
        isSelectedUnitAdded,
        setIsSelectedUnitAdded,
        isPastedUnits,
        setIsPastedUnits,
        isRemoveItemsClicked,
        setIsRemoveItemsClicked,
    } = useNodeContext();

    const ADJUST_PADDING = 3;
    const COLUMN_MIN = 3;   
    const ADJUST_PADDING_Selected = 3;
    const COLUMN_MIN_Selected = 3;  
    const [gridCurrent, setGridCurrent] = useState(0);
    const [gridCurrentSelected, setGridCurrentSelected] = useState(0);
    const [detectQtyChanged, setDetectQtyChanged] = useState(0);
    const [applyBtnDisabled, setpplyBtnDisabled] = useState(true);
    const [changesApplied, setChangesApplied] = useState(true);

    const [triggerCompute, setTriggerCompute] = useState(false);
    const [newItemData, setNewItemData] = useState(null);
    let [selectedItems, setSelectedItems] = useState([]);
    let [showCancelMsg, setShowCancelMsg] = useState(false);
    const [expand, setExpand] = useState({
        ids: [0],
        idField: "text",
    });

    const [panes, setPanes] = useState([{
        size: '20%',
        min: '20px',
        height:'100px',
        collapsible: true
    }, {}, {
        size: '30%',
        min: '20px',
        height:'100px',
        collapsible: true
    }]);

    const closingForm = () => {
        if(totalCost > 0) {
            setShowCancelMsg(true);
        } else setLabourFormVisible(false);
        //addToQuote
    }

    const closeForm = () => {
        setLabourFormVisible(false);
    }

    const toggleDialog = () => {
        setShowCancelMsg(false);
    };

    const onChangeCurrMargin = (e) => {
        setMarginPercent(e.target.value);
        setCurrMarginPercent(e.target.value + "%");
        setpplyBtnDisabled(false);
    }
    
    const onItemClick = (event) => {
        setSelect([event.itemHierarchicalIndex]);
        setSelectedLabour(event.item.text)
    };
    
    const onExpandChange = (event) => {
        const updatedData = [...dataExp];
        const itemIndex = updatedData.findIndex(item => item.id === event.item.id);

        if (itemIndex !== -1) {
            updatedData[itemIndex] = { ...updatedData[itemIndex], expanded: !updatedData[itemIndex].expanded };
            setDataExp(updatedData);
        }
    };

    const onCheckChange = (event) => {
        const settings = {
            singleMode: false,
            checkChildren: false,
            checkParents: false
        };

        setCheck(handleTreeViewCheckChange(event, check, tree, settings));
    };

    const onChange = event => {
        setPanes(event.newState);
    };

    const handleItemChange = async (event) => {
        const { field, dataItem, clearInput } = event;

        if (event.value < 0) {
            toast.error("Please enter a valid value greater than zero.", { autoClose: 5000,  position: "top-center", transition: Slide, });
        }

        setNewItemData(dataItem);
        setDetectQtyChanged(Math.random());
    }

    const calculatetotals = () =>{
        let totalHr = 0;
        let totCost = 0;
        let totPrice = 0;
        let totNetEA = 0;
        let FCost = 0;
        let totLabCost = 0;
        let totLabTime = 0;
        
        let itm = loadItemLabour.filter((f)=>f.adjQty > 0); 

        itm.map((i)=>{
             ////Calculate the totals
            if (i.adjQty > 0) {
                totalHr = totalHr + ( Number(i.labTime) * Number(i.adjQty) );
                totLabCost = totLabCost + Number(i.labCost);
                totLabTime = totLabTime + Number(i.labTime);
                totCost = totCost + Number(i.labCost * i.adjQty)
                
            } 
        })
       
        totalHr = (totalHr == 0 
            ? totalHr 
            // : (totalHr < 60 
            //     ? 1 
            //     : Math.ceil(totalHr / 60)) ////old computation
             : totalHr / 60);
        totCost= parseFloat(totCost).toFixed(2); 
        FCost = (Number(totLabCost)/Number(totLabTime)) * 60;
        totPrice = ( totCost / ((100 - parseInt(marginPercent.toString().replace("%",""))) / 100) ).toFixed(2);
        totNetEA = ( FCost / ((100 - parseInt(marginPercent.toString().replace("%",""))) / 100) ).toFixed(2);
        
        setTotalHrs(totalHr.toFixed(2)); ////Set the total hours
        setTotalCost(totCost);
        setTotalPrice(totPrice);
        handleTotalTimeApprx(totalHr);
        setCurrNetEA(totNetEA);
        setFutCost(FCost);
    }

    useEffect(()=>{
        if (!effectRan.current) {
             generateData();
        }
         effectRan.current = true;
    },[])

    useEffect(()=>{
        calculatetotals();
        setChangesApplied(false);
    },[detectQtyChanged])

    const generateData = () => {
        LoadLabourHoursCategory();
        GetMargin();
        LoadData();

    }

    const resetData = () =>{
        gridSetup.gridData.map((itm)=>{
            itm.adjQty = 0;
            itm.totalCost = 0;
            itm.totalTime = 0;
        })

        calculatetotals();
        LoadLabourHoursCategory();

        
    }


    const LoadLabourHoursCategory = async () => {
        if (treeValues.length > 0) return;

        try {
            // Call Api
            const response = await getResponse('LabourHours/GetAllLabour', {});
            //// Loop on the resuit of Labour listing
            response.map((value,index)=> {
                //// Set the item values
                setTreeValues((treeValues) => {  
                    var newtreeValues = treeValues;
                    if(newtreeValues.length > 0) {
                        if(treeValues.filter((value)=>value.text == value["lcDesc"]).length == 0) newtreeValues.push({text:value["lcDesc"]});
                    } else newtreeValues.push({text:value["lcDesc"]});

            
                    return newtreeValues;
                });
            });

            setTreeValues([]); //// trigger refresh
            ////Set The item values
            setLabourTree((labourTree) => {  
                var labour = labourTree;
                labour[0].items = treeValues;
                return labour;
            });



        } catch (error) {
          // HTTP error handling
          if (error.response && error.response.status === 404) {
           console.log(error.response.data.message || "Error occurred while fetching Labour hours data.");
          } else {
            console.log(error.response.data.message);
          }
        }

    }
    const effectRan = useRef(false);
    const LoadData = async () => {
        try {

            if (gridData.length > 0) return;

            let stockPartList = [];

            
            // Call Api labour items
            const response = await getResponse('LabourHours/GetLabourItems', {});
            //// Loop on the resuit of Labour items
            setLoadItemLabour([]);

            //// Set the item values
            response.map((value,index)=> {
                setGridData((gridData) => {  
                    var newgridData = gridData;
                    newgridData.push({
                        labId:value["labId"],
                        labStockCode:value["labStockCode"],
                        labPos:value["labPos"],
                        labourId:value["labourId"],
                        labourHour:value["labourHour"],
                        labDescription:value["labDescription"],
                        labTime:value["labTime"],
                        labCost:value["labCost"],
                        defQty:value["defQty"],
                        adjQty:value["adjQty"],
                        totalCost:value["totalCost"].toFixed(2),
                        totalTime:value["totalTime"],
                        appliedMargin: currMarginPercent,
                        totalPrice: ( parseFloat(value["totalCost"]).toFixed(2) / ((100 - parseInt(currMarginPercent.toString().replace("%",""))) / 100) ).toFixed(2), //computation for total price
                        stId: "",
                        rowId: "",
                        assemblyId: "",
                        // futureCost: parseFloat((Number(value["labCost"]) / Number(value["labTime"])) * 60).toFixed(2),
                        // futActCost: parseFloat((Number(value["labCost"]) / Number(value["labTime"])) * 60).toFixed(2),
                        // futRealCost: parseFloat((Number(value["labCost"]) / Number(value["labTime"])) * 60).toFixed(2),
                        
                    });
                    return newgridData;
                });
                setLoadItemLabour(gridSetup.gridData);


            });


            itemList.map((i)=> {
                if (i?.stStockCode.length > 0 && (i?.rowType !== "A" && i?.rowType !== "LN")) stockPartList.push(i.stStockCode);
                
            })

             // Call Api labour items
            let responseStock = await postResponse('LabourHours/GetSpecificStockPart', stockPartList);
      
            let stockcount = Number(responseStock.length);
            let data = [];

            if (stockcount > 0) {
               
                gridSetup.gridData.map((itm)=>{
                    responseStock.map((r)=> {
                        
                        //seperate calculation per line during auto load labour items 
                        if (itm.labStockCode == r.ltpLtPart) {
                            itm.adjQty = 1;
                            itm.stId = "";
                            itm.defQty = 1; // need to confirm if its default as 1
                        }
                        
                        if(editLabourItems.length > 0) { //update listing if on edit mode
                            let checkQty = editLabourItems.filter((f)=>f.stStockCode === itm.labStockCode);
                            if(checkQty.length > 0) {
                                itm.adjQty = Number(checkQty[0].qty);
                                itm.rowId = checkQty[0].rowId;
                            } else {
                                itm.adjQty = "";
                            }
                        }

                        itm.totalCost = parseFloat(Number(itm.adjQty) *  Number(itm.labCost)).toFixed(2);
                        itm.totalTime = parseFloat(Number(itm.adjQty) *  Number(itm.labTime)).toFixed(0);


                    });
                    //setGridData(data);
                    
                })

                LoadLabourHoursCategory();
                setDetectQtyChanged(!detectQtyChanged);
            } 
            else {
                gridSetup.gridData.map((itm)=>{
                    if (editLabourItems) {
                        if(editLabourItems.length > 0) { //update listing if on edit mode
                            let checkQty = editLabourItems.filter((f)=>f.stStockCode === itm.labStockCode);
                            if(checkQty.length > 0) {
                                itm.adjQty = Number(checkQty[0].qty);
                                itm.rowId = checkQty[0].rowId;
                            } else {
                                itm.adjQty = "";
                            }
                        }
                    }
                    
                    itm.totalCost = parseFloat(Number(itm.adjQty) *  Number(itm.labCost)).toFixed(2);
                    itm.totalTime = parseFloat(Number(itm.adjQty) *  Number(itm.labTime)).toFixed(0);

                    
                })
                setDetectQtyChanged(!detectQtyChanged);
            }

        } catch (error) {
          // HTTP error handling
        if (error.response && error.response.status === 404) {
            console.log(error || "Error occurred while fetching Labour items.");
          } else {
            console.log(error);
          }
        }        
    }

    const GetMargin = async () =>{
        const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });
        if(data) {
            let margin = data.map((item) => item.lowerMargine);
            setMarginPercent(margin);
            setCurrMarginPercent(margin);
        }
    }
    
    const initialGroup = [{ field: "labourHour" }];

    const initialSort = [{ field: "labPos", dir: "asc" }];

    const gridSetup = {
        gridData: gridData,
        columns: [
            { field: "labId", title: "LT ID", show: false, editable: false },
            { field: "labStockCode", title: "LT StockCode", show: false, editable: false },
            { field: "labPos", title: "LT Pos", show: false, editable: false },
            { field: "labourId", title: "Labour Id", show: false, editable: false },
            { field: "labourHour", title: "Labour Hour", show: false, editable: false },
            { field: "labDescription", title: "Description", show: true, editable: false, minWith:500 },
            { field: "labTime", title: "Time(min)", show: true, editable: false },
            { field: "labCost", title: "CostEA", show: true, editable: false },
            { field: "defQty", title: "Def Qty", show: true, editable: false },
            { field: "adjQty", title: "Adj Qty", show: true, editor: "numeric",editable:true },
            { field: "totalCost", title: "Total Cost",show: true, editable: false },
            { field: "totalTime", title: "Total Time", show: true, editable: false },
            { field: "appliedMargin", title: "Margin", show: false, editable: false },
            { field: "totalPrice", title: "Total Price", show: false, editable: false },
            { field: "stId", title: "stId", show: false, editable: false }, //// used to identify specific item for editing in quotation. Usually we can have multiple labours for different items
            { field: "rowId", title: "rowId", show: false, editable: false }, //// used to identify specific item for editing in quotation. Usually we can have multiple labours for different items
            { field: "futureCost", title: "Future Cost", show: false, editable: false },
            { field: "futActCost", title: "Fut. Act Cost", show: false, editable: false },
            { field: "futRealCost", title: "fut. Real cost", show: false, editable: false },
        ]
    }

    const gridSetupSelected = {
        data: gridData,
        columns: [
            { field: "labId", title: "LT ID", show: false, editable: false },
            { field: "labStockCode", title: "LT StockCode", show: false, editable: false },
            { field: "labPos", title: "LT Pos", show: false, editable: false },
            { field: "labourId", title: "Labour Id", show: false, editable: false },
            { field: "labourHour", title: "Labour Hour", show: false, editable: false },
            { field: "labDescription", title: "Description", show: true, editable: false, minWith:500 },
            { field: "labTime", title: "Time(min)", show: true, editable: false },
            { field: "labCost", title: "CostEA", show: true, editable: false },
            { field: "defQty", title: "Def Qty", show: true, editable: false },
            { field: "adjQty", title: "Adj Qty", show: true,editable:false },
            { field: "totalCost", title: "Total Cost" ,show: false, editable: false },
            { field: "totalTime", title: "Total Time", show: true, editable: false },
            { field: "appliedMargin", title: "Margin", show: false, editable: false },
            { field: "totalPrice", title: "Total Price", show: false, editable: false },
            { field: "stId", title: "stId", show: false, editable: false }, //// used to identify specific item for editing in quotation. Usually we can have multiple labours for different items
            { field: "rowId", title: "rowId", show: false, editable: false }, //// used to identify specific item for editing in quotation. Usually we can have multiple labours for different items
            { field: "futureCost", title: "Future Cost", show: false, editable: false },
            { field: "futActCost", title: "Fut. Act Cost", show: false, editable: false },
            { field: "futRealCost", title: "fut. Real cost", show: false, editable: false },
        ]
    }
    
    const result = process(loadItemLabour, {
        filter: selectedLabour == "" || selectedLabour == "Labour Hours" ? null : { field: "labourHour", operator: "eq", value: selectedLabour },
        sort: initialSort,
        group: initialGroup,
    });

    const resultSelected = process(loadItemLabour, {
        filter: activeTab == 1 ? { field: "adjQty", operator: "gt", value: 0 } : null,
        sort: initialSort,
        group: initialGroup,
    });

    const setWidth = (minWidth) => {
        minWidth === undefined ? (minWidth = 0) : null;
        let width = applyMinWidth
        ? minWidth
        : minWidth + (gridCurrent - minGridWidth.current) / gridSetup.columns.length;
        width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
        return width;
    };

    const setWidthSelected = (minWidth) => {
        minWidth === undefined ? (minWidth = 0) : null;
        let width = applyMinWidthSelected
        ? minWidth
        : minWidth + (gridCurrentSelected - minGridWidthSelected.current) / gridSetupSelected.columns.length;
        width < COLUMN_MIN_Selected ? width : (width -= ADJUST_PADDING_Selected);
        return width;
    };

    const [currLabourCostName,setCurrLabourCostName] = useState("ASSEMBLY")

    const addToQuote = () => {
        setIsQuoteSaved(false);
        setItemAssemblyList(loadItemLabour.filter((f)=>f.adjQty > 0))
        setCurrentMargin(marginPercent);
        setLabourCostName(currLabourCostName);
        setCurrtotalCost(totalCost);
        setCurrTotalPrice(totalPrice);
        setLabourFormVisible(false);
        setShowCancelMsg(false);
        
    }

    const onChangeCostName = (e) =>{
        setCurrLabourCostName(e.target.value);
    }
      
    return (
        <React.Fragment>
            {isLoading && <Spinner />}

            {!isLoading ? (
                <>
                <Form
                    //onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                        <> 
                            <div className="d-flex flex-row mb-3 ip_labour_container">
                                <div className="d-flex w-75 align-items-center">
                                    <span className="k-whitespace-pre me-1">Labour Cost Name : </span>
                                    <Input
                                        className=""
                                        placeholder="Labour Cost Name"
                                        value={currLabourCostName}
                                        id="idLabourCostName"
                                        onChange={onChangeCostName}
                                    />
                                </div>
                                <div className="d-flex w-25 align-items-center ms-3">
                                    <span className="k-whitespace-pre me-1">Margin : </span>
                                    <Input
                                        type="number"
                                        min={0}
                                        value={marginPercent.toString().replace("%","")}
                                        className="k-textbox-xs"
                                        onChange={onChangeCurrMargin}
                                        onClick={()=>{
                                            setpplyBtnDisabled(true);
                                        }}
                                    />
                                    <span className="align-items-center ms-1"> % </span>
                                </div>
                            </div>
                            <div className="mb-2">
                                <Splitter panes={panes} onChange={onChange} style={{
                                    height: 500}}>
                                    <div className="pane-content mt-3 mb-3">
                                        <TreeView
                                            data={processTreeViewItems(labourTree, {
                                                select: select,
                                                check: check,
                                                expand: expand
                                            })} 
                                                onExpandChange={onExpandChange}
                                                onItemClick={onItemClick}
                                                onCheckChange={onCheckChange}
                                        />
                                    </div>
                                    <div className="pane-content labour-wrap-grid">
                                        <div>
                                            <FormElement style={{ Width: "50%", height: "auto" }}>
                                                <TabStrip selected={activeTab} onSelect={(e) => {
                                                        setActiveTab(e.selected);
                                                    }}>
                                                    <TabStripTab title="Available Items"  height="280px">
                                                        <Grid
                                                            // data={result}
                                                            data={(selectedLabour == "" || selectedLabour == "Labour Hours" ? groupBy(loadItemLabour,initialGroup) : result)}
                                                            ref={gridRef}
                                                            sortable={true}
                                                            groupable={true}
                                                            group={initialGroup}
                                                            className="labour-base-grid"
                                                            navigatable={true}
                                                        >
                                                            {
                                                                gridSetup.columns.map((column, index) => {
                                                                    
                                                                    if(column.show && !column.editable) {
                                                                        return  <Column key={index} field={column.field} title={column.title} width={column.field == "labDescription" ? setWidth(column.minWith) : ""} />;
                                                                    } else if (column.show && column.editable) {
                                                                        return  <Column key={index} title={column.title} 
                                                                        cell={(props) => {
                                                                            return (
                                                                                <EditableLabourCellNumeric 
                                                                                    {...props}
                                                                                    field={column.field}
                                                                                    editor="numeric"// (e)=>{refreshGrid(e);}
                                                                                    onChange={
                                                                                        column.field === "adjQty"
                                                                                        ? (value) => handleItemChange(value, props.dataItem)
                                                                                        : column.onChange

                                                                                    }
                                                                                />
                                                                            );
                                                                        }} />
                                                                    }

                      
                                                                })
                                                            }
                                                        </Grid>
                                                    </TabStripTab>

                                                    <TabStripTab title="Selected Items">
                                                        <Grid
                                                            data={resultSelected}
                                                            ref={gridRefSelected}
                                                            sortable={true}
                                                            groupable={true}
                                                            group={initialGroup}
                                                            className="labour-base-grid"
                                                        >
                                                            {
                                                                gridSetupSelected.columns.map((column, index) => {
                                                                    if(column.show ) return  <Column key={index} field={column.field} title={column.title} width={column.field == "labDescription" ? setWidthSelected(column.minWith) : ""} />;
                                                                })
                                                            }
                                                        </Grid>
                                                    </TabStripTab>
                                                </TabStrip>
                                            </FormElement>
                                        </div>
                                        <div className="labour-totalfooter">
                                            <span className="ms-3 float-start">Total time (approx) : <span className="value">{totalHrs} Hr(s).</span></span>
                                            <span className="me-3 float-end">
                                                <span className="ms-5">Margin : <span className="value">{currMarginPercent}</span>%</span>
                                                <span className="ms-5">Total Cost : <span className="value">{Number(totalCost)?.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2})}</span></span>
                                                <span className="ms-5">Total Price : <span className="value">{Number(totalPrice)?.toLocaleString("en-US", {style:"currency", currency:"USD", minimumFractionDigits: 2})}</span></span>
                                            </span>
                                        </div>
                                    </div>
                                </Splitter>
                                
                                <div className="pane-content mt-2 d-flex align-content-around justify-content-between">
                                    <div>
                                        <Button className="px-4 me-2 float-start"
                                                onClick={resetData}
                                            >
                                            Reset
                                        </Button>
                                    </div>
                                    <div>                                                           
                                        <Button className="px-4 float-end"
                                            onClick={closingForm}
                                        >
                                            Cancel
                                        </Button>
                                        <Button className="px-4 me-2 float-end ip-button-general"
                                            onClick={addToQuote}
                                        >
                                            Done
                                        </Button>
                                        <Button className="px-4 me-2 float-end ip-button-general"
                                                onClick={calculatetotals}
                                                disabled={applyBtnDisabled}
                                            >
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            
                            </div>

                        </>
                       
                    )}
                />
                {showCancelMsg && (
                    <Dialog title={"Confirm to submit"} onClose={closeForm}>
                        <p
                            style={{
                                margin: "30px",
                                textAlign: "center",
                            }}>
                            Do you want to save changes you made to the 'Labour hours'?
                        </p>
                        <DialogActionsBar>
                            <div className="d-flex justify-content-center py-3 px-2">                                                                                                                                           
                                <Button className="px-5 me-2 ip-button-positive" onClick={addToQuote}>
                                    Yes
                                </Button>
                                <Button className="px-5 me-2 ip-button-negative" onClick={closeForm}>
                                    No
                                </Button>
                                <Button className="px-5" onClick={toggleDialog}>
                                    Cancel
                                </Button>        
                            </div>
                        </DialogActionsBar>
                    </Dialog>
                )}
                </>
            ) : (
                <></>
            )}
        </React.Fragment>
    );
};



export default LabourDataGrid;
