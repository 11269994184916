import { React } from "react";

const OptionsPage = () => {
    return (
        <>
            <div className="options-container">
                <div className="w-100">
                    <div className="d-flex justify-content-center h-100 align-items-center">
                        <div className="px-2">
                            <button className="btn btn-primary">Add</button>
                        </div>
                        <div className="px-2">
                            <button className="btn btn-primary">Add</button>
                        </div>
                        <div className="px-2">
                            <button className="btn btn-primary">Add</button>
                        </div>
                        <div className="px-2">
                            <button className="btn btn-primary">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OptionsPage;
