export function getUserPrefColsGridModel(data) {
    return {
        userId : data?.userId ? data.userId : "00000000-0000-0000-0000-000000000000",
        module : data?.module ? data.module : "",
        gridMenusFor: data?.gridMenusFor ? data.gridMenusFor : "",
        userPrefColumns : data?.userPrefColumns ? data.userPrefColumns : [],
        createdOn: !data?.createdOn ? new Date() : data?.createdOn,
        modifiedOn: data?.modifiedOn ? data?.modifiedOn : new Date(),
        timeZone : data?.timeZone ? data.timeZone : ""
    }
}