import React, { useState, useEffect, useRef, useContext } from "react";
import "../../styles/CustomerEditForm.css";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { Input, TextArea, TextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Hint } from "@progress/kendo-react-labels";
import { ContactEditForm, contactDetails } from "./ContactEditForm";
import { getResponseWithoutParam } from "../../Services/Common/Get";
import { putResponse } from "../../Services/Common/Put";
// putResponse
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { toast, Slide } from "react-toastify";
import Spinner from "../Common/Spinner";
import { postResponse } from "../../Services/Common/Post";
import { confirmAlert } from "react-confirm-alert"; // Import
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { event } from "jquery";

const CustomerEditForm = ({
    data,
    updateGridData,
    toggleEditForm,
    selectedCustomer,
    setSelectedCustomer,
    setCustomerEditFormVisible,
    customerEditFormVisible,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    //const [formData, setFormData] = useState(customer);
    const [confirmAlertVisible, setconfirmAlertVisible] = useState(false);
    const [contactEditFormVisible, setContactEditFormVisible] = useState(false);
    const [contactData, setContactData] = useState(null);
    const [priceList, setPriceList] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const bool = useRef(false);

    const [customerName, setCustomerName] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerOwner, setCustomerOwner] = useState("");
    const [customerContact, setCustomerContact] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerComment, setCustomerComment] = useState("");

    const [detailsAlias, setDetailAlias] = useState("");
    const [detailsJobTitle, setDetailsJobTitle] = useState("");
    const [detailsMobile, setDetailsMobile] = useState("");
    const [detailsEmail, setDetailsEmail] = useState("");
    const [detailsDebtorType, setDetailsDebtorType] = useState("");
    const [detailsPriceCategory, setDetailsPriceCategory] = useState("");
    const [emailError, setEmailError] = useState("");

    const [detailsCusAddress, setDetailsCusAddress] = useState("");
    const [detailsSuburb, setDetailsSuburb] = useState("");
    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
    const commentsRef = useRef(null);
    const aliasRef = useRef(null);
    const customerRef = useRef(null);
    const contactRef = useRef(null);
    // const contactemailtRef = useRef(null);
    const [visible, setVisible] = useState(false);

    const { quoteDetails, updateQuoteDetailsForId } = useQuoteDetails();

    const toggleDialog = () => {
        setVisible(!visible);
    };

    const max = 25000;
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const emailValidator = (value) => (emailRegex.test(value) ? "" : "Please enter a valid email.");
    const EmailInput = (fieldRenderProps) => {
        const { validationMessage, visited, ...others } = fieldRenderProps;
        return (
            <div>
                <Input {...others} />
                {visited && validationMessage && <Error>{validationMessage}</Error>}
            </div>
        );
    };
    useEffect(() => {
        console.log("selected contact :", selectedContact);
        if (selectedContact !== null) {
            setCustomerContact(selectedContact?.contactFullName);
            setCustomerEmail(selectedContact?.contactEmail);
            console.log("set selected customer contact full name :", {
                ...selectedCustomer,
                contactFullName: selectedContact?.contactFullName,
            });
            setSelectedCustomer({ ...selectedCustomer, contactFullName: selectedContact?.contactFullName });
        }
    }, [selectedContact]);

    useEffect(() => {
        setIsLoading(true);

        if (selectedCustomer != null || selectedCustomer != undefined || selectedCustomer != "") {
            commentsRef.current = selectedCustomer.comments;
            customerRef.current = selectedCustomer.customerName;
            contactRef.current = selectedCustomer.contactFullName;
            aliasRef.current = selectedCustomer.alias;
            console.log("aliasRef.current = selectedCustomer.alias;", (aliasRef.current = selectedCustomer.alias));
            //   contactemailtRef.current = selectedCustomer.email;
        }

        if (data.isOwner) {
            setActiveTab(1);
        } else {
            setActiveTab(0);
        }

        getResponseWithoutParam(
            "Customer/getfilteredcustomerdetails?customerId=" +
            data.customerId +
            "&currentUserID=" +
            userId +
            "&customerNumber=" +
            data.customerNumber
        )
            .then((res) => {
                if (res) {
                    const r = res?.customerOwner;
                    const con = res?.custContact;

                    if (res?.userSpecificCustomer == null) {
                        const c = res?.customer;
                        //general tab
                        setCustomer(res.customer);
                        setCustomerName(c?.customerName);
                        setCustomerNumber(c?.customerNumber);
                        setCustomerPhone(c?.phone);
                        setCustomerOwner(c?.owner);

                        setCustomerComment(selectedCustomer?.comments ? selectedCustomer?.comments : r?.comments);

                        setCustomerContact(c?.contactFullName);
                        setCustomerEmail(c?.contactEmail);

                        // if (res?.custContact !== null) {
                        //     setCustomerContact(con?.firstName + " " + con?.lastName);
                        //     setCustomerEmail(con?.email);
                        // } else {
                        //     // setCustomerContact("");
                        //     // setCustomerEmail("");
                        //     setCustomerContact(r?.contactFullName);
                        //     setCustomerEmail(r?.contactEmail);
                        // }

                        //customer tab
                        setDetailsJobTitle(r?.jobTitle);
                        setDetailsMobile(r?.mobile);
                        setDetailsEmail(r?.email);
                        setDetailsDebtorType(c?.debtorType);
                        setDetailsPriceCategory(c?.priceCategory);
                        setDetailsCusAddress(c?.addressLine1 + c?.addressLine2 + c?.addressLine3);
                        setDetailsSuburb(c?.suburb + ", " + c?.state + " " + c?.postCode);
                        setDetailAlias(c?.owner);

                        const updatedPriceList = [
                            { Name: "Level60  Price", StartDate: res?.level60?.startDate || "", EndDate: res?.level60?.endDate || "" },
                            // { Name: "Level55  Price", StartDate: res?.level55?.startDate || "", EndDate: res?.level55?.endDate || "" },
                            { Name: "Level50  Price", StartDate: res?.level50?.startDate || "", EndDate: res?.level50?.endDate || "" },
                          //20/12/2023 changed by sachini -add L35 but same as L55 but this use for getting NettEA and changed price level order
                            { Name: "Level35  Price", StartDate: res?.level55?.startDate || "", EndDate: res?.level55?.endDate || "" },
                            { Name: "Level20  Price", StartDate: res?.level20?.startDate || "", EndDate: res?.level20?.endDate || "" },
                        ];

                        setPriceList(updatedPriceList);
                    } else {
                        const c = res?.userSpecificCustomer;

                        //general tab
                        setCustomer(res?.userSpecificCustomer);
                        setCustomerName(c?.customerName);
                        setCustomerNumber(c?.customerNumber);
                        setCustomerPhone(c?.phone);
                        setCustomerOwner(c?.owner);

                        setCustomerContact(c?.contactFullName);
                        setCustomerEmail(c?.contactEmail);

                        // if (res?.custContact !== null) {
                        //     // setCustomerContact(con?.firstName + " " + con?.lastName);
                        //     // setCustomerEmail(con?.email);
                        //     setCustomerContact(c?.contactFullName);
                        //     setCustomerEmail(c?.contactEmail);
                        // } else {
                        //     setCustomerContact(c?.contactFullName);
                        //     setCustomerEmail(c?.contactEmail);
                        // }

                        setCustomerComment(selectedCustomer?.comments ? selectedCustomer?.comments : r?.comments);

                        //customer tab
                        setDetailsJobTitle(c?.ownerjobtitle);
                        setDetailsMobile(c?.ownermobile);
                        setDetailsEmail(c?.owneremail);
                        setDetailsDebtorType(c?.debtorType);
                        setDetailsPriceCategory(c?.priceCategory);
                        setDetailsCusAddress(c?.addressLine1 + c?.addressLine2 + c?.addressLine3);
                        setDetailsSuburb(c?.suburb + ", " + c?.state + " " + c?.postCode);
                        setDetailAlias(c?.alias);

                        const updatedPriceList = [

                            { Name: "Level60  Price", StartDate: res?.level60?.startDate || "", EndDate: res?.level60?.endDate || "" },
                           // { Name: "Level55  Price", StartDate: res?.level55?.startDate || "", EndDate: res?.level55?.endDate || "" },
                            { Name: "Level50  Price", StartDate: res?.level50?.startDate || "", EndDate: res?.level50?.endDate || "" },
                           //20/12/2023 changed by sachini -add 35 but same as L55 but this use for getting NettEA and changed price level order
                            { Name: "Level35  Price", StartDate: res?.level55?.startDate || "", EndDate: res?.level55?.endDate || "" },
                            { Name: "Level20  Price", StartDate: res?.level20?.startDate || "", EndDate: res?.level20?.endDate || "" },
                        ];

                        setPriceList(updatedPriceList);
                    }
                } else {
                }

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error("Connection error. Please try agin later.", {
                    position: "top-center",
                    transition: Slide,
                });        
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
      //  updateGridData(formData);
        const data = {
            customerName: customerName,
        };
    };

    const handleFieldChange = (field, value) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    };
    const closeCustomerForm = (e) => {
        setCustomerEditFormVisible(!customerEditFormVisible);
    };

    const closeConfirmAlert = (e) => {
        setconfirmAlertVisible(false);
    };

    const closeContactForm = () => {
        setContactEditFormVisible(false);
    };

    const handleContactSubmit = async () => {
        try {
            if (contactDetails.AcctNo != "") {
                setIsLoading(true);

                const res = await putResponse(`Contact/UpdateContact?currentuserId=${data.homeAccountId}`, {
                    acctNo: contactDetails.AcctNo,
                    UserId: data.homeAccountId,
                    LastName: contactDetails.LastName,
                    FirstName: contactDetails.FirstName,
                    Email: contactDetails.Email,
                    Phone: contactDetails.Phone,
                    Enabled: true,
                    IsPrimary: true,
                    IsDeleted: false,
                });

                if (res) {
                    toggleCotactForm();
                } else {
                    toast.error("Something went wrong. Please try again later.", {
                        position: "top-center",
                        transition: Slide,
                    });            
                }
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toggleCotactForm();
            }
        } catch (error) {
            console.error("Error fetching contact data:", error);
        }
    };

    const toggleCotactForm = () => {
        setContactEditFormVisible(!contactEditFormVisible);
    };

    const handleOnClickContact = (customerNumber, bool1) => {
        toggleCotactForm();
    };

    const saveEditedCustomer = () => {
        setIsLoading(true);
        if (emailError) {
            setIsLoading(false);
            toast.error("Invalid email address. Please enter a valid email.", {
                position: "top-center",
                transition: Slide,
            });    
            return;
        }
        if (!customerName.trim()) {
            // Check if customerName is empty or only contains whitespace
            setIsLoading(false);
            toast.error("No company name is entered. Please enter a company name in the company field.", {
                position: "top-center",
                transition: Slide,
            });    
            return;
        }
        // Perform email validation using the emailValidator function
        //     const isValidEmail = emailValidator(detailsEmail);
        //     console.log('emaillll:',emailValidator(validateEmail))
        // console.log('detailsEmail :',isValidEmail)
        //     if (!emailValidator(detailsEmail)) {
        //         setIsLoading(false);
        //         toast.error("Invalid email address. Please enter a valid email.");
        //         return;
        //     }

        const editedCustomer = {
            customerId: customer?.customerId,
            customerName: customerName,
            customerNumber: customerNumber,
            addressName: customer?.addressName,
            addressLine1: customer?.addressLine1,
            addressLine2: customer?.addressLine2,
            addressLine3: customer?.addressLine3,
            suburb: customer?.suburb,
            state: customer?.state,
            country: customer?.country,
            postCode: customer?.postCode,
            phone: customerPhone,
            fax: customer?.fax,
            contactFname: selectedContact?.FirstName, //customerContact.split(" ")[0],
            contactLname: selectedContact?.LastName, //customerContact.split(" ")[1],
            contactFullname: customerContact,
            contactEmail: customerEmail,
            owner: customerOwner,
            ownerDomainName: customer?.ownerDomainName,
            debtorType: detailsDebtorType,
            priceCategory: detailsPriceCategory,
            enabled: customer?.enabled,
            createdOn: customer?.created,
            modifiedOn: new Date(),
            custCategory: customer?.custCategory,
            ctId: customer?.ctId,
            crmacctId: customer?.crmacctId,
            active: customer?.active,
            crmuniqueAcctId: customer?.crmuniqueAcctId,
            custClassification: customer?.custClassification,
            displayName: customer?.displayName,
            allowUpdate: 0,
            createdBy: user?.homeAccountId,
            modifedBy: user?.homeAccountId,

            //change here when the table names are changed
            ownerjobtitle: detailsJobTitle,
            ownermobile: detailsMobile,
            owneremail: detailsEmail,

            comments: customerComment,
            alias: detailsAlias,
        };

        postResponse(`Customer/addorupdateuserspecificcustomer`, editedCustomer)
            .then((res) => {
                if (res) {
                    setIsLoading(false);
                    toggleEditForm();
                } else {
                    setIsLoading(false);
                    toast.error("Something went wrong. Please try again later.", {
                        position: "top-center",
                        transition: Slide,
                    });            
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error : ", error);
                toast.error("Connection error. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });        
            });
    };

    // const handleCommentInput = (e) => {
    //     //setCustomerComment(e.target.value);
    //     //setSelectedCustomer({ ...selectedCustomer, comments: e.target.value });
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         // If Enter key is pressed, add a newline character "\n" to the text
    //         const newComment = e.target.value + '\n';
    //         setCustomerComment(newComment);
    //         setSelectedCustomer({ ...selectedCustomer, comments: newComment });
    //       } else {
    //         // If any other key is pressed, update normally
    //         setCustomerComment(e.target.value);
    //         setSelectedCustomer({ ...selectedCustomer, comments: e.target.value });
    //       }
    // };
    const handleCommentInput = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (e.target.value.trim() !== '') {
                // Only add a newline character if the input is not empty
                const newComment = customerComment + '\n';
                setCustomerComment(newComment);
                setSelectedCustomer({ ...selectedCustomer, comments: newComment });
            }
        } else {
            setCustomerComment(e.target.value);
            setSelectedCustomer({ ...selectedCustomer, comments: e.target.value });
        }
    };
    const handleAliasInput = (e) => {
        setDetailAlias(e.target.value);
        setSelectedCustomer({ ...selectedCustomer, alias: e.target.value });
    };

    const handleCustomerName = (e) => {
        setCustomerName(e.target.value);
        setSelectedCustomer({ ...selectedCustomer, customerName: e.target.value });
    };
    const handleContactName = (e) => {
        setCustomerContact(e.target.value);
        setSelectedCustomer({ ...selectedCustomer, contact: e.target.value });
    };
    const handleContactEmail = (e) => {
        setCustomerEmail(e.target.value);
        setSelectedCustomer({ ...selectedCustomer, email: e.target.value });
    };
    const validateEmail = (email) => {
        // Basic email validation using a regular expression
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setDetailsEmail(newEmail);

        if (!validateEmail(newEmail)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleA = (event) => {
        event.preventDefault();

        setSelectedCustomer({
            ...selectedCustomer,
            comments: commentsRef.current,
            customerName: customerRef.current,
            contactFullName: contactRef.current,
            alias: aliasRef.current,
            // contactEmail:contactemailtRef.current
        });
        toggleDialog();
        toggleEditForm();
    };

    return (
        <React.Fragment>
            {isLoading && <Spinner />}

            {!isLoading ? (
                <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                        <div>
                            <FormElement onKeyDown={handleKeyDown}>
                                <TabStrip selected={activeTab} onSelect={(e) => setActiveTab(e.selected)}>
                                    <TabStripTab title="General">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <div className="row">
                                                    <div className="mb-2">
                                                        <strong>Customer : </strong>
                                                        <hr />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 w-100">
                                                            <div className="mb-3">
                                                                <div className="form-group">
                                                                    <h6>Company:</h6>
                                                                    <TextBox
                                                                        name="customerName"
                                                                        label="customerName"
                                                                        id="customerName"
                                                                        component={Input}
                                                                        value={customerName}
                                                                        onChange={(e) => {
                                                                            //setCustomerName(e.target.value);
                                                                            handleCustomerName(e);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group">
                                                                <h6>Ref No:</h6>
                                                                <TextBox
                                                                    name="refNo"
                                                                    label="Ref No"
                                                                    id="refNo"
                                                                    component={Input}
                                                                    value={customerNumber}
                                                                    onChange={(e) => {
                                                                        setCustomerNumber(e.target.value);
                                                                    }}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group">
                                                                <h6>Phone:</h6>
                                                                <TextBox
                                                                    name="phone"
                                                                    label="phone"
                                                                    id="phone"
                                                                    type="text"
                                                                    value={customerPhone}
                                                                    onChange={(e) => {
                                                                        // const input = e.target.value;
                                                                        // const numericInput = input.replace(/[^0-9]/g, "").slice(0, 12);
                                                                        // // Format the numeric input as (XXX) XXXXXXXXXXXX
                                                                        // let formattedPhone = `(${numericInput.slice(
                                                                        //     0,
                                                                        //     3
                                                                        // )}) ${numericInput.slice(3, 6)} ${numericInput.slice(
                                                                        //     6,
                                                                        //     8
                                                                        // )} ${numericInput.slice(8, 10)} ${numericInput.slice(10)}`;
                                                                        // // setCustomerPhone(numericInput);

                                                                         setCustomerPhone(e.target.value)
                                                                        // setCustomerPhone(formattedPhone);
                                                                    }}
                                                                    placeholder="Enter only maximum 12 digits"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="form-group">
                                                                <h6>Owner:</h6>
                                                                <TextBox
                                                                    name="owner"
                                                                    label="Owner"
                                                                    id="owner"
                                                                    component={Input}
                                                                    value={customerOwner}
                                                                    onChange={(event) => {
                                                                        setCustomerOwner(event.target.value);
                                                                    }}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 px-md-4">
                                                <div className="mb-3">
                                                    <strong>Contact Details : </strong>
                                                    <hr />
                                                </div>
                                                <div className="mb-3">
                                                    <div className="form-group">
                                                        <h6>Contact:</h6>
                                                        <div className="row">
                                                            <div className="col-md-12 col-12 pe-0">
                                                                <TextBox
                                                                    name="contact"
                                                                    label="Contact"
                                                                    id="contact"
                                                                    component={Input}
                                                                    value={customerContact}
                                                                    onChange={(event) => {
                                                                        setCustomerContact(event.target.value);
                                                                    }}
                                                                    className="cef-custom-input"                                                   
                                                                    readOnly={true}
                                                                />
                                                                <button
                                                                    className="cef-custom-button"
                                                                    onClick={() =>
                                                                        handleOnClickContact(data.customerNumber, (bool.current = true))
                                                                    }>
                                                                    <i className="ri-pencil-line cef-custom-button-icon"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="col-md-12 col-12">
                                                        <div className="form-group">
                                                            <h6>Email:</h6>
                                                            <TextBox
                                                                name="email"
                                                                label="Email"
                                                                id="email"
                                                                component={Input}
                                                                value={customerEmail}
                                                                onChange={(event) => setCustomerEmail(event.target.value)}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <strong>Comments: </strong>
                                                    <hr />
                                                </div>
                                                <div className="mb-3">
                                                    <div className="form-group">
                                                        <TextArea
                                                            maxLength={max}
                                                            name="comment"
                                                            label="comment"
                                                            id="comment"
                                                            component={Input}
                                                            rows={4}
                                                            value={customerComment}
                                                            onKeyDown={(e) => handleCommentInput(e)}
                                                            onChange={(e) => setCustomerComment(e.target.value)}
                                                          //  onChange={(e) => handleCommentInput(e)}
                                                        />
                                                        {customerComment && (
                                                            <Hint direction="end">
                                                                {customerComment.length} / {max}
                                                            </Hint>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />                                   
                                    </TabStripTab>

                                    <TabStripTab title="Detail">
                                        <div className="row form-group">
                                            <div className="col-md-6 col-12">
                                                <div className="mb-3">
                                                    <strong>Account Owner: </strong>
                                                    <hr />
                                                </div>
                                                <div className="mb-3">
                                                    <h6>Alias:</h6>
                                                    <TextBox
                                                        name="alias"
                                                        label="alias"
                                                        id="alias"
                                                        component={Input}
                                                        value={detailsAlias}
                                                        onChange={(e) => handleAliasInput(e)}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <h6>Job Title:</h6>
                                                    <TextBox
                                                        name="jobTitle"
                                                        label="jobTitle"
                                                        id="jobTitle"
                                                        component={Input}
                                                        value={detailsJobTitle}
                                                        onChange={(event) => {
                                                            setDetailsJobTitle(event.target.value);
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <h6>Mobile:</h6>
                                                    <TextBox
                                                        name="mobile"
                                                        label="mobile"
                                                        id="mobile"
                                                        component={Input}
                                                        value={detailsMobile}
                                                        mask="(999) 000-00-00-00"
                                                        onChange={(e) => {
                                                            const input = e.target.value;
                                                            // const numericInput = input.replace(/[^0-9]/g, "").slice(0, 12);

                                                            // let formattedPhone = `(${numericInput.slice(0, 3)}) ${numericInput.slice(
                                                            //     3,
                                                            //     6
                                                            // )} ${numericInput.slice(6, 8)} ${numericInput.slice(
                                                            //     8,
                                                            //     10
                                                            // )} ${numericInput.slice(10)}`;

                                                            setDetailsMobile(input);
                                                        }}
                                                        placeholder="Enter only maximum 12 digits"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <h6>Email:</h6>
                                                    <TextBox
                                                        name={"email"}
                                                        type={"email"}
                                                        component={EmailInput}
                                                        value={detailsEmail}
                                                        validator={emailValidator}
                                                        onChange={handleEmailChange}
                                                    />
                                                    {/* {/* <TextBox
                                                        name="email"
                                                        label="email"
                                                        id="email"
                                                        component={Input}
                                                        value={detailsEmail}
                                                        onChange={handleEmailChange}
                                                    /> */}
                                                    {emailError && <div style={{ color: "red" }}>{emailError}</div>}
                                                </div>
                                                <div className="mt-4 mb-3">
                                                    <strong>Customer Address: </strong>
                                                    <hr />
                                                </div>
                                                <div className="mb-3">
                                                    <h6>Address:</h6>
                                                    <TextBox
                                                        name="address"
                                                        label="Address"
                                                        id="address"
                                                        component={Input}
                                                        value={detailsCusAddress}
                                                        onChange={(event) => setDetailsCusAddress(event.target.value)}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <h6>Suburb:</h6>
                                                    <TextBox
                                                        name="suburb"
                                                        label="Suburb"
                                                        id="suburb"
                                                        component={Input}
                                                        value={detailsSuburb}
                                                        onChange={(event) => setDetailsSuburb(event.target.value)}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="mb-3">
                                                    <strong>Price Info: </strong>
                                                    <hr />
                                                </div>
                                                <div className="mb-3">
                                                    <h6>Debtor Type:</h6>
                                                    <TextBox
                                                        name="debtortype"
                                                        label="Debtor Type"
                                                        id="debtortype"
                                                        component={Input}
                                                        value={detailsDebtorType}
                                                        onChange={(event) => {
                                                            setDetailsDebtorType(event.target.value);
                                                        }}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <h6>Price Category:</h6>
                                                    <TextBox
                                                        name="pricecategory"
                                                        label="Price Category"
                                                        id="pricecategory"
                                                        component={Input}
                                                        value={detailsPriceCategory}
                                                        onChange={(event) => {
                                                            setDetailsPriceCategory(event.target.value);
                                                        }}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <br />
                                                <strong> Price - List Dates:</strong>
                                                <hr />

                                                <Grid data={priceList}>
                                                    <Column field="Name" title="Name" />
                                                    <Column field="StartDate" title="Start Date" />
                                                    <Column field="EndDate" title="End Date" />
                                                </Grid>
                                            </div>
                                        </div>
                                        <br />
                                    </TabStripTab>
                                </TabStrip>
                            </FormElement>
                       
                            <DialogActionsBar>
                                <div className="d-flex justify-content-end ip-dialog-btnstatic-f2">                                
                                    <Button className="px-4 me-2 ip-button-general" onClick={saveEditedCustomer}>
                                        Done
                                    </Button>
                                    <Button className="px-4" onClick={toggleDialog}>
                                        Cancel
                                    </Button>
                                    {visible && (
                                        <Dialog title={"Confirm to submit"} onClose={toggleDialog}>
                                            <p
                                                style={{
                                                    margin: "30px",
                                                    textAlign: "center",
                                                }}>
                                                Do you want to save changes you made to the 'Customer'?
                                            </p>
                                            <DialogActionsBar>
                                                <div className="d-flex justify-content-center py-3 px-2">                                                
                                                    <Button className="px-5 me-2 ip-button-positive" onClick={saveEditedCustomer}>
                                                        Yes
                                                    </Button>
                                                    <Button
                                                        className="px-5 me-2 ip-button-negative"
                                                        onClick={(event) => handleA(event)}>
                                                        No
                                                    </Button>
                                                    <Button className="px-5" onClick={toggleDialog}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </DialogActionsBar>
                                        </Dialog>
                                    )}
                                </div>
                            </DialogActionsBar>
                            
                            {contactEditFormVisible && (
                                <Dialog
                                    className="ipd-dialog-in-dialog-container"
                                    title={`Contacts: ${customerName}`}
                                    onClose={() => setContactEditFormVisible(false)}>
                                    <ContactEditForm
                                        customerNumber={data.customerNumber}
                                        toggleCotactForm={toggleCotactForm}
                                        selectedContact={selectedContact}
                                        setSelectedContact={setSelectedContact}
                                        setContactEditFormVisible={setContactEditFormVisible}
                                        contactEditFormVisible={contactEditFormVisible}
                                    />
                                </Dialog>
                            )}
                        </div>
                    )}
                />
            ) : (
                <></>
            )}
        </React.Fragment>
    );
};

export default CustomerEditForm;
