import { GroupTypes } from "../../enums/GroupTypes";
import { GroupItemTypes } from "../../enums/GroupTypes";

export function isDbExtraAdded(items, groupId, subGroupId, rowType) {
    let isDbExtraAdded = false;

    switch (rowType) {
        case GroupTypes.GROUP:
            if (items && items.length > 0 && groupId !== null)
                isDbExtraAdded = items.some(
                    (item) => item.groupId === groupId && item.groupDbExtraId && item.groupDbExtraId !== ""
                );
            break;
        case GroupTypes.SUB_GROUP:
            if (items && items.length > 0 && groupId !== null && subGroupId !== null)
                isDbExtraAdded = items.some(
                    (item) =>
                        item.groupId === groupId &&
                        item.subGroupId === subGroupId &&
                        item.subGroupDbExtraId &&
                        item.subGroupDbExtraId !== ""
                );
            break;
        case GroupTypes.NON_GROUP:
            if (items && items.length > 0 && groupId !== null)
                isDbExtraAdded = items.some(
                    (item) => item.rowType === "DX" && item.nonGroupDbExtraId && item.nonGroupDbExtraId !== ""
                );
            break;
        default:
            break;
    }

    return isDbExtraAdded;
}

export function getDbExtraRowIndex(items, groupId, subGroupId, rowType) {
    let index = -1;

    switch (rowType) {
        case GroupTypes.GROUP:
            if (items && items.length > 0 && groupId !== null)
                index = items.findIndex((item) => {
                    return item.groupId === groupId && item.groupDbExtraId !== "" && item.subGroupId === "" && item.rowType === "DX";
                });
            break;
        case GroupTypes.SUB_GROUP:
            if (items && items.length > 0 && groupId !== null && subGroupId !== null)
                index = items.findIndex((item) => {
                    return item.groupId === groupId && item.subGroupId === subGroupId && item.subGroupDbExtraId !== "" && item.rowType === "DX";
                });
            break;
        case GroupTypes.NON_GROUP:
            if (items && items.length > 0 && groupId !== null)
                index = items.findIndex((item) => {
                    return item.nonGroupDbExtraId !== "" && item.groupId === groupId && item.rowType === "DX";
                });
            break;
        default:
            break;
    }

    return index;
}

export function getGroupedItemsWithoutDBExtra(items, currentItem) {
    return items.filter(
        (subItem) =>
            currentItem.groupId === subItem.groupId &&
            subItem.groupName !== GroupItemTypes.NON_GROUPED_ITEMS &&
            subItem.displayGroupName === "" &&
            subItem.stStockCode !== "" &&
            (!subItem.groupDbExtraId || (subItem.groupDbExtraId && subItem.groupDbExtraId === "")) &&
            (!subItem.subGroupDbExtraId || (subItem.subGroupDbExtraId && subItem.subGroupDbExtraId === "")) &&
            //newly added 18th october 2023
            (!subItem.nonGroupDbExtraId || (subItem.nonGroupDbExtraId && subItem.nonGroupDbExtraId === ""))
    );
}

export function getSubGroupedItemsWithoutDBExtra(items, currentItem) {
    return items.filter(
        (subItem) =>
            currentItem.groupId === subItem.groupId &&
            subItem.subGroupId !== "" &&
            currentItem.subGroupId === subItem.subGroupId &&
            subItem.displaySubGroupName === "" &&
            subItem.stStockCode !== "" &&
            (!subItem.subGroupDbExtraId || (subItem.subGroupDbExtraId && subItem.subGroupDbExtraId === "")) &&
            //newly added 18th october 2023
            (!subItem.groupDbExtraId || (subItem.groupDbExtraId && subItem.groupDbExtraId === "")) &&
            (!subItem.nonGroupDbExtraId || (subItem.nonGroupDbExtraId && subItem.nonGroupDbExtraId === ""))
    );
}

export function getNonGroupedItemsWithoutDBExtra(items, currentItem) {
    return items.filter(
        (subItem) =>
            currentItem.groupId === subItem.groupId &&
            subItem.groupName === GroupItemTypes.NON_GROUPED_ITEMS &&
            subItem.displaySubGroupName === "" &&
            subItem.stStockCode !== "" &&
            (!subItem.nonGroupDbExtraId || (subItem.nonGroupDbExtraId && subItem.nonGroupDbExtraId === "")) &&
            //newly added 18th october 2023
            (!subItem.subGroupDbExtraId || (subItem.subGroupDbExtraId && subItem.subGroupDbExtraId === "")) &&
            (!subItem.groupDbExtraId || (subItem.groupDbExtraId && subItem.groupDbExtraId === ""))
    );
}

export function getExistingGroupWiseDbExtraRow(items, groupId) {
    return items.filter((item) => item.groupDbExtraId && item.groupId === groupId);
}

export function getExistingNonGroupWiseDbExtraRow(items) {
    return items.filter((item) => item.nonGroupDbExtraId && item.nonGroupDbExtraId !== "");
}

export function getItemsWithoutDBExtra(items, rowType, groupId = null, subGroupId = null) {
    switch (rowType) {
        case GroupTypes.NON_GROUP:
            return items.filter(
                (item) =>
                    item.groupDbExtraId === "" &&
                    item.nonGroupDbExtraId === "" &&
                    item.subGroupDbExtraId === "" &&
                    item.groupId === groupId &&
                    item.stStockCode !== ""
            );
        case GroupTypes.GROUP:
            if (groupId !== null) {
                return items.filter(
                    (item) =>
                        item.groupDbExtraId === "" &&
                        item.nonGroupDbExtraId === "" &&
                        item.subGroupDbExtraId === "" &&
                        item.groupId === groupId &&
                        item.displayGroupName === "" &&
                        item.displaySubGroupName === "" &&
                        item.stStockCode !== ""
                );
            }
        case GroupTypes.SUB_GROUP:
            if (groupId !== null && subGroupId !== null) {
                return items.filter(
                    (item) =>
                        item.groupDbExtraId === "" &&
                        item.nonGroupDbExtraId === "" &&
                        item.subGroupDbExtraId === "" &&
                        item.displaySubGroupName === "" &&
                        item.subGroupId === subGroupId &&
                        item.groupId === groupId &&
                        item.stStockCode !== ""
                );
            }
        default:
            break;
    }
}

export function getExistingDbExtraRows(items, groupId, subGroupId, consumableId, rowType) {
    switch (rowType) {
        case GroupTypes.GROUP:
            if (groupId && consumableId) {
                return items.filter((item) => {
                    return item.groupId === groupId && item.groupDbExtraId === consumableId && item.subGroupId === "";
                });
            }
            return [];
        case GroupTypes.SUB_GROUP:
            if (groupId && subGroupId && consumableId) {
                return items.filter((item) => {
                    return (
                        item.groupId === groupId &&
                        item.subGroupId === subGroupId &&
                        item.subGroupDbExtraId === consumableId
                    );
                });
            }
        // return [];
        case GroupTypes.NON_GROUP:
            if (consumableId) {
                return items.filter((item) => {
                    return item.nonGroupDbExtraId === consumableId;
                });
            }
            return [];
        default:
            break;
    }
}

export function getExistingSubGroupWiseDbExtraRow(items, subGroupId) {
    return items.filter((item) => item.subGroupId === subGroupId && item.subGroupDbExtraId !== "");
}

export function getSubGroupItemsWithoutDBExtra(items, groupId, subGroupId) {
    return items.filter(
        (item) =>
            item.groupId === groupId &&
            item.subGroupId === subGroupId &&
            (item.groupDbExtraId === "" || typeof item.groupDbExtraId === "undefined") &&
            (item.subGroupDbExtraId === "" || typeof item.subGroupDbExtraId === "undefined") &&
            (item.nonGroupDbExtraId === "" || typeof item.nonGroupDbExtraId === "undefined") &&
            item.stStockCode !== ""
    );
}

export function getGroupedRowItemsWithoutDBExtra(items, groupId) {
    return items.filter(
        (item) =>
            item.groupId === groupId &&
            item.subGroupId === "" &&
            (item.groupDbExtraId === "" || typeof item.groupDbExtraId === "undefined") &&
            (item.subGroupDbExtraId === "" || typeof item.subGroupDbExtraId === "undefined") &&
            (item.nonGroupDbExtraId === "" || typeof item.nonGroupDbExtraId === "undefined") &&
            item.stStockCode !== ""
    );
}
