import React, { createContext, useContext, useState } from "react";

const ItemListContext = createContext();

export const useItemList = () => useContext(ItemListContext);

export const ItemListProvider = ({ children }) => {
    const [originalItems, setOriginalItems] = useState([]);

    const updateItemListForId = (id, data) => {
        setOriginalItems((prevItem) => ({
            ...prevItem,
            [id]: { ...prevItem[id], ...data },
        }));
    };

    return (
        <ItemListContext.Provider value={{   originalItems,  updateItemListForId,   setOriginalItems }}>
            {children}
        </ItemListContext.Provider>
    );
};
