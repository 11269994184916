import { React, useEffect, useState, useContext, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CustomerDataGrid from "../Qoutes/Customerdatagridview";
import ItemsMainDataGrid from "../Qoutes/QoutesItemdatagridview";
import "../../styles/LeftNavigationBar.scss";
import { putResponse } from "../../Services/Common/Put";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { toast, Slide } from "react-toastify";
import CustomerIdContext from "../../Contexts/CustomerIdContext";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { useQuoteDetails , updateQuoteDetailsForId } from "../../Contexts/QuoteDetailsProvider";
import { confirmAlert } from "react-confirm-alert";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { Button } from "@progress/kendo-react-buttons";
import { SystemSettingContext } from "../../Contexts/SystemSettingContext";
import authMatrixData from "../../auth-matrix.json";
import NewUnitDataGrid from "../NewUnits/NewUnitDataGrid";
import NewPriceListItemDataGrid from "../PriceLists/NewPriceListItemDataGrid";
import PriceListCustomerDataGrid from "../PriceLists/PriceListCustomerDataGrid";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useMarginAndPrice } from "../../Contexts/MarginAndPriceProvider";
import { QteCurGridViewTypeContext } from "../../Contexts/QteCurGridViewTypeContext";
import { QuoteContactTypes } from "../../enums/GroupTypes";
import ResetQuote from "../Qoutes/ResetQuote";
import { useResetQuote } from "../../Contexts/ResetProvider";
import { useAuthorizationContext } from "../Auth/useAuthorization";
/*UI*/
import { Row, Accordion, AccordionItem, Collapse } from "reactstrap";
import classnames from "classnames"; //for accordion classnames
import { Tooltip } from "react-tooltip";
import { getResponse } from "../../Services/Common/Get";
import { useTrigerSaveQuoteContext } from "../../Contexts/TrigerSaveQuoteContext";
//import { useQuoteContact } from "../../Contexts/QuoteContactProvider";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
import { useQuoteContact } from '../../Contexts/QuoteContactProvider';
import { Loadstatuscontext } from "../../Contexts/LoadStatus";
import { useStockCode } from "../../Contexts/StockCodeProvider";
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";

const SubNodePage = ({ localAccountId }) => {
    const { id } = useParams();
    const [dropdownValue, setDropdownValue] = useState();
    const [key, setKey] = useState();
    const [quoteNumber, setQuoteNumber] = useState("");
    const [createdOn, setCreatedOn] = useState("");
    const [modifiedOn, setModifiedOn] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [isEditable, setIsEditable] = useState(false);
    const [isNavigate, setIsNavigate] = useState(false);
    const [editableQuoteNumber, setEditableQuoteNumber] = useState("");
    const [fetchedQuoteIds, setFetchedQuoteIds] = useState(new Set());
    const isFirstMount = useRef(true);
    const { LoadStatus, SetLoadStatus } = useContext(Loadstatuscontext);
    //const [itemList, setItemList] = useState([]);

        const {
        quoteApprovalStatId, setQuoteApprovalStatId,
    } = useContext(QteApprovalNotificationContext);

    const { quotes, setQuotes, handleDeleteOpenQuote,handleDeleteOpenUnit, maxQuoteIndex, saveQuotation,nodes, setNodes,handleUnitDeleteNode,newUnits,setNewUnits, isRemoveItemsClicked ,
        setIsRemoveItemsClicked , module ,  setModule ,quoteUpdated ,  quoteContactTypes, setQuoteContactTypes} = useNodeContext();
    const { setCustomerId } = useContext(CustomerIdContext);
    const {quoteDetails,updateQuoteDetailsForId ,quoteCreatedDate, setQuoteCreatedDate  } = useQuoteDetails();
    const [timePeriods, setTimePeriods] = useState({});
    //const {selectedContactTypeId, setSelectedContactId , isDoneButtonClicked , setIsDoneButtonClicked} = useQuoteContact();
    
    const { hasZeroCostLPriceNettEA, setHasZeroCostLPriceNettEA } = useStockCode();
    const location = useLocation();
    const savedQuote = location?.state?.selectedQuote;
    const quoteName = location?.state?.quoteName;
    const navigate = useNavigate();

    const editableValue = useRef(null);
    const {qteCurGridViewType, setQteCurGridViewType, qteAuthType, setQteAuthType } = useContext(QteCurGridViewTypeContext);

    //Accordion handling*/
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(true);
    const [importShowProgress, setImportShowProgress] = useState(false);
    const [progressPercent, setprogressPercent] = useState({ percentage: 0, remainingTime: 0 });

    setModule(location.pathname.slice(1, location.pathname.lastIndexOf("/")));

    const {isOldQuoteDone , setIsOldQuoteDone} = useNodeContext();
    const {unitDetails,createdName,setIsUnitsLoad,unitName, setUnitName,totalOfUnitItems,newUnitName,triggerUnitSaving, setTriggerUnitSaving} = useUniteDetails();   
    const {currentContact, setCurrentContact,updatedQuoteContact, isDoneButtonClicked , setIsDoneButtonClicked , selectedContactOption, searchContactMobile , customPhone ,  usermobile } = useQuoteContact();
    const {
        margin,
        total,
        newPrice,
        setNewPrice,
        newMargin,
        setNewMargin,
        setAllowSetValue,
        setAllowSetMarginValue,
        isMarginSelected,
        setIsMarginSelected,
        isPriceSelected,
        setIsPriceSelected,
        setIsKeyPressedForSave,
        totalItemPrice, setTotalItemPrice,maxMargin, setMaxMargin
    } = useMarginAndPrice();
    // useEffect(() => {
    //     if (id) {
    //         localStorage.setItem('lastVisitedQuoteId', id);
    //     }
    // }, [id]);

    // // Check localStorage for ID on component mount and navigate
    // useEffect(() => {
    //     setIsNavigate(true);
    
    //     // Check if a saved quote is already loaded
    //     if (!savedQuote) {
    //         const savedId = localStorage.getItem('lastVisitedQuoteId');
    //         if (savedId) {
    //             navigate(`/${module}/${savedId}`);
    //         } else {
    //             navigate('/dashboard');
    //         }
    //     }
    //     setIsNavigate(false);
    // }, [navigate, savedQuote, module]);
    
    const [totalPartLines, setTotalPartLines] = useState(0);
   
    const [totalCost, setTotalCost] = useState(0);
    const [isQuoteContactSet , setIsQuoteContactSet] = useState(false)
    const [maxFrcMargin, setMaxFrcMargin] = useState(0);
    const [maxActMargin, setActMargin] = useState(0);
    const [footerDisc, setFooterDisc] = useState(0);
    const [mainCustomer, setMainCustomer] = useState("");
    const user = useContext(UserContext);

    const createdByName = user?.createdByName;

    const username = user?.userFullName;
    const userId = user?.homeAccountId;

    const [itemCount, setItemCount] = useState(0);
    const [triggerSaveQuote, setTriggerSaveQuote] = useState(0);
    const [hasCustomer, setHasCustomer] = useState(false);
    const [triggerAddCustomer, setTriggerAddCustomer] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [selectedquoteContacttype, setSelectedquoteContactType] = useState([]);
    //const [selectedContactTypeId, setSelectedContactId] = useState([]);

    const [baseCustomerOwner, setBaseCustomerOwner] = useState("");
    const [quoteCustomerOwner, setQuoteCustomerOwner] = useState("");
    const [isDraft, setIsDraft] = useState(false);
    const [isQuoteSaved, setIsQuoteSaved] = useState(false);
    const [isUnitSaved, setIsUnitSaved] = useState(false);
    const authMatrix = authMatrixData;

    const [resetQuoteDialogVisible, setResetQuoteDialogVisible] = useState(false); // when old quotes opening and click on reset button then open set to default price(reset quote)
    const { setAllowSet, selectedOption ,allowSet } = useResetQuote();

    const { canView, canEdit, canUpdate, getQuoteColMap, getQuoteViewOpt, getAccountRole , columnsMapping , 
        setColumnsMapping } = useAuthorizationContext();
    const authType = getAccountRole();
    const quoteViewType = getQuoteViewOpt();
    const excelExportRef = useRef(null);
    const [selectedView, setSelectedView] = useState(0);
    let noViewAccessSetup = 0;
    let index = 0;
    let text = "";
    let viewType = [];
    let viewText = "";
    let [viewOptions, setviewOptions] = useState([]);
    // const [columnsMapping , setColumnsMapping] = useState(["displayGroupName"]);
    const { isSaveClicked } = useTrigerSaveQuoteContext();
  
    // Define your mappings here
    const footerMappings = {
        General: {
            Normal: ["totalItemPrice"],
            Report: ["totalItemPrice"],
        },
        DBEstimators: {
            Normal: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
            Report: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
        },
        StateManagers: {
            Normal: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
            Report: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
        },
        Management: {
            Normal: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
            Management: ["maxActMargin", "maxMargin", "maxFrcMargin", "totalCost", "totalItemPrice"],
            Report: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
        },
        Admin: {
            Normal: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
            Management: ["maxActMargin", "maxMargin", "maxFrcMargin", "totalCost", "totalItemPrice"],
            Report: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
        },
        GeneralManager: {
            Normal: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
            Management: ["maxActMargin", "maxMargin", "maxFrcMargin", "totalCost", "totalItemPrice"],
            Report: ["footerDisc", "maxMargin", "totalCost", "totalItemPrice"],
        },
    };

    const getSysSetting = async () => {
        const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });
        if (data) {
            let cat = data.map((item) => item.qtType);
            let header = data.map((item) => item.headerName);

            sessionStorage.setItem(`category_${id}`, cat[0]);
            sessionStorage.setItem(`header_${id}`, header[0]);
        }
    };

    const hasCategoryKeyInSessionStorage = () => {
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (key.startsWith('category_')) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (!hasCategoryKeyInSessionStorage()) {
            getSysSetting();
        }
    }, [id, userId]);


    const handleDraftStatusChange = (isdraft) => {
        setIsDraft(isdraft);
    };

    const isSavedQuoteChange = (isSave) => {
        setIsQuoteSaved(isSave);
    };
    const isSavedUnitChange = (isSave) => {
        setIsUnitSaved(isSave);
    };


    // Function to get the footer content based on authType and quoteViewType
    function getFooterContent(authType) {
        // Check if the authType and quoteViewType exist in the mappings
        if (
            footerMappings[authType]
            // &&
            // footerMappings[authType][quoteViewType.text]
        ) {
            return footerMappings[authType];
        }

        return [];    }

    const footerContent = getFooterContent(authType);

    const [quoteContact, setQuoteContact] = useState("");


    // const returnListCount = (customerCount) => {
    //     if (customerCount <= 0) {
    //         setHasCustomer(false);
    //     } else {
    //         setHasCustomer(true);
    //         setItemList(Object.keys(quoteDetails[id]?.savedQuote?.quoteItems));
    //     }
    // };

    // const handleItemGrid = (index, viewType) => {
    //     setColumnsMapping(getQuoteColMap(index, viewType));

    //     const excelColumns = columnsMapping.map((column) => (
    //         <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
    //     ));

    //     excelExportRef.current.columns = excelColumns;
    // };

    const handleItemGrid = (index, viewType) => {
        // Get the columns mapping based on some logic
        setColumnsMapping(getQuoteColMap(index, viewType));
    
        // Ensure excelExportRef.current is not null before attempting to set its properties
        if (excelExportRef.current) {
            const excelColumns = columnsMapping.map((column) => (
                <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
            ));

            excelExportRef.current.columns = excelColumns;
        }
    };

   
    //this is added by sachini 14/05/2024 
    //If the quote was created more than a month ago (30 or more days), a notification message should be displayed similar to existing EE system.
    //The message should dynamically adjust based on the quote's creation date, accurately reflecting the number of days since its creation.
      useEffect(() => {
        const createdOn = quoteDetails[id]?.openOldQuoteTimePerid;
      
        if (createdOn) {
            const dateObject = new Date(createdOn);
            
            if (!isNaN(dateObject)) {           
            const currentDate = new Date();
            const nowDate = currentDate.getTime();
            const createdDate = dateObject.getTime();
            const millisecondsInADay = 1000 * 60 * 60 * 24;
            const differenceInDays = (nowDate - createdDate) / millisecondsInADay;
            const roundedDifference = Math.round(differenceInDays);
            setTimePeriods(prev => ({
                ...prev,
                [id]: roundedDifference,
            }));
           
        } else {
            console.error("Invalid date:", quoteDetails[id]?.openOldQuoteTimePerid);
        }
    } else {
        console.error("createdOn is undefined or null");
    }
    }, [quoteDetails, id]);

    const timePeriod = timePeriods[id] || 0;


    const toggleResetQuoteDialog = () => {
        setResetQuoteDialogVisible(!resetQuoteDialogVisible);
    };
    const handleOkSet = () => {
        updateQuoteDetailsForId(id, {
            resetQuote: true});
        setAllowSet(selectedOption);
        setResetQuoteDialogVisible(!resetQuoteDialogVisible);
       
    };
    //end

    const itemGridSetup = () => {
        if (itemList.length <= 0) {
            viewType = getQuoteViewOpt(); // fetch the listing of view based on role

            if (viewType.length === 0) {
                setColumnsMapping([]);
                return;
            } else {
                viewText = viewType?.text;
                switch (viewType[0].text) {
                    case "No View":
                        if (noViewAccessSetup == 0) toast.error("No View has been configured.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        noViewAccessSetup += 1; // to eliminate multiple message.
                        setColumnsMapping([]);
                        break;
                    case "No Role":
                        if (noViewAccessSetup == 0)
                            toast.error("No role has been set to your account.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        noViewAccessSetup += 1; // to eliminate multiple message.
                        setColumnsMapping([]);
                        break;
                    case "":
                        if (noViewAccessSetup == 0) toast.error("No View has been configured.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        noViewAccessSetup += 1; // to eliminate multiple message.
                        setColumnsMapping([]);
                        break;
                    default:
                        if (!!viewType.filter((src) => src.id == 0)[0]) {
                            if (noViewAccessSetup == 0)
                                toast.error(
                                    "Please check the matrix setup for " +
                                        viewType.filter((src) => src.id == 0)[0].text +
                                        ". Id should not be zero.",
                                    { autoClose: 5000, position: "top-center", transition: Slide, }
                                );
                            noViewAccessSetup += 1;
                            break;
                        } else {
                            //setHasCustomerId(!!customerIdContext.customerId);
                            setviewOptions(viewType); // set the values of dropdown view
                            setSelectedView(viewType.filter((src) => src.id == 1)[0]); // set the initial value of the dropdown view

                            setDropdownValue(viewType.filter((src) => src.id == 1)[0].text);
                            setQteAuthType(authType); // set the value of the authType
                            index = selectedView;
                            text =
                                viewType.filter((src) => src.id == 1)[0].id > 0
                                    ? viewType.filter((src) => src.id == 1)[0].text
                                    : "";
                            index >= 0 ? handleItemGrid(index, text) : null;
                            text.length > 0 ? setQteCurGridViewType(text) : null;
                        }
                }
            }
        }
    };
    const returnListCount = (customerCount) => {
        if (customerCount <= 0) {
            setHasCustomer(false);

        } else {
            setHasCustomer(true);     
          
        }
    };



    useEffect(() => {
        // Ensure that quoteDetails and id are defined to prevent undefined errors
        if (quoteDetails && id && quoteDetails[id] && quoteDetails[id].selectedItems) {
            if(isRemoveItemsClicked){
                SetLoadStatus(true);
            }
            else   SetLoadStatus(false);

            setIsRemoveItemsClicked(false);
          
            // Directly set itemList from selectedItems if it's an array
            setItemList([...quoteDetails[id].selectedItems]);
            
        } else {
            setItemList([]);
        }
    }, [quoteDetails[id], id , module] ); // Dependency array includes both quoteDetails and id to react on changes of either

    useEffect(() => {
        itemGridSetup();
    } , [quoteDetails , id]);


    useEffect(() => {
        if (isSaveClicked) {
            triggerSaveQuotation();
        }
    }, [isSaveClicked]);


    useEffect(() => {
        if (isSaveClicked) {
            triggerSaveQuotation();
        }
    }, [isSaveClicked]);

    useEffect(() => {
        setItemCount(totalPartLines);
    }, [totalPartLines]);

    const getCreatedByName = async(createdById) => {
        // Call your API here
        try {
            let result = await getResponse("User/getUserFullName", { userId: createdById });
            setCreatedBy(result.fullName);
        } catch (error) {
            console.log("Error getCreatedByName",error);
        }
        
    }

    useEffect(() => {
        if (quoteDetails && id !== undefined) {
            if(quoteDetails[id]?.selectedCustomers){
                
                const { savedQuote } = quoteDetails[id];

                if (savedQuote) {
                    getCreatedByName(savedQuote.createdBy);

                    const createdDateFormatted = formatDate(savedQuote.createdOn);
                    setCreatedOn(createdDateFormatted);
                    
                    const modifiedDateFormatted = formatDate(savedQuote.modifiedOn);
                    setModifiedOn(modifiedDateFormatted);
                }
            }
        }
    }, [quoteDetails, id, setCreatedBy, setCreatedOn, setModifiedOn]); // Ensure useEffect triggers on change of quoteDetails or id
    


 

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth() is zero-indexed, add 1 for the correct month
        const day = date.getDate();
        return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    }
    
    useEffect(() => {
        setKey(id);
        const isCustomerAvailable = quoteDetails[id]?.selectedCustomers?.length > 0;

        const baseCustomerId = quoteDetails[id]?.selectedCustomers?.filter((c) => {
            return c.isPrimary == true;
        })[0]?.customerId;

        !isCustomerAvailable ? setCustomerId("") : setCustomerId(baseCustomerId);

        quoteDetails[id]?.savedQuote?.quoteNumber
            ? setQuoteNumber(quoteDetails[id]?.savedQuote?.quoteNumber)
            : !quoteName
            ? setQuoteNumber("QUO-New-Quote " + maxQuoteIndex)
            : setQuoteNumber("QUO-New-Quote " + quoteName?.split(" ")[2]);
    if(!isDoneButtonClicked){
        quoteDetails[id]?.selectedquoteCustomers !== undefined
            ? setQuoteCustomerOwner(quoteDetails[id]?.selectedquoteCustomers)
            : setQuoteCustomerOwner(username);
    }
    }, [id]);

    useEffect(() => {
        const excludeRowType = ['DX','LN','A','AI','G','SG','','CI'];
        const checkIfHasZeroCost = itemList.some((item) =>  !excludeRowType.includes(item.rowType) && parseFloat(item.stEecost) <= 0 );
        const checkIfHasZeroLPrice = itemList.some((item) =>  !excludeRowType.includes(item.rowType) && parseFloat(item.listPrice) <= 0 );
        const checkIfHasZeroNettEA = itemList.some((item) =>  !excludeRowType.includes(item.rowType) && parseFloat(item.nettEA) <= 0 );

        if (!checkIfHasZeroCost && !checkIfHasZeroLPrice && !checkIfHasZeroNettEA) {
            setHasZeroCostLPriceNettEA(false);
        } else {
            setHasZeroCostLPriceNettEA(true);
        }

    }, [
        maxMargin,
        maxActMargin,
        totalItemPrice,
        totalCost,
        footerDisc,
        setDropdownValue,
        dropdownValue,
        itemList
    ]);
    const fetchloadData = async () => {
        if (fetchedQuoteIds.has(id)) {
            // If the current quote ID is already in the fetched IDs list, skip fetching
            console.log("Skipping fetch for previously fetched quote ID:", id);
            return;
        }

        try {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: userId });
            if (data && data.length > 0) {
                const quoteContact = data[0].quoteContact;
                const quoteContactId = data[0].quoteContactId;

                setSelectedquoteContactType(quoteContact);
                setQuoteContactTypes(prevTypes => ({
                    ...prevTypes,
                    [id]: quoteContactId ,
                }));

                // Update fetched quote IDs list
                setFetchedQuoteIds(prevIds => new Set(prevIds).add(id));
            } else {
                console.error("Data is empty or does not contain expected properties.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const currentSelectedContactTypeId = quoteContactTypes[id];

    
    

    useEffect(() => {
        fetchloadData();  
   
    }, [id]);

    const t_col2 = () => {
        setcol2(!col2);
        setcol1(true);
    };

    const navigateToDashboard = () => {
        navigate("/dashboard");
    };

    // useEffect(() => {
    //     try {
            
    //         if (currentSelectedContactTypeId === 5) {
    //             setQuoteContact(username ? username : quoteCustomerOwner);
    //             //setCurrentContact(username ? username : quoteCustomerOwner);
    //             setCurrentContact(prevTypes => ({
    //                 ...prevTypes,
    //                 [id]: username ? username : quoteCustomerOwner ,
    //             }));
    //             // updateQuoteDetailsForId(id, {
    //             //     quoteContact: username ? username : quoteCustomerOwner,
                    
    //             // });
    //         } else if (currentSelectedContactTypeId === 7) {
    //             setQuoteContact(quoteCustomerOwner ? quoteCustomerOwner : username);
    //             setCurrentContact(quoteCustomerOwner ? quoteCustomerOwner : username);
    //             setCurrentContact(prevTypes => ({
    //                 ...prevTypes,
    //                 [id]: quoteCustomerOwner ? quoteCustomerOwner : username,
    //             }));
                
    //         } else {
    //             console.error("Data is empty or does not contain expected properties.");
    //         }

    //         setCurrentContact(((currentSelectedContactTypeId === 7 && quoteDetails[id]?.savedQuote === undefined))? quoteDetails[id]?.selectedquoteCustomers: quoteDetails[id]?.quoteContact)
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // }, [currentSelectedContactTypeId, username, quoteCustomerOwner, setQuoteContact, quoteContact,setCurrentContact , isDoneButtonClicked]);

 // Updated dependencies

 //thisis added by sachini -bug 5913 fixed
//  const selectedCustomer = quoteDetails[id]?.selectedCustomers?.[0]; 
//  const isPrimary = selectedCustomer?.isPrimary;
//  const owner = selectedCustomer?.owner; 
//  useEffect(() => {
//      if (isPrimary) {            
//          if (quoteUpdated && owner && !isDoneButtonClicked) {
//              if (currentSelectedContactTypeId === 7) {
//                  updateQuoteDetailsForId(id, {
//                      selectedquoteCustomers: owner,
//                  });
//                  setQuoteContact(owner);
//                  setCurrentContact(owner);
//                  setIsDoneButtonClicked(false);
//              }
//          }
//      }
//  }, [quoteUpdated, isPrimary ,id,selectedCustomer,owner,quoteContact,currentContact]);
/////ended bug 5913 fixed

    useEffect(() => {
        try {
            
            if (currentSelectedContactTypeId === 5 ) {
                setQuoteContact(username ? username : quoteCustomerOwner);
                // setCurrentContact(username ? username : quoteCustomerOwner);
                setCurrentContact(prevTypes => ({
                    ...prevTypes,
                    [id]: username ? username : quoteCustomerOwner ,
                }));
                setIsDoneButtonClicked(true);
                // updateQuoteDetailsForId(id, {
                //     quoteContact: username ? username : quoteCustomerOwner,
                    
                // });
            } else if (currentSelectedContactTypeId === 7 ) {
                setQuoteContact(quoteCustomerOwner ? quoteCustomerOwner : username);
                setCurrentContact(quoteCustomerOwner ? quoteCustomerOwner : username);
                setCurrentContact(prevTypes => ({
                    ...prevTypes,
                    [id]: quoteCustomerOwner ? quoteCustomerOwner : username,
                }));
              
                // updateQuoteDetailsForId(id, {
                //     quoteContact: quoteCustomerOwner ? quoteCustomerOwner : username,
                    
                // });
                
            } else {
                console.error("Data is empty or does not contain expected properties.");
            }

            setCurrentContact(((currentSelectedContactTypeId === 7 && quoteDetails[id]?.savedQuote === undefined))?  quoteDetails[id]?.selectedquoteCustomers : isOldQuoteDone ? quoteDetails[id]?.savedQuote?.quoteContact  : quoteDetails[id]?.quoteContact);
            
            
        } catch (error) {
            console.error("Error:", error);
        }
    }, [currentSelectedContactTypeId, username, quoteCustomerOwner, setQuoteContact, quoteContact,setCurrentContact , id]);

    const handleDelete = () => {
        confirmAlert({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        if (!quoteDetails[id]?.savedQuote) {
                            toast.error(
                                "The quote you are trying to delete is not saved. If you want to close the quote, use the close button instead.", {
                                    position: "top-center",
                                    transition: Slide,
                                });                        
                        } else {
                            putResponse(`Quote/deletequotewithupdateisdeleted?quoteId=${id}`)
                                .then((res) => {
                                    if (res) {
                                        handleDeleteOpenQuote(quotes, setQuotes, id);
                                        toast.success("The Quote has been Deleted Successfully" , {
                                            position: "top-center",
                                            transition: Slide,
                                        });                        
                                    } else {
                                        toast.error("Either quote is not saved or something went wrong while deleting.", {
                                            position: "top-center",
                                            transition: Slide,
                                        });                                
                                    }
                                })
                                .catch((error) => {
                                    console.error("Failed to delete quote:", error);
                                    toast.error("Connection error. Please try again later.", {
                                        position: "top-center",
                                        transition: Slide,
                                    });                            
                                });
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };

    const handleDeleteUnit = () => {
        confirmAlert({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this unit?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        // if (!unitDetails[id]?.unitId) {
                        //     toast.error(
                        //         "The unit you are trying to delete is not saved. If you want to close the unit, use the close button instead."
                        //     );
                        // } else {
                            putResponse(`NewUnits/deletenewunit?unitId=${id}`)
                                .then((res) => {
                                    if (res) {
                                        // handleUnitDeleteNode,newUnits,setNewUnits
                                     //   handleDeleteOpenQuote(quotes, setQuotes, id);
                                     handleDeleteOpenUnit(newUnits,setNewUnits,id);
                                     setIsUnitsLoad(true);
                                        toast.success("The Quote has been Deleted Successfully", {
                                            position: "top-center",
                                            transition: Slide,
                                        });                                
                                        navigate(`/dashboard`, { replace: true });
                                    } else {
                                        toast.error("Either unit is not saved or something went wrong while deleting.", {
                                            position: "top-center",
                                            transition: Slide,
                                        });
                                    
                                    }
                                })
                                .catch((error) => {
                                    console.error("Failed to delete quote:", error);
                                    toast.error("Connection error. Please try again later.", {
                                        position: "top-center",
                                        transition: Slide,
                                    });                            
                                });
                        }
                    // },
                },
                {
                    label: "No",
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };

    // const handleDeleteUnit = () => {
    //     confirmAlert({
    //         title: "Confirm to delete Unit",
    //         message: "Are you sure you want to delete this?",
    //         buttons: [
    //             {
    //                 label: "Yes",
    //                 onClick: () => {
    //                     // if (!unitDetails[id]?.unitId) {
    //                     //     toast.error(
    //                     //         "The unit you are trying to delete is not saved. If you want to close the unit, use the close button instead."
    //                     //     );
    //                     // } else {
    //                         putResponse(`NewUnits/deletenewunit?unitId=${id}`)
    //                             .then((res) => {
    //                                 if (res) { 
    //                                     // handleUnitDeleteNode,newUnits,setNewUnits
    //                                  //   handleDeleteOpenQuote(quotes, setQuotes, id);
    //                                  handleDeleteOpenUnit(newUnits,setNewUnits,id);
    //                                  setIsUnitsLoad(true);
    //                                     toast.success("Quote deleted successfully");
    //                                 } else {
    //                                     toast.error(
    //                                         "Either unit is not saved or something went wrong while deleting."
    //                                     );
    //                                 }
    //                             })
    //                             .catch((error) => {
    //                                 console.error("Failed to delete quote:", error);
    //                                 toast.error("Connection error. Please try again later.");
    //                             });
    //                     }
    //                 // },
    //             },
    //             {
    //                 label: "No",
    //                 onClick: () => {
    //                     return;
    //                 },
    //             },
    //         ],
    //     });
    // };
    const toggleEdit = () => {
        setIsEditable(!isEditable);
    };

    const toggleCancel = () => {
        setIsEditable(!isEditable);
    };

    const saveUpdatedQuoteNumber = () => {
        let updatedQuoteNumber = "QUO-" + editableQuoteNumber;
        const updatingQuote = quoteDetails[id]?.savedQuote;
        const updatedQuote = { ...updatingQuote, quoteNumber: updatedQuoteNumber };
        quoteDetails[id].savedQuote = updatedQuote;
        setQuoteNumber(updatedQuoteNumber);
        toggleEdit();
    };

    const triggerSaveQuotation = () => {
        setTriggerSaveQuote((triggerSaveQuote) => triggerSaveQuote + 1);
        setTimeout(setTriggerSaveQuote, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
    };

    useEffect(()=>{
        triggerSaveQuotation();
    },[saveQuotation])

    const triggerAddCust = () => {
        setTriggerAddCustomer((triggerAddCustomer) => triggerAddCustomer + 1);
        setTimeout(setTriggerAddCustomer, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
    };

    //#region units
    const [libraryTypes, setLibraryTypes] = useState([]);
    

    const triggerSaveUnit = () => {
        setTriggerUnitSaving((triggerUnitSaving) => triggerUnitSaving + 1);
        setTimeout(setTriggerUnitSaving, 100, 0);
        
    };
    //#endregion

    //#region price lists
    const [triggerPriceListSaving, setTriggerPriceListSaving] = useState(0);

    const triggerSavePriceList = () => {
        setTriggerPriceListSaving((triggerPriceListSaving) => triggerPriceListSaving + 1);
        setTimeout(setTriggerPriceListSaving, 100, 0);
    };
    //#endregion
    
    //UI: Back to top
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.scrollY;

        if (scrollTop > 280) { // Adjust this value to determine when the button appears
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    //End Back to top

    if (module == "quotes") {
        //Temporarily we have repeated the code the new units. But we need to figure out none repeatition of code when we do the new units module
        return (
            
            <div className="ip-subnode-container" key={key}>
                <div className="row">
                    <div className="col">
                        <h1 className="ip-sub-h1 bold" data-tooltip-id="tooltip-quote-details" data-tooltip-place="bottom-start">
                            <Tooltip
                                id="tooltip-quote-details" // approverComment
                                html={`<div class='qnum-tooltipdiv'>Created By: <span> ${createdBy}</span> On <span>${createdOn}</span> Modified On:<span> ${modifiedOn}</span></div>`}
                            />
                            {/* Conditionally display "Draft" prefix based on isDraft state */}
                             {/* {isDraft && !isQuoteSaved && <span>Draft - </span>} */}
                            <span>{quoteNumber ? quoteNumber.split("-")[0] : "QUO"}-</span>
                           
                            <span
                                contentEditable={isEditable}
                                className={isEditable && "editable"}
                                ref={editableValue}
                                onInput={(e) => {
                                    e.preventDefault();
                                    setEditableQuoteNumber(e.currentTarget.textContent);
                                }}>
                                {quoteNumber && quoteNumber.split("-")[4]
                                    ? quoteNumber.split("-")[1] + "-" + quoteNumber.split("-")[2]+ "-" + quoteNumber.split("-")[3]+ "-" + quoteNumber.split("-")[4] :
                                quoteNumber && quoteNumber.split("-")[3]
                                    ? quoteNumber.split("-")[1] + "-" + quoteNumber.split("-")[2]+ "-" + quoteNumber.split("-")[3] :
                                
                                quoteNumber.includes("New") || quoteNumber.includes("Draft") || quoteNumber.split("-").length > 2
                                    ? quoteNumber.split("-")[1] + "-" + quoteNumber.split("-")[2] :
                                    quoteNumber
                                    ? quoteNumber.split("-")[1] :
                                
                                     "Quotes New"}
                            </span>

                            <span>
                                {/* {!isEditable && quoteDetails[id]?.savedQuote && (
                                    <button
                                        title="Edit"
                                        type="button"
                                        className="ip-subhead-btn btn btn-icon waves-effect waves-light"
                                        onClick={toggleEdit}>
                                        <i className="ri-pencil-line ip-icon-size-lg blue"></i>
                                    </button>
                                )} */}
                            </span>
                            <span className="flex-row">
                                {isEditable && quoteDetails[id]?.savedQuote && (
                                    <button
                                        title="Save/Update"
                                        type="button"
                                        className="ip-subhead-btn btn btn-icon waves-effect waves-light"
                                        onClick={saveUpdatedQuoteNumber}>
                                        <i className="ri-save-3-line ip-icon-size-lg blue"></i>
                                    </button>
                                )}
                                {isEditable && quoteDetails[id]?.savedQuote && (
                                    <button
                                        title="Cancel Editing"
                                        type="button"
                                        style={{ marginLeft: "30px" }}
                                        className="ip-subhead-btn btn btn-icon waves-effect waves-light"
                                        onClick={toggleCancel}>
                                        <i className="ri-close-line ip-icon-size-lg2 red"></i>
                                    </button>
                                )}
                            </span>
                        </h1>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-row-reverse">
                            {/* <button
                                type="button"
                                className="btn btn-icon waves-effect waves-light mx-1"
                                title="Hide"
                                onClick={navigateToDashboard}>
                                <i className="ri-eye-off-line ip-icon-size-lg blue"></i>
                            </button> */}
                            {/* {quoteDetails[id]?.savedQuote && (
                                <button
                                    type="button"
                                    className="btn btn-icon waves-effect waves-light mx-1"
                                    title="Delete Permanently"
                                    onClick={handleDelete}>
                                    <i className="ri-delete-bin-6-line ip-icon-size-lg red"></i>
                                </button>
                            )} */}
                            {/* <button
                                type="button"
                                className="btn btn-icon waves-effect waves-light mx-1"
                                title="Save Quote"
                                onClick={() => {
                                    triggerSaveQuotation();
                                }}>
                                <i className="ri-save-3-line ip-icon-size-lg blue"></i>
                            </button> */}
                            <nav className="hide-for-mobile" aria-label="breadcrumb">
                                <ol className="breadcrumb mx-3 pt-2">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            <i className="ri-home-5-fill"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <a href="#">Quotes</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <Row>
                
        {timePeriod > 30 && !quoteDetails[id]?.resetQuote  && (
            <div className="ip-special-alert rowone alert-top-border material-shadow mb-xl-0 alert alert-danger alert-dismissible fade show mb-2" role="alert">
                <i className="ri-alert-line me-3 ms-2 align-middle fs-18 text-danger "></i>
                <div><strong>Attention: </strong>Quote has been generated using {timePeriod} days old pricing and cost. Reset quote pricing</div>
                <button className="btn btn-outline-danger waves-effect waves-light material-shadow-none ms-3" onClick={toggleResetQuoteDialog}>Reset</button>
            </div>
        )}      
           {
    (quoteDetails[id]?.savedQuote?.createdBy !== userId && quoteDetails[id]?.savedQuote !== undefined)  &&  (
        <div className="ip-special-alert amber rowone alert-top-border material-shadow mb-xl-0 alert alert-warning alert-dismissible fade show mb-2" role="alert">
            <i className="ri-alert-line me-3 ms-2 align-middle fs-18 text-warning "></i>
            <div>
                <strong>Currently Viewing Someone Else's Quote: </strong>
                If you've made changes to this quote, they won't be saved unless you save it as a suffix, revision, or new quote, since it's not originally yours
            </div>
        </div>
    )
}
    {
        (quoteDetails[id]?.savedQuote?.createdBy === userId && quoteApprovalStatId === 2)  &&  
        (
            <div className="ip-special-alert amber rowone alert-top-border material-shadow mb-xl-0 alert alert-warning alert-dismissible fade show mb-2" role="alert">
                <i className="ri-alert-line me-3 ms-2 align-middle fs-18 text-warning "></i>
                <div>
                    If you've made changes to a quote pending approval, they won't be saved unless you save it as a suffix, revision, or a new quote and resend it for approval.
                </div>
            </div>
        )
    }
            <CustomerDataGrid
                        localAccountId={localAccountId}
                        selectedQuote={savedQuote}
                        setQuoteNumber={setQuoteNumber}
                        setMainCustomer={setMainCustomer}
                        triggerSaveQuote={triggerSaveQuote}
                        returnListCount={returnListCount}
                        triggerAddCustomer={triggerAddCustomer}
                        baseCustomerOwner={baseCustomerOwner}
                        setBaseCustomerOwner={setBaseCustomerOwner}
                        quoteCustomerOwner={quoteCustomerOwner}
                        setQuoteCustomerOwner={setQuoteCustomerOwner}
                        quoteContact={quoteContact}
                        onDraftStatusChange={handleDraftStatusChange}
                        selectedContactTypeId = {currentSelectedContactTypeId}
                        setQuoteContact={setQuoteContact}
                        isNavigate = {isNavigate}
                        isSavedQuoteChange = {isSavedQuoteChange}
                        // totalItemPrice={totalItemPrice}
                        // setTotalItemPrice={setTotalItemPrice}
                        totalCost={totalCost}
                        setTotalCost={setTotalCost}
                        setImportShowProgress = {setImportShowProgress}
                        importShowProgress = {importShowProgress}
                        progressPercent = {progressPercent}
                        setprogressPercent = {setprogressPercent}
                    />

                    <div className="mt-3"></div>
                    <Accordion id="default-accordion-example2">
                        <AccordionItem>
                            <h2 className="accordion-header" id="headingTwo">
                                <button
                                    id="ip-items-detect"
                                    className={classnames("accordion-button", {
                                        collapsed: !col2,
                                    })}
                                    type="button"
                                    onClick={t_col2}
                                    style={{ cursor: "pointer" }}>
                                    Items ({itemCount})
                                </button>
                            </h2>
                            <Collapse isOpen={col2} className="accordion-collapse" id="collapseTwo">
                                <div className="accordion-body">
                                <div class={`ip-special-alert rowtwo alert-top-border material-shadow mb-xl-0 alert alert-danger alert-dismissible fade show  ${!hasZeroCostLPriceNettEA ? "displaynone" : ""}`} role="alert">
                                    <i class="ri-alert-line me-3 ms-2 align-middle fs-18 text-danger "></i>
                                    <div>One or more parts neither have cost, list price, nettea nor all three.</div>
                                </div>
                    
                                    {hasCustomer && (
                                        <ItemsMainDataGrid
                                            totalPartLines={totalPartLines}
                                            setTotalPartLines={setTotalPartLines}
                                            // totalItemPrice={totalItemPrice}
                                            // setTotalItemPrice={setTotalItemPrice}
                                            totalCost={totalCost}
                                            setTotalCost={setTotalCost}
                                            mainCustomer={mainCustomer}
                                            setMainCustomer={setMainCustomer}
                                            itemList={itemList}
                                            setItemList={setItemList}
                                            // maxMargin={maxMargin}
                                            // setMaxMargin={setMaxMargin}
                                            maxFrcMargin={maxFrcMargin}
                                            columnsMapping = {columnsMapping}
                                            handleItemGrid = {handleItemGrid}
                                            setMaxFrcMargin={setMaxFrcMargin}
                                            footerDisc={footerDisc}
                                            setviewOptions = {setviewOptions}
                                            viewOptions  = {viewOptions}
                                            excelExportRef = {excelExportRef}
                                            selectedView = {selectedView}
                                            setSelectedView = {setSelectedView}
                                            setFooterDisc={setFooterDisc}
                                            itemGridSetup = {itemGridSetup}
                                            maxActMargin={maxActMargin}
                                            setActMargin={setActMargin}
                                            setDropdownValue={setDropdownValue}
                                            setImportShowProgress = {setImportShowProgress}
                                            importShowProgress = {importShowProgress}     
                                            progressPercent = {progressPercent}
                                            setprogressPercent = {setprogressPercent}
                                        />
                                    )}
                                    {!hasCustomer && (
                                        <div className="ip-firsttime-itemgrid text-center text-muted">
                                            Please{" "}
                                            <span className="text text-decoration-underline k-cursor-pointer m-1">
                                                <a
                                                    onClick={() => {
                                                        triggerAddCust();
                                                    }}>
                                                    add a customer{" "}
                                                </a>
                                            </span>{" "}
                                            first to see the items.
                                        </div>
                                    )}
                                    {hasCustomer && (
                                        <div className="row m-0 ip-item-summary">
                                            <div className="col-md-5 col-lg-6 col-sm-12">
                                                <div className="">
                                                    Total Part Lines:{" "}
                                                    <span className="text-black">
                                                        <strong>{totalPartLines}</strong>
                                                    </span>
                                                </div>
                                                <div className="">
                                                    Quote Contact:{" "}
                                                    {/* <span className="text-black">
                                                        <strong>{(updatedQuoteContact !== undefined && updatedQuoteContact !== "")  ? updatedQuoteContact : quoteDetails[id].quoteContact}</strong>
                                                    </span> */}
                                                    <span className="text-black">
                                                        <strong>{ isDoneButtonClicked ? quoteDetails[id]?.quoteContact : currentSelectedContactTypeId === 5 ? quoteDetails[id]?.quoteContact : currentSelectedContactTypeId === 7 && quoteDetails[id]?.quoteNumber === undefined ? quoteDetails[id]?.quoteContact :currentSelectedContactTypeId === 7 && quoteDetails[id]?.quoteNumber.startsWith("Draft")? quoteDetails[id]?.selectedquoteCustomers : currentSelectedContactTypeId === 7 && quoteDetails[id]?.savedQuote !== undefined ? quoteDetails[id]?.quoteContact :quoteDetails[id]?.quoteContact}</strong>
                                                    </span>                                       
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-lg-6 col-sm-12 ip-item-calc-r">
                                                <div className="d-md-flex flex-row-reverse justify-content-between text-end">
                                                    {footerContent[dropdownValue] &&
                                                        footerContent[dropdownValue].includes &&
                                                        footerContent[dropdownValue].includes("totalItemPrice") && (
                                                            <div className="d-md-flex flex-column">
                                                                Total Price:{" "}
                                                                <span className="text-black">
                                                                    <strong>
                                                                        $
                                                                        {totalItemPrice?.toLocaleString("en-US", {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })}
                                                                    </strong>
                                                                </span>
                                                            </div>
                                                        )}
                                                    {footerContent[dropdownValue] &&
                                                        footerContent[dropdownValue].includes &&
                                                        footerContent[dropdownValue].includes("totalCost") && (
                                                            <div className="d-md-flex flex-column">
                                                                Total Cost:{" "}
                                                                <span className="text-black">
                                                                    <strong>
                                                                        $
                                                                        {totalCost?.toLocaleString("en-US", {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })}
                                                                    </strong>
                                                                </span>
                                                            </div>
                                                        )}

                                                    {footerContent[dropdownValue] &&
                                                        footerContent[dropdownValue].includes &&
                                                        footerContent[dropdownValue].includes("maxMargin") && (
                                                            <div className="d-md-flex flex-column">
                                                                Margin:{" "}
                                                                <span className="text-black">
                                                                    <strong>{parseFloat(maxMargin)?.toFixed(1)}%</strong>
                                                                </span>
                                                            </div>
                                                        )}
                                                    {footerContent[dropdownValue] &&
                                                        footerContent[dropdownValue].includes &&
                                                        footerContent[dropdownValue].includes("maxFrcMargin") && (
                                                            <div className="d-md-flex flex-column">
                                                                FC Margin:{" "}
                                                                <span className="text-black">
                                                                    <strong>{parseFloat(maxFrcMargin)?.toFixed(1)}%</strong>
                                                                </span>
                                                            </div>
                                                        )}
                                                    {footerContent[dropdownValue] &&
                                                        footerContent[dropdownValue].includes &&
                                                        footerContent[dropdownValue].includes("footerDisc") && (
                                                            <div className="d-md-flex flex-column">
                                                                Disc:{" "}
                                                                <span className="text-black">
                                                                    <strong>{footerDisc?.toFixed(1)}%</strong>
                                                                </span>
                                                            </div>
                                                        )}

                                                    {footerContent[dropdownValue] &&
                                                        footerContent[dropdownValue].includes &&
                                                        footerContent[dropdownValue].includes("maxActMargin") && (
                                                            <div className="d-md-flex flex-column">
                                                                AvgC Margin:{" "}
                                                                <span className="text-black">
                                                                    <strong>{maxActMargin?.toFixed(1)}%</strong>
                                                                </span>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                  {resetQuoteDialogVisible && (
                            <Dialog title={"Reset Quote"} onClose={toggleResetQuoteDialog}>
                                <ResetQuote handleOkSet={handleOkSet} />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button className="px-4 me-2 ip-button-general" onClick={handleOkSet}>
                                            Execute
                                        </Button>
                                        <Button className="px-4" onClick={toggleResetQuoteDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                                </div>
                            </Collapse>
                        </AccordionItem>
                    </Accordion>
                </Row>

                <button className={`back-to-top ${isVisible ? 'show' : 'hide'}`} onClick={scrollToTop} title="Back to Top"><i className="ri-arrow-up-line"></i></button>
            </div>
        );
    } else if (module == "new-units") {
        //Temporarily we have repeated the code the new units. But we need to figure out none repeatition of code when we do the new units module
        /// we need to re-look at the repeating code and solve it on another way.
        return (
            <div className="ip-subnode-container new-units" key={key}>
                <div className="row">
                    <div className="col">
                        <h1 className="ip-sub-h1 bold">
                        <span>Unit: {unitDetails[id]?.newUnitName}</span>
                            <span contentEditable={false}></span>
                        </h1>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-row-reverse">
                        

                            <nav className="hide-for-mobile" aria-label="breadcrumb">
                                <ol className="breadcrumb mx-3 pt-2">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            <i className="ri-home-5-fill"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <a href="#">New Units</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Row>
                    <NewUnitDataGrid 
                    triggerUnitSaving={triggerUnitSaving} 
                    isSavedUnitChange={isSavedUnitChange}
                    isNavigate = {isNavigate}
                    />
                </Row>
                <div className="row m-0 ip-item-summary">
                    <div className="col-md-8 col-sm-12">
                    <div className="">
                        Total Part Lines:{" "}
                        <span className="text-black">
                            <strong>{totalOfUnitItems}</strong>
                        </span>
                    </div>
                    </div>
                </div>
            </div>
        );
    } else if (module == "price-lists") {
        //Temporarily we have repeated the code the new units. But we need to figure out none repeatition of code when we do the new units module
        /// we need to re-look at the repeating code and solve it on another way.
        // return (
        //     <div className="ip-subnode-container" key={key}>
        //         Pricing Under Construction...
        //     </div>
        return (
                        
            <div className="ip-subnode-container price-lists" key={key}>
                <div className="row">
                    <div className="col">
                        <h1 className="ip-sub-h1 bold">
                            <span>{"Price Lists"}</span>
                            <span contentEditable={false}></span>
                        </h1>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-row-reverse">
                           
                            {/* {true && (
                                <button
                                    type="button"
                                    className="btn btn-icon waves-effect waves-light mx-1"
                                    title="Delete Permanently"
                                    onClick="">
                                    <i className="ri-delete-bin-6-line ip-icon-size-lg red"></i>
                                </button>
                            )} */}
                            {/* <button
                                type="button"
                                className="btn btn-icon waves-effect waves-light mx-1"
                                title="Save Price List"
                                // onClick={triggerSaveUnit}>
                                onClick={triggerSavePriceList}>
                                <i className="ri-save-3-line ip-icon-size-lg blue"></i>
                            </button> */}
                            <nav className="hide-for-mobile" aria-label="breadcrumb">
                                <ol className="breadcrumb mx-3 pt-2">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            <i className="ri-home-5-fill"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <a href="#">New Price Lists</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Row>
                    {/* <NewPriceListDataGrid triggerUnitSaving={triggerUnitSaving} /> */}
                    <NewPriceListItemDataGrid triggerPriceListSaving={triggerPriceListSaving} />
                </Row>
            </div>
            
        );
    } else if (module == "library") {
        //Temporarily we have repeated the code the new units. But we need to figure out none repeatition of code when we do the new units module
        /// we need to re-look at the repeating code and solve it on another way.
        return (
            <div className="ip-subnode-container" key={key}>
              {/* <Row>
                    <NewUnitDataGrid 
                    triggerUnitSaving={triggerUnitSaving} 
                    isSavedUnitChange={isSavedUnitChange}
                    isNavigate = {isNavigate}
                    />
                </Row> */}
              </div>
        );
    }else if (module == "unit") {
        //Temporarily we have repeated the code the new units. But we need to figure out none repeatition of code when we do the new units module
        /// we need to re-look at the repeating code and solve it on another way.
        return (
            <div className="ip-subnode-container" key={key}>
                <div className="row">
                    <div className="col">
                        <h1 className="ip-sub-h1 bold">
                        <span>Unit: {createdName !== "" ? createdName : "New Unit"}</span>
                            <span contentEditable={false}></span>
                        </h1>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-row-reverse">
                           
                            <nav className="hide-for-mobile" aria-label="breadcrumb">
                                <ol className="breadcrumb mx-3 pt-2">
                                    <li className="breadcrumb-item">
                                        <a href="#">
                                            <i className="ri-home-5-fill"></i>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <a href="#">Units</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Row>
                    <NewUnitDataGrid 
                    triggerUnitSaving={triggerUnitSaving} 
                    isSavedUnitChange={isSavedUnitChange}
                    isNavigate = {isNavigate}
                    />
                </Row>
                <div className="row m-0 ip-item-summary">
                    <div className="col-md-8 col-sm-12">
                    <div className="">
                        Total Part Lines:{" "}
                        <span className="text-black">
                            <strong>{totalOfUnitItems}</strong>
                        </span>
                    </div>
                    </div>
                </div>
     </div>


        );
    }
    
};

export default SubNodePage;
