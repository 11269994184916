// HiddenStatusContext.js
import React, { createContext, useState , useEffect } from 'react';

export const HiddenStatusContext = createContext();

export const HiddenStatusProvider = ({ children }) => {
  const [hiddenStatus, setHiddenStatus] = useState({});
  const [graphVisible, setGraphVisible] = useState(false);

  useEffect(() => {
    // Load hiddenStatus from local storage when component mounts
    const savedStatus = localStorage.getItem('hiddenStatus');
    if (savedStatus) {
      setHiddenStatus(JSON.parse(savedStatus));
    }
  }, []);

  useEffect(() => {
    // Save hiddenStatus to local storage whenever it changes
    localStorage.setItem('hiddenStatus', JSON.stringify(hiddenStatus));
  }, [hiddenStatus]);

  const handleColumnToggle = (dataItem, column) => {
    const id = dataItem.rowId;  // Assume stStockCode uniquely identifies a row

    setHiddenStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        if (!newStatus[id]) {
            newStatus[id] = { hideRow: false, hideQty: false, hidePrice: false };
        }
        newStatus[id][column] = !newStatus[id][column];
        return newStatus;
    });
};


  return (
    <HiddenStatusContext.Provider value={{ hiddenStatus, handleColumnToggle ,setHiddenStatus,graphVisible, setGraphVisible }}>
      {children}
    </HiddenStatusContext.Provider>
  );
};
