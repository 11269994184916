import React from "react";

const Spinner = () => {
    return (
        <React.Fragment>
            <div className="ip-loader">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Spinner;
