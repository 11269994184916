import React, { createContext, useContext, useState } from "react";

const MarginAndPriceContext = createContext();

export const useMarginAndPrice = () => useContext(MarginAndPriceContext);

export const MarginAndPriceProvider = ({ children }) => {
    const [margin, setMargin] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalCostAmt, setTotalCostAmt] = useState(0);
    const [newPrice, setNewPrice] = useState(0); 
    const [newMargin, setNewMargin] = useState(0); 
    const [allowSetValue, setAllowSetValue] = useState(false);
    const [allowSetMarginValue, setAllowSetMarginValue] = useState(false);
    const [isMarginSelected, setIsMarginSelected] = useState(true); // Initialize with true for margin
    const [isPriceSelected, setIsPriceSelected] = useState(false);
    const [itemGridCount, setItemGridCount] = useState(0);
    const [isKeyPressedForSave, setIsKeyPressedForSave] = useState(false);
    const [totalItemPrice, setTotalItemPrice] = useState(0);
    const [maxMargin, setMaxMargin] = useState(0);
    const [isValue, setIsValue] = useState(false);
    return (
        <MarginAndPriceContext.Provider value={{maxMargin, setMaxMargin,totalItemPrice, setTotalItemPrice, margin, setMargin,total, setTotal, totalCostAmt, setTotalCostAmt,newPrice, setNewPrice,newMargin, 
        setNewMargin,allowSetValue, setAllowSetValue,allowSetMarginValue, setAllowSetMarginValue,isMarginSelected, 
        setIsMarginSelected,isPriceSelected, setIsPriceSelected,itemGridCount, setItemGridCount,isKeyPressedForSave, setIsKeyPressedForSave, isValue, setIsValue}}>
            {children}
        </MarginAndPriceContext.Provider>
    );
};
