import React, { createContext, useContext, useState } from "react";


const TrigerSaveQuoteContext = createContext();

export const useTrigerSaveQuoteContext = () => useContext(TrigerSaveQuoteContext);

export const TrigerSaveQuote = ({children}) => {
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [isdraftToOriginal, setIsdraftToOriginal] = useState(false);  
    const [isSavedUpdated, setIsSavedUpdated] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isSaveAs, setIsSaveAs] = useState(false);
    const [visibleSaveAs, setVisibleSaveAs] = useState(false);
    const [quoteCreatedbyUser, setQuoteCreatedbyUser] = useState("");
    const [quoteDateCreatedOn, setQuoteDateCreatedOn] = useState("");
    const [revision, setRevision] = useState(false);
    const [suffix, setSuffix] = useState(false);
    return (
      <TrigerSaveQuoteContext.Provider value={{quoteDateCreatedOn, setQuoteDateCreatedOn,suffix, setSuffix, revision, setRevision,isSaveClicked, setIsSaveClicked, isSaved, setIsSaved,isSaveAs, setIsSaveAs,visibleSaveAs, setVisibleSaveAs,quoteCreatedbyUser, setQuoteCreatedbyUser, isdraftToOriginal, setIsdraftToOriginal , isSavedUpdated, setIsSavedUpdated }}>
        {children}
      </TrigerSaveQuoteContext.Provider>
    );
  };
