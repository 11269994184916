import React, {useContext} from "react";
import { postResponse } from "../Services/Common/Post";
import { getResponse } from "../Services/Common/Get";
import { toast, Slide } from "react-toastify";
//#region import approval workflow
import { QteApprovalNotificationContext } from "../Contexts/QteApprovalNotificationContext";
//#endregion

export async function handleQuoteAuditTrail (audit,shareQuote = false) 
{   
   if (audit === null) 
   {
       toast.error("Audit data is Empty.", {
        position: "top-center",
        transition: Slide,
    });
       return;
   }
   if(audit.approvedUserId === null)
   {
       toast.error("Approver Id is Empty.", {
        position: "top-center",
        transition: Slide,
    });
       return;
   }
   if(audit.quoteId === null)
   {
       toast.error("Quote Id is Empty.", {
        position: "top-center",
        transition: Slide,
    });
       return;
   }
   if(audit.userId === null)
   {
       toast.error("User Id is Empty.", {
        position: "top-center",
        transition: Slide,
    });
       return;
   }
   await postResponse("Quote/createapprovalaudit", audit)
       .then((response) => 
       {
           if (response.data !== null) 
           {
               console.log("Successfully created an approval audit.");
               handleSendEmail(audit.quoteId, audit.userId, shareQuote, "");
               
           }
           else 
           {
               console.log("Failed to save data.");
           }
       })
       .catch((error) => 
       {
           console.error("Error auditing:", error);
           toast.error("Error auditing approval.", {
                position: "top-center",
                transition: Slide,
            });
            return;
       })
}

export async function handleSendEmail (qID, userId, shareQuoteVal = false, sharedComment = "")
{
    const emailData = {
       quoteId : qID,
       userId : userId,
       shareQuote: shareQuoteVal,
       webUrl: window.location.origin,
       sharedComment: sharedComment.length === 0 ? "N/A" : sharedComment
   };

   await getResponse("Quote/sendemail", emailData)
   .then((response) => 
       {
           if (response.data !== null) 
           {
               return response;
           }
           else 
           {
               console.log("Failed sending email.");
           }
       })
       .catch((error) => 
       {
           console.error("Error sending email:", error);
           toast.error("Error sending email.", {
                position: "top-center",
                transition: Slide,
            });
           return;
       })
}

// export async function fetchApprovalStatus() 
// {
//     const response = await getResponse("Quote/getapprovalstatslist",{});
//     if (Object.keys(response).length > 0 ) {
//         setApprovalStatList(response.data);
//     }
// }


