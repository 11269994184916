import React, { createContext, useContext, useState } from "react";

const IGDconlpRowContext = createContext();

export const useDconlpRow = () => useContext(IGDconlpRowContext);

export const IGDconlpProvider = ({ children }) => {
    const [rowDconlpData, setDconlpRowData] = useState({});

    const updateDconlpRowForId = (id, data) => {
        setDconlpRowData((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <IGDconlpRowContext.Provider value={{ rowDconlpData, setDconlpRowData, updateDconlpRowForId }}>
            {children}
        </IGDconlpRowContext.Provider>
    );
};
