import React, { createContext, useState } from "react";

const QuoteCustomerIdContext = createContext();

const AppProvider = ({ children }) => {
  const [quoteCustomerId, setQuoteCustomerId] = useState([]);

  return (
    <CustomerNumberContext.Provider value={{ quoteCustomerId, setQuoteCustomerId }}>
      {children}
    </CustomerNumberContext.Provider>
  );
};

export { QuoteCustomerIdContext, AppProvider };