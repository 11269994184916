import React, { createContext, useContext, useState } from "react";

const SubGroupListContext = createContext();

export const useSubGroupList = () => useContext(SubGroupListContext);

export const SubGroupListProvider = ({ children }) => {
    const [sgList, setSGroupList] = useState({});

    const updateSubGroupListForId = (id, data) => {
        setSGroupList((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <SubGroupListContext.Provider value={{ sgList, setSGroupList, updateSubGroupListForId }}>
            {children}
        </SubGroupListContext.Provider>
    );
};
