import React, { useState, useEffect, useContext } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useParams } from "react-router-dom";
import { QuoteCustomerContext } from "../../Contexts/QuoteCustomerContext"; // adjust path as needed
import { useQuoteHeaders } from "../../Contexts/QuoteHeadersProvider";
import { useNodeContext } from "../../Contexts/NodeProvider";

const QouteHeaders = ({
    projectName,
    setProjectName,
    category,
    setCategory,
    quoteDate,
    setQuoteDate,
    isCateoryChanged,
    setIscategoryChanged,
    isHeaderChanged,
    setIsHeaderChanged,
    header,
    setHeader,
    quoteDetails,
    updateQuoteDetailsForId,
    categories,
    headers,
}) => {
    const { quoteCustomer } = useContext(QuoteCustomerContext);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedHeader, setSelectedHeader] = useState();
    const { id } = useParams();
    const {
        setQuoteUpdated,
        setIsQuoteSaved
    } = useNodeContext();
    const { quoteHeaders, updateQuoteHeadersForId, setQuoteHeaders, systemId, setSystemId } = useQuoteHeaders();
    
    useEffect(() => {
        const storedCategory = sessionStorage.getItem(`category_${id}`);
        const storedHeader = sessionStorage.getItem(`header_${id}`);
        
        if (quoteDetails[id]  && quoteDetails[id]?.quoteType == null && quoteDetails[id]?.header == null && (quoteDetails[id]?.savedQuote == null || quoteDetails[id]?.savedQuote?.quoteNumber.includes("Draft"))) {
            setSelectedCategory(storedCategory ? storedCategory : categories[0]);
            setSelectedHeader(storedHeader ? storedHeader : headers[0]);
            setQuoteDate(quoteDate ? quoteDate : new Date());
        }
    }, [id, systemId, categories, headers, quoteDate, setQuoteDate , quoteDetails , setQuoteHeaders, setSelectedCategory , setSelectedHeader , category]);

    useEffect(() => {
        if (quoteDetails[id]) {
            setQuoteDate(quoteDetails[id]?.quoteDate ? quoteDetails[id]?.quoteDate : new Date());
            setProjectName(quoteDetails[id]?.projectName ? quoteDetails[id]?.projectName : "");

            setCategory(quoteDetails[id]?.quoteType ? quoteDetails[id]?.quoteType : categories[0]);
            setSelectedCategory(quoteDetails[id]?.quoteType ? quoteDetails[id]?.quoteType : categories[0]);

            setHeader(quoteDetails[id]?.header ? quoteDetails[id]?.header : headers[0]);
            setSelectedHeader(quoteDetails[id]?.header ? quoteDetails[id]?.header : headers[0]);
        }
    }, [id]);

    const handleQuoteTypeCategory = (e) => {
        
        setQuoteUpdated(true);
        const selectedValue = e.target.value;
        setSelectedCategory(selectedValue);
        setCategory(selectedValue);
        setIsQuoteSaved(false);
        setIscategoryChanged(true);
        updateQuoteDetailsForId(id, { quoteType: selectedValue });
    };

    const handleHeaderType = (e) => {
        setQuoteUpdated(true);
        setIsQuoteSaved(false);
        const selectedValue = e.target.value;
        setSelectedHeader(selectedValue);
        setHeader(selectedValue);
        setIsHeaderChanged(true);
        updateQuoteDetailsForId(id, { header: selectedValue });
    };

    const handleNameChange = (e) => {
        setQuoteUpdated(true);
        setIsQuoteSaved(false);
        const name = e.target.value;
        setProjectName(name);
        updateQuoteDetailsForId(id, { projectName: name });
    };

    const handleDateChange = (e) => {
        setQuoteUpdated(true);
        setIsQuoteSaved(false);
        const date = e.target.value;
        setQuoteDate(date);
        updateQuoteDetailsForId(id, { quoteDate: date });
    };

    return (
        <div className="row p-0 m-0">
            <div className="mb-2">
                <div className="col-12 col-md-6 pe-2 float-start">
                    <strong>Project Name</strong>
                    <Input id = "txtProjectName" value={projectName} onChange={(e) => handleNameChange(e)} />
                </div>

                <div className="col-12 col-md-2 pe-2 float-start">
                    <strong>Category</strong>
                    <DropDownList
                        id = "btnDrplCategory"
                        data={categories}
                        valueField="category"
                        value={selectedCategory}
                        onChange={(e) => {
                            handleQuoteTypeCategory(e);
                        }}
                        defaultValue={categories[0]}
                    />
                </div>
                <div className="col-12 col-md-2 pe-2 float-start">
                    <strong>Quote Date</strong>
                    <DatePicker 
                        id = "dtpQuoteDate"
                        format={"dd/MM/yyyy"}
                        value={quoteDate}
                        onChange={(e) => {
                            handleDateChange(e);
                        }}
                    />
                </div>

                <div className="col-12 col-md-2 float-start">
                    <strong>Header</strong>
                    <DropDownList
                        id = "btnDrplHeader"
                        data={headers}
                        valueField="headerName" // Add this line
                        value={selectedHeader}
                        onChange={(e) => {
                            handleHeaderType(e);
                        }}
                        defaultValue={headers[0]}
                        style={{ marginLeft: "auto" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default QouteHeaders;
