import React, { createContext, useContext, useState } from "react";

const SystemSettingsContext = createContext();

export const useSystemSettings = () => useContext(SystemSettingsContext);

export const SystemSettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(null);

    return (
        <SystemSettingsContext.Provider value={{ settings, setSettings }}>{children}</SystemSettingsContext.Provider>
    );
};
