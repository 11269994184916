import React, { createContext, useState } from "react";

const QteRepDiscCheckerContext = createContext();

const AppProvider = ({ children }) => {
    const [quoteRepDiscData, setQuoteRepDiscData] = useState([]);



    return (
        <QteRepDiscCheckerContext.Provider value={{ quoteRepDiscData, setQuoteRepDiscData }}>
        {children}
        </QteRepDiscCheckerContext.Provider>
    );
};

export { QteRepDiscCheckerContext, AppProvider };

