import React, { createContext, useContext, useState } from "react";

const QuoteHeadersContext = createContext();

export const useQuoteHeaders = () => useContext(QuoteHeadersContext);

export const QuoteHeadersProvider = ({ children }) => {
    const [quoteHeaders, setQuoteHeaders] = useState([]);
    const [systemId,setSystemId]=useState("");
    const updateQuoteHeadersForId = (id, data) => {
        setQuoteHeaders((prevQuoteDetails) => ({
            ...prevQuoteDetails,
            [id]: { ...prevQuoteDetails[id], ...data },
        }));
    };

    return (
        <QuoteHeadersContext.Provider value={{ quoteHeaders, updateQuoteHeadersForId, setQuoteHeaders,systemId,setSystemId }}>
            {children}
        </QuoteHeadersContext.Provider>
    );
};
