import React, { useState ,useMemo,useEffect} from 'react';
import { Dialog, DialogActionsBar} from '@progress/kendo-react-all';
import { Button } from "@progress/kendo-react-buttons";
import { TextBox, Input, RadioButton, TextArea, Checkbox } from "@progress/kendo-react-inputs";
import  "../../styles/importDialog.css";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import Papa from 'papaparse'; 
import { useImportData } from "../../Contexts/ImportUnitData";
import { toast, Slide } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const ImportDialogUnit = ({onClose , handlePasteButtonClick}) => {

  const [file, setFile] = useState(null);
  const [hasHeaders, setHasHeaders] =useState(true)
  const [hasPartNo, setPartNo] =useState(true)
  const [hasQty, setQty] =useState(true)
  const [hasEachPrice, setEachPrice] =useState(false)
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPartNo, setSelectedPartNo] = useState({ id: 0, text: "PartNo" });
  const [selectedQty, setSelectedQty] = useState({ id: 1, text: "Description" });
  const [selectedEachPrice, setSelectedEachPrice] = useState({ id: 2, text: "Type" });
  const [csvData, setCsvData] = useState([]);
  const {isImportData, setIsImportData,isLastRowSelected, setIsLastRowSelected,isSelectedRow, setIsSelectedRow,importedData,storeImportedData} = useImportData();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const isNextButtonDisabled = !file; // Disable the "Next" button if no file is selected

  const handleNextButtonClick = () => {
    setCurrentStep(currentStep + 1);

    if (currentStep === 1) {
      Papa.parse(file, {
        header: hasHeaders,
        dynamicTyping: true,
        complete: (result) => {
          // Assuming the CSV has columns "Part No" and "Qty"
          // setCsvData(result.data.map((row) => ({
          //   stStockCode: row["PartNo"],
          //   description: row["Description"],
          //   type: row["Type"],
          //   listPrice : row["ListPrice"],
          //   qty: row["Qty"],
            
            

          //   // Add other fields as needed
          // })));
          const importedDataWithUuid = result.data.map((row) => ({
            rowId: uuidv4(), // Generate a unique UUID for each row
            stStockCode: row["PartNo"],
            description: row["Description"],
            type: row["Type"],
            listPrice: row["ListPrice"],
            qty: row["Qty"],
            // Add other fields as needed
          }));

          setCsvData(importedDataWithUuid);
          //setIsImportData(true);
        },
      });
    }
  };
  const handleQtyCheckboxChange = () => {
    setQty(!hasQty);
  };
  const handleHeaderCheckboxChange = () => {
    setHasHeaders(!hasHeaders);
  };

  const handleEachPriceCheckboxChange = () => {
    setEachPrice(!hasEachPrice);
  };
  const optionsImport = useMemo(
    () => [
        { id: 0, text: "PartNo" ,field:"stStockCode" },
        { id: 1, text: "Description" ,field:"description" },
        { id: 2, text: "Type" ,field:"type" },
        { id: 3, text: "ListPrice" ,field:"listPrice" },
        { id: 4, text: "Qty" ,field:"qty" },
    ],
    []
  );
  const columns = useMemo(
    () => [
        { field: "stStockCode", title: "Part No", editable: true },
        { field: "description", title: "Description", editable: false },
        { field: "type", title: "Type", editable: false },
        { field: "listPrice", title: "List Price", editable: false },
        { field: "qty", title: "Qty", editable: true },
    ],
    []
  );
 

  useEffect(() => {
    console.log("Dropdown values:", selectedPartNo.field, selectedQty.field, selectedEachPrice.field);
    if(selectedPartNo.field === undefined){
      selectedPartNo.field = 'stStockCode'
    }
    if(selectedQty.field === undefined){
      selectedQty.field = 'description'
    }
    if(selectedEachPrice.field === undefined){
      selectedEachPrice.field = 'type'
    }
  }, [selectedPartNo.field, selectedQty.field, selectedEachPrice.field]);

  const maxRowsToShow = 3; //in grid show only no of 3 data rows
  const dataLength = csvData.length;

  //radio buttons handling
  const handleImportLastRowSelection = () => {
    setIsLastRowSelected(true);
    setIsSelectedRow(false);
  };
  const handleImportSelectedRow = () => {
    setIsLastRowSelected(false);
    setIsSelectedRow(true);
  };
  const handleImportData = async () => {
    if((hasQty == true && selectedQty.field !== 'qty') || (hasEachPrice == true && selectedEachPrice.field !== 'listPrice') || selectedPartNo.field !== 'stStockCode'){
      toast.error("Data type mismatch in criteria expression", {
        position: "top-center",
        transition: Slide,
    });
      setIsImportData(false)
    }
    else{
    setIsImportData(true);
    storeImportedData(csvData);
    await handlePasteButtonClick(); // Wait for the paste operation to complete
    onClose(); 
    }   
  };
  useEffect(() => {
    if (isImportData) {
      handleImportData();
    }
  }, [isImportData, csvData]);
  
  

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
                  <Dialog width="50%" title="Import Multiple Records from a File" onClose={onClose}>
                  <div class="mb-3">This wizard helps you import Part No and Qty into the current quote or unit</div>
                  <div class="mb-2">Source import file :</div>
                  <div className="imp-dialog-section">  
                    <input type="file" onChange={handleFileChange} />
                    </div>
                    <div className="d-flex align-items-center">
                  <Checkbox
                      type="checkbox"
                      className="form-check-input form-check-input ip-SOHsub-check-show me-1 mt-2"
                      checked={hasHeaders}
                      onChange={handleHeaderCheckboxChange}
                  />
                  <div className="me-2 mb-3 mt-2">First row contains column headings</div>
                </div>
                <div className="me-2 mb-1">Fields to be imported :</div>
                <div className="d-flex align-items-center">
                        <Checkbox
                              type="checkbox"
                              className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                              checked={hasPartNo}                
                              disabled={true}
                          />
                  
                        <div className="me-2 mb-1">Part No</div>
                  </div>

                  <div className="d-flex align-items-center">
                          <Checkbox
                              type="checkbox"
                              className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                              checked={hasQty}
                              onChange={handleQtyCheckboxChange}
                          />
                          <div className="me-2 mb-1">Qty</div>
                  </div>

                  <div className="d-flex align-items-center">
                        <Checkbox
                            type="checkbox"
                            className="form-check-input form-check-input ip-SOHsub-check-show me-1"
                            checked={hasEachPrice}
                            onChange={handleEachPriceCheckboxChange}
                        />
                        <div className="me-2 mb-1">Each Price</div>
                  </div>

                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button className="px-4 me-2 ip-button-general" onClick={handleNextButtonClick} disabled={isNextButtonDisabled}>
                                Next
                            </Button>
                            <Button className="px-4" onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                    </DialogActionsBar>
                  </Dialog>
                </>
        );

        case 2:
          return (
            <>
                    <Dialog width="50%" title="Import Multiple Records from a File" onClose={onClose}>
                    <div class="mb-3">This wizard helps you import Part No and Qty in to the current quote or unit</div>
                    <div class="mb-3">Source import file : {file ? file.name : 'N/A'}</div>
                              <table className="mt-2 ip-set-pricemargin">
                                      <tr>
                                          <th> </th>
                                          <th>Target Field</th>
                                          <th>Source Field</th>
                                      </tr>
                                      <tr>
                                          <td>Part No :</td>
                                          <td>
                                          <DropDownList
                                                        data={optionsImport}
                                                        textField="text"
                                                        dataItemKey="id"
                                                        value={optionsImport.find((opt) => opt.id === selectedPartNo.id)}
                                                        onChange={(e) => setSelectedPartNo(optionsImport.find((opt) => opt.id === e.target.value.id))}
                                                      //  onChange={(e) => setSelectedPartNo(e.target.value)}
                                                      //  disabled={true}
                                           />
                                          </td>
                                    </tr>
                                    <tr>
                                          <td>Qty :</td>
                                          <td>
                                          <DropDownList
                                                        data={optionsImport}
                                                        textField="text"
                                                        dataItemKey="id"
                                                        value={hasQty ? optionsImport.find((opt) => opt.id === selectedQty.id) : 'N/A'}
                                                        onChange={(e) => setSelectedQty(optionsImport.find((opt) => opt.id === e.target.value.id))}     
                                                      //  onChange={(e) => setSelectedQty(e.target.value)}
                                                        disabled={!hasQty}
                                           />
                                          </td>
                                    </tr>
                                    <tr>
                                          <td>Each Price :</td>
                                          <td>
                                          <DropDownList
                                                        data={optionsImport}
                                                        textField="text"
                                                        dataItemKey="id"
                                                        value={hasEachPrice ? optionsImport.find((opt) => opt.id === selectedEachPrice.id) : null}
                                                        onChange={(e) => setSelectedEachPrice(optionsImport.find((opt) => opt.id === e.target.value.id))}
                                                        disabled={!hasEachPrice}
                                                        // value={hasEachPrice ? optionsImport.find((opt) => opt.text === setSelectedEachPrice)  : 'N/A' }
                                                        // onChange={(e) => setSelectedEachPrice(e.target.value)}
                                                        // disabled={!hasEachPrice}
                                           />
                                          </td>
                                    </tr>
                              </table>
  
                      <DialogActionsBar>
                          <div className="d-flex justify-content-end ip-dialog-btnstatic">
                          <Button className="px-4 me-2 ip-button-general" onClick={() => setCurrentStep(currentStep - 1)}>
                                  Back
                              </Button>
                              <Button className="px-4 me-2 ip-button-general" onClick={handleNextButtonClick} disabled={isNextButtonDisabled}>
                                  Next
                              </Button>
                              <Button className="px-4" onClick={onClose}>
                                  Cancel
                              </Button>
                          </div>
                      </DialogActionsBar>
                    </Dialog>
                  </>
          );
  
        
      
          case 3:
            return (
              <>
                      <Dialog width="50%" title="Import Multiple Records from a File" onClose={onClose}>
                      <div class="mb-3">This wizard helps you import Part No and Qty in to the current quote or unit</div>
                      <div class="mb-3">Source import file : {file ? file.name : 'N/A'}</div>
                      <div class="mb-3"><strong>Sample Data :</strong></div>
                                {/* view data grid view, colums field are (partno,description,type) using import .csv file column name and mapping this grid column name and load data
                        */}
                      
                        <Grid data={csvData.slice(0, maxRowsToShow)}>
                          <GridColumn field={selectedPartNo.field} title={selectedPartNo.text} />
                          <GridColumn  field={hasQty ? selectedQty.field : 'N/A'} title={hasQty ? selectedQty.text: 'N/A'} />
                          <GridColumn field={hasEachPrice ? selectedEachPrice.field : 'N/A'} title={hasEachPrice ?selectedEachPrice.text : 'N/A'} />
                        </Grid>
                        <div className="d-flex align-items-center">
                              <input
                                  type="radio"
                                  id="lastrow"
                                  name="lastrow"
                                  checked={isLastRowSelected}
                                  onChange={handleImportLastRowSelection}                
                              />
                            <div className="me-2">Import at the last row</div>   
                        </div>
                        <div className="d-flex align-items-center">
                              <input
                                  type="radio"
                                  id="selectedrow"
                                  name="selectedrow"
                                  checked={isSelectedRow}
                                  onChange={handleImportSelectedRow}                
                              />
                              <div className="me-2">Insert at the selected row</div>   
                        </div>

                        <div class="mb-3"><strong>Total Record To Import : </strong> {dataLength} </div>
                             

                        <DialogActionsBar>
                            <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button className="px-4 me-2 ip-button-general" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Back
                                </Button>
                                <Button className="px-4 me-2 ip-button-general" onClick={handleImportData} disabled={isNextButtonDisabled}>
                                    Import
                                </Button>
                                <Button className="px-4" onClick={onClose}>
                                    Cancel
                                </Button>
                            </div>
                        </DialogActionsBar>
                      </Dialog>
                    </>
            );
    
          
        
        default:
          return null;
      };
  };
  return <>{renderStepContent()}</>;
}
export default ImportDialogUnit;