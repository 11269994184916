import { React, useState, useContext } from "react";
import { LookupTypes } from "../../enums/LeftNavTypes"; 
import Lookup from "../Layouts/Lookup";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { getStockModel } from "../../models/Quotations";
import { getResponse } from "../../Services/Common/Get";
import { useStockCode } from "../../Contexts/StockCodeProvider";
const selectColumns = [
    { field: "stStockCode", title: "Part No" },
    { field: "fullDescription", title: "Full Desc" },
    { field: "stStatus", title: "Type" },
];
const selectColumnss = [
    { field: "stStockCode", title: "Part No" },
    { field: "fullDescription", title: "Full Desc" },
];

const addColumns = [{ field: "whse", title: "Whse" },{ field: "soh", title: "Available" }];



const SOHLookup = ({ onAddItems }) => {
    const {stockCode, setStockCode,ststatus, setStstatus,totalSoh, setTotalSoh,stockNumber, setStockNumber} = useStockCode();
    // const [ stockNumber, setStockNumber ] =useState(" ")
    const apiURL = "Stocks/searchpartsforcomponentparts"; // your API endpoint
    const filterOptions = [{ text: "All", value: "all" }, ...selectColumnss.map((column) => ({ text: column.title, value: column.field }))];
    const { addItems, setAddItems } = useContext(RemoveItemsContext);

    const handleAddItems = (selectedItems) => {
        console.log("Selected items:", selectedItems);
        onAddItems && onAddItems(selectedItems);

        // console.log("hihhihihi :",stockNumber)
        // const data =  getResponse("SOH/getsohbystockcode", { stStockCode: stockNumber });

        //         console.log("data :", data);
        // Add the selected items to the "Items to Add" grid
        setAddItems( [...selectedItems]);
       
    };
    const handleRowClick = (selectedRow) => {
        console.log("Row clicked:", selectedRow);
        // You can perform additional actions here when a row is clicked.
      };
    return (
        <Lookup
           type = {LookupTypes.SOH}
            apiURL={apiURL} // pass the API endpoint here
            selectColumns={selectColumns}
            addColumns={addColumns}
            showSelectionColumn={false}
            idField="stStockCode"
            selectTableName="Stock Number to Select"
            conversionFunction={getStockModel}
            addTableName="Available SOH And Whse"
            filterOptions={filterOptions}
            onRowClick={handleRowClick} 
            onAddItems={handleAddItems}
            onAddItemsToGrid={handleAddItems}
            stockNumber={stockNumber}
            setStockNumber={setStockNumber}
            singleSelect={true}
        />
    );
};

export default SOHLookup;









