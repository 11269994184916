import React, { createContext, useContext, useState } from "react";

const IGMarginRowContext = createContext();

export const useMarginRow = () => useContext(IGMarginRowContext);

export const IGMarginProvider = ({ children }) => {
    const [rowMarginData, setMarginRowData] = useState({});

    const updateMarginRowForId = (id, data) => {
        setMarginRowData((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <IGMarginRowContext.Provider value={{ rowMarginData, setMarginRowData, updateMarginRowForId }}>
            {children}
        </IGMarginRowContext.Provider>
    );
};
