import React from "react";
import ReactDOM from "react-dom/client";

/*UI*/
import 'remixicon/fonts/remixicon.css';
import 'boxicons/css/boxicons.css';
import "./assets/kendo-ipd/scss/index.scss";
import "./assets/default/scss/themes.scss";
import '@progress/kendo-font-icons/dist/index.css';
//import '@progress/kendo-theme-bootstrap/node_modules/@progress/kendo-font-icons/dist/index.css';

import App from "./App.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>
);
