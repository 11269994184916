import React, { createContext, useContext, useState } from "react";

const QuoteDetailsContext = createContext();

export const useQuoteDetails = () => useContext(QuoteDetailsContext);

export const QuoteDetailsProvider = ({ children }) => {
    const [quoteDetails, setQuoteDetails] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [quoteCreatedDate, setQuoteCreatedDate] = useState("");
    const [isUngroup, setIsUngroup] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const updateQuoteDetailsForId = (id, data) => {
        setQuoteDetails((prevQuoteDetails) => ({
            ...prevQuoteDetails,
            [id]: { ...prevQuoteDetails[id], ...data },
        }));
    };

    return (
        <QuoteDetailsContext.Provider value={{ quoteDetails, updateQuoteDetailsForId, setQuoteDetails,quoteCreatedDate, 
                setQuoteCreatedDate,isUngroup, setIsUngroup,isGroup, setIsGroup, checkedItems, setCheckedItems }}>
            {children}
        </QuoteDetailsContext.Provider>
    );
};
