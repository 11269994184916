import React, { useState, useEffect, useContext } from "react";

const PrimaryCustomerContext = React.createContext();

const PrimaryCustomerProvider = ({ children }) => {
  const [primaryCustomerId, setPrimaryCustomerId] = useState(null);

  return (
    <PrimaryCustomerContext.Provider value={{ primaryCustomerId, setPrimaryCustomerId }}>
      {children}
    </PrimaryCustomerContext.Provider>
  );
};

export { PrimaryCustomerContext, PrimaryCustomerProvider };
