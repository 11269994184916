import React, { useContext, useEffect } from "react";
import { LookupTypes } from "../../enums/LeftNavTypes";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomerNumberContext } from "../../Contexts/CustomerNumberContext";
import { useStockCode } from "../../Contexts/StockCodeProvider";
import { useNewItemList } from "../../Contexts/NewItemListContextProvider";
//import { QouteCustomerIdContext, QuoteCustomerIdContext } from "../../Contexts/QouteCustomerIdContext";

const CheckboxCell = ({
    type,
    dataItem,
    selectedRows,
    setSelectedRows,
    idField,
    singleSelect = false,
    onRowSelection,
    getQuoteDetailsById,
    isGroupable,
    selectMultipleItems,
    stockNumber,
    setStockNumber
}) => {
    const {allowCopy, setAllowCopy }=useNewItemList()
    const isChecked = selectedRows.some((row) => row[idField] === dataItem[idField]);
    const { customerNumber, setCustomerNumber } = useContext(CustomerNumberContext);
    //const { quoteCustomerId, setQuoteCustomerId } = useContext(QuoteCustomerIdContext);
    const { ststatus,setStockCode,stockCode,setStstatus,compcode, setCompcode, compstatus,setCompstatus, setCompSOH,compSOH, setSelectedStockType,bomPart, setBOMPart,substockCode, setSubStockCode,bomPartSub, setBOMPartSub} = useStockCode();
    const handleChange = () => {
        var isChecked = !selectedRows.some((row) => row[idField] === dataItem[idField]);
        const idFieldVal = dataItem[idField];
        const stStockCode = dataItem.stStockCode;
        const type = dataItem.type;
        if (idFieldVal === undefined && stStockCode === undefined) {
            isChecked = false;
        }
        if(allowCopy){
            isChecked =true
        }
        
        if (isChecked) {
            if (singleSelect) {
                setSelectedRows([dataItem]);

                if (getQuoteDetailsById !== undefined) {
                    getQuoteDetailsById(dataItem);
                }
            } else {
                setSelectedRows([...selectedRows, dataItem]);
            }
            console.log("Selected rows ststatus for show components type: ", dataItem.type);
            console.log("Selected rows ststatus for show components: ", dataItem.stStatus);
            console.log("Selected rows after addition: ", dataItem.stStockCode);
            console.log("Selected rows ststatus for show components : ", dataItem);
            console.log("Selected rows after addition compcode: ", dataItem.compcode);
            console.log("Selected rows after addition compstatus: ", dataItem.compstatus);
          //  console.log("Selected rows after addition soh: ", dataItem.soh);
            
            setStockCode && setStockCode(dataItem.stStockCode)
            setStstatus && setStstatus (dataItem.stStatus)
            console.log("compcode :",compcode)
        //  const instance = compcode.filter()
        console.log("comp array :",{ id: "", compcode: dataItem.compcode,soh:dataItem.soh })
            setCompcode([...compcode, { id: "", compcode: dataItem.compcode,soh:dataItem.soh }]);

            console.log("set compcode :",([...compcode, { id: "", compcode: dataItem.compcode,soh:dataItem.soh }]))

           

            setCompSOH && setCompSOH (dataItem.soh)
            setCompstatus && setCompstatus (dataItem.compstatus)
           
            if(dataItem.rowType ==='M' || dataItem.rowType ==='K' || dataItem.type ==='M' || dataItem.type ==='K' ){
                setBOMPart(true)  
            }
            else{
                setBOMPart(false)
            }
        
            // if(!bomPartSub && dataItem.compcode){
            //     setSubStockCode(dataItem.compcode)
            // }
            
            // else{
            //     console.log("wrong")
            // }
          //  setSelectedStockType && setSelectedStockType(dataItem.type)
         //   setTotalSoh && setTotalSoh (dataItem.soh)
          
            console.log("Customer Number ::", dataItem.customerNumber);
            // console.log("isGroupable ::", isGroupable);
            
            setStockNumber && setStockNumber(dataItem.stStockCode) //this use to componentparts lookup
           
            setCustomerNumber(dataItem.customerNumber);
          
        } else {
            setSelectedRows(selectedRows.filter((row) => row[idField] !== dataItem[idField]));
          //  setStockCode && setStockCode("")
            setStstatus && setStstatus ("")
            setCompSOH && setCompSOH ("")
            setCompstatus && setCompstatus ("")
            setCompcode && setCompcode("")
            setBOMPart(false)
            setStockNumber && setStockNumber('');
        }

        if (isGroupable) {
            selectMultipleItems(dataItem, isChecked);
        }

        onRowSelection && onRowSelection(dataItem);
    };

    useEffect(() => {
        // console.log("loadStatus:", customerNumber);
    }, [customerNumber,stockNumber,ststatus,compstatus,stockCode,compSOH,compcode]);

    // useEffect(() => {
    //     // console.log("loadStatus:", quoteCustomerId);
    // }, [quoteCustomerId]);

    const isRowEmpty = Object.keys(dataItem).length === 0;
    

    return (
        <td>
            <div onClick={handleChange} data-id={dataItem[idField]}>
                <Checkbox checked={isChecked} onChange={handleChange}    disabled={isRowEmpty}  />
            </div>
        </td>
    );
};

export default CheckboxCell;
