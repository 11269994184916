import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

const ImportLogDialog = ({ visible, logDetails, onClose }) => {
    return (
        <Dialog width="40%" height="60%" title="Import Log" onClose={onClose} open={visible}>
            <div class="ip-importlog">{logDetails}</div>
            <DialogActionsBar>
                <div className="d-flex justify-content-end ip-dialog-btnstatic">
                    <Button
                        className="px-4 me-2 ip-button-general"
                        onClick={onClose}>
                        Close
                    </Button>
                </div>
            </DialogActionsBar>
        </Dialog>
    );
};

export default ImportLogDialog;
