import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Container, Input, Row, Button } from "reactstrap";
import { msalConfig, loginRequest } from "../../authConfig";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";

import AuthCarouselSlider from "../Common/authCarouselSlider";
import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";

// Import Images
import logoLight from "../../assets/default/images/ipd/ipd.png";

const Authentication = () => {
    const { instance } = useMsal();
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        // if (loginType === "popup") {
        //     instance
        //         .loginPopup(loginRequest)
        //         .then((res) => {})
        //         .catch((e) => {
        //             console.log(e);
        //         });
        // } else if (loginType === "redirect") {
        //     instance
        //         .loginRedirect(loginRequest)
        //         .then((res) => {})
        //         .catch((e) => {
        //             console.log("error : ", e);
        //         });
        // }

        // instance
        //     .loginRedirect(loginRequest)
        //     .then((res) => {})
        //     .catch((e) => {
        //         console.log("error : ", e);
        //     });

        instance
            .loginPopup(loginRequest)
            .then((res) => {
                console.log("res ", res);
                window.localStorage.clear();
                instance.setActiveAccount(res); // mark the account as active to be able to fetch the current account for role checking
                navigate("/dashboard");
            })
            .catch((e) => {
                console.log("error : ", e);
            });
    };

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden">
                    <Container fluid className="p-0 h-100">
                        <Row className="h-100">
                            <Col lg={12} className="h-100">
                                <Card className="overflow-hidden h-100">
                                    <Row className="g-0 h-100">
                                        <AuthCarouselSlider />

                                        <Col lg={4} className="m-auto p-5">
                                            <div className="mb-2 m-auto text-center">
                                                <Link to="">
                                                    <img src={logoLight} alt="" height="60" />
                                                </Link>
                                            </div>
                                            <div className="p-lg-5 p-4 text-center">
                                                <div>
                                                    <h5 className="text-primary">Welcome Back!</h5>
                                                    <p className="text-muted">Sign in to continue to the IPD EE System.</p>
                                                </div>

                                                <div className="mt-4">
                                                    <form action="/">
                                                        <div className="mt-4">
                                                            <Button
                                                                color="primary"
                                                                className="w-100"
                                                                type="submit"
                                                                onClick={(e) => handleLogin(e)}>
                                                                Sign In
                                                            </Button>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="mt-5 text-center displaynone">
                                                    <p className="mb-0">
                                                        Issue logging in?{" "}
                                                        <a href="mailto:rushira.muhandiramge@ipd.com.au" className="fw-semibold text-primary text-decoration-underline">
                                                            {" "}
                                                            Contact Administrator
                                                        </a>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="ip-login-footer">
                                                <p className="mb-0">
                                                    &copy; {new Date().getFullYear()} <Link to={"https://ipdgroup.com.au/"} target="_blank">IPD Group.</Link>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Authentication;
