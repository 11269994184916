import React, { useState, useMemo, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, RadioButton } from '@progress/kendo-react-inputs';
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { toast, Slide } from 'react-toastify';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';
import "../../styles/importDialog.css";
import { useImportData } from "../../Contexts/ImportUnitData";

const ImportDialog = ({ visible, onClose, onImport }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('N/A');
  const [noOfHeaders, setNoOfHeaders] = useState(0);
  const [headers, setHeaders] = useState([]);
  const [hasHeaders, setHasHeaders] = useState(false);
  const [hasQty, setHasQty] = useState(false);
  const [hasEachPrice, setHasEachPrice] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [gridCsvData, setGridCsvData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [insertOption, setInsertOption] = useState('last');

  const [dynamicOptionsImport, setDynamicOptionsImport] = useState([]);
  const [dynamicOptionsImport2, setDynamicOptionsImport2] = useState([]);

  const [selectedPartNo, setSelectedPartNo] = useState({ id: 0, text: '', field: '' });
  const [selectedQty, setSelectedQty] = useState({ id: 1, text: '', field: '' });
  const [selectedEachPrice, setSelectedEachPrice] = useState({ id: 2, text: '', field: '' });

  
  const {isNotLastRow,SetIsNotLastRow} = useImportData();
  
const optionsImport = useMemo(() => dynamicOptionsImport, [dynamicOptionsImport]);
const optionsImport2 = useMemo(() => dynamicOptionsImport2, [dynamicOptionsImport2]);

  const handleFileChange = (event) => {
    const fname = event.target.files[0];
    setFile(event.target.files[0]);
    setFileName(fname.name);
    
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval:""});
  
      const camelCaseHeaders = jsonData[0].map(header => {
        // Remove unwanted characters (except dot) from the header
        const cleanedHeader = header.replace(/[^a-zA-Z0-9\s]/g, '');
      
        const words = cleanedHeader.split(/\s+/);
        const camelCaseHeader = words.map((word, index) => {
          if (index === 0) {
            return word.toLowerCase();
          } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        }).join('');
        return camelCaseHeader;
      });
      const dynamicOptions = camelCaseHeaders.map((header, index) => {
        // Clean up the header to remove any special characters, such as dots
        const cleanField = header.replace(/[^a-zA-Z0-9]/g, '');
      
        return {
          id: index,
          text: header === "" ? `F${index}-empty` : header,
          field: cleanField === "" ? `F${index}` : cleanField,
        };
      });
      
      const dynamicOptions2 = camelCaseHeaders.map((header, index) => {
        // Clean up the header to remove any special characters, such as dots
        const cleanField = header.replace(/[^a-zA-Z0-9]/g, '');
        return {
        id: index,
        text: `F${index + 1}`,
        field: cleanField === "" ? `F${index}` : cleanField,
        };
      });
      
      setDynamicOptionsImport(dynamicOptions);
      setDynamicOptionsImport2(dynamicOptions2);
      setNoOfHeaders(camelCaseHeaders.length);
      setHeaders(camelCaseHeaders);

      // Set default dropdown values
    if(hasHeaders){
      if(camelCaseHeaders.length ===2){
        setSelectedPartNo(dynamicOptions[0]);
        setSelectedQty(dynamicOptions[1]);
        setSelectedEachPrice(dynamicOptions[1]);  
      }else{
        setSelectedPartNo(dynamicOptions[0]);
        setSelectedQty(dynamicOptions[1]);
        setSelectedEachPrice(dynamicOptions[2]);  
      }
      
    } 
    else{
      if(camelCaseHeaders.length ===2){
        setSelectedPartNo(dynamicOptions2[0]);
      setSelectedQty(dynamicOptions2[1]);
      setSelectedEachPrice(dynamicOptions2[1]);
      }
      else{
        setSelectedPartNo(dynamicOptions2[0]);
      setSelectedQty(dynamicOptions2[1]);
      setSelectedEachPrice(dynamicOptions2[2]);
      }
      

    } 
    
      const dataRows = jsonData.slice(1).map(row => {
        const rowData = {};
        camelCaseHeaders.forEach((header, index) => {
          rowData[header] = row[index];
        });
        return rowData;
      });
      console.log("Headers in dataRows:", dataRows);
     setGridCsvData(dataRows);
     setCsvData(jsonData.slice(1));
    
    //  setCsvData(jsonData.slice(1)); // Exclude header row
      // Further processing with headers...
    };
  
    reader.readAsBinaryString(event.target.files[0]);
  };
  console.log("Headers total:", noOfHeaders);

  const handleNextButtonClick = () => {
    if (currentStep === 1 && file) {
      let count = 0;
      if (hasHeaders) count++;
      if (hasQty) count++;
      if (hasEachPrice) count++;
      if(count > noOfHeaders ){
        toast.error("The file that you are try to open does not have enough columns of data. Please check the file.", {
          position: "top-center",
          transition: Slide,
        });
        return;
      }
   
    }
    setCurrentStep(currentStep + 1);
  };

  const handleBackButtonClick = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
 
    if(hasHeaders){
      if(noOfHeaders === 2){
        setSelectedPartNo(dynamicOptionsImport[0]);
        setSelectedQty(dynamicOptionsImport[1]);
        setSelectedEachPrice(dynamicOptionsImport[1]);  
      }else{
        setSelectedPartNo(dynamicOptionsImport[0]);
        setSelectedQty(dynamicOptionsImport[1]);
        setSelectedEachPrice(dynamicOptionsImport[2]);  
      }
      
    } 
    else{
      if(noOfHeaders === 2){
        setSelectedPartNo(dynamicOptionsImport2[0]);
      setSelectedQty(dynamicOptionsImport2[1]);
      setSelectedEachPrice(dynamicOptionsImport2[1]);
      }
      else{
        setSelectedPartNo(dynamicOptionsImport2[0]);
      setSelectedQty(dynamicOptionsImport2[1]);
      setSelectedEachPrice(dynamicOptionsImport2[2]);
      }
      

    } 
  }, [hasHeaders,noOfHeaders]);//selectedPartNo, selectedQty, selectedEachPrice,

 
  const handleImport = () => {
    // if (!selectedPartNo || !selectedQty || !selectedEachPrice) {
    //   toast.error("Please select Part No, Qty, and Each Price fields.", {
    //     position: "top-center",
    //     transition: Slide,
    //   });
    //   return;
    // }
  
    // Validate selected fields and map data
    const importFields = [];
  
    // Map data for each row
    for (let i = 0; i < csvData.length; i++) {
      const row = csvData[i];
      const importField = {};
  
      // Map Part No data
      if (selectedPartNo) {
        const partNoValue = row[headers.indexOf(selectedPartNo.field)];
        importField.stStockCode = partNoValue;
      }
  
      // Skip the row if stStockCode is empty
      if (importField.stStockCode === "" || importField.stStockCode === undefined) {
        continue;
      }
  
      // Map Qty data
      if (hasQty) {
        let qtyValue = row[headers.indexOf(selectedQty.field)];
        //const parsedQty = parseFloat(qtyValue.trim());
       // if (!isNaN(parsedQty) && qtyValue.trim() !== "") {
        //  importField.qty = parsedQty;
        if (qtyValue === "" || qtyValue === undefined) {
          qtyValue = "0"; // Set to "0" if empty
        }
        qtyValue = qtyValue.toString().replace(/[%,\$]/g, ''); // Remove % , and $ signs
        const parsedQty = parseFloat(qtyValue.trim());
        if (!isNaN(parsedQty)) {
        importField.qty = parsedQty;
        } else {
          toast.error(`Data type mismatch in criteria expression.`, {
            position: "top-center",
            transition: Slide,
          });
          return;
        }
      }
  
      // Map Each Price data
      if (hasEachPrice) {
        let eachPriceValue = row[headers.indexOf(selectedEachPrice.field)];
        if (eachPriceValue === "" || eachPriceValue === undefined) {
          eachPriceValue = "0"; // Set to "0" if empty
        }        
        eachPriceValue = eachPriceValue.toString().replace(/[%,\$]/g, ''); // Remove % , and $ signs
        const parsedEachPrice = parseFloat(eachPriceValue.trim());
       // const parsedEachPrice = parseFloat(eachPriceValue.trim());
       // if (!isNaN(parsedEachPrice) && eachPriceValue.trim() !== "") {
        if (!isNaN(parsedEachPrice) && parsedEachPrice >=0 ) {
          importField.nettEA = parsedEachPrice;
        } 
        else if(parsedEachPrice < 0){
          importField.nettEA = "";
        }
        else {
          toast.error(`Data type mismatch in criteria expression.`, {
            position: "top-center",
            transition: Slide,
          });
          return;
        }
      }
  
      // Add the row to importFields array
      importFields.push(importField);
    }
  
    // Log the importFields array for debugging purposes
    console.log("importFieldswwww:", importFields);
  
    // Call onImport function with the structured importFields array
    onImport(file, hasHeaders, importFields);
    onClose();
  };
  
  const handleDropDownChange = (field, value) => {
    switch (field) {
      case 'PartNo' :
        setSelectedPartNo(value);
        break;
      case 'Qty' :
        setSelectedQty(value);
        break;
      case 'EachPrice':
        setSelectedEachPrice(value);
        break;
        case  'F1':
        setSelectedPartNo(value);
        break;
      case 'F3':
        setSelectedQty(value);
        break;
      case 'F6':
        setSelectedEachPrice(value);
        break;
      default:
        break;
    }
  };

  const handleHeaderCheckboxChange = () => {
    setHasHeaders(!hasHeaders);
  };

  const isNextButtonDisabled = !file;

  const onChangeLastRow = () => {
    setInsertOption('last');
    SetIsNotLastRow(false);
  }
  const onChangeSelectedRow = () => {
    setInsertOption('selected')
    SetIsNotLastRow(true);
  }
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <div className="mb-3">This wizard helps you import Part No and Qty into the current quote or unit</div>
            <div className="mb-2">Source import file: {file ? file.name : 'N/A'}</div>
            <div className="imp-dialog-section">
              <input type="file" onChange={handleFileChange} />
              {/* {fileName && <p>Selected file: {fileName}</p>} */}
            </div>
            <div className="d-flex align-items-center">
              <Checkbox
                className="mt-2 checkthis"
                checked={hasHeaders}
                onChange={handleHeaderCheckboxChange}
              />
              <div className="ms-2 me-2 mb-3 mt-2">First row contains column headings</div>
            </div>
            <div className="me-2 mb-1">Fields to be imported:</div>
            <div className="d-flex align-items-center mb-1">
              <Checkbox checked={true} disabled />
              <div className="ms-2">Part No</div>
            </div>
            <div className="mb-1">
              <Checkbox checked={hasQty} onChange={() => setHasQty(!hasQty)}>
                <span className="ms-2">Qty</span>
              </Checkbox>
            </div>
            <div className="mb-1">
              <Checkbox checked={hasEachPrice} onChange={() => setHasEachPrice(!hasEachPrice)}>
                <span className="ms-2">Each Price</span>
              </Checkbox>
            </div>
            <DialogActionsBar>
              <div className="d-flex justify-content-end">
                <Button className="px-4 me-2" onClick={handleNextButtonClick} disabled={!file}>
                  Next
                </Button>
                <Button className="px-4" onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </DialogActionsBar>
          </div>
        );
        case 2:
          return (
            <div>
              <div className="mb-3">This wizard helps you import Part No and Qty into the current quote or unit</div>
              <div className="mb-3">Source import file: {file ? file.name : 'N/A'}</div>
              <div className="imp-dialog-section">
                <div className="mt-2">
                  <label className="mb-1">Part No</label>
                  <DropDownList
                    data={hasHeaders ? optionsImport : optionsImport2}
                    textField="text"
                    dataItemKey="field"
                    onChange={(e) => !hasHeaders ? handleDropDownChange('F1', e.target.value):handleDropDownChange('PartNo', e.target.value)}
                    value={selectedPartNo}
                  />
                </div>
                <div className="mt-2">
                  <label className="mb-1">Qty</label>
                  <DropDownList
                    data={hasHeaders && hasQty ? optionsImport : !hasHeaders && hasQty ? optionsImport2 : !hasHeaders && !hasQty ? [{ text: 'N/A', field: 'N/A' }] : [{ text: 'N/A', field: 'N/A' }]}
                    textField="text"
                    dataItemKey="field"
                    onChange={(e) =>!hasHeaders ? handleDropDownChange('F3', e.target.value): handleDropDownChange('Qty', e.target.value)}
                    value={
                      hasHeaders && hasQty
                        ? selectedQty : !hasHeaders && hasQty ? selectedQty
                        : { text: 'N/A', field: 'N/A' }
                    }
                    disabled={!hasQty}
                  />
                </div>
                <div className="mt-2">
                  <label className="mb-1">Each Price</label>
                  <DropDownList
                    data={hasHeaders && hasEachPrice ? optionsImport : !hasHeaders && hasEachPrice ? optionsImport2 : !hasHeaders && !hasEachPrice ? [{ text: 'N/A', field: 'N/A' }] : [{ text: 'N/A', field: 'N/A' }]}
                    textField="text"
                    dataItemKey="field"
                    onChange={(e) =>!hasHeaders ? handleDropDownChange('F6', e.target.value): handleDropDownChange('EachPrice', e.target.value)}
                    value={
                      hasHeaders && hasEachPrice
                        ? selectedEachPrice : !hasHeaders && hasEachPrice ? selectedEachPrice
                        : { text: 'N/A', field: 'N/A' }
                    }
                  //  value={hasEachPrice ? selectedEachPrice : { text: 'N/A', field: 'N/A' }}
                    disabled={!hasEachPrice}
                  />
                </div>
              </div>
              <DialogActionsBar>
                <div className="d-flex justify-content-end">
                  <Button className="px-4 me-2" onClick={handleBackButtonClick}>
                    Back
                  </Button>
                  <Button className="px-4 me-2" onClick={handleNextButtonClick} disabled={isNextButtonDisabled}>
                    Next
                  </Button>
                  <Button className="px-4" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </DialogActionsBar>
            </div>
          );
      case 3:
        return (
          <div>
            <div className="mb-3">This wizard helps you import Part No and Qty into the current quote or unit</div>
            <div className="mb-3">Source import file: {file ? file.name : 'N/A'}</div>
            <div className="mb-3">
              <Grid data={gridCsvData.slice(0, 3)}>
                <GridColumn field={selectedPartNo.field} title={selectedPartNo.text} />
                <GridColumn field={hasQty ? selectedQty.field : 'N/A'} title={hasQty ? selectedQty.text : 'N/A'} />
                <GridColumn field={hasEachPrice ? selectedEachPrice.field : 'N/A'} title={hasEachPrice ? selectedEachPrice.text : 'N/A'} />
              </Grid>
            </div>
            <div className="d-flex align-items-center mb-2">
              <RadioButton
                name="importOption"
                value="last"
                checked={insertOption === 'last'}
                onChange={onChangeLastRow}
              >
                <span className="ms-2">Insert after the last row</span>
              </RadioButton>
            </div>
            <div className="d-flex align-items-center mb-2">
              <RadioButton
                name="importOption"
                value="selected"
                checked={insertOption === 'selected'}
                onChange={onChangeSelectedRow}
              >
                <span className="ms-2">Insert after the selected row</span>
              </RadioButton>
            </div>
            <DialogActionsBar>
              <div className="d-flex justify-content-end">
                <Button className="px-4 me-2" onClick={handleBackButtonClick}>
                  Back
                </Button>
                <Button className="px-4 me-2" onClick={handleImport}>
                  Import
                </Button>
                <Button className="px-4" onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </DialogActionsBar>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog width="50%" title="Import Multiple Records from a file" onClose={onClose} open={visible}>
      {renderStepContent()}
    </Dialog>
  );
};

export default ImportDialog;