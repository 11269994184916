import React, { useState, useEffect, useContext } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { getResponseWithoutParam } from "../../Services/Common/Get";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import Spinner from "../Common/Spinner";
import { putResponse } from "../../Services/Common/Put";
import "../../styles/ContactEditForm.css";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { toast, Slide } from "react-toastify";
import { Checkbox } from "@progress/kendo-react-inputs";

export let contactDetails = {
    ContactId: "",
    AcctNo: "",
    LastName: "",
    FirstName: "",
    Email: "",
    Phone: "",
    Enabled: 0,
    IsPrimary: 0,
    IsDeleted: 0,
};

export const ContactEditForm = ({
    customerNumber,
    toggleCotactForm,
    selectedContact,
    setSelectedContact,
    contactRef,
    contactemailRef,
    setContactEditFormVisible,
    contactEditFormVisible,
    selectedCustomer,
    setSelectedCustomer,
    triggerCustomerEditFormClose
}) => {
    const [contact, setContact] = useState(null);
    const [contactdata, setContactdata] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const user = useContext(UserContext);
    //const contactRef = useRef(null);
    // const contactemailRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [selectedC, setSelectedC] = useState(null);

    const toggleDialog = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        setIsLoading(true);
        getResponseWithoutParam(`Contact/GetUserSpecificContact?currentUserId=${user?.homeAccountId}&customerNo=${customerNumber}`)
            .then((res) => {
                setContactdata(res);
                console.log("GetUserSpecificContact :",res)
                if (res.length > 0) {
                    const formattedContactData = res.map((contact) => ({
                        ...contact,
                        fullName: `${contact.firstName} ${contact?.lastName ? contact?.lastName : ""}`,
                    }));
                    const filteredList = formattedContactData.filter((c) => c.firstName  !== "");
                    setContact(filteredList);
                    const s = formattedContactData.filter((c) => c.isPrimary === true)[0];
                    setSelectedC(s);
                }
                // if (res.length > 0) {
                //     const formattedContactData = res.map((contact) => ({
                //         ...contact,
                //         fullName: `${contact.firstName} ${contact?.lastName ? contact?.lastName : ""}`,
                //     }));
                //     setContact(formattedContactData);
                //     const s = formattedContactData.filter((c) => c.isPrimary === true)[0];
                //     setSelectedC(s);
                // }

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error : ", error);
            });
    }, []);

    useEffect(()=>{
        if(triggerCustomerEditFormClose == true) toggleDialog()
    },[triggerCustomerEditFormClose])

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleSave = () => {
        setIsLoading(true);

        if (contactDetails?.AcctNo !== "") {
            const data = {
                acctNo: contactDetails.AcctNo,
                UserId: user.homeAccountId,
                LastName: contactDetails.LastName ? contactDetails.LastName : "",
                FirstName: contactDetails.FirstName ? contactDetails.FirstName : "",
                Email: contactDetails.Email,
                Phone: contactDetails.Phone,
                Enabled: true,
                IsPrimary: true,
                IsDeleted: false,
            };

            setSelectedContact(data);

            putResponse(`Contact/UpdateContact?currentuserId=${user.homeAccountId}`, data)
                .then((res) => {
                    if (res) {
                        const contact = {
                            ...selectedCustomer,
                            contactFullName: data.FirstName + " " + data.LastName,
                            contactEmail: data.Email,
                            contactFname: data.FirstName || "",
                            contactLname: data.LastName || "",
                        };

                        if (setSelectedCustomer) {
                            setSelectedCustomer(contact);
                        }

                        setSelectedContact(contact);
                        setIsLoading(false);
                        toggleDialog();
                        toggleCotactForm();
                    } else {
                        setIsLoading(false);
                        toast.error("Something went wrong. Please try again later.", {
                            position: "top-center",
                            transition: Slide,
                        });                
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error(error);
                });
        } else {
            setIsLoading(false);
            toast.error("Please select a Contact to continue.", {
                position: "top-center",
                transition: Slide,
            });    
            //  toggleCotactForm();
        }
    };

    const handleContactName = (e) => {
        setCustomerContact(e.target.value);
        setSelectedCustomer({ ...selectedCustomer, fullName: '${contact.firstName} ${contact?.lastName ? contact?.lastName : ""}' });
    };

    const handleContactEmail = (e) => {
        setCustomerEmail(e.target.value);
        setSelectedCustomer({ ...selectedCustomer, email: contactDetails.Email });
    };

    // const QuoteContactCell = (props) => {
    //     const { dataItem, field } = props;
    //     const [checked, setChecked] = useState(dataItem.isPrimary || false);

    //     const handleCheckboxChange = (event) => {
    //         //     setChecked(event.target.checked);

    //         console.log("is primary id :", props.dataItem.contactId);

    //         const isChecked = event.target.checked;
    //         setChecked(isChecked);

    //         if (isChecked) {
    //             // Update contactDetails when the checkbox is checked
    //             contactDetails.AcctNo = dataItem.acctNo;
    //             contactDetails.LastName = dataItem.lastName;
    //             contactDetails.FirstName = dataItem.firstName;
    //             contactDetails.Email = dataItem.email;
    //             contactDetails.Phone = dataItem.phone;
    //         }
    //     };

    //     return (
    //         <td>
    //             <input
    //                 type="checkbox"
    //                 className="form-check-input form-check-input ip-tick-show"
    //                 checked={checked}
    //                 onChange={handleCheckboxChange}
    //             />
    //         </td>
    //     );
    // };

    const QuoteContactCell = (props) => {
        const { dataItem, field, selected, onToggle } = props;
        const handleCheckboxChange = () => {
            onToggle(dataItem, !selected);
        };
        return (
            <td>
                <input
                    type="checkbox"
                    className="form-check-input form-check-input ip-tick-show"
                    checked={selected}
                    onChange={handleCheckboxChange}
                />
            </td>
        );
    };

    // const handleToggle = (dataItem) => {
    //     if (selectedC === dataItem) {
    //         setSelectedC(null);
    //     } else {
    //         setSelectedC(dataItem);
    //         contactDetails.AcctNo = dataItem.acctNo;
    //         contactDetails.LastName = dataItem.lastName;
    //         contactDetails.FirstName = dataItem.firstName;
    //         contactDetails.Email = dataItem.email;
    //         contactDetails.Phone = dataItem.phone;
    //     }
    // };
    const handleToggle = (dataItem, status) => {

        if (status) {
            if (selectedC === dataItem) {
                setSelectedC(null);
            } else {
                setSelectedC(dataItem);
                contactDetails.AcctNo = dataItem.acctNo;
                contactDetails.LastName = dataItem.lastName;
                contactDetails.FirstName = dataItem.firstName;
                contactDetails.Email = dataItem.email;
                contactDetails.Phone = dataItem.phone;
            }
        } else {
            setSelectedC(null);
            contactDetails.AcctNo = "";
        }

    };
    const handleRemove = () => {
        toggleDialog();
        setContactEditFormVisible(!contactEditFormVisible);
    };

    return (
        <React.Fragment>
            {isLoading && <Spinner />}

            {!isLoading ? (
                <>
                    <Form
                        onSubmit={handleSubmit}
                        render={() => (
                            <FormElement style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <strong>Contacts List : </strong>
                                <hr />
                                <Grid resizable={true} data={contact}>
                                    <Column field="fullName" title="Full Name" />
                                    <Column field="lastName" title="Last Name" />
                                    <Column field="firstName" title="First Name" />
                                    <Column field="email" title="Email" />
                                    <Column field="phone" title="Phone" />
                                    {/* <Column field="QuoteContact" title="Quote Contact" cell={QuoteContactCell} /> */}
                                    <Column
                                        field="QuoteContact"
                                        title="Quote Contact"
                                        cell={(props) => (
                                            <QuoteContactCell {...props} selected={props.dataItem === selectedC} onToggle={handleToggle} />
                                        )}
                                    />
                                </Grid>
                            </FormElement>
                        )}
                    />
                 
                        <DialogActionsBar>
                            <div className="d-flex justify-content-end ip-dialog-btnstatic-f2">
                                <Button className="px-4 me-2 ip-button-general" onClick={handleSave}>
                                    Done
                                </Button>   
                                <Button className="px-4" onClick={toggleDialog}>
                                    Cancel
                                </Button>
                                                     
                                {visible && (
                                    <Dialog title={"Confirm to submit"} onClose={toggleDialog}>
                                        <p
                                            style={{
                                                margin: "30px",
                                                textAlign: "center",
                                            }}>
                                            Do you want to save changes you made to the 'Contact'?
                                        </p>
                                        <DialogActionsBar>
                                            <div className="d-flex justify-content-center py-3 px-2">                                              
                                                <Button className="px-5 me-2 ip-button-positive" onClick={handleSave}>
                                                    Yes
                                                </Button>                                
                                                <Button className="px-5 me-2 ip-button-negative" onClick={handleRemove}>
                                                    No
                                                </Button>                                              
                                                <Button className="px-5" onClick={toggleDialog}>
                                                    Cancel
                                                </Button>        
                                            </div>
                                        </DialogActionsBar>
                                    </Dialog>
                                )}
                            </div>
                        </DialogActionsBar>
                    
                </>
            ) : (
                <></>
            )}
        </React.Fragment>
    );
};

export default ContactEditForm;
