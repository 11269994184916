// ResetQuote.js
import React, { useState, useContext, useEffect } from 'react';
import { useResetQuote } from '../../Contexts/ResetProvider';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ResetQuoteTypes } from "../../enums/GroupTypes";
const ResetQuote = ({ handleOkSet }) => {
    const [resetQuoteDialogVisible, setResetQuoteDialogVisible] = useState(false);
    const { allowSet, setAllowSet, selectedOption, setSelectedOption } = useResetQuote();

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
    };
    const toggleResetQuoteDialog = () => {
        setResetQuoteDialogVisible(!resetQuoteDialogVisible);
    };

    useEffect(() => {

        const handleEnterKeyPress = (event) => {
            if (event.key === 'Enter') {

                console.log('Enter pressed, execute the reset!');
                handleOkSet();
            }
        };

        // Add event listener for the Enter key press
        window.addEventListener('keydown', handleEnterKeyPress);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleEnterKeyPress);
        };
    }, []); // Empty dependency effect runs once on mount and cleanup on unmount






    return (
        <div className="reset-quote-container p-2">

            <header >
                <p>Here you are able to reset the quote to its recent values from the database. When you reset,you can either choose to change the nettprice or keep margin.</p>
            </header>

            <section className="card-content">
                <h6 className='mb-3'>Reset Options</h6>
                <div className="options">
                    <div className="radio-buttons">
                        <div className='mb-1'>
                            <input
                                type="radio"
                                id="option1"
                                name="resetoption"
                                value={ResetQuoteTypes.SETDEFAULT}
                                checked={selectedOption === ResetQuoteTypes.SETDEFAULT}
                                onChange={handleOptionChange}
                                className='me-1'
                            />
                            <label className="align-middle" htmlFor="default-price">Change to default price</label>
                        </div>

                        <div className='mb-1'>
                            <input
                                type="radio"
                                id="keep-nettEA"
                                name="resetoption" // Corrected name attribute
                                value="keep-nettEA"
                                checked={selectedOption === "keep-nettEA"}
                                onChange={handleOptionChange}
                                className='me-1'
                            />
                            <label className="align-middle" htmlFor="keep-nettEA">Keep NettEA price</label>
                        </div>

                        <div className='mb-1'>
                            <input
                                type="radio"
                                id="option3"
                                name="resetoption"
                                value={ResetQuoteTypes.KEEPMARGIN}
                                checked={selectedOption === ResetQuoteTypes.KEEPMARGIN}
                                onChange={handleOptionChange}
                                className='me-1'
                            />
                            <label className="align-middle" htmlFor="keep-margin">Keep Margin</label>
                        </div>
                    </div>

                    <div className="mt-4 warning">
                        <span className="text-danger">Warning: Please note the changes made cannot be reversed. 
                            However if the quote that you are working is already saved, you can discard the changes by reopening the old quote. 
                            The cost always will be reset</span>
                    </div>

                </div>
            </section>

        </div>
    );
};

export default ResetQuote;
