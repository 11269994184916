import { baseURL } from "../../App";
import { msalInstance } from "../../index"; // Update with the correct path to your MSAL instance

// Helper function to get access token
const getAccessToken = async () => {
    const requestForApi = {
        scopes: ["api://d055ad45-59fa-4049-9802-3efb2ab98eed/access_as_user"],
        account: msalInstance.getAllAccounts()[0] // Assumes you want to use the first account
    };

    try {
        const response = await msalInstance.acquireTokenSilent(requestForApi);
        return response.accessToken;
    } catch (error) {
        if (error.name === "InteractionRequiredAuthError") {
            try {
                const response = await msalInstance.acquireTokenPopup(requestForApi);
                return response.accessToken;
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error(error);
        }
    }
};

export const deleteResponse = async (url, params = {}) => {

    const urlParams = new URLSearchParams(Object.entries(params));

 

    
    
    const apiDeleteAccessToken = await getAccessToken();
    if (!apiDeleteAccessToken) {
        throw new Error("Access token is not available.");
    }
    const options = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${apiDeleteAccessToken}`,
            "Content-type": "application/json",
        },
    };

    // Construct full URL with parameters
    const fullUrl = `${baseURL}/${url}?${urlParams.toString()}`;

    return await fetch(fullUrl, options)
        .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                return data;
            }
        })
        .catch((error) => {
            console.error("Failed to insert data:", error);
        });
};
