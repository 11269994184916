import { Checkbox } from '@progress/kendo-react-inputs';
import React, {  useEffect } from "react";

const HeaderCheckboxCell = ({ onHeaderSelectionChange, selectedRows, totalNonEmptyRows, total, isHeaderCheckboxRender }) => {
    if (!isHeaderCheckboxRender) {
        return null;
    }
    const checked = selectedRows && totalNonEmptyRows && selectedRows.length === totalNonEmptyRows;
    const indeterminate = selectedRows && totalNonEmptyRows && selectedRows.length > 0 && selectedRows.length < totalNonEmptyRows;

    
    
  

    return (
        <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={(e) => onHeaderSelectionChange(e.value)}
        />
    );
};

export default HeaderCheckboxCell;
