import React, {useEffect, useContext, useState } from "react";
import * as ReactDOM from "react-dom";
import { Form, FormElement, Field, FieldWrapper } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, RadioButton, TextArea, RadioGroup } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { getResponse } from "../../Services/Common/Get";
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { postResponse } from "../../Services/Common/Post";
import { toast, Slide } from "react-toastify";
import { handleQuoteAuditTrail } from "../../utils/QuoteApproval";
import { v4 as uuidv4 } from "uuid";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";


const QteUserApprovalAction = ({id,qteApprovalHeaderMsg, qteApprovalFormType}) => {
    const user = useContext(UserContext);
    const { quoteDetails, updateQuoteDetailsForId } = useQuoteDetails();
    const [currentStatusId,setCurrentStatusId] = useState(0);
    const [ userDetails, setUserDetails ] = useState({});
    const { 
         quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        currentApprover, setCurrentApprover,
        quoteOwner, setQuoteOwner,
        approverName, setApproverName,
        shareQuoteFlag, setShareQuoteFlag,
        currAccessLevel, setCurrAccessLevel
        } = useContext(QteApprovalNotificationContext);
    const getUserDetails = async () => {
        if (user?.homeAccountId === null || user?.homeAccountId === undefined) return;  
        try {
            const result = await getResponse("User/getuserdetails", {userId: user?.homeAccountId});
            if (result !== null) {
                setUserDetails(result);   
            }
        } catch (error) {
            console.log("Error getUserDetails",error);
        }
    }



    const columns = [

        {
            field: "approver",
            header: "Name",
        },
        {
            field: "emailAddress",
            header: "Email Address",
        }
        
    ]

    const rdApprovalSelection = (currAccessLevel <= userDetails.userAccessLevel  
        ? 
            [
                {
                    label: "Approve",
                    value: "Approved",
                },
                {
                    label: "Reject",
                    value: "Rejected",
                },
                {
                    label: "Need Change",
                    value: "Review Required",
                },
            ]
        :
            [
                {
                    label: "Reject",
                    value: "Rejected",
                },
                {
                    label: "Need Change",
                    value: "Review Required",
                },
                {
                    label: "Re-send for approval",
                    value: "Escalate"
                }
                // {
                //     label: "Escalate to another approver",
                //     value: "Escalate",
                // },
            ]
    );

    const [approverList, setApproverList] = React.useState([]);
    

    const fetchApprover = async () => {
        const result = 
            shareQuoteFlag === 1 
            ? await getResponse("Quote/getlistofuserforharedquote", { })
            : await getResponse("Quote/getmanagerforapproverlist", { });

        if (result.data !== null) {
            // const filterBasedOnAccessLevel = shareQuoteFlag === 1 ? result.data : result.data.filter(x => x.userAccessLevel === currAccessLevel);
            const filterBasedOnAccessLevel = result.data;
            setApproverList(filterBasedOnAccessLevel);
        }
    }

    let approverId = null;
    const fetchSpecificApprover = async (userId) => {
        const result = await getResponse("Quote/getspecificapprover", {
            userId: user?.homeAccountId,
        });

        if (result.data !== null) {
            setValue(result.data);
            approverId = result.data.id;
        }
    }

    const [value, setValue] = React.useState(null);
    const [textDisplay, setTextDisplay] = React.useState();
    const [filter, setFilter] = React.useState();
    const handleFilterChange = (event) => {
        if (event) {
            setFilter(event.filter);
        }
    };

    const handleChange = (event) => {
        if (event) {
            setValue(event.target.value);
            event.target.value ? setTextDisplay(event.target.value.approver + " - " + event.target.value.emailAddress) : setTextDisplay("");
            approverId = event.target.value ? event.target.value.id : null;
            setCurrentApprover(approverId);
            
        }
    };

    const [userComment, setUserComment] = useState('');
    const [approverComment, setapproverComment] = useState('');
    const [lblToApprover, setLblToApprover] = useState('To approver');
    const handleChangeUComment = event => {
        setUserComment(event.target.value);
    };
    const handleAppChangeUComment = event => {
        setapproverComment(event.target.value);
    };

    

    const handleSendForApproval = async () => {
        const msg = "Please select an approver.";
        if (value === null || value === undefined) {
            toast.warning(msg, {position: "top-center",transition: Slide,});
            return;
        }
        if (value.length === 0) {
            toast.warning(msg, {position: "top-center",transition: Slide,});
            return;
        }
        setQuoteApprovalStatId(2);
        setTriggerSaveQteForApproval({
            quoteId: quoteDetails[id].savedQuote?.quoteId,
            approvalStatusId: 2,
            triggerSave:true,
            comment: userComment,
            approvedUserId: value.id,
            emailSentBy: "",
            emailSentTo: "",
            activeLog: 1
        });
        setShowFormForApproval(false);

    }

    const handleApproverAction = async () => {
        if (currentStatusId === 0) {
            toast.warning("Please select an action.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        setQuoteApprovalStatId(currentStatusId);
        setQuoteApprovalStatus(approvalStatList[currentStatusId].approvalStatus);
        setQuoteOwner(quoteDetails[id]?.savedQuote.owner ? quoteDetails[id]?.savedQuote.owner : quoteDetails[id]?.savedQuote.quoteContact);
        setApproverName(quoteDetails[id]?.savedQuote.approvalAudit.approvedUserID ===  user?.homeAccountId ? user?.userFullName : "");
        
        setTriggerSaveQteForApproval({
            quoteId: quoteDetails[id].savedQuote?.quoteId,
            approvalStatusId: currentStatusId,
            triggerSave:true,
            comment: approverComment,
            approvedUserId: currentApprover,
        });

        setShowFormForApproval(false);

    }

    const [showEscalate, setShowEscalate] = useState(false);
    const handleEscalate = (event) => {
        if (event.value === "Escalate") {
            setShowEscalate(true);
            setCurrentStatusId(2);
        }
        else
        {
            const idx = approvalStatList.findIndex(x => x.approvalStatus === event.value);
            if(idx < 0) return;
            setCurrentStatusId(approvalStatList[idx].approvalStatusID);
            setShowEscalate(false);
        }
    }

    //#region useEffect
    const [needForActualQuote, setNeedForActualQuote] = useState(false);
    useEffect(() => {
        (quoteApprovalStatId === 3 ? fetchSpecificApprover() : <></>);
        fetchApprover();

        if (shareQuoteFlag === 1)
        {
            setLblToApprover('Share with:');
        }else{
            setLblToApprover('To approver:');
        }
        const res = getUserDetails();
        
    }, [quoteApprovalStatId]);
    //#endregion

    return (
        <>
            {!needForActualQuote && (
                <Form
                    render={() => 
                    (   
                        (quoteApprovalStatId === 1 || quoteApprovalStatId === 3 || shareQuoteFlag === 1 ?
                        <FormElement>
                            <fieldset className={"k-form-fieldset"}>
                                <p>
                                    {shareQuoteFlag !== 1 ? qteApprovalHeaderMsg : ""}
                                </p>
                                <FieldWrapper>
                                    <label className={"k-form-field-label"}>{lblToApprover}</label>
                                    <br/>
                                    <MultiColumnComboBox
                                        filterable={true}
                                        data={filter ? filterBy(approverList, filter) : approverList}
                                        value={value}
                                        columns={columns}
                                        textField={"approver"}
                                        onFilterChange={handleFilterChange}
                                        onChange={handleChange}
                                        style={{
                                            width: "400px",
                                        }}
                                        placeholder="Please select ..."
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <label className={"k-form-field-label"}>Comments:</label>
                                    <Field
                                        id={"usercomments"}
                                        name={"usercomments"}
                                        component={TextArea}
                                        labelClassName={"k-form-label"}
                                        rows={5}
                                        maxLength={250}
                                        onChange={handleChangeUComment}
                                    />
                                </FieldWrapper>

                            </fieldset>
                            <div className="k-form-buttons float-end">
                                <button
                                    type={"button"}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    onClick={() => handleSendForApproval()}
                                >
                                Send
                                </button>
                                <button
                                    type={"button"}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    onClick={() => setShowFormForApproval(false)}
                                >
                                Cancel
                                </button>
                            </div>
                        </FormElement>
                        :
                        <FormElement>
                            <fieldset className={"k-form-fieldset"}>
                                <FieldWrapper>
                                    <label className={"k-form-field-label my-0"}>Quote Owner:</label>
                                    <br/>
                                    <label className={"k-form-field-label"}><b>{quoteOwner}</b></label>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <label className={"k-form-field-label my-0"}>Approval Action By:</label>
                                    <br/>
                                    <label className={"k-form-field-label"}><b>{approverName}</b></label>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <RadioGroup
                                        name="rdApprovalSelection"
                                        className="appr-action-radioset"
                                        layout={"horizontal"}
                                        data={rdApprovalSelection}
                                        onChange={handleEscalate}
                                    />
                                </FieldWrapper>
                                {showEscalate && (
                                <FieldWrapper>
                                    <label className={"k-form-field-label"}>To approver:</label>
                                    <br/>
                                    <MultiColumnComboBox
                                        filterable={true}
                                        data={filter ? filterBy(approverList, filter) : approverList}
                                        value={value}
                                        columns={columns}
                                        textField={"approver"}
                                        onFilterChange={handleFilterChange}
                                        onChange={handleChange}
                                        style={{
                                            width: "400px",
                                        }}
                                        placeholder="Please select ..."
                                    />
                                </FieldWrapper>
                                )}
                                <FieldWrapper>
                                    <label className={"k-form-field-label"}>Comments:</label>
                                    <Field
                                        id={"appcomments"}
                                        name={"appcomments"}
                                        component={TextArea}
                                        labelClassName={"k-form-label"}
                                        rows={5}
                                        maxLength={250}
                                        onChange={handleAppChangeUComment}
                                    />
                                </FieldWrapper>
                            </fieldset>
                            <div className="k-form-buttons float-end">
                                <button
                                    type={"button"}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    onClick={handleApproverAction}
                                >
                                Done
                                </button>
                                <button
                                    type={"button"}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                    onClick={() => setShowFormForApproval(false)}
                                >
                                Cancel
                                </button>
                            </div>
                        </FormElement>
                        )
                    )}
                >
                </Form>
            )}
        </>
    )
}

export default QteUserApprovalAction