// ResetContext.js
import React, { createContext, useState,useContext  } from 'react';
import { ResetQuoteTypes } from "../enums/GroupTypes";

const ResetContext = createContext();

export const useResetQuote = () => useContext(ResetContext);

export const ResetProvider = ({ children }) => {
  
  const [allowSet, setAllowSet] = useState(false);
  // const [isDefaultPriceSelected, setIsDefaultPriceSelected] = useState(true);
  // const [isKeepMarginSelected, setIsKeepMarginSelected] = useState(false);
  // const [isKeepNettEASelected, setIsKeepNettEASelected] = useState(false);
  // const [allowKeepMarginValue, setAllowKeepMarginValue] = useState(false);
  // const [allowKeepNettEAValue, setAllowKeepNettEAValue] = useState(false);
  const [selectedOption, setSelectedOption] = useState(ResetQuoteTypes.SETDEFAULT);
  return (
    <ResetContext.Provider value={{ allowSet, setAllowSet,selectedOption, setSelectedOption }}>
      {children}
    </ResetContext.Provider>
  );
};
