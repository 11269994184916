import { object } from "prop-types";
import React from "react";

const applyStyling = (item) => {
    if (typeof item.unitId !== "undefined") return;
    let applyClass = '';
    try {
        const itemData = typeof item.rowType === "undefined" ? item.items[0] : item;
        let newStkcode = itemData?.stStockCode?.trimEnd() || "";
        let oldStkcode = itemData?.oldStockCode?.trimEnd() || "";

        const obsolete = itemData.description?.includes("Obsolete") || itemData.description?.includes("Obsolete") || itemData?.stStatus === "X" || itemData?.rowType === "CI";
        const collapsibleRows = ['G', 'SG', 'A'];

        switch (itemData.rowType) {
            case "DX":
                applyClass = (" ip-gridclr-dbextra");     //dbextra
                break;
            case "A":
            case "AI":                              //assembly header
                applyClass = (" ip-gridclr-assembly");    //assembly item
                break;
            case "LN":
                applyClass = (" ip-gridclr-note");        //line notes
                break;
            // case "":
            // case "K":
            // case "L":
            // case "Z":
            // case "K":
            // case "I":
            // case "M":
            // case "X":
            case "CI":
                if (obsolete) {
                    applyClass = (" ip-gridclr-custom");  //custom items
                    break;
                }
            default:
                if (itemData.isDisabled) {
                    applyClass = (" ip-gridclr-notvalid");
                    break;
                }
                if (obsolete) {
                    applyClass = (" ip-gridclr-custom");  //custom items
                    break;
                }
                else if ((parseFloat(itemData.nettEA) <= 0 && parseFloat(itemData.stEecost) <= 0) && !collapsibleRows.includes(itemData.rowType)) {
                    applyClass = (" ip-gridclr-no-listprice");
                    break;
                }
                else if (parseFloat(itemData.stEecost) <= 0 && !collapsibleRows.includes(itemData.rowType)) {
                    if (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() && oldStkcode && !obsolete) {
                        applyClass = " ip-gridclr-cross ip-grid-caution";
                    } else {
                        applyClass = " ip-grid-caution";
                    }
                    break;
                }
                // //Check list price
                
                else if ((parseFloat(itemData.listPrice) <= 0 || Number(itemData.listPrice) <=0)  && !collapsibleRows.includes(itemData.rowType)) {
                    if (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() && (oldStkcode !== "") && !obsolete) {
                        applyClass = " ip-gridclr-no-listprice ip-gridclr-cross";
                    }
                    else if(oldStkcode === "" || oldStkcode === null){
                        applyClass = (" ip-gridclr-no-listprice"); //no list price
                    }
                    else{
                        applyClass = " ip-gridclr-no-listprice";
                    }
                    break;
                }

                //Check Netea value
                // else if ((parseFloat(itemData.nettEA) <= 0 || Number(itemData.nettEA) <=0) && !collapsibleRows.includes(itemData.rowType)) {
                //     if(oldStkcode != ""){
                //         if (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() && oldStkcode && !obsolete) {
                //             applyClass = " ip-gridclr-no-listprice ip-gridclr-cross";
                //         } 
                //     }
                //     else{
                //         applyClass = (" ip-gridclr-no-listprice"); //no list price
                //     }
                //     // break;
                // }
                
                // else if (parseFloat(itemData.nettEA) <= 0) {
                // }
                else if (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() && (oldStkcode) && (!obsolete) && !collapsibleRows.includes(itemData.rowType)) {
                    applyClass = (" ip-gridclr-cross");
                    break;
                }
                else if (itemData.stkWithDiffSPPrice === true && !collapsibleRows.includes(itemData.rowType)) {
                    applyClass = (" ip-gridclr-diffstock");
                    break;
                }
                else {
                     applyClass = ("");  
                    break;
                }
        }

        if (itemData.iconIndicator === "notif-repdisc") applyClass += " ip-gridclr-notif-repdisc";

        return applyClass;    
    } catch (error) {
        console.log("Error in applyStyling", error);
        return '';
    }
    
}

export function indicatorToolTip(item) {
    
    let applyToolTip = '';
    try {
        if (typeof item.unitId !== "undefined") return;
        const itemData = typeof item.rowType === "undefined" && typeof item.type === "undefined" ? item.items[0]  : item;
        let newStkcode = typeof itemData === "undefined" ? "" : itemData.stStockCode ? itemData.stStockCode : "";
        let oldStkcode = typeof itemData === "undefined" ? "" : itemData.oldStockCode ? itemData.oldStockCode : "";
        if (typeof itemData === "undefined") return;
        const obsolete = itemData.description?.includes("Obsolete") || itemData.description?.includes("Obsolete") || itemData?.stStatus === "X";
        switch (itemData.rowType) {
        case "DX":
            case "A":
            case "AI":                              
            case "LN":
                break;
            default:
                if (itemData.isDisabled) {
                    applyToolTip = ("Part is no more valid");
                    break;
                }
                // if (parseFloat(itemData.stEecost) <= 0 && !(itemData.description).includes("Obsolete")) {
                if (parseFloat(itemData.stEecost) <= 0 && (!obsolete)) {
                    applyToolTip = ("No cost is found");
                    break;
                }
                // else if (parseFloat(itemData.listPrice) <= 0 && !(itemData.description).includes("Obsolete")) {
                else if ((parseFloat(itemData.listPrice) <= 0 || Number(itemData.listPrice) <= 0) && (!obsolete)) {
                    applyToolTip = ("No list price or default price");
                    break;
                }
                // else if (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() && (oldStkcode) && !(itemData.description).includes("Obsolete")) {
                else if (newStkcode.toLowerCase() !== oldStkcode.toLowerCase() && (oldStkcode) && (!obsolete) && newStkcode.length > 0 && oldStkcode.length > 0) {
                    applyToolTip = (itemData.oldStockCode + " is X referenced to " + itemData.stStockCode);
                    break;
                }
                else {
                    applyToolTip = ("");
                }
        }

        if (itemData.iconIndicator === "notif-repdisc") applyToolTip += " Line item discount is higher than the allowed threshold";

        return applyToolTip;
    } catch (error) {
        console.log("Error in indicatorToolTip", error);
        
    }
}

export function itemStyle(ItemData) {
    let appStyle = applyStyling(ItemData);
    return appStyle;
}

