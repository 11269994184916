
import React, { createContext, useState } from "react";

const CustomerNumberContext = createContext();

const AppProvider = ({ children }) => {
  const [customerNumber, setCustomerNumber] = useState([]);

  return (
    <CustomerNumberContext.Provider value={{ customerNumber, setCustomerNumber }}>
      {children}
    </CustomerNumberContext.Provider>
  );
};

export { CustomerNumberContext, AppProvider };
