import React, { useState ,useContext , useEffect } from "react";
import { Link } from "react-router-dom";
import { DraftSaveContext } from "../../Contexts/DraftSaveContext";

//import images
import logoSm from "../../assets/default/images/ipd/ipd-sm.png";
import logoDark from "../../assets/default/images/ipd/ipd.png";
import logoLight from "../../assets/default/images/ipd/ipd.png";

import FullScreenDropdown from "../Common/FullScreenDropdown";
import ProfileDropdown from "../Common/ProfileDropdown";
import NotificationDropdown from "../Common/NotificationDropdown";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { getResponse } from "../../Services/Common/Get";
//#region import approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
//#endregion

const Header = ({ toggleQuotesModal, toggleSystemSettingModal, setDefaultTab }) => {
    const {isDraftQuoteSave, setIsDraftQuoteSave } = useContext(DraftSaveContext);
    const [isSidebarEnabled, setisSidebarEnabled] = useState(false);
    const [lastSavedTime, setLastSavedTime] = useState(null);
    const [IsAutoSaved , setIsAutoSaved] = useState(false);
    const [formattedLastSaved, setFormattedLastSaved] = useState("");
    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
    const sidebarEnabled = sessionStorage.getItem('vertical-sidebar-enable',false);
    const { forApprovalCount, setForApprovalCount } = useContext(QteApprovalNotificationContext);
    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize > 767) document.querySelector(".hamburger-icon").classList.toggle("open");
        //For collapse vertical and semibox menu
        if (
            document.documentElement.getAttribute("data-layout") === "vertical" ||
            document.documentElement.getAttribute("data-layout") === "semibox"
        ) {
             
            //Checks if the key 'vertical-sidebar-enable' is not set in sessionStorage
            if (!sessionStorage.getItem('vertical-sidebar-enable')) {
                // Set the data-sidebar-size attribute of the root HTML element to 'lg'
                document.documentElement.setAttribute("data-sidebar-size", "lg");

                 //Sets a key-value pair in the sessionStorage object
                sessionStorage.setItem("vertical-sidebar-enable", "true");
              }
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.getAttribute("data-sidebar-size") === "sm"
                    ? (document.documentElement.setAttribute("data-sidebar-size", "lg"),
                    sessionStorage.setItem("data-sidebar-size", "lg"))  
                    : (document.documentElement.setAttribute("data-sidebar-size", "sm"),
                    sessionStorage.setItem("data-sidebar-size", "sm"));
            } else if (windowSize > 1025) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.getAttribute("data-sidebar-size") === "lg" ?
                (document.documentElement.setAttribute("data-sidebar-size", "sm"),
                sessionStorage.setItem("data-sidebar-size", "sm")) :
                (document.documentElement.setAttribute("data-sidebar-size", "lg"),
                sessionStorage.setItem("data-sidebar-size", "lg"));

            } else if (windowSize <= 767) {
                document.body.classList.add("vertical-sidebar-enable");
                document.documentElement.setAttribute("data-sidebar-size", "lg");
                sessionStorage.setItem("data-sidebar-size", "lg");
            }

        }
    };

    // Function to format the last saved time
    function formatLastSavedTime(date) {
        const diffMs = new Date() - date; // difference in milliseconds
        const diffSecs = Math.round(diffMs / 1000); // difference in seconds
        const diffMins = Math.round(diffMs / 60000); // difference in minutes
        const diffHours = Math.round(diffMs / 3600000); // difference in hours
        const diffDays = Math.round(diffMs / 86400000); // difference in days
    
        if (diffSecs < 60) return "Just now";
        if (diffMins < 60) return `${diffMins} min${diffMins > 1 ? "s" : ""} ago`;
        if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
        return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`; 
    }
    
    useEffect(() => {
        var lastSize = sessionStorage.getItem('data-sidebar-size');
        if (!sidebarEnabled) {
            setisSidebarEnabled(document.documentElement.setAttribute("data-sidebar-size", "lg"))
        } else {
            setisSidebarEnabled(document.documentElement.setAttribute("data-sidebar-size", lastSize))
        }
      }, [isSidebarEnabled]);
    // Update lastSavedTime when isDraftQuoteSave changes to false
    useEffect(() => {
        if (!isDraftQuoteSave) {
            const currentTime = new Date();
            setLastSavedTime(currentTime);
            setFormattedLastSaved(formatLastSavedTime(currentTime));
        }
        else setIsAutoSaved(true);
    }, [isDraftQuoteSave]);

    // Setup interval to update the formatted last saved time
    useEffect(() => {
        const interval = setInterval(() => {
            if (lastSavedTime) {
                setFormattedLastSaved(formatLastSavedTime(lastSavedTime));
            }
        }, 60000); // Update every minute

        return () => clearInterval(interval); // Cleanup on unmount
    }, [lastSavedTime]);


    // Fetch approval count
    async function fetchApprovalCount() 
    {   
        
        await getResponse("Quote/getapprovalcount",{userId: userId})
            .then((response) => {
                if (response != null) {
                        setForApprovalCount(Number(response));
                    }
                })
            .catch((error) => {
                console.error("Error fetching approval count:", error);
            });
    }

    useEffect(() => {
        fetchApprovalCount();
    }, []);

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/dashboard" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/dashboard" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-2 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>

                            <div className="position-relative">
                                <div className="h-100 w-100 ip-topbar-headtxt">Estimate Express</div>
                            </div>
                        </div>
                        
                        <div className="d-flex align-items-center">
                            {isDraftQuoteSave && (
                                <div className="autosaving-spinner me-4">
                                    <div className="autodotted-spinner"></div>
                                    <div className="text">Auto Saving</div>                              
                                </div>
                            )}
                            {IsAutoSaved && lastSavedTime && !isDraftQuoteSave && (
                                <div className="autosave-check me-4">
                                    <i className="align-middle ri-check-line"></i>
                                    <div className="text">Last Auto Saved: {formatLastSavedTime(lastSavedTime)}</div>
                                </div>
                            )}
                            {forApprovalCount > 0 && (
                                <NotificationDropdown
                                toggleQuotesModal={toggleQuotesModal}
                                setDefaultTab={setDefaultTab}
                                />
                            )}
                            <FullScreenDropdown />

                            <ProfileDropdown toggleQuotesModal={toggleQuotesModal} toggleSystemSettingModal={toggleSystemSettingModal} />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
