import React, { createContext, useContext, useState } from "react";

const ItemGridRowContext = createContext();

export const useDataRow = () => useContext(ItemGridRowContext);

export const ItemGridRowProvider = ({ children }) => {
    const [rowData, setRowData] = useState([]);

    const updateRowForId = (id, data) => {
        setRowData((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <ItemGridRowContext.Provider value={{ rowData, setRowData, updateRowForId }}>
            {children}
        </ItemGridRowContext.Provider>
    );
};
