import React, { useState, useEffect } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import DataGridView from "../Layouts/DataGridView";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { v4 as uuidv4 } from 'uuid'; 
import { RadioGroup } from "@progress/kendo-react-inputs";
import GroupDataGridView from "../Layouts/GroupableDataGridView";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import {
    calculateNettEaForDConLPChange,
    calculateTotalPriceForDConLPChange,
    calculateRepDiscForDConLPChange,
    calculateMarginForDConLPChange,
    calculateActMarginForDConLPChange,
    calculateLcMarginForDConLPChange,
    calculateNettEaForMarginChange,
    calculateTotalPriceForMarginChange,
    calculateRepDiscForMarginChange,
    calculateDConLPForMarginChange,
    calculateActMarginForMarginChange,
    calculateLcMarginForMarginChange,
    calculateFrcMarginForMarginChange,
    calculateTotalPriceForNetEEPriceChange,
    calculateRepDiscForNetEEPriceChange,
    calculateDConLPForNetEEPriceChange,
    calculateMarginForNetEEPriceChange,
    calculateActMarginForNetEEPriceChange,
    calculateLcMarginForNetEEPriceChange,
    calculateFrcMarginForDConLPChange,
    calculateFrcMarginForNetEEPriceChange,
} from "../../utils/Calculations";
import { toast, Slide } from "react-toastify";
import { usePartConsolidatedDetail } from "../../Contexts/PartConsolidatedDetailContext";
import { isNaN, isUndefined } from "lodash";
import { useAuthorizationContext } from "../Auth/useAuthorization";
import { useItemList } from "../../Contexts/ItemListProvider";

const PartsConsolidatedView = (props, setProjectName) => {
    const { quoteDetails, updateQuoteDetailsForId } = useQuoteDetails();
    const {
        partConsolidatedDetail,
        updatePartConsolidatedDetailForId,
        isApplyActionForParts,
        setIsApplyActionForParts,
        differ,
        setDiffer,
        differNettEA,
        setDifferNettEA,
        isAppliedDeleted,setIsAppliedDeleted
    } = usePartConsolidatedDetail();

    const [itemGridData, setItemGridData] = useState([]);
    const [originalItemGridData, setOriginalItemGridData] = useState([]);
    const [presentageValue, setPresentageValue] = useState("0.00");
    const [partsSelectionValue, setPartsSelectionValue] = useState();
    const [actionSelectionValue, setActionSelectionValue] = useState();
    const [periodEntered, setPeriodEntered] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const [selectedGridValue, setSelectedGridValue] = React.useState([]);
    const [unSelectedGridValue, setUnSelectedGridValue] = React.useState([]);

    const [partSearchValue, setPartSearchValue] = useState("");
    const [id, setId] = useState(props.id);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);
    const { canView, canEdit, canUpdate, getQuoteColMap, getQuoteViewOpt, getAccountRole } = useAuthorizationContext();
    const authType = getAccountRole();
    const { originalItems, updateItemListForId } = useItemList();
    const [currentQuoteId, setCurrentQuoteId] = useState();
    const [skipPriceLists, setSkipPriceLists] = useState(0);
    const [takePriceLists, setTakePriceLists] = useState(50);
    const [itemspageNumber, setitemsPageNumber] = useState(1);
    const [itemspageSize, setitemsPageSize] = useState(50);


    const paginate = (items, pageNumber, pageSize) => {
        if (!Array.isArray(items) || items.length <= 0) {
            return [];
        } else {
            const startIndex = (pageNumber - 1) * pageSize;
            return items.slice(startIndex, startIndex + pageSize);
        }
    };

    const handlePageChangePriceList = ({ skip, take }) => {
        setSkipPriceLists(skip);
        setTakePriceLists(take);
        setitemsPageNumber(skip / take + 1);
    };

    const handlePresentageValueKeyPress = (event) => {
        // Get the character code of the key pressed
        // const charCode = event.which || event.keyCode;
        const charCode = event.which || event.keyCode;
        const key = event.key;

        if (charCode === 13) {
            if (presentageValue !== "") {
                // Parse the input value as a float and format it to two decimal places
                const parsedValue = parseFloat(presentageValue).toFixed(2);
                setPresentageValue(parsedValue);
                setIsApplyDisabled(false);
            } else {
                setPresentageValue("0.00");
                setIsApplyDisabled(true);
            }
        }

        // Allow numeric digits (0-9) and a single decimal point (.)
        // if ((charCode < 48 || charCode > 57 && charCode !== 46 && // Check for decimal point
        // charCode !== 9))   //&& // Check for numeric digits
        // {
        if (charCode < 48 || (charCode > 57 && charCode !== 46 && key !== "." && key !== "Tab")) {
            //&& // Check for numeric digits
            // if (key === '.') {
            //   // Handle the period character as needed
            // }
            if (
                charCode === 9 ||
                charCode === 8 ||
                charCode === 37 ||
                charCode === 39 ||
                (charCode > 95 && charCode < 106) ||
                (charCode > 36 && charCode < 41)
            ) {
                // Allow the Tab key to propagate
                // return;
            } else {
                event.preventDefault(); // Prevent the character from being entered
            }
        }
    };

    const handlePresentageValueBlur = () => {
        if (presentageValue !== "") {
            // Parse the input value as a float and format it to two decimal places
            const parsedValue = parseFloat(presentageValue).toFixed(2);
            if (parsedValue > 100) {
                setPresentageValue("100.00");
                setIsApplyDisabled(false);
            } else {
                setPresentageValue(parsedValue);

                setIsApplyDisabled(false);
            }
        } else {
            setIsApplyDisabled(true);
            setPresentageValue("0.00");
        }
    };

    useEffect(() => {
        if (actionSelectionValue === "Delete") {
            setIsInputDisabled(true);
            setPresentageValue("0.00");
            setIsApplyDisabled(false);
        } else if (actionSelectionValue === "Nett Price By") {
            setIsInputDisabled(false);
            if (presentageValue !== "0.00") {
                setIsApplyDisabled(false);
                
            } else {
                setIsApplyDisabled(true);
                setIsAppliedDeleted(false);
            }
        } else if (actionSelectionValue === "Margin") {
            setIsInputDisabled(false);
            if (presentageValue !== "0.00") {
                setIsApplyDisabled(false);
            } else {
                setIsApplyDisabled(true);
                setIsAppliedDeleted(false);
            }
        } else if (actionSelectionValue === "Discount") {
            setIsInputDisabled(false);
            if (presentageValue !== "0.00") {
                setIsApplyDisabled(false);
            } else {
                setIsApplyDisabled(true);
                setIsAppliedDeleted(false);
            }
        }
        // else {
        //   setIsInputDisabled(false);
        //   setIsApplyDisabled(true);
        // }
    }, [actionSelectionValue]);

    useEffect(() => {
        setIsApplyDisabled(false);
    }, [partsSelectionValue]);

    useEffect(() => { }, [presentageValue]);

    useEffect(() => { }, [partSearchValue]);

    const handlePartsSelectionChange = (e) => {
        setPartsSelectionValue(e.value);
    };

    const handleActionSelectionChange = (e) => {
        setActionSelectionValue(e.value);
    };

    const handlePartSearchValueChange = (e) => {
        setPartSearchValue(e.value);
    };

    useEffect(() => { }, [selectedGridValue]);

    useEffect(() => { }, [unSelectedGridValue]);

    useEffect(() => {
        setSelectedGridValue(selectedItems);
        setUnSelectedGridValue(itemGridData.filter((item) => !selectedItems.includes(item)));
    }, [selectedItems]);

    useEffect(() => { }, [partConsolidatedDetail]);

    useEffect(() => {
        setActionSelectionValue("Discount");
        setPartsSelectionValue("Unselected Parts");
        setIsUndeleteAllDisabled(true);
        const newId = props.id;

        if (quoteDetails[newId] !== undefined) {
            if (quoteDetails[newId]?.savedQuote && quoteDetails[newId].savedQuote?.originalQuoteId !== newId) {
                setCurrentQuoteId(quoteDetails[newId].savedQuote?.originalQuoteId);
            } else {
                setCurrentQuoteId(newId);
            }

            let nullstIds = quoteDetails[newId].selectedItems.filter((f) => f.stId === null || f.stId === undefined);

            if (nullstIds.length > 0) {
                quoteDetails[newId].selectedItems = quoteDetails[newId].selectedItems.map((item) => {
                    if (item.stId === null) {
                        return {
                            ...item,
                            stId: uuidv4(),
                        };
                    }
                    return item;
                });
            }

            let newItemsGridData = quoteDetails[newId].selectedItems.filter(
                (f) => f.rowType !== "AI" && f.rowType !== "LN"
            );
           


            // Remove items with qty = 0
            newItemsGridData = newItemsGridData.filter(item => {
                if (item.rowType === 'CI' && item.qty > 0) {
                    return true;
                } else if (item.rowType !== 'CI') {
                    return true;
                }
                return false;
            });

            const consolidatedData = newItemsGridData.reduce((acc, item) => {
                if (item.rowType === 'CI' || item.rowType === 'A') {
                    const existingItem = acc.find(i => i.stStockCode === item.stStockCode);
                    if (existingItem) {
                        existingItem.qty += item.qty;
                    } else {
                        acc.push({ ...item });
                    }
                } else {
                    acc.push(item);
                }
                return acc;
            }, []);

         // Ensure that each item has a defined stId
         newItemsGridData = consolidatedData.map(item => {
            if (item === undefined || item.stId === undefined) {
                return {
                    ...item,
                    stId: '00000000-0000-0000-0000-000000000000'
                    
                };
            }
            else if (item.rowType === 'CI') {
                return {
                    ...item,
                    stId: uuidv4(),
                };
            }
            return item;
        });

            const dxItems = quoteDetails[newId].selectedItems.filter(
                (f) => f.rowType === "DX"
            );

            
            

            

            // let newItemsGridDataAssembly = quoteDetails[newId].selectedItems.filter((f) => f.rowType === "A");

            // // Merge assembly items into one if there are multiple indexes
            // if (newItemsGridDataAssembly.length > 1) {
            //     newItemsGridDataAssembly = newItemsGridDataAssembly.reduce((acc, item) => {

            //     const hasGroup = newItemsGridData.find((f) => f.groupId === item.groupId && f.rowType === 'G');
            //     const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === item.subGroupId && f.rowType === 'SG');


            //         if (acc.length === 0) {
            //             acc.push({ ...item });
            //         } else {
            //             if (hasGroup) {
            //                 if (hasSubGroup) {
            //                     acc[0].qty += item.qty * hasSubGroup.qty * hasGroup.qty;
            //                 } else {
            //                     acc[0].qty += item.qty * hasGroup.qty;
            //                 }
            //             } else {
            //                 acc[0].qty += item.qty;
            //             }
            //             acc[0].totalPrice += item.totalPrice;
            //             acc[0].margin = acc[0].margin || item.margin;
            //             acc[0].actMargin = acc[0].actMargin || item.actMargin;
            //             acc[0].frcMargin = acc[0].frcMargin || item.frcMargin;
            //             acc[0].stEecostRound += item.stEecostRound;
            //             acc[0].actCostExact += item.actCostExact;
            //             acc[0].lastCostExact += item.lastCostExact;
            //         }
            //         return acc;
            //     }, []);
            // }

            const findDuplicates = (arr) => {
                const set = new Set();
                const duplicates = [];
                arr.forEach((item) => {
                    if (set.has(item)) {
                        duplicates.push(item);
                    } else {
                        set.add(item);
                    }
                });
                return duplicates;
            };
            let consumable = '';
            const sumByStId =
                newItemsGridData === undefined
                    ? 0
                    : newItemsGridData.reduce((acc, item) => {
                        const { stId, stStockCode, qty} = item;

                        findDuplicates(newItemsGridData.map((item) => item.stStockCode));

                        if (stStockCode !== 'CONSUMABLES') {
                            if (!acc[stId]) {
                                acc[stId] = 0;
                            }

                            if (item.rowType !== 'G' && item.rowType !== "SG") {
                                const hasGroup = newItemsGridData.find((f) => f.groupId === item.groupId && f.rowType === 'G');
                                const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === item.subGroupId && f.rowType === 'SG');

                                if (hasGroup) {
                                    let totals = 0;
                                    if (hasSubGroup) {
                                        totals += qty * hasSubGroup.qty;
                                        acc[stId] += hasGroup.qty * totals;
                                    } else {
                                        acc[stId] += qty * hasGroup.qty;
                                    }
                                } else {
                                    acc[stId] += qty;
                                }
                            }
                        }
                        return acc;
                    }, []);

            for (let i = 0; i < dxItems.length; i++) {
                const { stId, qty } = dxItems[i];

                const hasGroup = newItemsGridData.find((f) => f.groupId === dxItems[i].groupId && f.rowType === 'G');
                const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === dxItems[i].subGroupId && f.rowType === 'SG');

                if (consumable === '' || consumable === undefined) {
                    if (!sumByStId[stId]) {
                        sumByStId[stId] = 0;
                    }

                    if (hasGroup) {
                        if (hasSubGroup) {
                            sumByStId[stId] += qty * hasSubGroup.qty * hasGroup.qty;
                        } else {
                            sumByStId[stId] += qty * hasGroup.qty;
                        }
                    } else {
                        sumByStId[stId] += qty;
                    }
                    consumable = stId;
                }
                 else {
                    if (hasGroup) {
                        if (hasSubGroup) {
                            sumByStId[consumable] += qty * hasSubGroup.qty * hasGroup.qty;
                        } else {
                            sumByStId[consumable] += qty * hasGroup.qty;
                        }
                    } else {
                        sumByStId[consumable] += qty;
                    }
                }
            }

            const newGridData = Object.keys(sumByStId).map((stId) => {
                return {
                    stId,
                    stStockCode: newItemsGridData.find((item) => item.stId === stId).stStockCode,
                    soh: newItemsGridData.find((item) => item.stId === stId).soh,
                    description: newItemsGridData.find((item) => item.stId === stId).description,
                    nettEA:
                        newItemsGridData.filter((item) => item.stId === stId).length > 1 && !isNettEASame(newItemsGridData, stId)
                            ? ""
                            : parseFloat(
                                parseFloat(
                                    newItemsGridData.find((item) => item.stId === stId).nettEA
                                        ? newItemsGridData.find((item) => item.stId === stId).nettEA
                                        : "0.0"
                                ).toFixed(2)
                            ),
                    dcOnLp:
                        newItemsGridData.filter((item) => item.stId === stId).length > 1 && !isDcOnLPSame(newItemsGridData, stId)
                            ? ""
                            : parseFloat(
                                parseFloat(
                                    newItemsGridData.find((item) => item.stId === stId).dcOnLp
                                        ? newItemsGridData.find((item) => item.stId === stId).dcOnLp
                                        : "0.0"
                                ).toFixed(1)
                            ),
                    qty: sumByStId[stId],
                    listPrice: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).listPrice))
                        ? ""
                        : parseFloat(
                            parseFloat(newItemsGridData.find((item) => item.stId === stId).listPrice).toFixed(2)
                        ),
                    totalPrice: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).totalPrice))
                        ? ""
                        : parseFloat(
                            parseFloat(newItemsGridData.find((item) => item.stId === stId).totalPrice).toFixed(2)
                        ),
                    margin: (isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).margin)) || (parseFloat(newItemsGridData.find((item) => item.stId === stId).margin).toFixed(2)) <= 0)
                        ? ""
                        : parseFloat(parseFloat(newItemsGridData.find((item) => item.stId === stId).margin).toFixed(2)),
                    actMargin: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).actMargin))
                        ? ""
                        : parseFloat(
                            parseFloat(newItemsGridData.find((item) => item.stId === stId).actMargin).toFixed(2)
                        ),
                    lcMargin: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).lcMargin))
                        ? ""
                        : parseFloat(
                            parseFloat(newItemsGridData.find((item) => item.stId === stId).lcMargin).toFixed(2)
                        ),
                    frcMargin: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).frcMargin))
                        ? ""
                        : parseFloat(
                            parseFloat(newItemsGridData.find((item) => item.stId === stId).frcMargin).toFixed(2)
                        ),
                    repDisc: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).repDisc))
                        ? ""
                        : parseFloat(newItemsGridData.find((item) => item.stId === stId).repDisc),
                    stEecost: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).stEecostRound))
                        ? ""
                        : parseFloat(newItemsGridData.find((item) => item.stId === stId).stEecostRound),
                    actCostExact: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).actCostExact))
                        ? ""
                        : parseFloat(newItemsGridData.find((item) => item.stId === stId).actCostExact),
                    lastCostExact: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).lastCostExact))
                        ? ""
                        : parseFloat(newItemsGridData.find((item) => item.stId === stId).lastCostExact),
                    FutureCostExact: isNaN(parseFloat(newItemsGridData.find((item) => item.stId === stId).stFutureCostRound))
                        ? ""
                        : parseFloat(newItemsGridData.find((item) => item.stId === stId).stFutureCostRound),
                    isDeleted: false,
                    rowType: newItemsGridData.find((item) => item.stId === stId).rowType,
                    assemblyId: newItemsGridData.find((item) => item.stId === stId).assemblyId,
                };
            });

            // const sumByAssemblyId =
            //     newItemsGridDataAssembly === undefined
            //         ? 0
            //         : newItemsGridDataAssembly.reduce((acc, item) => {
            //             const hasGroup = newItemsGridData.find((f) => f.groupId === item.groupId && f.rowType === 'G');
            //             const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === item.subGroupId && f.rowType === 'SG');

            //             if (acc.length === 0) {
            //                 acc.push({ ...item });
            //             } else {
            //                 if (hasGroup) {
            //                     if (hasSubGroup) {
            //                         acc[0].qty += item.qty * hasSubGroup.qty * hasGroup.qty;
            //                     } else {
            //                         acc[0].qty += item.qty * hasGroup.qty;
            //                     }
            //                 } else {
            //                     acc[0].qty += item.qty;
            //                 }
            //                 acc[0].totalPrice += item.totalPrice;
            //                 acc[0].margin = acc[0].margin || item.margin;
            //                 acc[0].actMargin = acc[0].actMargin || item.actMargin;
            //                 acc[0].frcMargin = acc[0].frcMargin || item.frcMargin;
            //                 acc[0].stEecostRound += item.stEecostRound;
            //                 acc[0].actCostExact += item.actCostExact;
            //                 acc[0].lastCostExact += item.lastCostExact;
            //             }
            //             return acc;
            //         }, []);

            // const newGridDataAssembly = sumByAssemblyId.map((item) => ({
            //     assemblyId: item.assemblyId,
            //     stStockCode: item.stStockCode,
            //     soh: item.soh,
            //     description: item.description,
            //     nettEA: parseFloat(parseFloat(item.nettEA ? item.nettEA : "0.0").toFixed(2)),
            //     dcOnLp: parseFloat(parseFloat(item.dcOnLp ? item.dcOnLp : "0.0").toFixed(1)),
            //     qty: item.qty,
            //     listPrice: 0,
            //     totalPrice: parseFloat(parseFloat(item.totalPrice).toFixed(2)),
            //     margin: parseFloat(parseFloat(item.margin).toFixed(2)),
            //     actMargin: parseFloat(parseFloat(item.actMargin).toFixed(2)),
            //     frcMargin: parseFloat(parseFloat(item.frcMargin).toFixed(2)),
            //     stEecost: parseFloat(item.stEecostRound).toFixed(2),
            //     actCostExact: parseFloat(item.actCostExact),
            //     lastCostExact: parseFloat(parseFloat(item.lastCostExact).toFixed(2)),
            //     isDeleted: false,
            //     rowType: item.rowType,
            // }));

            const groupFilteredGridData = newGridData.filter((item) => item.stStockCode !== "" && item.stId !== "");
            // const groupFilteredGridDataAssembly = newGridDataAssembly.filter(
            //     (item) => item.stStockCode !== "" && item.assemblyId !== ""
            // );
            const combinedItem = [...groupFilteredGridData];

            setOriginalItemGridData(combinedItem);
            setItemGridData(combinedItem);
            setUnSelectedGridValue(combinedItem);
            updatePartConsolidatedDetailForId(id, { selectedItems: combinedItem });
        } else {
            toast.error("Sorry ! Cannot go to the part list without a customer and items", {
                position: "top-center",
                transition: Slide,
            });
        }
    }, [props.id]);




    function isNettEASame(items, stid) {
        const lstItems = items.find((item) => item.stId === stid);
        let netEAVal = lstItems.nettEA;

        for (let i = 0; i < items.length; i++) {
            if (items[i].stId === stid && items[i].nettEA !== netEAVal) {
                return false;
            }
        }

        return true;
    }

    function isDcOnLPSame(items, stid) {
        const lstItems = items.find((item) => item.stId === stid);
        let dcOnLpVal = lstItems.dcOnLp;

        for (let i = 0; i < items.length; i++) {
            if (items[i].stId === stid && items[i].dcOnLp !== dcOnLpVal) {
                return false;
            }
        }

        return true;
    }

    const checkNaN = (val) => {
        if (isNaN(val)) return 0;
        else return parseFloat(parseFloat(val).toFixed(2));
    };

    const checkUndefined = (val) => {
        if (isUndefined(val)) return "";
        else val;
    };

    const tasks = [
        {
            label: "Margin",
            value: "Margin",
            className: "task red",
            disabled: authType === "General" ? true : false,
        },
        {
            label: "Discount",
            value: "Discount",
            className: "task orange",
        },
        {
            label: "Nett Price By",
            value: "Nett Price By",
            className: "task green",
        },
        {
            label: "Delete",
            value: "Delete",
            className: "task blue",
        },
    ];

    const partsSelection = [
        {
            label: "Selected Parts",
            value: "Selected Parts",
            className: "task red",
        },
        {
            label: "Unselected Parts",
            value: "Unselected Parts",
            className: "task orange",
        },
    ];

    const columns = [
        { field: "stId", title: "ID", width: "50px", show: false },
        { field: "stStockCode", title: "Part No", width: "240px" },
        { field: "description", title: "Description", minWidth: "300px" },
        { field: "qty", title: "Qty", width: "100px", editable: false },
        { field: "stEecost", title: "CostEA", width: "100px", show: authType !== "General" ? true : false },
        { field: "dcOnLp", title: "Discount (%)", width: "100px", editable: true },
        { field: "nettEA", title: "NettEA", width: "100px", editable: true },
        { field: "listPrice", title: "List Price", width: "100px", show: false },
        { field: "soh", title: "SOH", width: "100px", show: false },
        { field: "totalPrice", title: "Total Price", width: "100px", show: false },
        {
            field: "margin",
            title: "Margin",
            width: "100px",
            editable: true,
            show: authType !== "General" ? true : false,
        },
        { field: "actMargin", title: "Act Margin", width: "100px", show: false },
        { field: "lcMargin", title: "Lc Margin", width: "100px", show: false },
        { field: "frcMargin", title: "Fc Margin", width: "100px", show: false },

        { field: "actCostExact", title: "ActCost Exact", width: "100px", show: false },
        { field: "lastCostExact", title: "LastCost Exact", width: "100px", show: false },
        { field: "repDisc", title: "RepDisc", width: "100px", show: false },
        { field: "isDeleted", title: "isDeleted", width: "100px", show: false },
    ];

    const [layout, setLayout] = React.useState("Discount");
    const [partsSelectionLayout, setPartsSelectionLayout] = React.useState("Unselected Parts");

    const inputStyle = {
        width: "100px", // Set your desired width here
        textAlign: "right", // Set your desired text alignment here
    };
    const [selectedItem, setSelectedItem] = useState(null);

    //check and delete if not needed handleRowSelect
    const handleRowSelect = (event) => {
        setSelectedItem(event.dataItem); // Update the selected item
    };

    const findByPartNo = (event) => {
        const filteredData = originalItemGridData.filter((item) =>
            (item.stStockCode || "").toLowerCase().includes(partSearchValue.toLowerCase())
        );
        if (filteredData.length > 0) {
            setItemGridData(filteredData);
            updatePartConsolidatedDetailForId(id, { selectedItems: filteredData });
        } else {
            toast.error(`Sorry! No part is found for the item named or item name begin with "${partSearchValue}"`, {
                position: "top-center",
                transition: Slide,
            });
        }
    };

    const showAllInGrid = (event) => {
        setPartSearchValue("");
        setItemGridData(originalItemGridData);
        setOriginalItemGridData(originalItemGridData);
        updatePartConsolidatedDetailForId(id, { selectedItems: originalItemGridData });
    };

    const applyItemById = (e) => {
        if (partsSelectionValue === "Selected Parts") {
            if (actionSelectionValue === "Margin") {
                
                setIsAppliedDeleted(false);
                for (let i = 0; i < selectedGridValue.length; i++) {
                   
                    const newData = [...itemGridData];
                    const itemIndex = itemGridData.findIndex((item) => item.stId === selectedGridValue[i].stId);
                    if (selectedGridValue[i].rowType === "A"){
                        newData[itemIndex].isDeleted = false;
                        continue;
                }

                    if (itemIndex !== -1) {
                        //calculations
                        let ogItem = quoteDetails[currentQuoteId]?.selectedItems?.find(
                            (item) => item.stStockCode === selectedGridValue[i].stStockCode
                        );
                        // let ogItem = originalItems[currentQuoteId]?.items?.find(
                        //     (item) => item.stStockCode === unSelectedGridValue[i].stStockCode &&
                        //     (item.rowtype !== 'AI' && item.rowtype !== 'DX')
                        // );

                        const originalmargin = ogItem?.margin;
                        const originalnettEA = ogItem?.nettEA;

                        const previousNettEA = selectedGridValue[i].nettEA;
                        //const listPrice = selectedGridValue[i].listPrice;
                        //const newData = [...itemGridData];
                        newData[itemIndex].isDeleted = false;
                        const listPrice = newData[itemIndex].rowType === 'CI' ? newData[itemIndex].nettEA : newData[itemIndex].listPrice;
                        const updatedMargin = parseFloat(parseFloat(presentageValue).toFixed(2));
                        const updatedNettEA = calculateNettEaForMarginChange(
                            newData[itemIndex].stEecost,
                            newData[itemIndex].margin
                        );
                        const updatedDcOnLp = calculateDConLPForMarginChange(
                            listPrice,
                            newData[itemIndex].nettEA
                        );
                        const updatedTotalPrice = calculateTotalPriceForMarginChange(
                            newData[itemIndex].qty,
                            newData[itemIndex].nettEA
                        );
                        const updatedRepDisc = calculateRepDiscForMarginChange(
                            newData[itemIndex].nettEA,
                            previousNettEA
                        );
                        const updatedActMargin = calculateActMarginForMarginChange(
                            newData[itemIndex].actCostExact,
                            newData[itemIndex].nettEA
                        );
                        const updatedLcMargin = calculateLcMarginForMarginChange(
                            newData[itemIndex].lastCostExact,
                            newData[itemIndex].nettEA
                        );
                        //Added by Manoj on 08/04/2024 for FRC Margin
                        const updatedFrcMargin = calculateFrcMarginForMarginChange(
                            ogItem?.stFutureCost,
                            newData[itemIndex].nettEA
                        );

                        newData[itemIndex].nettEA = updatedNettEA;
                        newData[itemIndex].dcOnLp = updatedDcOnLp;
                        newData[itemIndex].totalPrice = updatedTotalPrice;

                        //ended from here on 06/10/2023
                        newData[itemIndex].repDisc = updatedRepDisc;
                        newData[itemIndex].margin =updatedMargin <= 0 ? "" :updatedMargin;
                        newData[itemIndex].actMargin = updatedActMargin;
                        newData[itemIndex].lcMargin = updatedLcMargin;
                        newData[itemIndex].frcMargin = updatedFrcMargin;

                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                        if (originalmargin !== updatedMargin) {
                            setDiffer(true);
                            console.log("Differ in Margin");
                        }
                        if (originalnettEA !== updatedNettEA) {
                            setDifferNettEA(true);
                            console.log("Differ in NettEA");
                        }

                        const OrginalItemGridIndex = originalItemGridData.findIndex(
                            (item) => item.stId === selectedGridValue[i].stId
                        );
                        if (OrginalItemGridIndex !== -1) {
                            const newOrginalItemGridData = [...originalItemGridData];
                            const newOrginalItemPreviousNettEA = selectedGridValue[i].nettEA;

                            newOrginalItemGridData[OrginalItemGridIndex].nettEA = calculateNettEaForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].margin
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = calculateDConLPForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].listPrice,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].totalPrice =
                                calculateTotalPriceForMarginChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].qty,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                );
                            newOrginalItemGridData[OrginalItemGridIndex].repDisc = calculateRepDiscForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                                newOrginalItemPreviousNettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].margin = updatedMargin;
                            newOrginalItemGridData[OrginalItemGridIndex].actMargin = calculateActMarginForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].lcMargin = calculateLcMarginForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].frcMargin = calculateFrcMarginForMarginChange(
                                ogItem?.stFutureCost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );

                            setOriginalItemGridData(newOrginalItemGridData);
                        }
                    }
                }
            } else if (actionSelectionValue === "Discount") {
                setIsAppliedDeleted(false);
                for (let i = 0; i < selectedGridValue.length; i++) {
                    const newData = [...itemGridData];
                    const itemIndex = itemGridData.findIndex((item) => item.stId === selectedGridValue[i].stId);
                    if (selectedGridValue[i].rowType === "A" || selectedGridValue[i].rowType === "DX") {
                        newData[itemIndex].isDeleted = false;
                        continue;
                }
                   

                    if (itemIndex !== -1) {
                        //calculations
                        let ogItem = quoteDetails[currentQuoteId]?.selectedItems?.find(
                            (item) => item.stStockCode === selectedGridValue[i].stStockCode
                        );
                        // let ogItem = originalItems[currentQuoteId]?.items?.find(
                        //     (item) => item.stStockCode === unSelectedGridValue[i].stStockCode &&
                        //     (item.rowtype !== 'AI' && item.rowtype !== 'DX')
                        // );

                        const originalmargin = ogItem?.margin;
                        const originalnettEA = ogItem?.nettEA;

                        const updatedPrecentage = parseFloat(parseFloat(presentageValue).toFixed(1));
                        const previousNettEA = parseFloat(selectedGridValue[i].nettEA);
                        // const listPrice = checkNaN(selectedGridValue[i].listPrice);
                        const listPrice = checkNaN(selectedGridValue[i].rowType === 'CI' ? selectedGridValue[i].nettEA : selectedGridValue[i].listPrice);
                        //const newData = [...itemGridData];
                        newData[itemIndex].isDeleted = false;

                        const updatedDcOnLp = updatedPrecentage;
                        const updatedNettEA = calculateNettEaForDConLPChange(previousNettEA, updatedPrecentage);
                        const updatedTotalPrice = calculateTotalPriceForDConLPChange(
                            newData[itemIndex].qty,
                            newData[itemIndex].nettEA
                        );
                        const updatedRepDisc = calculateRepDiscForDConLPChange(updatedNettEA, previousNettEA);
                        const updatedMargin = calculateMarginForDConLPChange(
                            newData[itemIndex].stEecost,
                            newData[itemIndex].nettEA
                        );
                        const actMargin = calculateActMarginForDConLPChange(
                            newData[itemIndex].actCostExact,
                            newData[itemIndex].nettEA
                        );
                        const lcMargin = calculateLcMarginForDConLPChange(
                            newData[itemIndex].lastCostExact,
                            newData[itemIndex].nettEA
                        );

                        const updatedFrcMargin = calculateFrcMarginForDConLPChange(
                            ogItem?.stFutureCost,
                            newData[itemIndex].nettEA
                        );

                        newData[itemIndex].dcOnLp = updatedDcOnLp;
                        newData[itemIndex].nettEA = updatedNettEA;
                        newData[itemIndex].totalPrice = updatedTotalPrice;
                        //ended from here on 06/10/2023
                        newData[itemIndex].repDisc = updatedRepDisc;
                        newData[itemIndex].margin = updatedMargin <= 0 ? "" : updatedMargin;
                        newData[itemIndex].actMargin = actMargin;
                        newData[itemIndex].lcMargin = lcMargin;
                        newData[itemIndex].frcMargin = updatedFrcMargin;

                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                        if (originalmargin !== updatedMargin) {
                            setDiffer(true);
                            console.log("Differ in Margin");
                        }
                        if (originalnettEA !== updatedNettEA) {
                            setDifferNettEA(true);
                            console.log("Differ in NettEA");
                        }
                        const OrginalItemGridIndex = originalItemGridData.findIndex(
                            (item) => item.stId === selectedGridValue[i].stId
                        );

                        if (OrginalItemGridIndex !== -1) {
                            const newOrginalItemGridData = [...originalItemGridData];
                            const newOrginalItemPreviousNettEA = selectedGridValue[i].nettEA;
                            newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = parseFloat(
                                parseFloat(presentageValue).toFixed(1)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].nettEA = parseFloat(
                                parseFloat(calculateNettEaForDConLPChange(listPrice, presentageValue)).toFixed(2)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].totalPrice = parseFloat(
                                parseFloat(
                                    calculateTotalPriceForDConLPChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].qty,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].repDisc = parseFloat(
                                parseFloat(
                                    calculateRepDiscForDConLPChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                                        newOrginalItemPreviousNettEA
                                    )
                                ).toFixed(1)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].margin = parseFloat(
                                parseFloat(
                                    calculateMarginForDConLPChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].actMargin = parseFloat(
                                parseFloat(
                                    calculateActMarginForDConLPChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].lcMargin = parseFloat(
                                parseFloat(
                                    calculateLcMarginForDConLPChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].frcMargin = parseFloat(
                                parseFloat(
                                    calculateFrcMarginForDConLPChange(
                                        ogItem?.stFutureCost,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2)
                            );

                            setOriginalItemGridData(newOrginalItemGridData);
                        }
                    }
                }
            } else if (actionSelectionValue === "Nett Price By") {
                setIsAppliedDeleted(false);
                for (let i = 0; i < selectedGridValue.length; i++) {
                    const itemIndex =
                        selectedGridValue[i].stId === undefined
                            ? itemGridData.findIndex((item) => item.assemblyId === selectedGridValue[i].assemblyId)
                            : itemGridData.findIndex((item) => item.stId === selectedGridValue[i].stId);

                    if (itemIndex !== -1) {
                        let ogItem = quoteDetails[currentQuoteId]?.selectedItems?.find(
                            (item) => item.stStockCode === selectedGridValue[i].stStockCode
                        );
                        // let ogItem = originalItems[currentQuoteId]?.items?.find(
                        //     (item) => item.stStockCode === unSelectedGridValue[i].stStockCode &&
                        //     (item.rowtype !== 'AI' && item.rowtype !== 'DX')
                        // );
                        const originalmargin = ogItem?.margin;
                        const originalnettEA = ogItem?.nettEA;

                        const previousNettEA = parseFloat(selectedGridValue[i].nettEA);
                        const nettEAPercentage = (selectedGridValue[i].nettEA * presentageValue) / 100;
                        const newNettEA = parseFloat(nettEAPercentage) + parseFloat(previousNettEA);
                        // const listPrice = checkNaN(selectedGridValue[i].listPrice);
                        const listPrice = checkNaN(selectedGridValue[i].rowType === 'CI' ? selectedGridValue[i].nettEA : selectedGridValue[i].listPrice);
                        const newData = [...itemGridData];
                        newData[itemIndex].isDeleted = false;
                        newData[itemIndex].isDeleted = false;
                        newData[itemIndex].nettEA = parseFloat(newNettEA).toFixed(2);
                        newData[itemIndex].totalPrice = parseFloat(
                            calculateTotalPriceForNetEEPriceChange(newData[itemIndex].qty, newNettEA)
                        ).toFixed(2);

                        //ended from here on 06/10/2023
                        if (unSelectedGridValue[i].stId !== undefined) {
                            newData[itemIndex].dcOnLp = parseFloat(
                                calculateDConLPForNetEEPriceChange(listPrice, newNettEA)
                            ).toFixed(1);
                            newData[itemIndex].repDisc = parseFloat(
                                calculateRepDiscForNetEEPriceChange(newNettEA, previousNettEA)
                            ).toFixed(1);
                            newData[itemIndex].margin = parseFloat(
                                calculateMarginForNetEEPriceChange(
                                    newData[itemIndex].stEecost,
                                    newData[itemIndex].nettEA
                                )
                            ).toFixed(2);
                            newData[itemIndex].actMargin = parseFloat(
                                calculateActMarginForNetEEPriceChange(newData[itemIndex].actCostExact, newNettEA)
                            ).toFixed(2);
                            newData[itemIndex].lcMargin = parseFloat(
                                calculateLcMarginForNetEEPriceChange(newData[itemIndex].lastCostExact, newNettEA)
                            ).toFixed(2);
                            newData[itemIndex].frcMargin = parseFloat(
                                parseFloat(
                                    calculateLcMarginForNetEEPriceChange(newData[itemIndex].stFutureCost, newNettEA)
                                ).toFixed(2)
                            );
                        }
                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                        if (originalmargin !== newData[itemIndex].margin) {
                            setDiffer(true);
                            console.log("Differ in Margin");
                        }
                        if (originalnettEA !== newNettEA) {
                            setDifferNettEA(true);
                            console.log("Differ in NettEA");
                        }

                        const OrginalItemGridIndex =
                            selectedGridValue[i].stId === undefined
                                ? originalItemGridData.findIndex(
                                    (item) => item.assemblyId === selectedGridValue[i].assemblyId
                                )
                                : originalItemGridData.findIndex((item) => item.stId === selectedGridValue[i].stId);

                        if (OrginalItemGridIndex !== -1) {
                            const newOrginalItemGridData = [...originalItemGridData];
                            const newOrginalItemPreviousNettEA = selectedGridValue[i].nettEA;
                            const listPrice = selectedGridValue[i].rowType === 'CI' ? selectedGridValue[i].nettEA : selectedGridValue[i].listPrice;
                            // newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = parseFloat(
                            //     calculateDConLPForNetEEPriceChange(listPrice, newNettEA)
                            // ).toFixed(1);
                            newOrginalItemGridData[OrginalItemGridIndex].nettEA = parseFloat(newNettEA).toFixed(2);
                            newOrginalItemGridData[OrginalItemGridIndex].totalPrice = parseFloat(
                                calculateTotalPriceForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].qty,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                )
                            ).toFixed(2);
                            newOrginalItemGridData[OrginalItemGridIndex].repDisc = parseFloat(
                                calculateRepDiscForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                                    newOrginalItemPreviousNettEA
                                )
                            ).toFixed(1);
                            newOrginalItemGridData[OrginalItemGridIndex].margin = parseFloat(
                                calculateMarginForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                )
                            ).toFixed(2);
                            newOrginalItemGridData[OrginalItemGridIndex].actMargin = parseFloat(
                                calculateActMarginForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                )
                            ).toFixed(2);
                            newOrginalItemGridData[OrginalItemGridIndex].lcMargin = parseFloat(
                                calculateLcMarginForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                )
                            ).toFixed(2);
                            newOrginalItemGridData[OrginalItemGridIndex].frcMargin = parseFloat(
                                calculateFrcMarginForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].stFutureCost,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                )
                            ).toFixed(2);

                            setOriginalItemGridData(newOrginalItemGridData);
                        }
                    }
                }
            } else if (actionSelectionValue === "Delete") {
                handleUnDeleteAll();
                for (let i = 0; i < selectedGridValue.length; i++) {
                    const itemIndex =
                        selectedGridValue[i].stId === undefined
                            ? itemGridData.findIndex((item) => item.assemblyId === selectedGridValue[i].assemblyId)
                            : itemGridData.findIndex((item) => item.stId === selectedGridValue[i].stId);

                    if (itemIndex !== -1) {
                        const newData = [...itemGridData];

                        newData[itemIndex].isDeleted = true;
                        setIsUndeleteAllDisabled(false);
                        setIsAppliedDeleted(true);
                        //const updatedItems = newData.filter((item) => item.isStriked !== true);
                        //setItemGridData(updatedItems);
                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                    }
                }
                setPresentageValue("0.00");
            }
        } else if (partsSelectionValue === "Unselected Parts") {
            if (actionSelectionValue === "Margin") {
                setIsAppliedDeleted(false);
                for (let i = 0; i < unSelectedGridValue.length; i++) {
                    
                    const newData = [...itemGridData];
                    const itemIndex = itemGridData.findIndex((item) => item.stId === unSelectedGridValue[i].stId);
                    if (unSelectedGridValue[i].rowType === "A"){
                        newData[itemIndex].isDeleted = false;
                        continue;
                }

                    if (itemIndex !== -1) {
                        //calculations
                        // let ogItem = originalItems[currentQuoteId]?.items?.find(
                        //     (item) => item.stStockCode === unSelectedGridValue[i].stStockCode
                        // );
                        let ogItem = quoteDetails[currentQuoteId]?.selectedItems?.find(
                            (item) => item.stStockCode === unSelectedGridValue[i].stStockCode
                        );

                        const originalmargin = ogItem?.margin;
                        const originalnettEA = ogItem?.nettEA;

                        const previousNettEA = unSelectedGridValue[i].nettEA;
                        // const listPrice = unSelectedGridValue[i].listPrice;

                        const newData = [...itemGridData];
                        newData[itemIndex].isDeleted = false;
                        const listPrice = newData[itemIndex].rowType === 'CI' ? newData[itemIndex].nettEA : newData[itemIndex].listPrice;

                        const updatedMargin = parseFloat(parseFloat(presentageValue).toFixed(1));
                        const updatedNettEA = calculateNettEaForMarginChange(
                            newData[itemIndex].stEecost,
                            newData[itemIndex].margin
                        );
                        const updatedDcOnLp = calculateDConLPForMarginChange(
                            listPrice,
                            newData[itemIndex].nettEA
                        );
                        const updatedTotalPrice = calculateTotalPriceForMarginChange(
                            newData[itemIndex].qty,
                            newData[itemIndex].nettEA
                        );
                        const updatedRepDisc = calculateRepDiscForMarginChange(
                            newData[itemIndex].nettEA,
                            originalItems[currentQuoteId]?.items?.find(
                                (item) => item.stStockCode === unSelectedGridValue[i].stStockCode
                            )?.nettEA
                            // previousNettEA //always use the original nettEA
                        );
                        const updatedActMargin = calculateActMarginForMarginChange(
                            newData[itemIndex].actCostExact,
                            newData[itemIndex].nettEA
                        );
                        const updatedLcMargin = calculateLcMarginForMarginChange(
                            newData[itemIndex].lastCostExact,
                            newData[itemIndex].nettEA
                        );
                        const updatedFrcMargin = calculateFrcMarginForMarginChange(
                            // newData[itemIndex].stFutureCost,
                            ogItem?.stFutureCost,
                            newData[itemIndex].nettEA
                        );


                        newData[itemIndex].nettEA = updatedNettEA;
                        newData[itemIndex].dcOnLp = updatedDcOnLp;
                        newData[itemIndex].totalPrice = updatedTotalPrice;

                        //ended from here on 06/10/2023
                        newData[itemIndex].repDisc = updatedRepDisc;
                        newData[itemIndex].margin =  updatedMargin <= 0 ? "" :updatedMargin;
                        newData[itemIndex].actMargin = updatedActMargin;
                        newData[itemIndex].lcMargin = updatedLcMargin;
                        newData[itemIndex].frcMargin = updatedFrcMargin;

                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                        if (originalmargin !== updatedMargin) {
                            setDiffer(true);
                        }
                        if (originalnettEA !== updatedNettEA) {
                            setDifferNettEA(true);
                        }
                        const OrginalItemGridIndex = originalItemGridData.findIndex(
                            (item) => item.stId === unSelectedGridValue[i].stId
                        );

                        if (OrginalItemGridIndex !== -1) {
                            const newOrginalItemGridData = [...originalItemGridData];

                            ogItem = originalItems[currentQuoteId]?.items?.find(
                                (item) => item.stStockCode === newOrginalItemGridData[OrginalItemGridIndex].stStockCode
                            );
                            const newOrginalItemPreviousNettEA = unSelectedGridValue[i].nettEA;

                            newOrginalItemGridData[OrginalItemGridIndex].nettEA = calculateNettEaForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].margin
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = calculateDConLPForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].listPrice,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].totalPrice =
                                calculateTotalPriceForMarginChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].qty,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                );
                            newOrginalItemGridData[OrginalItemGridIndex].repDisc = calculateRepDiscForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                                // newOrginalItemPreviousNettEA
                                ogItem?.nettEA === undefined ? 0 : ogItem?.nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].margin = updatedMargin;
                            newOrginalItemGridData[OrginalItemGridIndex].actMargin = calculateActMarginForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].lcMargin = calculateLcMarginForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );

                            newOrginalItemGridData[OrginalItemGridIndex].frcMargin = calculateFrcMarginForMarginChange(ogItem?.stFutureCost, newOrginalItemGridData[OrginalItemGridIndex].nettEA);

                            setOriginalItemGridData(newOrginalItemGridData);
                        }
                    }
                }
            } else if (actionSelectionValue === "Discount") {
                setIsAppliedDeleted(false);
                for (let i = 0; i < unSelectedGridValue.length; i++) {
                 
                    const newData = [...itemGridData];
                    const itemIndex = itemGridData.findIndex((item) => item.stId === unSelectedGridValue[i].stId);
                    if (unSelectedGridValue[i].rowType === "A" || unSelectedGridValue[i].rowType === "DX") {

                        newData[itemIndex].isDeleted = false;
                        continue;
                }

                    if (itemIndex !== -1) {
                        //calculations
                        let ogItem = quoteDetails[id]?.selectedItems?.find(
                            (item) => item.stStockCode === unSelectedGridValue[i].stStockCode
                        );
                        const originalmargin = ogItem?.margin;
                        const originalnettEA = ogItem?.nettEA;
                        const updatedPrecentage = parseFloat(parseFloat(presentageValue).toFixed(1));
                        const previousNettEA = parseFloat(unSelectedGridValue[i].nettEA);
                        const listPrice = checkNaN(unSelectedGridValue[i].rowType === 'CI' ? unSelectedGridValue[i].nettEA : unSelectedGridValue[i].listPrice);
                        const newData = [...itemGridData];
                        newData[itemIndex].isDeleted = false;
                        const updatedDcOnLp = updatedPrecentage;
                        const updatedNettEA = calculateNettEaForDConLPChange(previousNettEA, updatedPrecentage);
                        const updatedTotalPrice = calculateTotalPriceForDConLPChange(
                            newData[itemIndex].qty,
                            newData[itemIndex].nettEA
                        );
                        const updatedRepDisc = calculateRepDiscForDConLPChange(updatedNettEA, previousNettEA);
                        const updatedMargin = calculateMarginForDConLPChange(
                            newData[itemIndex].stEecost,
                            newData[itemIndex].nettEA
                        );
                        const actMargin = calculateActMarginForDConLPChange(
                            newData[itemIndex].actCostExact,
                            newData[itemIndex].nettEA
                        );
                        const lcMargin = calculateLcMarginForDConLPChange(
                            newData[itemIndex].lastCostExact,
                            newData[itemIndex].nettEA
                        );
                        const updatedFrcMargin = calculateFrcMarginForDConLPChange(
                            ogItem?.stFutureCost,
                            newData[itemIndex].nettEA
                        );
                        newData[itemIndex].dcOnLp = updatedDcOnLp;
                        newData[itemIndex].nettEA = updatedNettEA;
                        newData[itemIndex].totalPrice = updatedTotalPrice;
                        newData[itemIndex].repDisc = updatedRepDisc;
                        newData[itemIndex].margin = updatedMargin <= 0 ? "" : updatedMargin;
                        newData[itemIndex].actMargin = actMargin;
                        newData[itemIndex].lcMargin = lcMargin;
                        newData[itemIndex].frcMargin = updatedFrcMargin;
                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });

                        if (originalmargin !== updatedMargin) {
                            setDiffer(true);
                        }
                        if (originalnettEA !== updatedNettEA) {
                            setDifferNettEA(true);
                        }

                        const OrginalItemGridIndex = originalItemGridData.findIndex(
                            (item) => item.stId === unSelectedGridValue[i].stId
                        );

                        if (OrginalItemGridIndex !== -1) {
                            const newOrginalItemGridData = [...originalItemGridData];
                            const newOrginalItemPreviousNettEA = unSelectedGridValue[i].nettEA;
                            newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = parseFloat(
                                parseFloat(presentageValue).toFixed(1)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].nettEA = calculateNettEaForDConLPChange(
                                listPrice,
                                parseFloat(presentageValue)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].totalPrice =
                                calculateTotalPriceForDConLPChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].qty,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                );
                            // newOrginalItemGridData[OrginalItemGridIndex].repDisc = calculateRepDiscForDConLPChange(
                            //     newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                            //     newOrginalItemPreviousNettEA
                            // );

                            newOrginalItemGridData[OrginalItemGridIndex].repDisc = updatedRepDisc;
                            newOrginalItemGridData[OrginalItemGridIndex].margin = calculateMarginForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].actMargin = calculateActMarginForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].lcMargin = calculateLcMarginForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].frcMargin = parseFloat(
                                parseFloat(
                                    calculateFrcMarginForDConLPChange(
                                        ogItem?.stFutureCost,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2)
                            );

                            setOriginalItemGridData(newOrginalItemGridData);
                        }
                    }
                }
            } else if (actionSelectionValue === "Nett Price By") {
                setIsInputDisabled(false);
                setIsAppliedDeleted(false);

                for (let i = 0; i < unSelectedGridValue.length; i++) {
                    const itemIndex =
                        unSelectedGridValue[i].stId === undefined
                            ? itemGridData.findIndex((item) => item.assemblyId === unSelectedGridValue[i].assemblyId)
                            : itemGridData.findIndex((item) => item.stId === unSelectedGridValue[i].stId);

                    if (itemIndex !== -1) {
                        let ogItem = quoteDetails[currentQuoteId]?.selectedItems?.find(
                            (item) => item.stStockCode === unSelectedGridValue[i].stStockCode
                        );
                        // let ogItem = originalItems[currentQuoteId]?.items?.find(
                        //     (item) => item.stStockCode === unSelectedGridValue[i].stStockCode &&
                        //     (item.rowtype !== 'AI' && item.rowtype !== 'DX')
                        // );
                        const originalmargin = ogItem?.margin;
                        const originalnettEA = ogItem?.nettEA;
                        const previousNettEA = parseFloat(unSelectedGridValue[i].nettEA);
                        const nettEAPercentage =
                            (parseFloat(unSelectedGridValue[i].nettEA) * parseFloat(presentageValue)) / 100;
                        const newNettEA = parseFloat(nettEAPercentage) + parseFloat(previousNettEA);
                        const listPrice = unSelectedGridValue[i].rowType === 'CI' ? unSelectedGridValue[i].nettEA : unSelectedGridValue[i].listPrice;
                        const newData = [...itemGridData];
                        newData[itemIndex].isDeleted = false;
                        

                        newData[itemIndex].nettEA = parseFloat(newNettEA.toFixed(2));
                        newData[itemIndex].totalPrice = calculateTotalPriceForNetEEPriceChange(
                            newData[itemIndex].qty,
                            newNettEA
                        );

                        if (unSelectedGridValue[i].stId !== undefined) {
                            newData[itemIndex].dcOnLp = calculateDConLPForNetEEPriceChange(listPrice, newNettEA);
                            newData[itemIndex].repDisc = calculateRepDiscForNetEEPriceChange(newNettEA, previousNettEA);
                            newData[itemIndex].margin = calculateMarginForNetEEPriceChange(
                                newData[itemIndex].stEecost,
                                newData[itemIndex].nettEA
                            );
                            newData[itemIndex].actMargin = calculateActMarginForNetEEPriceChange(
                                newData[itemIndex].actCostExact,
                                newNettEA
                            );
                            newData[itemIndex].lcMargin = calculateLcMarginForNetEEPriceChange(
                                newData[itemIndex].lastCostExact,
                                newNettEA
                            );
                            newData[itemIndex].frcMargin = calculateFrcMarginForNetEEPriceChange(
                                //newData[itemIndex].stFutureCost,
                                ogItem?.stFutureCost,
                                newNettEA
                            );
                        }

                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                        if (originalmargin !== newData[itemIndex].margin) {
                            setDiffer(true);
                        }
                        if (originalnettEA !== newNettEA) {
                            setDifferNettEA(true);
                        }
                        const OrginalItemGridIndex =
                            unSelectedGridValue[i].stId === undefined
                                ? originalItemGridData.findIndex(
                                    (item) => item.assemblyId === unSelectedGridValue[i].assemblyId
                                )
                                : originalItemGridData.findIndex((item) => item.stId === unSelectedGridValue[i].stId);

                        if (OrginalItemGridIndex !== -1) {
                            const newOrginalItemGridData = [...originalItemGridData];
                            const previousNettEA = parseFloat(unSelectedGridValue[i].nettEA);
                            // newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = parseFloat(
                            //     calculateDConLPForNetEEPriceChange(unSelectedGridValue[i].listPrice, newNettEA)
                            // ).toFixed(1);
                            newOrginalItemGridData[OrginalItemGridIndex].nettEA = parseFloat(
                                parseFloat(newNettEA).toFixed(2)
                            );
                            newOrginalItemGridData[OrginalItemGridIndex].totalPrice =
                                calculateTotalPriceForNetEEPriceChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].qty,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                );
                            newOrginalItemGridData[OrginalItemGridIndex].repDisc = calculateRepDiscForNetEEPriceChange(
                                parseFloat(newOrginalItemGridData[OrginalItemGridIndex].nettEA),
                                previousNettEA
                            );
                            // here 01
                            newOrginalItemGridData[OrginalItemGridIndex].margin = calculateMarginForNetEEPriceChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            //here 02
                            newOrginalItemGridData[OrginalItemGridIndex].actMargin = calculateActMarginForNetEEPriceChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );
                            //here 03
                            newOrginalItemGridData[OrginalItemGridIndex].lcMargin =
                                unSelectedGridValue[i].stId === undefined
                                    ? 0
                                    : calculateLcMarginForNetEEPriceChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    );

                            //here 04
                            newOrginalItemGridData[OrginalItemGridIndex].frcMargin = calculateFrcMarginForNetEEPriceChange(
                                // newOrginalItemGridData[OrginalItemGridIndex].stFutureCost,
                                ogItem?.stFutureCost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            );

                            setOriginalItemGridData(newOrginalItemGridData);
                        }
                    }
                }
            } else if (actionSelectionValue === "Delete") {
                setIsInputDisabled(true);
                handleUnDeleteAll();
                for (let i = 0; i < unSelectedGridValue.length; i++) {
                    const itemIndex =
                        unSelectedGridValue[i].stId === undefined
                            ? itemGridData.findIndex((item) => item.assemblyId === unSelectedGridValue[i].assemblyId)
                            : itemGridData.findIndex((item) => item.stId === unSelectedGridValue[i].stId);

                    if (itemIndex !== -1) {
                        const newData = [...itemGridData];

                        newData[itemIndex].isDeleted = true;
                        
                        setIsUndeleteAllDisabled(false);
                        setIsAppliedDeleted(true);
                        //const updatedItems = newData.filter((item) => item.isStriked !== true);
                        //setItemGridData(updatedItems);
                        setItemGridData(newData);
                        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                    }
                }
                setPresentageValue("0.00");
            }
        }

        setIsApplyActionForParts(false);
        setIsApplyDisabled(true);
    };

    const handleUnDeleteAll = (e) => {
        const newData = [...itemGridData];
        for (let i = 0; i < newData.length; i++) {
            newData[i].isDeleted = false;
            setIsUndeleteAllDisabled(true);
        }

        setIsApplyDisabled(false);
        setIsApplyActionForParts(true);
        setItemGridData(newData);
        updatePartConsolidatedDetailForId(id, { selectedItems: newData });
    };

    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [isUndeleteAllDisabled, setIsUndeleteAllDisabled] = useState(false);

    const handlePartSearchKeyPress = (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            findByPartNo();
        }
    };

    const handleGridChange = (e) => {
        if (e.field === "nettEA") {
            const itemIndex = itemGridData.findIndex((item) => item.stId === e.dataItem.stId);

            if (itemIndex !== -1) {
                if (itemGridData[itemIndex].rowType !== "A") {
                    const newData = [...itemGridData];

                    const previousNettEA = parseFloat(itemGridData[itemIndex].nettEA);
                    //const nettEAPercentage = (itemGridData[itemIndex].nettEA * presentageValue) / 100;
                    const newNettEA = isNaN(e.value) || e.value === '' ? 0 : parseFloat(e.value); //+ parseFloat(previousNettEA);
                    //const listPrice = itemGridData[itemIndex].listPrice;
                    const listPrice = itemGridData[itemIndex].rowType === 'CI' ? itemGridData[itemIndex].nettEA : itemGridData[itemIndex].listPrice;

                    newData[itemIndex].nettEA = parseFloat(newNettEA.toFixed(2));
                    newData[itemIndex].totalPrice = parseFloat(
                        parseFloat(calculateTotalPriceForNetEEPriceChange(newData[itemIndex].qty, newNettEA)).toFixed(2)
                    );

                    newData[itemIndex].dcOnLp = parseFloat(
                        calculateDConLPForNetEEPriceChange(listPrice, newNettEA)
                    ).toFixed(1);
                    newData[itemIndex].repDisc = parseFloat(
                        calculateRepDiscForNetEEPriceChange(newNettEA, previousNettEA)
                    ).toFixed(1);
                    newData[itemIndex].margin = parseFloat(
                        parseFloat(
                            calculateMarginForNetEEPriceChange(newData[itemIndex].stEecost, newData[itemIndex].nettEA)
                        ).toFixed(2)
                    );
                    newData[itemIndex].actMargin = parseFloat(
                        parseFloat(
                            calculateActMarginForNetEEPriceChange(newData[itemIndex].actCostExact, newNettEA)
                        ).toFixed(2)
                    );
                    newData[itemIndex].lcMargin = parseFloat(
                        parseFloat(
                            calculateLcMarginForNetEEPriceChange(newData[itemIndex].lastCostExact, newNettEA)
                        ).toFixed(2)
                    );
                    newData[itemIndex].frcMargin = parseFloat(
                        parseFloat(
                            calculateLcMarginForNetEEPriceChange(newData[itemIndex].stEecost, newNettEA)
                        ).toFixed(2)
                    );

                    setItemGridData(newData);
                    updatePartConsolidatedDetailForId(id, { selectedItems: newData });

                    const OrginalItemGridIndex =
                        itemGridData[itemIndex].stId === undefined
                            ? originalItemGridData.findIndex(
                                (item) => item.assemblyId === itemGridData[itemIndex].assemblyId
                            )
                            : originalItemGridData.findIndex((item) => item.stId === itemGridData[itemIndex].stId);
                    if (OrginalItemGridIndex !== -1) {
                        const newOrginalItemGridData = [...originalItemGridData];
                        const previousNettEA = parseFloat(itemGridData[itemIndex].nettEA);
                        // newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = parseFloat(
                        //     calculateDConLPForNetEEPriceChange(unSelectedGridValue[i].listPrice, newNettEA)
                        // ).toFixed(1);
                        newOrginalItemGridData[OrginalItemGridIndex].nettEA = parseFloat(
                            parseFloat(newNettEA).toFixed(2)
                        );
                        newOrginalItemGridData[OrginalItemGridIndex].totalPrice = parseFloat(
                            parseFloat(
                                calculateTotalPriceForNetEEPriceChange(
                                    newOrginalItemGridData[OrginalItemGridIndex].qty,
                                    newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                )
                            ).toFixed(2)
                        );
                        newOrginalItemGridData[OrginalItemGridIndex].repDisc = parseFloat(
                            calculateRepDiscForNetEEPriceChange(
                                parseFloat(newOrginalItemGridData[OrginalItemGridIndex].nettEA),
                                previousNettEA
                            )
                        ).toFixed(1);
                        newOrginalItemGridData[OrginalItemGridIndex].margin = parseFloat(
                            calculateMarginForNetEEPriceChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].actMargin = parseFloat(
                            calculateActMarginForNetEEPriceChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].lcMargin =
                            itemGridData[itemIndex].stId === undefined
                                ? 0
                                : parseFloat(
                                    calculateLcMarginForNetEEPriceChange(
                                        newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                        newOrginalItemGridData[OrginalItemGridIndex].nettEA
                                    )
                                ).toFixed(2);

                        newOrginalItemGridData[OrginalItemGridIndex].frcMargin = parseFloat(
                            calculateLcMarginForNetEEPriceChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);

                        setOriginalItemGridData(newOrginalItemGridData);
                    }
                }
            }
        } else if (e.field === "margin") {
            const itemIndex = itemGridData.findIndex((item) => item.stId === e.dataItem.stId);
            if (itemGridData[itemIndex].rowType !== "A") {
                if (itemIndex !== -1) {
                    //calculations
                    const previousNettEA = itemGridData[itemIndex].nettEA;
                    const listPrice = itemGridData[itemIndex].rowType === 'CI' ? itemGridData[itemIndex].nettEA : itemGridData[itemIndex].listPrice;
                    const newData = [...itemGridData];

                    newData[itemIndex].nettEA = parseFloat(
                        calculateNettEaForMarginChange(newData[itemIndex].stEecost, newData[itemIndex].margin)
                    ).toFixed(2); //parseFloat(calculateNettEaForDConLPChange(listPrice, presentageValue)).toFixed(2);
                    newData[itemIndex].dcOnLp = parseFloat(
                        calculateDConLPForMarginChange(listPrice, newData[itemIndex].nettEA)
                    ).toFixed(1);
                    newData[itemIndex].totalPrice = parseFloat(
                        calculateTotalPriceForMarginChange(newData[itemIndex].qty, newData[itemIndex].nettEA)
                    ).toFixed(2);

                    //ended from here on 06/10/2023
                    newData[itemIndex].repDisc = calculateRepDiscForMarginChange(
                        newData[itemIndex].nettEA,
                        previousNettEA
                    );
                    newData[itemIndex].margin = isNaN(e.value) || e.value === '' ? 0 : parseFloat(e.value);
                    newData[itemIndex].actMargin = parseFloat(
                        calculateActMarginForMarginChange(newData[itemIndex].actCostExact, newData[itemIndex].nettEA)
                    ).toFixed(2);
                    newData[itemIndex].lcMargin = parseFloat(
                        calculateLcMarginForMarginChange(newData[itemIndex].lastCostExact, newData[itemIndex].nettEA)
                    ).toFixed(2);

                    setItemGridData(newData);
                    updatePartConsolidatedDetailForId(id, { selectedItems: newData });

                    const OrginalItemGridIndex = originalItemGridData.findIndex(
                        (item) => item.stId === itemGridData[itemIndex].stId
                    );
                    if (OrginalItemGridIndex !== -1) {
                        const previousNettEA = itemGridData[itemIndex].nettEA;
                        const newOrginalItemGridData = [...originalItemGridData];

                        newOrginalItemGridData[OrginalItemGridIndex].nettEA = parseFloat(
                            calculateNettEaForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].margin
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = parseFloat(
                            calculateDConLPForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].listPrice,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(1);
                        newOrginalItemGridData[OrginalItemGridIndex].totalPrice = parseFloat(
                            calculateTotalPriceForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].qty,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].repDisc = calculateRepDiscForDConLPChange(
                            newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                            previousNettEA
                        );
                        newOrginalItemGridData[OrginalItemGridIndex].margin = isNaN(e.value) || e.value === '' ? 0 : parseFloat(e.value).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].actMargin = parseFloat(
                            calculateActMarginForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].lcMargin = parseFloat(
                            calculateLcMarginForMarginChange(
                                newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);

                        setOriginalItemGridData(newOrginalItemGridData);
                    }
                }
            }
        } else if (e.field === "dcOnLp") {
            const itemIndex = itemGridData.findIndex((item) => item.stId === e.dataItem.stId);

            if (itemIndex !== -1) {
                //calculations
                if (itemGridData[itemIndex].rowType !== "A") {
                    const previousNettEA = itemGridData[itemIndex].nettEA;
                    const listPrice = itemGridData[itemIndex].rowType === 'CI' ? itemGridData[itemIndex].nettEA : itemGridData[itemIndex].listPrice;
                    const newData = [...itemGridData];
                    newData[itemIndex].dcOnLp = isNaN(parseFloat(e.value).toFixed(1)) || e.value === '' ? 0 : parseFloat(e.value).toFixed(1);
                    newData[itemIndex].nettEA = parseFloat(calculateNettEaForDConLPChange(listPrice, e.value)).toFixed(2);
                    newData[itemIndex].totalPrice = parseFloat(
                        calculateTotalPriceForDConLPChange(newData[itemIndex].qty, newData[itemIndex].nettEA)
                    ).toFixed(2);

                    //ended from here on 06/10/2023
                    newData[itemIndex].repDisc = calculateRepDiscForDConLPChange(
                        newData[itemIndex].nettEA,
                        previousNettEA
                    );
                    newData[itemIndex].margin = parseFloat(
                        calculateMarginForDConLPChange(newData[itemIndex].stEecost, newData[itemIndex].nettEA)
                    ).toFixed(2);
                    newData[itemIndex].actMargin = parseFloat(
                        calculateActMarginForDConLPChange(newData[itemIndex].actCostExact, newData[itemIndex].nettEA)
                    ).toFixed(2);
                    newData[itemIndex].lcMargin = parseFloat(
                        calculateLcMarginForDConLPChange(newData[itemIndex].lastCostExact, newData[itemIndex].nettEA)
                    ).toFixed(2);

                    setItemGridData(newData);
                    updatePartConsolidatedDetailForId(id, { selectedItems: newData });
                    const OrginalItemGridIndex = originalItemGridData.findIndex(
                        (item) => item.stId === itemGridData[itemIndex].stId
                    );
                    if (OrginalItemGridIndex !== -1) {
                        const previousNettEA = itemGridData[itemIndex].nettEA;
                        const newOrginalItemGridData = [...originalItemGridData];
                        newOrginalItemGridData[OrginalItemGridIndex].dcOnLp = isNaN(parseFloat(e.value).toFixed(1)) || e.value === '' ? 0 : parseFloat(e.value).toFixed(1);
                        newOrginalItemGridData[OrginalItemGridIndex].nettEA = parseFloat(
                            calculateNettEaForDConLPChange(listPrice, e.value)
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].totalPrice = parseFloat(
                            calculateTotalPriceForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].qty,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].repDisc = calculateRepDiscForDConLPChange(
                            newOrginalItemGridData[OrginalItemGridIndex].nettEA,
                            previousNettEA
                        );
                        newOrginalItemGridData[OrginalItemGridIndex].margin = parseFloat(
                            calculateMarginForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].stEecost,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].actMargin = parseFloat(
                            calculateActMarginForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].actCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);
                        newOrginalItemGridData[OrginalItemGridIndex].lcMargin = parseFloat(
                            calculateLcMarginForDConLPChange(
                                newOrginalItemGridData[OrginalItemGridIndex].lastCostExact,
                                newOrginalItemGridData[OrginalItemGridIndex].nettEA
                            )
                        ).toFixed(2);

                        setOriginalItemGridData(newOrginalItemGridData);
                    }
                }
            }
        }
    };

    return (
        <Container fluid className="ip-dialog-parts">
            <Row>
                <Col xs={12} md={12}>
                    <div className="ip-p-search mb-4">
                        {/* <Input value={projectName} onChange={(e) => handleNameChange(e)} />   */}
                        <Input
                            className="me-1"
                            placeholder="Search Part..."
                            value={partSearchValue}
                            onChange={(e) => {
                                setPartSearchValue(e.value);
                            }}
                            onKeyDown={handlePartSearchKeyPress}
                        />
                        {/* <Col><Button onClick={updateUserTemplateDescription}>Find</Button></Col> */}
                        <Button onClick={findByPartNo}>Find</Button>&nbsp;&nbsp;
                        <Button onClick={showAllInGrid}>Show All</Button>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    Action : <hr />
                    <Col xs={12} className="ip-p-side-by-side">
                        <div className="ip-p-side-by-side radio">
                            <RadioGroup
                                className="ip-p-actionradio"
                                data={tasks}
                                defaultValue={"Discount"}
                                layout={layout}
                                onChange={handleActionSelectionChange}
                            />
                        </div>
                        <div className="ip-p-side-by-side image">
                            <div>
                                <svg
                                    id="fi_8571447"
                                    enable-background="new 0 0 512 512"
                                    height="100"
                                    viewBox="0 0 512 512"
                                    width="100"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="m195 420.8c0-6.3 5.1-11.4 11.4-11.4h15c9.3 0 15.5-2.9 18.4-8.6s4.4-13.4 4.4-23v-74.4c0-14.7 3.7-25.7 11.2-33s17.1-12.5 28.8-15.4v-.8c-11.7-3.5-21.3-9.1-28.8-17s-11.2-19.3-11.2-34.2v-70.4c0-9.9-1.4-17.3-4.2-22.4s-8.9-7.6-18.2-7.6h-15.4c-6.3 0-11.4-5.1-11.4-11.4 0-6.3 5.1-11.4 11.4-11.4h23c5.3 0 10.6.9 15.8 2.6s9.8 4.3 13.8 7.8 7.3 7.9 9.8 13.4 3.8 11.9 3.8 19.4v76c0 13.1 3.9 23.5 11.8 31.4 5.9 5.9 13.4 9.6 22.6 11.3 5.8 1 10 6.2 10 12.1 0 5.9-4.2 11.1-10.1 12.2-9 1.6-16.4 5.2-22.3 10.8-8 7.6-12 18.2-12 31.8v80.8c0 7.5-1.3 13.9-3.8 19.4s-5.8 9.9-9.8 13.4-8.5 6-13.6 7.6-10.3 2.4-15.6 2.4h-23.4c-6.3 0-11.4-5.1-11.4-11.4z"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className="ip-p-side-by-side">
                            <Input
                                value={presentageValue}
                                onChange={(e) => {
                                    setPresentageValue(e.target.value);
                                    setIsApplyDisabled(false);
                                }}
                                onBlur={handlePresentageValueBlur}
                                onKeyDown={handlePresentageValueKeyPress}
                                style={inputStyle}
                                disabled={isInputDisabled}
                            />{" "}
                            &nbsp;%
                        </div>
                    </Col>
                </Col>

                <Col xs={12} md={6} className="mt-2 mt-md-0">
                    On : <hr />
                    <RadioGroup
                        className="mb-2"
                        data={partsSelection}
                        defaultValue={"Unselected Parts"}
                        layout={partsSelectionLayout}
                        onChange={handlePartsSelectionChange}
                    />
                    <Button className="me-2" onClick={applyItemById} disabled={isApplyDisabled}>
                        Apply
                    </Button>
                    <Button onClick={handleUnDeleteAll} disabled={isUndeleteAllDisabled}>
                        Un Deleted All
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="ip-p-note">
                        Note: Nett Price increase or decrease by a % will not be applied to items that have different
                        prices. If there is a price difference, set a price first then apply the nett price % increase
                        or decrease.
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="part-consolidated-grid">
                    <DataGridView
                        columns={columns}
                        data={paginate(itemGridData || [], itemspageNumber, itemspageSize)}
                        //data={itemGridData}
                        selectedRows={selectedItems}
                        setSelectedRows={setSelectedItems}
                        total={itemGridData ? itemGridData.length : 0}
                        showEmptyRow={false}
                        idField="stId"
                        selected={selectedItem} // Set the selected item
                        onSelectionChange={handleRowSelect}
                        isApplyDisabled={isApplyDisabled}
                        handleItemChange={handleGridChange}
                        skip={skipPriceLists}
                        take={takePriceLists}
                        onPageChange={handlePageChangePriceList}
                        isHeaderCheckboxRender={true}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default PartsConsolidatedView;
