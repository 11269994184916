import React, { createContext, useContext, useState } from "react";

const ItemGridQtyRowContext = createContext();

export const useQtyRow = () => useContext(ItemGridQtyRowContext);

export const ItemGridQtyRowProvider = ({ children }) => {
    const [rowQtyData, setQtyRowData] = useState({});

    const updateQtyRowForId = (id, data) => {
        setQtyRowData((r) => ({
            ...r,
            [id]: { ...r[id], ...data },
        }));
    };

    return (
        <ItemGridQtyRowContext.Provider value={{ rowQtyData, setQtyRowData, updateQtyRowForId }}>
            {children}
        </ItemGridQtyRowContext.Provider>
    );
};
