import React, { useState, useEffect, useContext } from 'react';
import { LookupTypes } from '../../enums/LeftNavTypes';
import Lookup from '../Layouts/Lookup';
import { getResponse } from '../../Services/Common/Get'; // adjust the path according to your project structure
import { CustomerCustPriceContext } from '../../Contexts/CustomerCustPriceContext';
import { add } from 'lodash';
import { getCustomerModel } from '../../models/Quotations';
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const selectColumns = [
    { field: 'customerNumber', title: 'Code' },
    { field: 'customerName', title: 'Name' },
    { field: 'endDate', title: 'End Date' },
];

const addColumns = [
    { field: 'customerNumber', title: 'Item Code' }
];

const PLCustomerLookup = ({ onAddItems, optPListTypeTypesId, plistTypeId }) => {  // new prop: onAddItems

    // const apiURL = 'Customer/searchcustomer'; // your API endpoint
    let apiURL = ''; // your API endpoint

    if (optPListTypeTypesId.id !== undefined) {
        if (optPListTypeTypesId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
            //Customer Price (Level 60)
            apiURL = 'Customer/searchcustomerbyl60'
        } else if (optPListTypeTypesId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
            //Industry Price (Level 50)
            apiURL = 'Customer/searchcustomersbyl50'
        }
    }else if (plistTypeId !== undefined) {
        if (plistTypeId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
            //Customer Price (Level 60)
            apiURL = 'Customer/searchcustomerbyl60'
        } else if (plistTypeId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
            //Industry Price (Level 50)
            apiURL = 'Customer/searchcustomersbyl50'
        }
    }

    if (apiURL === '') {
        toast.error("Error: Price List Type not found", {
            position: "top-center",
            transition: Slide,
        });
        return;
    }   



    const filterOptions = selectColumns.map(column => ({ text: column.title, value: column.field }));
    //   const {addItems, setAddItems} = useContext(CustomerCustPriceContext);
    const { addCustomerCustPrice, setCustomerCustPrice } = useContext(CustomerCustPriceContext);
    const handleAddCustomers = (selectedItems) => {
        console.log('Selected items:', selectedItems);
        onAddItems && onAddItems(selectedItems);
        // Add the selected items to the "Items to Add" grid
        setCustomerCustPrice(prevItems => [...prevItems, ...selectedItems]);
    };




    return (
        <Lookup
            type={LookupTypes.CUSTOMER}
            apiURL={apiURL}
            selectColumns={selectColumns}
            addColumns={addColumns}
            idField='customerNumber'
            selectTableName='Pricelist items to add:'
            addTableName='To be added:'
            filterOptions={filterOptions}
            conversionFunction={getCustomerModel}
            onAddItems={handleAddCustomers} // Pass the handleAddItems function as a prop
            onAddItemsToGrid={handleAddCustomers}
        />
    );
};

export default PLCustomerLookup;
