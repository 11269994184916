import { v4 as uuidv4 } from "uuid";

export function getDbExtraRow(data) {
    return {
        stStockCode: data.dbExtraName,
        description: data.dbExtraDescription,
        // type: "DX",
        rowType: "DX",
        listPrice: "",
        l60: "",
        l55: "",
        l50: "",
        l20: "",
        costEA: "",
        costEAExact: "",
        actCost: "",
        actCostExact: "",
        lastCost: "",
        lastCostExact: "",
        qty: 1,
        nettEA: "",
        // margin: 0.0,
        groupName: "",
        groupId: "",
        displayGroupName: "",
        subGroupName: "",
        subGroupId: "",
        displaySubGroupName: "",
        assemblyGroupName: "",
        displayAssemblyGroupName: "",
        AssemblyGroupName: "",
        assemblyId :"",
        stId: uuidv4(),
        totalPrice: 0,
        totalCost: "",
        groupDbExtraId: "",
        subGroupDbExtraId: "",
        nonGroupDbExtraId: "",
        stFutureCost: "",
        stFutureCostRound: "",
        frcMargin: "",
        stEecostRound: "",
        stEecost: "",
        rowId: uuidv4(),
        //added on 21st oct 2023
        // actMargin: 0,
        // customerId: "00000000-0000-0000-0000-000000000000",
        // dcCode: "",
        // dcOnLp: 0,
        // dcRate: 0,
        // dcOnLp: 0,
        // lcMargin: 0,
        // repDisc: 0,
        // soh: 0,
        //added on 21st oct 2023
        actMargin: "",
        customerId: "00000000-0000-0000-0000-000000000000",
        dcCode: "",
        dcOnLp: "",
        dcRate: "",
        dcOnLp: "",
        lcMargin: "",
        repDisc: "",
        soh: "",
        margin: "",
    };
}

export function createGroupRow(groupId, groupCount) {
    const id = uuidv4();
    return {
        groupLevelName: `Group ${groupCount}`,
        stStockCode: "",
        description: "",
        // type: "G",
        rowType: "G",
        listPrice: "",
        l60: "",
        l55: "",
        l50: "",
        l20: "",
        costEA: "",
        costEAExact: "",
        actCost: "",
        actCostExact: "",
        lastCost: "",
        lastCostExact: "",
        qty: 1,
        nettEA: 0,
        margin: 0,
        groupName: `Group ${groupCount}`,
        groupId: groupId,
        displayGroupName: `Group ${groupCount}`,
        subGroupName: "",
        subGroupId: "",
        displaySubGroupName: "",
        assemblyGroupName: "",
        displayAssemblyGroupName: "",
        assemblyId :"",
        stId: uuidv4(),
        totalPrice: 0,
        totalCost: 0,
        groupDbExtraId: "",
        subGroupDbExtraId: "",
        nonGroupDbExtraId: "",
        stFutureCost: "",
        stFutureCostRound: "",
        frcMargin: 0,
        stEecostRound: "",
        stEecost: "",
        rowId: id,
        //added on 21st oct 2023
        actMargin: 0,
        customerId: "00000000-0000-0000-0000-000000000000",
        dcCode: "",
        dcOnLp: 0,
        dcRate: 0,
        dcOnLp: 0,
        lcMargin: 0,
        repDisc: 0,
        soh: 0,
        id: id,
        parentId: null,
        //added on 21st oct 2023
    };
}

export function createSubGroupRow(groupId, groupCount, subGroupId, subGroupCount,parentGroupId) {
    const id = uuidv4();
    return {
        groupLevelName: `Sub Group ${subGroupCount}`,
        stStockCode: "",
        description: "",
        // type: "SG",
        rowType: "SG",
        listPrice: "",
        l60: "",
        l55: "",
        l50: "",
        l20: "",
        costEA: "",
        costEAExact: "",
        actCost: "",
        actCostExact: "",
        lastCost: "",
        lastCostExact: "",
        qty: 1,
        nettEA: 0,
        margin: 0,
        groupName: `Group ${groupCount - 1}`, // affects filtering
        groupId: groupId,
        displayGroupName: "",
        subGroupName: `Sub Group ${subGroupCount}`,
        subGroupId: subGroupId,
        displaySubGroupName: `Sub Group ${subGroupCount}`,
        assemblyGroupName: "",
        displayAssemblyGroupName: "",
        assemblyId :"",
        stId: uuidv4(),
        totalPrice: 0,
        totalCost: 0,
        groupDbExtraId: "",
        subGroupDbExtraId: "",
        nonGroupDbExtraId: "",
        stFutureCost: "",
        stFutureCostRound: "",
        frcMargin: 0,
        stEecostRound: "",
        stEecost: "",
        rowId: id,
        //added on 21st oct 2023
        actMargin: 0,
        customerId: "00000000-0000-0000-0000-000000000000",
        dcCode: "",
        dcOnLp: 0,
        dcRate: 0,
        dcOnLp: 0,
        lcMargin: 0,
        repDisc: 0,
        soh: 0,
        id: id,
        parentId: parentGroupId,
        //added on 21st oct 2023
    };
}

export function createNonSubGroupRow(groupId, groupName, subGroupId, subGroupCount) {
    return {
        stStockCode: "",
        description: "",
        // type: "SG",
        rowType: "SG",
        listPrice: "",
        l60: "",
        l55: "",
        l50: "",
        l20: "",
        costEA: "",
        costEAExact: "",
        actCost: "",
        actCostExact: "",
        lastCost: "",
        lastCostExact: "",
        qty: 1,
        nettEA: 0,
        margin: 0,
        groupName: groupName, // affects filtering
        groupId: groupId,
        displayGroupName: "",
        subGroupName: `Non Sub Group ${subGroupCount}`,
        subGroupId: subGroupId,
        displaySubGroupName: "",//`Non Sub Group ${subGroupCount}`,
        assemblyGroupName: "",
        displayAssemblyGroupName: "",
        assemblyId :"",
        stId: uuidv4(),
        totalPrice: 0,
        totalCost: 0,
        groupDbExtraId: "",
        subGroupDbExtraId: "",
        nonGroupDbExtraId: "",
        stFutureCost: "",
        stFutureCostRound: "",
        frcMargin: 0,
        stEecostRound: "",
        stEecost: "",
        rowId: uuidv4(),
        //added on 21st oct 2023
        actMargin: 0,
        customerId: "00000000-0000-0000-0000-000000000000",
        dcCode: "",
        dcOnLp: 0,
        dcRate: 0,
        dcOnLp: 0,
        lcMargin: 0,
        repDisc: 0,
        soh: 0,
        //added on 21st oct 2023
    };
}

export function createEmptyRow(
    groupId,
    groupName,
    subGroupId,
    subGroupName,
    groupDbExtraId = "",
    subGroupDbExtraId = "",
    nonGroupDbExtraId = "",
    parentGroupId
) {
    const id = uuidv4();
    return {
        groupLevelName: "",
        stStockCode: "",
        description: "",
        type: "",
        rowType: "",
        listPrice: "",
        l60: "",
        l55: "",
        l50: "",
        l20: "",
        costEA: "",
        costEAExact: "",
        stEecost: "",
        stEecostRound: "",
        stFutureCost: "",
        stFutureCostRound: "",
        actCost: "",
        actCostExact: "",
        lastCost: "",
        lastCostExact: "",
        qty: "",
        nettEA: "",
        margin: "",
        rowId: id,
        stId: uuidv4(),
        totalPrice: 0,
        totalCost: 0,
        type: "",
        rowType: "",
        displayGroupName: "",
        displaySubGroupName: "",
        assemblyGroupName: "",
        displayAssemblyGroupName: "",
        assemblyId :"",
        // groupId: groupId,
        groupId: groupId,
        // groupName: `Group ${groupCount - 1}`,
        groupName: groupName,
        subGroupId: subGroupId,
        subGroupName: subGroupName,
        groupDbExtraId: groupDbExtraId,
        subGroupDbExtraId: subGroupDbExtraId,
        nonGroupDbExtraId: nonGroupDbExtraId,
        id: id,
        parentId: parentGroupId,
    };
}

export function getEmptyRow() {
    return {
        stStockCode: "",
        description: "",
        type: "",
        rowType: "",
        listPrice: "",
        l60: "",
        l55: "",
        l50: "",
        l20: "",
        costEA: "",
        costEAExact: "",
        actCost: "",
        actCostExact: "",
        lastCost: "",
        lastCostExact: "",
        qty: "",
        nettEA: "",
        margin: "",
        groupName: "",
        groupId: "",
        displayGroupName: "",
        subGroupName: "",
        subGroupId: "",
        displaySubGroupName: "",
        assemblyGroupName: "",
        displayAssemblyGroupName: "",
        assemblyId :"",
        stId: "",
        totalPrice: "",
        totalCost: "",
        groupDbExtraId: "",
        subGroupDbExtraId: "",
        nonGroupDbExtraId: "",
        stFutureCost: "",
        stFutureCostRound: "",
        frcMargin: "",
        stEecostRound: "",
        stEecost: "",
        rowId: uuidv4(),
        actMargin: "",
        customerId: "",
        dcCode: "",
        dcOnLp: "",
        dcRate: "",
        dcOnLp: "",
        lcMargin: "",
        repDisc: "",
        soh: "",
    };
}
