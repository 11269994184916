export const LeftNavTypes = {
    QUOTES: "quotes",
    NEW_UNITS: "new_units",
    PRICE_LISTS: "price_lists",
    LIBRARIES: "libraries",
};
export const LookupTypes = {
    CUSTOMER: "customer",
    ITEM: "item",
    SOH: "soh",
    PRICELISTS: "priceLists",
};


