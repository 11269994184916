import React, { createContext, useContext, useState } from "react";

const PriceListDetailsContext = createContext();

export const usePriceListDetails = () => useContext(PriceListDetailsContext);

export const PriceListDetailsProvider = ({ children }) => {
    const [priceListDetails, setPriceListDetails] = useState([]);
    const [copiedPriceList, setCopiedPriceList] = useState([]);
    const [isPriceListCopyed, setIsPriceListCopyed] = useState(false);
    const [isPriceListItemSelected, setIsPriceListItemSelected] = useState(false);

    const updatePriceListDetailsForId = (id, data) => {
        setPriceListDetails((prevPriceListDetails) => ({
            ...prevPriceListDetails,
            [id]: { ...prevPriceListDetails[id], ...data },
        }));
    };

    return (
        <PriceListDetailsContext.Provider value={{ priceListDetails, updatePriceListDetailsForId, setPriceListDetails, copiedPriceList, setCopiedPriceList, isPriceListCopyed, setIsPriceListCopyed, isPriceListItemSelected, setIsPriceListItemSelected }}>
            {children}
        </PriceListDetailsContext.Provider>
    );
};